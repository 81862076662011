import React, { Component, Fragment } from 'react';
import Page from '../../../containers/page';

import {
    Container,
    Row,
    Col, Input, Label, Modal, ModalBody, ModalHeader, FormGroup, Button, Table
} from 'reactstrap';
import { Link } from 'react-router-dom';

import EventComponent from './EventComponentt'

import FormBuilder from '../../../components/forms/modalFormBuilder';
import { required, checkSocialSecurityNumber, dateValidate } from '../../../components/forms/validation';
import { connect } from 'react-redux';
import { formValueSelector, change } from 'redux-form';  // ES6
import ListBuilder from '../../../components/listBuilder';
import DeleteModal from '../../../components/deleteModal';
import ErrorModal from '../../../components/errorModal';
import { getSearchParams, generateSearchLink, generateSearchLinkMultiple } from '../../../helpers/linkHelper';
import { API_ENDPOINT, CHILD_AGE_LIMIT } from '../../../constants';

import TimePicker from '../../../components/forms/fields/timepicker';
import TimePickerV2 from '../../../components/forms/fields/timepicker_v2';

import SocialSecurityNumber, { isSocialSecurityNumberValid } from '../../../components/forms/fields/socialSecurityNumber';

import Isvg from 'react-inlinesvg'
import Search from '../../../components/search';
import PatientFilter from '../../../components/patientFilter';
import profileUser from '../../../assets/images/user.png'
import notification from '../../../assets/svg/notification.svg';
import moreIcon from '../../../assets/svg/more.svg';
import warrning from '../../../assets/images/warrning.png';
import xIcon from '../../../assets/svg/x.svg';
import InfoModal from '../../../components/infoModal';

import TriangleIcon from '../../../assets/svg/alert-triangle-icon.svg';

import { Calendar, Day, momentLocalizer } from 'react-big-calendar';
import CustomToolbar from './CustomToolbar';
import "react-big-calendar/lib/css/react-big-calendar.css";
import { Tooltip } from '@material-ui/core';
import { withStyles, makeStyles } from "@material-ui/core/styles";

import Checkbox from '../../../components/forms/fields/checkbox';
import ListCheckBox from '../../../components/forms/fields/listCheckbox'
import DatePicker from '../../../components/forms/fields/datePicker';

import SelectWithCheckbox from '../../../components/forms/fields/selectWithCheckbox'
import CustomSelectWithSearch from '../../../components/forms/fields/customSelectWithSearch';
import openChat from '../../../assets/svg/open_chat.svg';
import waitingRoomIcon from '../../../assets/svg/waiting-room.svg';




import { Player, Controls } from "@lottiefiles/react-lottie-player";

import infoIcon from "../../../assets/svg/link-new-tab.svg";

import multiIcon from "../../../assets/svg/multi.svg";
import FormPreview from '../../forms/previewAnsweredForm';
import ReferralContent from '../../../components/referralContent';
const LightTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: "rgba(58, 63, 85, 0.9)",
        color: "#fff",
        boxShadow: theme.shadows[1],
        fontSize: 14
    }
}))(Tooltip);

function isValidDate(d) {
    return d instanceof Date && !isNaN(d);
}
function validateTime(timeString) {
    const timeRegex = /^([01]?\d|2[0-3]):([0-5]?\d)$/;
    if (timeString && timeRegex.test(timeString)) {
        const [hours, minutes] = timeString.split(":").map((x) => parseInt(x, 10));
        const paddedHours = hours.toString().padStart(2, "0");
        const paddedMinutes = minutes.toString().padStart(2, "0");
        return `${paddedHours}:${paddedMinutes}`;
    }
    return null;
}

import moment from 'moment';
import { faTintSlash } from '@fortawesome/free-solid-svg-icons';
import ModalFooter from 'reactstrap/lib/ModalFooter';
import striptags from 'striptags';
import UserIsNotVerifiedTooltip from '../../../components/UserIsNotVerifiedTooltip';
import { isStringAValidNumber } from '../../../components/TwoFactorAuthModal';
import { validateSocialSecurityNumber } from '../../patients/searchEnterprise';
import AddSecondParentModal from '../../../components/addSecondParentModal';
import PhoneField from '../../../components/forms/fields/phone';

function parseJSON(response) {
    return response.json().then(json => {
        return {
            result: json,
            status: response.status
        }
    })
}


const localizer = momentLocalizer(moment)

function pad(n, width, z) {
    z = z || '0';
    n = n + '';
    return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
}

/**
* @typedef {{
    *  id: string;
    *  title?: string;
    *  status?: string;
    *  fullName: string;
    *  dob: string;
    *  gender: string;
    *  email?: string;
    *  phones?: { phoneType: string; phoneNumber: string; }[];
    *  address?: { address?: string; city?: string; postcode?: string; };
    *  numbers?: { id: string; name: string; value: string; }[];
    *  relatedAccounts?: {
    *  relationshipId: string;
    *  relationshipType: ('FAMILY' | 'EMERGENCY_CONTACT' | 'NEXT_OF_KIN' | 'INSURER' | 'PRACTITIONER' | 'PAYER' | 'REFERRING_CLINICIAN' | 'OTHER' | 'GP' | 'PARENT_GUARDIAN' | 'SOCIAL_WORKER' | 'SPOUSE_PARTNER' | 'PHARMACY' | 'ANAESTHETIST');
    *  relationshipLabel: string;
    *  deleted: boolean;
    *  contactDetails: {
    *   relatedAccountId?: string | null;
    *   source?: string | null;
    *   sourceId?: string | null;
    *   firstName?: string | null;
    *   lastName?: string | null;
    *   phones?: { phoneType: string; phoneNumber: string; }[];
    *   email?: string | null;
    *   address?: string | null;
    *   city?: string | null;
    *   postcode?: string | null;
    *   country?: string | null;
    *   name?: string | null;
    *   contactInfo?: string | null;
    *  };
    * }[];
    * }} SemblePatient
    */


const tcDoctorTitles = ['practitioner', 'performer'];

/**
* Calendar for doctors
* @author   Stefan Pantic, Aleksandar Dabic, Milan Stanojevic
*/
class Wiki extends Component {
    constructor(props) {
        super(props);
        this.player = React.createRef();
        this.player2 = React.createRef();
        this.state = {
            ...props.initialData,
            form: false,
            useState: false,
            params: {
                entries: 10,
                page: 0
            },
            items: [],
            total: 0,
            loading: true,
            activeRevisitForm: 1,
            step: 5,
            referrals: [],
            view: typeof window != 'undefined' && window.innerWidth < 768 ? 'day' : 'month',
            searchItemNumber: 0,
            day: new Date(),
            doctorsList: [],
            errorAdditionalDoctors: null,
            visitReasons: [],
            revisitForms: [],
            revisitFormsSelected: [],
            revisitFormsSelect: [],
            daysWithDuplicate: [],
            journalNoteFormSelected: null,
            webBookingForms: [],
            selectOpen: false,
            creatingEvent: false,
            searchByNameAndEmail: false
            // currentDateForDay: new Date()


        };
        this.state.min = new Date();
        this.state.min.setHours(7, 0, 0)
        this.state.max = new Date();
        this.state.max.setHours(18, 0, 0)
    }
    showPlayer = () => {
        this.setState({
            showPlayer: true,
        });
    };

    hidePlayer = () => {
        this.setState({
            showPlayer: false,
        });
    };
    stopAnimation = () => {
        this.player.current.play();
    };

    setWorkingTimeConfig = () => {
        let obj = {

        };
        obj.min = new Date();
        obj.min.setHours(7, 0, 0)
        obj.max = new Date();
        obj.max.setHours(18, 0, 0)

        obj.min = new Date(this.props.getStringDateTs(Math.floor(new Date(obj.min).getTime() / 1000), 'MM/DD/YYYY HH:mm'))
        obj.max = new Date(this.props.getStringDateTs(Math.floor(new Date(obj.max).getTime() / 1000), 'MM/DD/YYYY HH:mm'))

        if (this.props.uData && this.props.uData.workingTimeConfig && this.props.uData.workingTimeConfig[this.props.selectedGroup] && this.props.uData.workingTimeConfig[this.props.selectedGroup][this.props.selectedClinic]) {
            //alert(true);

            obj = this.props.uData.workingTimeConfig[this.props.selectedGroup][this.props.selectedClinic];

            let minTime = obj.min;
            obj.min = new Date();
            obj.min.setHours(new Date(minTime).getHours(), new Date(minTime).getMinutes(), 0)
            let maxTime = obj.max;
            obj.max = new Date();
            obj.max.setHours(new Date(maxTime).getHours(), new Date(maxTime).getMinutes(), 0)
            obj.freeMin = new Date(obj.min)
            obj.freeMax = new Date(obj.max)
        }

        this.setState({
            ...obj
        })
    }

    getTsWithZeroSec = (ts) => {

        let formatedTs = moment.unix(ts).format('MM/DD/YYYY HH:mm');
        if (formatedTs) {
            ts = Math.floor(new Date(formatedTs).getTime() / 1000)
        }

        return ts;
    }

    get = () => {
        this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));

        for (let i = 0; i < this.props.loadData.length; i++) {
            this.props.loadData[i](window.fetch, this.props.lang, this.props[0].match, this.state.useState ? this.state.params : getSearchParams(this.props[0].location.search, { entries: 10, page: 0, group: this.props.selectedGroup, clinic: this.props.selectedClinic, doctor: this.props.uData._id, filter: this.state.searchVisitReason ? this.state.searchVisitReason : this.state.selectRevisitForm ? this.state.searchRevisitForm : '', day: this.state.day ? new Date(this.props.getStringDateInverseTsV1(Math.floor(new Date(this.state.day).getTime() / 1000 + 60 * 60), 'MM/DD/YYYY HH:mm')) : new Date(this.props.getStringDateInverseTsV1(Math.floor(new Date().getTime() / 1000), 'MM/DD/YYYY HH:mm')), view: this.state.view })).then((data) => {
                this.setState({
                    ...data,
                    loading: null
                }, () => {
                    this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
                })
            })
        }
        for (let i = 0; i < this.props.loadDataWithQuery.length; i++) {
            this.props.loadDataWithQuery[i](window.fetch, this.props.lang, this.props[0].match, this.state.useState ? this.state.params : getSearchParams(this.props[0].location.search, { entries: 10, page: 0, group: this.props.selectedGroup, clinic: this.props.selectedClinic, doctor: this.props.uData._id, filter: this.state.searchVisitReason ? this.state.searchVisitReason : this.state.selectRevisitForm ? this.state.searchRevisitForm : '', day: this.state.day ? new Date(this.props.getStringDateInverseTsV1(Math.floor(new Date(this.state.day).getTime() / 1000 + 60 * 60), 'MM/DD/YYYY HH:mm')) : new Date(this.props.getStringDateInverseTsV1(Math.floor(new Date().getTime() / 1000), 'MM/DD/YYYY HH:mm')), view: this.state.view }), this.props.getStringDateTs).then((data) => { // + 60 * 60 zbog promjene vremenske zone kod zimskog racunanja vremena
                this.setState({
                    ...data,
                    loading: null,
                    daysWithDuplicate: []
                }, () => {
                    // let minDuration = 20;
                    // for (let i = 0; i < this.state.items.length; i++) {
                    //     if (this.state.items[i] && !this.state.items[i].reserved) {
                    //         if (Math.floor((this.state.items[i].endTime - this.state.items[i].startTime) / 60) < minDuration) {
                    //             minDuration = Math.floor((this.state.items[i].endTime - this.state.items[i].startTime) / 60);
                    //         }
                    //     }
                    // }
                    // if (minDuration < 20 && this.state.view != 'month' && this.props[0].location.search.indexOf('fromAllCases') == -1) {
                    //     this.setState({ step: 1 })
                    // } else if (this.state.step === 1) {
                    //     this.setState({ step: 5 })
                    // }
                    if (this.state.view == 'month' && this.state.items && this.state.items.length) {
                        let ITEMS = [...(this.state.items || [])];
                        // if (this.props.hasTakeCare && this.state.takeCareAppointments && Array.isArray(this.state.takeCareAppointments) && this.state.takeCareAppointments?.length) ITEMS = [...ITEMS, ...this.state.takeCareAppointments];
                        let items = ITEMS?.filter(item => item.group.toString() == this.props.selectedGroup.toString() && (item.type != 'temporaryEvent' || (item.type == 'temporaryEvent' && this.state.view == 'month')));
                        let daysWithDuplicate = []
                        for (let i = 0; i < items.length; i++) {
                            if (items[i] && items[i].startTime && !items[i].reserved) {
                                let date = this.props.getStringDateTs(items[i].startTime, 'MM-DD-YYYY')/*moment.unix(items[i].startTime).format('MM-DD-YYYY')*/
                                // let date = moment.unix(items[i].startTime).format('MM-DD-YYYY')
                                if (daysWithDuplicate.filter(el => el == date).length == 0) {
                                    if (items.filter(el => el._id != items[i]._id &&
                                        (
                                            (this.getTsWithZeroSec(items[i].startTime) <= this.getTsWithZeroSec(el.startTime) && this.getTsWithZeroSec(items[i].endTime) >= this.getTsWithZeroSec(el.endTime))
                                            || (this.getTsWithZeroSec(items[i].startTime) <= this.getTsWithZeroSec(el.startTime) && this.getTsWithZeroSec(items[i].endTime) > this.getTsWithZeroSec(el.startTime) && this.getTsWithZeroSec(items[i].endTime) <= this.getTsWithZeroSec(el.endTime))
                                            || (this.getTsWithZeroSec(items[i].startTime) >= this.getTsWithZeroSec(el.startTime) && this.getTsWithZeroSec(items[i].startTime) < this.getTsWithZeroSec(el.endTime) && this.getTsWithZeroSec(items[i].endTime) >= this.getTsWithZeroSec(el.endTime))
                                        )
                                    ).length) {
                                        daysWithDuplicate.push(date)
                                    }
                                }

                            }
                        }
                        if (daysWithDuplicate && daysWithDuplicate.length) {
                            this.setState({ daysWithDuplicate })
                        }

                    }

                    this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
                })
            })
        }
        this.setWorkingTimeConfig();






    }
    fetchFromWebDoc = () => {
        fetch(API_ENDPOINT + '/working-hours/webdoc/bookings/fetch', {
            method: 'POST',
            headers: {
                'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                'content-type': 'application/json'
            },
            body: JSON.stringify({ doctor: this.props.uData._id, group: this.props.selectedGroup, clinic: this.props.selectedClinic })
        }).then(res => res.json()).then((result) => {
            if (result.error) {
                this.setState({
                    error: result.error.translate(this.props.lang)
                })
            } else {
                this.get()

            }
        })
    }
    getDoctors = () => {
        let query = { entries: 10, page: 0, group: this.props.selectedGroup, clinic: this.props.selectedClinic, doctor: this.props.uData._id, filter: this.state.searchVisitReason ? this.state.searchVisitReason : this.state.selectRevisitForm ? this.state.searchRevisitForm : '', day: this.state.day ? this.state.day : new Date(), view: this.state.view };
        let queryFields = {};
        for (var key in query) {
            if (key && key.indexOf('query-') === 0) {
                queryFields[key.replace('query-', '')] = query[key];
            }
        }

        fetch(API_ENDPOINT + '/users/subaccounts/doctors', {
            method: 'POST',
            headers: {
                'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                'content-type': 'application/json'
            },
            body: JSON.stringify({
                page: null,
                entries: null,
                filter: query.filter,
                sortType: query.sortType,
                sortField: query.sortField,
                groupId: query.group,
                clinic: query.clinic,
                query: queryFields
            })
        }).then(res => res.json()).then((result) => {
            if (result) {
                this.setState({ doctors: result })
            }
        })


    }
    getPriceList = () => {
        let query = { entries: 10, page: 0, group: this.props.selectedGroup, clinic: this.props.selectedClinic, doctor: this.props.uData._id, filter: this.state.searchVisitReason ? this.state.searchVisitReason : this.state.selectRevisitForm ? this.state.searchRevisitForm : '', day: this.state.day ? this.state.day : new Date(), view: this.state.view };
        let queryFields = {};
        for (var key in query) {
            if (key && key.indexOf('query-') === 0) {
                queryFields[key.replace('query-', '')] = query[key];
            }
        }

        fetch(API_ENDPOINT + '/users/price-list/all', {
            method: 'POST',
            headers: {
                'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                'content-type': 'application/json'
            },
            body: JSON.stringify({

                group: query.group,
                clinic: query.clinic,
                query: queryFields,
                filter: query.filter
            })
        }).then(res => res.json()).then((result) => {
            if (result) {
                this.setState({ visitReasons: result })
            }
        })

    }
    getRevisitQuestionary = () => {
        let query = { entries: 10, page: 0, group: this.props.selectedGroup, clinic: this.props.selectedClinic, doctor: this.props.uData._id, filter: this.state.searchVisitReason ? this.state.searchVisitReason : this.state.selectRevisitForm ? this.state.searchRevisitForm : '', day: this.state.day ? this.state.day : new Date(), view: this.state.view };
        let queryFields = {};
        for (var key in query) {
            if (key && key.indexOf('query-') === 0) {
                queryFields[key.replace('query-', '')] = query[key];
            }
        }


        fetch(API_ENDPOINT + '/data/revisit-questionary/all', {
            method: 'POST',
            headers: {
                'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                'content-type': 'application/json'
            },
            body: JSON.stringify({

                group: query.group,
                clinic: query.clinic,
                query: queryFields,
                filter: query.filter
            })
        }).then(res => res.json()).then((result) => {
            if (result) {
                this.setState({ revisitForms: result })
            }
        })

    }
    getAllReferrals = () => {
        let query = { entries: 10, page: 0, group: this.props.selectedGroup, clinic: this.props.selectedClinic, doctor: this.props.uData._id, filter: this.state.searchVisitReason ? this.state.searchVisitReason : this.state.selectRevisitForm ? this.state.searchRevisitForm : '', day: this.state.day ? this.state.day : new Date(), view: this.state.view };
        let queryFields = {};
        for (var key in query) {
            if (key && key.indexOf('query-') === 0) {
                queryFields[key.replace('query-', '')] = query[key];
            }
        }


        fetch(API_ENDPOINT + '/referrals/all', {
            method: 'POST',
            headers: {
                'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                'content-type': 'application/json'
            },
            body: JSON.stringify({
                doctor: query.doctor,
                group: query.group,
                clinic: query.clinic,
                sortType: query.sortType,
                sortField: query.sortField,
                lang: lang
            })
        }).then(res => res.json()).then((result) => {
            if (result) {
                this.setState({ referrals: result })
            }
        })


    }


    initialGet = () => {

        this.getDoctors()
        this.getPriceList()
        this.getRevisitQuestionary()
        this.getAllReferrals()


    }
    checkToday = () => {
        if (typeof window !== 'undefined') {
            let check = document.getElementsByClassName('rbc-now');
            if (check && check.length) {
                for (let i = 0; i < check.length; i++) {
                    if (check && check[i] && check[i].children && check[i].children[0] && check[i].children[0].innerHTML) {
                        let calendarToday = Number(check[i].children[0].innerHTML);
                        let today = this.props.getStringDateTs(Math.floor(new Date().getTime() / 1000), 'DD')
                        if (today) {
                            today = Number(today);
                        }
                        if (calendarToday !== today) {
                            document.getElementsByClassName('rbc-now')[i].className = document.getElementsByClassName('rbc-now')[i].className.replace(/rbc-now/, '');
                        }
                    }
                }
            }


            let checkToday = document.getElementsByClassName('rbc-today');
            if (checkToday && checkToday.length) {
                for (let i = 0; i < checkToday.length; i++) {
                    if (checkToday && checkToday[i] && checkToday[i].className && checkToday[i].className.indexOf('rbc-today') !== -1) {
                        let checkString = `date_${this.props.getStringDateTs(Math.floor(new Date().getTime() / 1000), 'YYYY-MM-DD')}_date`;
                        if (checkToday[i].className.indexOf(checkString) === -1) {
                            document.getElementsByClassName('rbc-today')[i].className = document.getElementsByClassName('rbc-today')[i].className.replace(/rbc-today/, '');
                        }

                    }
                }
            }


        }

    }
    handleResize = () => {
        if (this.checkTodayTimeout) {
            clearTimeout(this.checkTodayTimeout)
        }
        this.checkTodayTimeout = setTimeout(() => {
            this.checkToday()
        }, 400);
    }
    componentDidMount() {
        if (typeof window !== 'undefined') { window.scrollTo(0, 0); }

        this.getClinicGroupSettings();
        window.addEventListener('resize', this.handleResize)

        this.get();
        this.initialGet()
        // this.getTakeCareAppointments?.();

        // this.fetchFromWebDoc()
        // this.fetchWebDocInterval = setInterval(() => {
        //     this.fetchFromWebDoc()
        // }, 1000 * 30);



        // if(this.state.items){
        //     this.setState({ events: this.state.items })
        // }
        if (this.props[0].location && this.props[0].location.state && this.props[0].location.state.date && this.state.day && this.state.view) {
            // console.log(this.props[0].location.state.date)
            this.setState({
                day: new Date(this.props[0].location.state.date),
                view: 'day',
                scroll: true
            }, () => this.get())

        }
        document.addEventListener('mousedown', this.handleClick);

        if (this.props.uData && this.props.socket && !this.state._registerEvents) {
            this.setState({
                _registerEvents: true
            }, () => {
                this.registerEvents();


            })
        } else {
            this.socketInterval = setInterval(() => {
                if (this.props.uData && this.props.socket && !this.state._registerEvents) {
                    if (this.socketInterval) {
                        clearInterval(this.socketInterval)
                    }

                    this.setState({
                        _registerEvents: true
                    }, () => {
                        this.registerEvents();


                    })
                }

            }, 150);
        }
    }
    registerEvents = async () => {

        this.props.socket.on('newEventCreated', (data) => {
            if (data?.error && this.scheduleControlApiCalled) {
                this.setState({ error: data.error.translate(this.props.lang) })
            }
            this.get()
            this.getAllReferrals()
        })

        this.props.socket?.on('isPatientInWaitingRoom', (data) => {
            if (data?.patientId) {
                let waitingRoom = {
                    patientId: data.patientId,
                    patientInWaitingRoom: data.patientInWaitingRoom,
                    patientHasActiveVideoCall: data.activeCall,
                    showInfoWaitingRoom: !data.activeCall && data.patientInWaitingRoom
                }
                this.setState({ waitingRoom })
            } else {
                if (this.state.waitingRoom) {
                    this.setState({ waitingRoom: null })
                }
            }

        })


    }
    handleClick = (event) => {
        if (this.state.view == 'month' && event && event.target && event.target.className && event.target.className.indexOf) {
            // console.log(event.target.className)
            if (event.target.className.indexOf('rbc-date-cell') != -1) {
                if (event.target.children && event.target.children[0] && event.target.children[0].innerHTML) {
                    let day = new Date(this.state.day);
                    day.setDate(1)
                    if (event.srcElement && event.srcElement.className, event.srcElement && event.srcElement.className.indexOf('rbc-off-range') != -1) {
                        if (Number(event.target.children[0].innerHTML) && Number(event.target.children[0].innerHTML) > 20) {
                            day.setMonth(day.getMonth() - 1)
                        } else {
                            day.setMonth(day.getMonth() + 1)
                        }
                    }
                    day.setDate(Number(event.target.children[0].innerHTML))
                    this.setState({ view: 'day', day: day })
                }

            } else if (event.target.className.indexOf('rbc-day-bg') != -1) {
                let split = event.target.className.split('date_')
                if (split[1]) {
                    let split2 = split[1].split('_date')
                    if (split2[0]) {
                        let day = new Date(split2[0])
                        if (split2[0] && split2[0].split('-') && split2[0].split('-').length > 2) {
                            let dayString = split2[0].split('-')[split2[0].split('-').length - 1]
                            let monthString = split2[0].split('-')[split2[0].split('-').length - 2]
                            if (dayString && Number(dayString))
                                day.setDate(Number(dayString))
                            if (monthString && Number(monthString))
                                day.setMonth(Number(monthString) - 1)
                        }
                        this.setState({ view: 'day', day: day })

                    }

                }
            }
        }

    }

    checkValidateParent2 = () => {
        let parent2Required = false;
        let socialSecurityNumberParent2 = this.props.socialSecurityNumberParent2;
        if (socialSecurityNumberParent2 === '000000000000') {
            socialSecurityNumberParent2 = null;
        }
        if (socialSecurityNumberParent2 || this.props.email2 || this.props.phone2 || this.props.nameParent2) {
            parent2Required = true;
        }
        if (this.state.parent2Required !== parent2Required) {
            this.setState({ parent2Required })
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if ((!prevProps?.selectedGroup && this.props.selectedGroup) || (prevProps?.selectedGroup !== this.props.selectedGroup)) {
            this.getClinicGroupSettings();
        }
        this.checkToday()

        if (prevProps[0].location.search != this.props[0].location.search) {
            this.setState({
                loading: true
            }, () => {
                this.get();

            })
        }
        if (prevProps[0].location.pathname != this.props[0].location.pathname) {
            // if (this.fetchWebDocInterval) {
            //     clearInterval(this.fetchWebDocInterval)
            // }
            // this.fetchFromWebDoc()
            // this.fetchWebDocInterval = setInterval(() => {
            //     this.fetchFromWebDoc()
            // }, 1000 * 30);
        }

        if (prevProps.selectedGroup != this.props.selectedGroup || prevProps.selectedClinic != this.props.selectedClinic) {
            this.setWorkingTimeConfig();
            this.get();
            this.initialGet()
        }

        // if ((!prevProps?.selectedGroup && this.props?.selectedGroup) || (prevProps?.selectedGroup !== this.props?.selectedGroup) || (!prevProps?.selectedClinic && this.props?.selectedClinic) || (prevProps?.selectedClinic !== this.props?.selectedClinic) || (!prevProps?.uData && this.props?.uData) || (String(prevProps?.uData?._id) !== String(this.props?.uData?._id)) || (!prevProps?.hasTakeCare && this.props?.hasTakeCare)) {
        //     this.setState({ takeCareAppointments: null }, () => {
        //         this.getTakeCareAppointments?.();
        //     })
        // }

        if (this.props[0].location && this.props[0].location.state && this.props[0].location.state.day) {
            this.setState({
                day: this.props[0].location.state.day,
                view: 'day',
                scroll: true,
            }, () => {
                this.props[0].history.push({
                    search: '?fromAllCases=true',
                    state: {
                        doctor: null,
                        day: null,
                        step: null,


                    }
                })
                this.get()
            })
        }

        // if(this.state.scroll && !prevState.scroll){
        //     setTimeout(() => {
        //         this.setState({
        //             scroll: false
        //         })
        //     }, 100)

        // }
        if (prevProps.socialSecurityNumberParent != this.props.socialSecurityNumberParent && this.props.socialSecurityNumberParent && (this.props.socialSecurityNumberParent.length == 12 || (this.props.country === 'denmark' && this.props.socialSecurityNumberParent.length == 10))) {
            this.checkUser();
        }

        if (prevProps.socialSecurityNumber !== this.props.socialSecurityNumber) {
            if ((this.props.country === 'denmark' && this.props.socialSecurityNumber?.length === 10) || this.props.socialSecurityNumber?.length === 12) {
                let dateOfBirth = this.props.getDateOfBirthFromSSN(this.props.socialSecurityNumber);
                if (dateOfBirth && this.props.changeDateOfBirth) {
                    this.props.changeDateOfBirth(dateOfBirth)
                    if (!this.state.disabledDateOfBirth) { this.setState({ disabledDateOfBirth: true }) }
                } else {
                    if (this.state.disabledDateOfBirth) { this.setState({ disabledDateOfBirth: false }) }
                }
            } else {
                if (this.state.disabledDateOfBirth) { this.setState({ disabledDateOfBirth: false }) }
            }
        }
        if (prevProps.socialSecurityNumberParent !== this.props.socialSecurityNumberParent) {

            if ((this.props.country === 'denmark' && this.props.socialSecurityNumberParent?.length === 10) || this.props.socialSecurityNumberParent?.length === 12) {
                let dateOfBirth = this.props.getDateOfBirthFromSSN(this.props.socialSecurityNumberParent);
                if (dateOfBirth && this.props.changeDateOfBirthParent) {
                    this.props.changeDateOfBirthParent(dateOfBirth)
                    if (!this.state.disabledDateOfBirthParent) { this.setState({ disabledDateOfBirthParent: true }) }
                } else {
                    if (this.state.disabledDateOfBirthParent) { this.setState({ disabledDateOfBirthParent: false }) }
                }
            } else {
                if (this.state.disabledDateOfBirthParent) { this.setState({ disabledDateOfBirthParent: false }) }
            }
        }

        if (prevProps.socialSecurityNumberParent2 != this.props.socialSecurityNumberParent2 && this.props.socialSecurityNumberParent2 && ((this.props.country === 'denmark' && this.props.socialSecurityNumberParent2.length == 10) || this.props.socialSecurityNumberParent2.length == 12)) {
            this.checkUser2();
        }
        if (prevProps.socialSecurityNumberParent2 !== this.props.socialSecurityNumberParent2) {

            if ((this.props.country === 'denmark' && this.props.socialSecurityNumberParent2?.length === 10) || this.props.socialSecurityNumberParent2?.length === 12) {
                let dateOfBirth = this.props.getDateOfBirthFromSSN(this.props.socialSecurityNumberParent2);
                if (dateOfBirth && this.props.changeDateOfBirthParent) {
                    this.props.changeDateOfBirthParent2(dateOfBirth)
                    if (!this.state.disabledDateOfBirthParent2) { this.setState({ disabledDateOfBirthParent2: true }) }
                } else {
                    if (this.state.disabledDateOfBirthParent2) { this.setState({ disabledDateOfBirthParent2: false }) }
                }
            } else {
                if (this.state.disabledDateOfBirthParent2) { this.setState({ disabledDateOfBirthParent2: false }) }
            }
        }

        if ((prevProps.socialSecurityNumberParent2 !== this.props.socialSecurityNumberParent2) || (prevProps.email2 !== this.props.email2) || (prevProps.phone2 !== this.props.phone2) || (prevProps.nameParent2 !== this.props.nameParent2)) {
            this.checkValidateParent2()
        }

        if (prevState.updateEvent !== this.state.updateEvent) {
            if (this.waitingRoomInterval) clearInterval(this.waitingRoomInterval)
            this.checkWaitingRoom()
            this.waitingRoomInterval = setInterval(() => {
                this.checkWaitingRoom()
            }, 5000);
        }

    }
    checkWaitingRoom = () => {

        if (this.state.updateEvent?.patientId) {

            let patientId = this.state.updateEvent?.patientId
            let data = {
                patientId: patientId,
                groupId: this.props.selectedGroup
            }
            this.props.socket.emit('isPatientInWaitingRoom', data);

        } else {
            if (this.waitingRoomInterval) clearInterval(this.waitingRoomInterval)
            if (this.state.waitingRoom) {
                this.setState({ waitingRoom: null })
            }
        }


    }
    checkUser2 = () => {
        fetch(API_ENDPOINT + '/users/subaccounts/get', {
            method: 'POST',
            headers: {
                'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                'content-type': 'application/json'
            },
            body: JSON.stringify({ socialSecurityNumber: this.props.socialSecurityNumberParent2 })
        }).then(parseJSON).then(({ result, status }) => {
            if (status == 200 && result && result.userData) {

                // console.log(result)
                // if (result.groupPermissions && result.groupPermissions[this.props.selectedGroup] && result.groupPermissions[this.props.selectedGroup][this.props.selectedClinic] && result.groupPermissions[this.props.selectedGroup][this.props.selectedClinic].indexOf(this.state.doctorIdPermissions) != -1) {
                //     this.setState({ doctorPermissions: true })
                // }

                // this.setState({
                //     form: result
                // }, () => {
                //     if (!result.email) {
                //         this.setState({ changeEmail: true })
                //     }
                this.props.changeNameParent2(result.name);
                this.props.changeEmail2(result.email);
                if (result.userData.sex)
                    this.props.changeSexParent2(result.userData.sex);
                this.props.changeLanguage2(result.language);
                this.props.changePhone2(result.phone);
                // this.setState({ lockFields: true })
                //     })

                // }
                // if (status == 405) {
                //     this.setState({
                //         error: 'The user is forbidden!'.translate(this.props.lang)
                //     })
                //     this.props.changeSocialSecurityNumber('')
            } else {
                this.props.changeNameParent2('');
                this.props.changeEmail2('');
                this.props.changeSexParent2('');
                this.props.changeLanguage2('');
                this.props.changePhone2('');
            }
        })

    }
    checkUser = () => {
        fetch(API_ENDPOINT + '/users/subaccounts/get', {
            method: 'POST',
            headers: {
                'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                'content-type': 'application/json'
            },
            body: JSON.stringify({ socialSecurityNumber: this.props.socialSecurityNumberParent })
        }).then(parseJSON).then(({ result, status }) => {
            if (status == 200 && result && result.userData) {

                this.props.changeNameParent(result.name);
                this.props.changeEmail(result.email);
                if (result.userData.sex)
                    this.props.changeSexParent(result.userData.sex);
                this.props.changeLanguage(result.language);
                this.props.changePhone(result.phone);
            } else {
                this.props.changeNameParent('');
                this.props.changeEmail('');
                this.props.changeSexParent('');
                this.props.changeLanguage('');
                this.props.changePhone('');
            }
        })

    }
    componentWillUnmount = () => {
        if (this.waitingRoomInterval) clearInterval(this.waitingRoomInterval)

        if (this.props.socket) {
            this.props.socket.removeListener('newEventCreated');
            this.props.socket.removeListener('isPatientInWaitingRoom');
        }
        if (this.fetchWebDocInterval) {
            clearInterval(this.fetchWebDocInterval)
        }

        document.removeEventListener('mousedown', this.handleClick);

        document.removeEventListener('resize', this.handleResize);
        if (this.checkTodayTimeout) {
            clearTimeout(this.checkTodayTimeout)
        }
    }


    updateSort = (field, type) => {
        if (this.state.useState) {
            let state = this.state.params;
            state.sortField = field;
            state.sortType = type;
            this.setState({
                params: state
            })
        } else {
            this.props[0].history.push(generateSearchLinkMultiple(this.props[0].location.search, { entries: 10, page: 0 }, [{ name: 'sortField', value: field }, { name: 'sortType', value: type }], false));

        }
    }

    updateParams = (name, value, restart = false) => {
        if (this.state.useState) {
            if (restart) {
                let obj = {
                    entries: 10,
                    page: 0
                };
                obj[name] = value;
                this.setState({ params: obj });
            } else {
                let obj = this.state.params;
                obj[name] = value;
                this.setState({ params: obj })
            }
        } else {
            this.props[0].history.push(generateSearchLink(this.props[0].location.search, { entries: 10, page: 0 }, name, value, restart));
        }
    }
    updateMultipleParams = (fields, restart = false) => {

        if (this.state.useState) {
            if (restart) {
                let obj = {
                    entries: 10,
                    page: 0
                };
                for (let i = 0; i < fields.length; i++) {
                    obj[fields[i].name] = fields[i].value;
                }
                this.setState({ params: obj });

            } else {
                let obj = this.state.params;
                for (let i = 0; i < fields.length; i++) {
                    obj[fields[i].name] = fields[i].value;
                }
                this.setState({ params: obj })
            }
        } else {
            this.props[0].history.push(generateSearchLinkMultiple(this.props[0].location.search, { entries: 10, page: 0 }, fields, restart));
        }
    }
    checkRecurring = (data) => {
        let obj = { ...data }
        let recurringObj = {};
        if (obj.frequencyType) {
            recurringObj.frequencyType = obj.frequencyType;
            delete obj.frequencyType;
        }
        if (obj.frequencyDayText) {
            if (recurringObj.frequencyType == 'day')
                recurringObj.frequencyDayText = obj.frequencyDayText;
            delete obj.frequencyDayText;
        }
        if (obj.frequencyMonthScheduled) {
            if (recurringObj.frequencyType == 'month')
                recurringObj.frequencyMonthScheduled = obj.frequencyMonthScheduled;
            delete obj.frequencyMonthScheduled;
        }
        if (obj.frequencyDayScheduledDateFrom) {
            if (recurringObj.frequencyType == 'day')
                recurringObj.frequencyDayScheduledDateFrom = obj.frequencyDayScheduledDateFrom;
            delete obj.frequencyDayScheduledDateFrom;
        }
        if (obj.frequencyDayScheduledDateTo) {
            if (recurringObj.frequencyType == 'day')
                recurringObj.frequencyDayScheduledDateTo = obj.frequencyDayScheduledDateTo;
            delete obj.frequencyDayScheduledDateTo;
        }
        if (obj.frequencyMonthScheduledDateFrom) {
            if (recurringObj.frequencyType == 'month')
                recurringObj.frequencyMonthScheduledDateFrom = obj.frequencyMonthScheduledDateFrom;
            delete obj.frequencyMonthScheduledDateFrom;
        }
        if (obj.frequencyMonthScheduledDateTo) {
            if (recurringObj.frequencyType == 'month')
                recurringObj.frequencyMonthScheduledDateTo = obj.frequencyMonthScheduledDateTo;
            delete obj.frequencyMonthScheduledDateTo;
        }
        if (obj.frequencyWeekScheduledDateFrom) {
            if (recurringObj.frequencyType == 'week')
                recurringObj.frequencyWeekScheduledDateFrom = obj.frequencyWeekScheduledDateFrom;
            delete obj.frequencyWeekScheduledDateFrom;
        }
        if (obj.frequencyWeekScheduledDateTo) {
            if (recurringObj.frequencyType == 'week')
                recurringObj.frequencyWeekScheduledDateTo = obj.frequencyWeekScheduledDateTo;
            delete obj.frequencyWeekScheduledDateTo;
        }
        if (obj.checkedMonday) { if (recurringObj.frequencyType == 'week') recurringObj.checkedMonday = obj.checkedMonday; delete obj.checkedMonday; }
        if (obj.checkedTuesday) { if (recurringObj.frequencyType == 'week') recurringObj.checkedTuesday = obj.checkedTuesday; delete obj.checkedTuesday; }
        if (obj.checkedWednesday) { if (recurringObj.frequencyType == 'week') recurringObj.checkedWednesday = obj.checkedWednesday; delete obj.checkedWednesday; }
        if (obj.checkedThursday) { if (recurringObj.frequencyType == 'week') recurringObj.checkedThursday = obj.checkedThursday; delete obj.checkedThursday; }
        if (obj.checkedFriday) { if (recurringObj.frequencyType == 'week') recurringObj.checkedFriday = obj.checkedFriday; delete obj.checkedFriday; }
        if (obj.checkedSaturday) { if (recurringObj.frequencyType == 'week') recurringObj.checkedSaturday = obj.checkedSaturday; delete obj.checkedSaturday; }
        if (obj.checkedSunday) { if (recurringObj.frequencyType == 'week') recurringObj.checkedSunday = obj.checkedSunday; delete obj.checkedSunday; }

        obj.recurringObj = recurringObj;
        return obj;
    }

    /**
    * Schedule doctor events on calendar
    * @author   Stefan Pantic
    * @Objectparam    {String} date           date when event start
    * @Objectparam    {Number} startTime      start time for event
    * @Objectparam    {Number} endTime        start time for event
    * @Objectparam    {String} doctor         doctor id
    * @Objectparam    {String} group          group id
    * @Objectparam    {String} clinic         clinic id
    * @Objectparam    {String} title          title for event
    */
    scheduleEvent = (data) => {
        let date = data.date;
        if (data.recurring) {
            if (data.frequencyType == 'day') {
                date = data.frequencyDayScheduledDateFrom;
            } else if (data.frequencyType == 'week') {
                date = data.frequencyWeekScheduledDateFrom;
            } else if (data.frequencyType == 'month') {
                date = data.frequencyMonthScheduledDateFrom;
            }
        }
        let checkDate = new Date(date.replace(/-/g, "/") + ' ' + data.startTime);
        let today = new Date();
        if (data && data.recurring) {
            data = this.checkRecurring(data);
        }
        if (checkDate >= today) {
            this.setState({
                loadingScheduleEvent: true
            }, () => {

                fetch(API_ENDPOINT + '/working-hours/new', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        startEventReservedDateOriginal: data.date + ' ' + data.startTime,
                        endEventReservedDateOriginal: data.date + ' ' + data.endTime,
                        startEventReservedDate: this.props.getStringDateInverseTs(Math.floor(new Date(data.date + ' ' + data.startTime).getTime() / 1000), `${this.props.dateFormat} HH:mm`),
                        endEventReservedDate: this.props.getStringDateInverseTs(Math.floor(new Date(data.date + ' ' + data.endTime).getTime() / 1000), `${this.props.dateFormat} HH:mm`),
                        doctor: this.props.uData._id,
                        title: data.title,
                        group: this.props.selectedGroup,
                        clinic: this.props.selectedClinic,
                        recurring: data.recurring,
                        recurringObj: data.recurringObj,
                        title: data.title ? data.title : 'Event'
                    })
                }).then(res => res.json()).then((result) => {
                    if (result.error) {
                        this.setState({
                            error: result.error.translate(this.props.lang),
                            loadingscheduleEvent: false
                        })
                    } else {
                        // this.get()
                        this.setState({
                            scheduleModal: null,
                            scheduleEvent: null,
                            schedulePatientTime: null,
                            recurringModal: null,
                            // view: 'week',
                            day: new Date(data.date.replace(/-/g, "/") + ' ' + data.startTime),
                            scroll: true,
                            loadingScheduleEvent: false
                        }, () => {
                            this.get()
                        })
                    }

                    this.setState({
                        creatingEvent: false
                    })

                })
            })
        } else {
            this.setState({
                wrongDate: true,
                creatingEvent: false
            })
        }

    }
    markAsAvailable = (data) => {
        let date = data.date;
        if (data.recurring) {
            if (data.frequencyType == 'day') {
                date = data.frequencyDayScheduledDateFrom;
            } else if (data.frequencyType == 'week') {
                date = data.frequencyWeekScheduledDateFrom;
            } else if (data.frequencyType == 'month') {
                date = data.frequencyMonthScheduledDateFrom;
            }
        }
        let checkDate = new Date(date.replace(/-/g, "/") + ' ' + data.startTime);
        let today = new Date();

        if (data && data.recurring) {
            data = this.checkRecurring(data);
        }

        if (checkDate >= today) {
            this.setState({
                loading: true
            }, () => {

                fetch(API_ENDPOINT + '/working-hours/patient-time-available/new', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        startEventReservedDateOriginal: data.date + ' ' + data.startTime,
                        endEventReservedDateOriginal: data.date + ' ' + data.endTime,
                        startEventReservedDate: this.props.getStringDateInverseTs(Math.floor(new Date(data.date + ' ' + data.startTime).getTime() / 1000), `${this.props.dateFormat} HH:mm`),
                        endEventReservedDate: this.props.getStringDateInverseTs(Math.floor(new Date(data.date + ' ' + data.endTime).getTime() / 1000), `${this.props.dateFormat} HH:mm`),
                        doctor: this.props.uData._id,
                        group: this.props.selectedGroup,
                        clinic: this.props.selectedClinic,
                        recurring: data.recurring,
                        recurringObj: data.recurringObj,
                        title: data.title ? data.title : 'Available for booking',
                        allActiveForms: data.allActiveForms ? data.allActiveForms : false,
                        eventForms: data.eventForms ? data.eventForms : []

                    })
                }).then(res => res.json()).then((result) => {
                    if (result.error) {
                        this.setState({
                            error: result.error.translate(this.props.lang),
                            loading: false,
                        })
                    } else {
                        // this.get()
                        this.setState({
                            scheduleModal: null,
                            markAsAvailable: null,
                            recurringModal: null,
                            schedulePatientTime: null,
                            // view: 'week',
                            day: new Date(data.date.replace(/-/g, "/") + ' ' + data.startTime),
                            scroll: true,
                            loading: false

                        }, () => {
                            this.get()
                        })
                    }

                    this.setState({
                        creatingEvent: false
                    })

                })
            })
        } else {
            this.setState({
                wrongDate: true,
                creatingEvent: false
            })
        }
    }

    /**
    * Schedule control events for patients on calendar
    * @author   Stefan Pantic
    * @Objectparam    {String} date                 date when event start
    * @Objectparam    {Number} startTime            start time for event
    * @Objectparam    {Number} endTime              start time for event
    * @Objectparam    {String} doctor               doctor id
    * @Objectparam    {String} group                group id
    * @Objectparam    {String} clinic               clinic id
    * @Objectparam    {String} title                title for event
    * @Objectparam    {String} socialSecurityNumber patient socialSecurityNumber
    * @Objectparam    {String} patientControlNote   event note
    */
    scheduleControl = (data) => {
        let checkDate = new Date(data.date.replace(/-/g, "/") + ' ' + data.startTime);
        let today = new Date();


        data.journalNoteFormSelected = this.state.journalNoteFormSelected;
        // console.log(data)
        if (data.controlSchedule) {
            data.patientControlNote = data.patientNote;
            data.title = data.controlSchedule.event && data.controlSchedule.event.title;
            data.socialSecurityNumber = data.controlSchedule.patient ? data.controlSchedule.patient.socialSecurityNumber : '';
            data.visitReasonOnline = data.controlSchedule.event && data.controlSchedule.event.visitReasonOnline;
            data.videoCall = data.controlSchedule.event && data.controlSchedule.event.videoCall;
            data.titleDoctor = '';
            data.revisitForms = data.controlSchedule.event && data.controlSchedule.event.revisitForms;
            data.visitReasonName = data.controlSchedule.event && data.controlSchedule.event.visitReasonName;
            data.visitReasonPrice = data.controlSchedule.event && data.controlSchedule.event.questionaryPrice ? data.controlSchedule.event.questionaryPrice : 0;
            data.visiReason = data.controlSchedule.event && data.controlSchedule.event.visiReason;
            data.hideTimeFromThePatient = data.controlSchedule.event && data.controlSchedule.event.hideTimeFromThePatient;
            data.closeCaseManually = data.controlSchedule.event && data.controlSchedule.event.closeCaseManually;
            data.referralExists = data.controlSchedule ? data.controlSchedule._id : '';
            data.appointmentPaid = data.controlSchedule.event.appointmentPaid ? data.controlSchedule.event.appointmentPaid : false;
            data.revisitFormsArray = data.controlSchedule.event.revisitFormsArray ? data.controlSchedule.event.revisitFormsArray : [];
            data.journalNoteFormSelected = data.controlSchedule.event.journalNoteFormSelected ? data.controlSchedule.event.journalNoteFormSelected : null;
            data.visitReasonOnline = data.controlSchedule.event.onlineVisit ? data.controlSchedule.event.onlineVisit : false;
            data.selectedParents = data.controlSchedule?.event?.selectedParents ?? null;
            data.rescheduleRevisit = data.rescheduleRevisit ? true : false;

        }


        if (checkDate >= today) {
            this.setState({
                loading: true
            }, () => {
                this.scheduleControlApiCalled = true;
                fetch(API_ENDPOINT + '/working-hours/control', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        startEventReservedDateOriginal: data.date + ' ' + data.startTime,
                        endEventReservedDateOriginal: data.date + ' ' + data.endTime,
                        startEventReservedDate: this.props.getStringDateInverseTs(Math.floor(new Date(data.date + ' ' + data.startTime).getTime() / 1000), `${this.props.dateFormat} HH:mm`),
                        endEventReservedDate: this.props.getStringDateInverseTs(Math.floor(new Date(data.date + ' ' + data.endTime).getTime() / 1000), `${this.props.dateFormat} HH:mm`),
                        title: data.title,
                        socialSecurityNumber: data.socialSecurityNumber,
                        doctor: this.props.uData._id,
                        group: this.props.selectedGroup,
                        clinic: this.props.selectedClinic,
                        patientControlNote: data.patientControlNote,
                        onlineVisit: data.visitReasonOnline,
                        videoCall: data.videoCall,
                        additionalDoctors: data.additionalDoctors,
                        titleDoctor: data.titleDoctor,
                        revisitForms: data.revisitForms ? data.revisitForms : this.state.revisitFormsSelected,
                        visitReasonName: data.visitReasonName,
                        visitReasonPrice: data.visitReasonPrice,
                        visiReason: data.visiReason,
                        freePayment: data.freePayment,
                        hideTimeFromThePatient: data.hideTimeFromThePatient,
                        closeCaseManually: data.closeCaseManually,
                        referralExists: data.referralExists ? data.referralExists : null,
                        appointmentPaid: data.appointmentPaid ? data.appointmentPaid : false,
                        revisitFormsArray: data.revisitFormsArray ? data.revisitFormsArray : [],
                        journalNoteFormSelected: data.journalNoteFormSelected,
                        rescheduleRevisit: data.rescheduleRevisit,
                        selectedParents: data.selectedParents

                    })
                }).then(res => res.json()).then((result) => {
                    if (result.error) {
                        this.setState({
                            error: result.error.translate(this.props.lang),
                            loading: false
                        })
                    } else {
                        if (data.controlSchedule) {
                            // this.get();
                            this.setState({
                                scheduleReferral: null,
                                // view: 'week',
                                day: new Date(data.date.replace(/-/g, "/") + ' ' + data.startTime),
                                scroll: true,
                                doctorsList: [],
                                referralId: null
                            }, () => this.get())
                        } else {
                            // this.get()
                            this.setState({
                                scheduleModal: null,
                                referralId: null,
                                scheduleControl: null,
                                patient: null,
                                age: null,
                                revisitFormsSelected: [],
                                revisitFormsSelect: [],
                                journalNoteFormSelected: null,
                                // view: 'week',
                                day: new Date(data.date.replace(/-/g, "/") + ' ' + data.startTime),
                                scroll: true,
                                doctorsList: []

                            }, () => {
                                this.get()
                            })
                        }

                    }
                    this.setState({
                        creatingEvent: false
                    })

                })
            })
        } else {
            this.setState({
                wrongDate: true,
                creatingEvent: false
            })
        }

    }

    /**
    * Select time range on calendar
    * @author   Stefan Pantic, Aleksandar Dabic
    * @Objectparam    {Object} event        data from calendar range
    */
    handleSelect = (event) => {
        var startDate = moment.unix(Math.floor(event.start.getTime() / 1000)).format('YYYY-MM-DD');
        let endDate = moment.unix(Math.floor(event.end.getTime() / 1000)).format('YYYY-MM-DD');
        let start = moment.unix(Math.floor(event.start.getTime() / 1000)).format('HH:mm')
        var end = moment.unix(Math.floor(event.end.getTime() / 1000)).format("HH:mm");

        // var startDate = this.props.getStringDateTs(Math.floor(event.start.getTime() / 1000), 'YYYY-MM-DD');
        // let endDate = this.props.getStringDateTs(Math.floor(event.end.getTime() / 1000), 'YYYY-MM-DD');
        // let start = this.props.getStringDateTs(Math.floor(event.start.getTime() / 1000), 'HH:mm');
        // var end = this.props.getStringDateTs(Math.floor(event.end.getTime() / 1000), 'HH:mm');


        // let minTime = moment.unix(Math.floor(this.state.min.getTime() / 1000)).format('HH:mm');
        // let maxTime = moment.unix(Math.floor(this.state.max.getTime() / 1000)).format('HH:mm');
        let minTime = this.props.getStringDateTs(Math.floor(this.state.min.getTime() / 1000), 'HH:mm');
        let maxTime = this.props.getStringDateTs(Math.floor(this.state.max.getTime() / 1000), 'HH:mm');

        if (Number(start.split(":")[0]) < Number(minTime.split(":")[0]) || (Number(start.split(":")[0]) == Number(minTime.split(":")[0]) && Number(start.split(":")[1]) < Number(minTime.split(":")[1]))) {
            start = minTime;
            let newEnd = Math.floor(this.state.min.getTime() / 1000) + 5 * 60;
            if (Number(end.split(":")[0]) < Number(moment.unix(newEnd).format('HH:mm').split(":")[0]) || (Number(end.split(":")[0]) == Number(moment.unix(newEnd).format('HH:mm').split(":")[0]) && Number(end.split(":")[1]) < Number(moment.unix(newEnd).format('HH:mm').split(":")[1])))
                end = moment.unix(newEnd).format('HH:mm');
        } else if (Number(end.split(":")[0]) > Number(maxTime.split(":")[0]) || (Number(end.split(":")[0]) == Number(maxTime.split(":")[0]) && Number(end.split(":")[1]) > Number(maxTime.split(":")[1]))) {
            let newStart = Math.floor(this.state.max.getTime() / 1000) - 5 * 60;
            if (Number(start.split(":")[0]) > Number(moment.unix(newStart).format('HH:mm').split(":")[0]) || (Number(start.split(":")[0]) == Number(moment.unix(newStart).format('HH:mm').split(":")[0]) && Number(start.split(":")[1]) > Number(moment.unix(newStart).format('HH:mm').split(":")[1])))
                start = moment.unix(newStart).format('HH:mm');
            end = maxTime;
        }

        let obj = {
            date: startDate,
            endDate: endDate,
            startTime: start,
            endTime: end,
            title: ''

        }

        this.setState({
            scheduleModal: obj,
            doctorsList: []
        })


    }
    /**
    * Select time range for free events on calendar
    * free events are used when patitent schedule his referral
    * @author   Stefan Pantic
    */
    handleSelectFree = () => {
        var startDate = this.props.getStringDateTs(Math.floor(new Date().getTime() / 1000), 'YYYY-MM-DD')// moment.unix(Math.floor(new Date().getTime() / 1000)).format('YYYY-MM-DD');
        let endDate = this.props.getStringDateTs(Math.floor(new Date().getTime() / 1000), 'YYYY-MM-DD')// moment.unix(Math.floor(new Date().getTime() / 1000)).format('YYYY-MM-DD');
        let start = this.props.getStringDateTs(Math.floor(this.state.min.getTime() / 1000), 'HH:mm')// moment.unix(Math.floor(this.state.min.getTime() / 1000)).format('HH:mm')
        var end = this.props.getStringDateTs(Math.floor(this.state.max.getTime() / 1000), 'HH:mm') // moment.unix(Math.floor(this.state.max.getTime() / 1000)).format("HH:mm");

        let obj = {
            date: startDate,
            endDate: endDate,
            startTime: start,
            endTime: end,
            title: ''
            // recurring: true
            // title: '',
            // monday: true,
            // tuesday: true,
            // wednesday: true,
            // thursday: true,
            // friday: true,
            // saturday: false,
            // sunday: false

        }
        this.setState({
            schedulePatientTime: obj,

        })
    }

    /**
    * Update time range when doctor is working
    * @author   Stefan Pantic
    */
    update = () => {

        let selectedGroup = this.props.selectedGroup;
        let selectedClinic = this.props.selectedClinic;

        let workingTimeConfig = this.props.uData.workingTimeConfig ? this.props.uData.workingTimeConfig : {};

        if (!workingTimeConfig[this.props.selectedGroup]) {
            workingTimeConfig[this.props.selectedGroup] = {};
        }


        if (!workingTimeConfig[this.props.selectedGroup][this.props.selectedClinic]) {
            workingTimeConfig[this.props.selectedGroup][this.props.selectedClinic] = {};
        }

        workingTimeConfig[this.props.selectedGroup][this.props.selectedClinic].min = this.state.min;
        workingTimeConfig[this.props.selectedGroup][this.props.selectedClinic].max = this.state.max;

        this.setState({ showPlayer: true }, () => {
            this.player.current.play();
            fetch(API_ENDPOINT + '/users/account/update/working-time-config', {
                method: 'POST',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
                body: JSON.stringify({ workingTimeConfig: workingTimeConfig })
            }).then(parseJSON).then(({ result, status }) => {
                if (result.error) {
                    this.setState({ error: result.error.translate(this.props.lang) })
                } else {
                    this.setState({
                        message: result.message
                    })
                    this.props.verifyUser(() => {
                        this.setWorkingTimeConfig();

                    }, () => {
                        this.props.changeGroup(selectedGroup);
                        this.props.changeClinic(selectedClinic);

                    });
                    this.setState({
                        showPlayer: false,
                        showPlayer2: true,
                    }, () => {
                        setTimeout(() => {
                            this.setState({ showPlayer2: false });
                        }, 1000);
                    })
                }
            })
        })
    }
    // getWorkingTime = () => {
    //     {
    //         if (this.props.uData.workingTimeConfig) {
    //             if (this.props.uData.workingTimeConfig[this.props.selectedGroup] && this.props.uData.workingTimeConfig[this.props.selectedGroup][this.props.selectedClinic]) {
    //                 this.setState({
    //                     min: new Date(this.props.uData.workingTimeConfig[this.props.selectedGroup][this.props.selectedClinic].min),
    //                     max: new Date(this.props.uData.workingTimeConfig[this.props.selectedGroup][this.props.selectedClinic].max)
    //                 })
    //             }
    //         }
    //     }

    // }

    /**
    * Select event on calendar
    * @author   Stefan Pantic
    * @Objectparam    {Object} event        data from calendar event
    */
    selectedEvent = (event) => {
        if (this.props.selectedClinic !== event?.clinic) return;

        if (event?.type && event.type === 'take-care') return this.setState({
            takeCareEvent: {
                ...event,
                date: moment.unix(Math.floor(event.start.getTime() / 1000)).format('YYYY-MM-DD'),
                startTime: moment.unix(Math.floor(event.start.getTime() / 1000)).format('HH:mm'),
                endTime: moment.unix(Math.floor(event.end.getTime() / 1000)).format("HH:mm")
            }
        })

        if (event?.type && event.type === 'semble') {
            return this.getSembleEvent((event._id ?? event.id), event);

            //  return this.setState({
            //     sembleEvent: {
            //         ...event,
            //         date: moment.unix(Math.floor(event.start.getTime() / 1000)).format('YYYY-MM-DD'),
            //         startTime: moment.unix(Math.floor(event.start.getTime() / 1000)).format('HH:mm'),
            //         endTime: moment.unix(Math.floor(event.end.getTime() / 1000)).format("HH:mm")
            //     }
            // });
        }

        this.setState({ previewReferral: null }, async () => {
            if (event.id && (event.type === 'revisit' || event.type === 'referral')) {
                const response = await fetch(API_ENDPOINT + '/data/referral-from-event/' + event.id,
                    {
                        method: 'GET',
                        headers: {
                            'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                            'content-type': 'application/json'
                        }
                    });
                if (response && response.ok) {
                    const result = await response.json();
                    if (result && typeof result === 'object') {
                        if (result && result._2fa) event._2fa = true;
                        this.setState({
                            previewReferral: { ...result, event: event }
                        });
                    }
                }


                // fetch(API_ENDPOINT + '/data/referral-from-event/' + event.id, {
                //     method: 'GET',
                //     headers: {
                //         'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                //         'content-type': 'application/json'
                //     }
                // }).then(res => res.json()).then((result) => {
                //     if (result.error) {
                //         this.setState({
                //             error: result.error.translate(this.props.lang)
                //         })
                //     } else {
                //         this.setState({
                //             previewReferral: { ...result, event: event }
                //         });
                //     }
                // })
            }

            if (event.type == 'temporaryEvent') {
                let date = new Date(event.start)
                date.setHours(0, 0, 0, 0)
                this.setState({ view: 'day', day: date })


            } else {
                var startDate = moment.unix(Math.floor(event.start.getTime() / 1000)).format('YYYY-MM-DD');
                let start = moment.unix(Math.floor(event.start.getTime() / 1000)).format('HH:mm')
                var end = moment.unix(Math.floor(event.end.getTime() / 1000)).format("HH:mm");
                let obj = {
                    title: event.title,
                    titleDoctor: event.titleDoctor,
                    baseTitle: event.baseTitle,
                    visitReasonName: event.visitReasonName,
                    id: event.id,
                    date: startDate,
                    startTime: start,
                    endTime: end,
                    patientNote: event.patientNote,
                    patientId: event.patientId,
                    patientControlNote: event.patientControlNote,
                    type: event.type,
                    appointmentPaid: event.appointmentPaid,
                    additionalDoctor: event.additionalDoctor,
                    patientProfile: event.patientProfile,
                    onlineVisit: event.onlineVisit ? true : false,
                    additionalDoctors: event.additionalDoctors,
                    closeCaseManually: event.closeCaseManually,
                    allActiveForms: event.allActiveForms,
                    eventForms: event.eventForms,
                    _2fa: event?._2fa ? true : false
                }

                if (event && event.additionalDoctors) {
                    this.setState({ doctorsList: event.additionalDoctors })
                }
                if (event.type === 'free') {
                    if (event.type === 'free') {

                        obj.allActiveForms = event.allActiveForms ? event.allActiveForms : false
                        obj.eventForms = event.eventForms ? event.eventForms : []
                        obj.id = event.id
                    }
                    this.setState({
                        schedulePatientTime: obj
                    })
                } else {
                    this.setState({
                        updateEvent: obj,
                        prevUpdateEvent: { ...obj }
                    }, () => {
                        this.checkWaitingRoom()
                    })
                }

            }
        })




    }

    /**
    * Update start and end time for event or title on calendar
    * @author   Stefan Pantic
    * @Objectparam    {String} date                 date when event start
    * @Objectparam    {Number} startTime            start time for event
    * @Objectparam    {Number} endTime              start time for event
    * @Objectparam    {String} doctor               doctor id
    * @Objectparam    {String} title                title for event
    * @Objectparam    {String} patientControlNote   event note
    * @Objectparam    {String} patientNote          note for events with referral
    */
    updateEvent = (data) => {
        if (data && data.additionalDoctors && data.additionalDoctors.length) {
            this.checkDoctorsAvailable(data, 4, () => {
                if (!this.state.errorAdditionalDoctors) {
                    let checkDate = new Date(data.date.replace(/-/g, "/") + ' ' + data.startTime);
                    let today = new Date();

                    if (checkDate >= today) {
                        this.setState({
                            loading: true
                        }, () => {

                            fetch(API_ENDPOINT + '/working-hours/update/' + data.id, {
                                method: 'POST',
                                headers: {
                                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                                    'content-type': 'application/json'
                                },
                                body: JSON.stringify({
                                    allActiveForms: data.allActiveForms ? data.allActiveForms : false,
                                    eventForms: data.eventForms ? data.eventForms : [],
                                    startEventReservedDateOriginal: data.date + ' ' + data.startTime,
                                    endEventReservedDateOriginal: data.date + ' ' + data.endTime,
                                    startEventReservedDate: this.props.getStringDateInverseTs(Math.floor(new Date(data.date + ' ' + data.startTime).getTime() / 1000), `${this.props.dateFormat} HH:mm`),
                                    endEventReservedDate: this.props.getStringDateInverseTs(Math.floor(new Date(data.date + ' ' + data.endTime).getTime() / 1000), `${this.props.dateFormat} HH:mm`),
                                    title: data.title,
                                    titleDoctor: data.titleDoctor,
                                    doctor: this.props.uData._id,
                                    patientNote: data.patientNote,
                                    patientControlNote: data.patientControlNote,
                                    additionalDoctors: data.additionalDoctors,
                                    closeCaseManually: data.closeCaseManually
                                })
                            }).then(res => res.json()).then((result) => {
                                if (result.error) {
                                    this.setState({
                                        error: result.error.translate(this.props.lang),
                                        loading: false
                                    })
                                } else {

                                    this.setState({
                                        updateEvent: null,
                                        prevUpdateEvent: null,
                                        schedulePatientTime: null,
                                        // view: 'week',
                                        day: new Date(data.date.replace(/-/g, "/") + ' ' + data.startTime),
                                        scroll: true,
                                        loading: false,
                                        doctorsList: []
                                    }, () => {
                                        this.get()
                                    })
                                }

                                this.setState({
                                    creatingEvent: false
                                })

                            })
                        })
                    } else {
                        this.setState({
                            wrongDate: true,
                            creatingEvent: false
                        })


                    }
                } else {
                    this.setState({
                        creatingEvent: false
                    })
                }

            })
        } else {
            let checkDate = new Date(data.date.replace(/-/g, "/") + ' ' + data.startTime);
            let today = new Date();

            if (checkDate >= today) {
                this.setState({
                    loading: true
                }, () => {

                    fetch(API_ENDPOINT + '/working-hours/update/' + data.id, {
                        method: 'POST',
                        headers: {
                            'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                            'content-type': 'application/json'
                        },
                        body: JSON.stringify({
                            allActiveForms: data.allActiveForms && data.type !== 'event' ? data.allActiveForms : false,
                            eventForms: data.eventForms ? data.eventForms : [],
                            type: data.type,
                            startEventReservedDateOriginal: data.date + ' ' + data.startTime,
                            endEventReservedDateOriginal: data.date + ' ' + data.endTime,
                            startEventReservedDate: this.props.getStringDateInverseTs(Math.floor(new Date(data.date + ' ' + data.startTime).getTime() / 1000), `${this.props.dateFormat} HH:mm`),
                            endEventReservedDate: this.props.getStringDateInverseTs(Math.floor(new Date(data.date + ' ' + data.endTime).getTime() / 1000), `${this.props.dateFormat} HH:mm`),
                            title: data.title,
                            titleDoctor: data.titleDoctor,
                            doctor: this.props.uData._id,
                            patientNote: data.patientNote,
                            patientControlNote: data.patientControlNote,
                            additionalDoctors: data.additionalDoctors,
                            closeCaseManually: data.closeCaseManually
                        })
                    }).then(res => res.json()).then((result) => {
                        if (result.error) {
                            this.setState({
                                error: result.error.translate(this.props.lang),
                                loading: false
                            })
                        } else {

                            this.setState({
                                updateEvent: null,
                                prevUpdateEvent: null,
                                schedulePatientTime: null,
                                // view: 'week',
                                day: new Date(data.date.replace(/-/g, "/") + ' ' + data.startTime),
                                scroll: true,
                                loading: false,
                                doctorsList: []
                            }, () => {
                                this.get()
                            })
                        }

                        this.setState({
                            creatingEvent: false
                        })

                    })
                })
            } else {
                this.setState({
                    wrongDate: true,
                    creatingEvent: false
                })


            }
        }


    }

    /**
    * Delete event from calendar
    * @author   Stefan Pantic
    * @Objectparam    {String} date                 date when event start
    * @Objectparam    {Number} startTime            start time for event
    * @Objectparam    {Number} endTime              start time for event
    * @Objectparam    {String} doctor               doctor id
    * @Objectparam    {String} title                title for event
    */
    deleteEvent = (data) => {
        this.setState({
            loading: true
        }, () => {

            fetch(API_ENDPOINT + '/working-hours/delete/' + data.id, {
                method: 'POST',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
                body: JSON.stringify({ startEventReservedDateOriginal: data.date + ' ' + data.startTime, endEventReservedDateOriginal: data.date + ' ' + data.endTime, startEventReservedDate: this.props.getStringDateInverseTs(Math.floor(new Date(data.date + ' ' + data.startTime).getTime() / 1000), `${this.props.dateFormat} HH:mm`), endEventReservedDate: this.props.getStringDateInverseTs(Math.floor(new Date(data.date + ' ' + data.endTime).getTime() / 1000), `${this.props.dateFormat} HH:mm`), title: data.title, doctor: this.props.uData._id })
            }).then(res => res.json()).then((result) => {
                if (result?.error) {
                    this.setState({
                        error: result.error?.translate(this.props.lang),
                        loading: false
                    })
                } else {
                    this.get()
                    this.getAllReferrals()
                    this.setState({
                        updateEvent: null,
                        prevUpdateEvent: null,
                        warningModal: null,
                        loading: false
                    })
                }

            })
        })
    }

    /**
    * Schedule referral event on calendar
    * @author   Stefan Pantic
    * @Objectparam    {String} date                 date when event start
    * @Objectparam    {Number} startTime            start time for event
    * @Objectparam    {Number} endTime              start time for event
    * @Objectparam    {String} doctor               doctor id
    * @Objectparam    {String} patientNote          note for events with referral
    */
    scheduleReferral = (data) => {

        if (this.state.referralId) {
            for (let i = 0; i < this.state.referrals.length; i++) {
                if (this.state.referralId == this.state.referrals[i]._id) {
                    this.setState({
                        referral: this.state.referrals[i]
                    })
                }
            }
        }

        let checkDate = new Date(data.date.replace(/-/g, "/") + ' ' + data.startTime);
        let today = new Date();

        if (checkDate >= today) {
            this.setState({
                loading: true
            }, () => {
                fetch(API_ENDPOINT + '/referrals/schedule/' + this.state.referral._id, {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        startEventReservedDateOriginal: data.date + ' ' + data.startTime,
                        endEventReservedDateOriginal: data.date + ' ' + data.endTime,
                        startPatientReservedDate: this.props.getStringDateInverseTs(Math.floor(new Date(data.date + ' ' + data.startTime).getTime() / 1000), `${this.props.dateFormat} HH:mm`),
                        endPatientReservedDate: this.props.getStringDateInverseTs(Math.floor(new Date(data.date + ' ' + data.endTime).getTime() / 1000), `${this.props.dateFormat} HH:mm`),
                        doctor: this.props.uData._id,
                        patientNote: data.patientNote,
                        additionalDoctors: data.additionalDoctors,
                        rescheduleReferral: data.rescheduleReferral ? true : false
                    })
                }).then(res => res.json()).then((result) => {
                    if (result.error) {
                        this.setState({ error: result.error.translate(this.props.lang), loading: false })
                        this.get();
                        this.getAllReferrals()
                    } else {
                        this.get();
                        this.getAllReferrals()
                        // console.log(new Date(data.date.replace(/-/g, "/") + ' ' + data.startTime))
                        this.setState({
                            scheduleReferral: null,
                            // view: 'week',
                            day: new Date(data.date.replace(/-/g, "/") + ' ' + data.startTime),
                            scroll: true,
                            doctorsList: [],
                            referralId: null
                        }, () => this.get())
                    }

                    this.setState({
                        creatingEvent: false
                    })

                })
            })
        } else {
            this.setState({
                wrongDate: true,
                creatingEvent: false
            })


        }
    }

    /**
    * Schedule free event on calendar
    * @author   Stefan Pantic
    * @Objectparam    {String} date                 date when event start
    * @Objectparam    {String} endDate              date when event end
    * @Objectparam    {Number} startTime            start time for event
    * @Objectparam    {Number} endTime              start time for event
    * @Objectparam    {String} doctor               doctor id
    * @Objectparam    {String} title                event title
    * @Objectparam    {String} group                group id
    * @Objectparam    {String} clinic               clinic id
    * @Objectparam    {String} referral             referral id
    */
    schedulePatientTime = (data) => {
        let checkDate = new Date(data.date.replace(/-/g, "/") + ' ' + data.startTime);
        let today = new Date();

        if (checkDate >= today) {
            this.setState({
                loading: true
            }, () => {

                fetch(API_ENDPOINT + '/working-hours/patient-time/new', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({ startEventReservedDateOriginal: data.date + ' ' + data.startTime, endEventReservedDateOriginal: data.date + ' ' + data.endTime, startEventReservedDate: this.props.getStringDateInverseTs(Math.floor(new Date(data.date + ' ' + data.startTime).getTime() / 1000), `${this.props.dateFormat} HH:mm`), endEventReservedDate: this.props.getStringDateInverseTs(Math.floor(new Date(data.date + ' ' + data.endTime).getTime() / 1000), `${this.props.dateFormat} HH:mm`), lastDaydate: data.endDate + ' ' + data.startTime, doctor: this.props.uData._id, title: data.title, group: this.props.selectedGroup, clinic: this.props.selectedClinic, referral: this.state.referralId, monday: data.monday, tuesday: data.tuesday, wednesday: data.wednesday, thursday: data.thursday, friday: data.friday, saturday: data.saturday, sunday: data.sunday })
                }).then(res => res.json()).then((result) => {
                    if (result.error) {
                        this.setState({
                            error: result.error.translate(this.props.lang),
                            loading: false
                        })
                    } else {
                        // this.get()
                        this.setState({
                            scheduleModal: null,
                            schedulePatientTime: null,
                            // view: 'week',
                            day: checkDate,
                            scroll: true,
                            loading: false

                        }, () => {
                            this.get()
                        })
                    }

                })
            })
        } else {
            this.setState({
                wrongDate: true
            })
        }
    }
    generateSections = (itemsArr) => {
        let sections = {};
        let items = [{ type: 0 }, ...itemsArr];
        let lastSectionId = -1;
        for (let i = 0; i < items.length; i++) {
            if (items[i].type == 0) {
                lastSectionId++;
                sections[lastSectionId] = {
                    name: items[i].name,
                    displayOnReferral: false,
                    questions: [],
                    conditions: items[i].conditions ? items[i].conditions : [],
                    displayOnReferral: items[i].displayOnReferral,
                    fontStyle: items[i].fontStyle,
                    fontSize: items[i].fontSize,
                    fontWeight: items[i].fontWeight,

                }
            }
            if (lastSectionId != -1 && items[i].type != 0) {


                let wikiLinks = [];

                items[i].wikiLinks = wikiLinks;


                if (items[i].type == 1) {
                    let displayOnReferral = false;
                    if (items[i].answers) {
                        for (let j = 0; j < items[i].answers.length; j++) {
                            if (items[i].answers[j].text) {
                                displayOnReferral = true;
                            }
                        }
                    }

                    sections[lastSectionId].questions.push({
                        id: items[i].id,
                        type: items[i].type,
                        name: items[i].name,
                        answers: items[i].answers,
                        conditions: items[i].conditions ? items[i].conditions : [],
                        displayOnReferral: displayOnReferral,
                        wikiLinks: items[i].wikiLinks,
                        fontStyle: items[i].fontStyle,
                        fontSize: items[i].fontSize,
                        fontWeight: items[i].fontWeight,
                    });

                } else if (items[i].type == 2) {
                    let displayOnReferral = false;
                    if (items[i].answers) {
                        for (let j = 0; j < items[i].answers.length; j++) {

                            if (items[i].answers[j].text) {
                                displayOnReferral = true;
                            }
                        }
                    }
                    sections[lastSectionId].questions.push({
                        id: items[i].id,
                        type: items[i].type,
                        name: items[i].name,
                        answers: items[i].answers,
                        conditions: items[i].conditions ? items[i].conditions : [],
                        answerPrefix: items[i].answerPrefix,
                        userInput: items[i].userInput,
                        displayOnReferral: displayOnReferral || items[i].answerPrefix ? true : false,
                        wikiLinks: items[i].wikiLinks,
                        fontStyle: items[i].fontStyle,
                        fontSize: items[i].fontSize,
                        fontWeight: items[i].fontWeight,

                    })
                } else if (items[i].type == 3) {
                    sections[lastSectionId].questions.push({
                        id: items[i].id,
                        type: items[i].type,
                        name: items[i].name,
                        conditions: items[i].conditions ? items[i].conditions : [],
                        answerPrefix: items[i].answerPrefix,
                        wikiLinks: items[i].wikiLinks,
                        displayOnReferral: true,
                        fontStyle: items[i].fontStyle,
                        fontSize: items[i].fontSize,
                        fontWeight: items[i].fontWeight,
                    })

                } else if (items[i].type == 4) {
                    sections[lastSectionId].questions.push({
                        id: items[i].id,
                        type: items[i].type,
                        name: items[i].name,
                        text: items[i].text,
                        userInput: items[i].userInput,
                        conditions: items[i].conditions ? items[i].conditions : [],
                        wikiLinks: items[i].wikiLinks,
                        fontStyle: items[i].fontStyle,
                        fontSize: items[i].fontSize,
                        fontWeight: items[i].fontWeight,
                    })
                } else if (items[i].type == 15) {
                    sections[lastSectionId].questions.push({
                        id: items[i].id,
                        type: items[i].type,
                        name: items[i].name,
                        text: items[i].text,
                        userInput: items[i].userInput,
                        conditions: items[i].conditions ? items[i].conditions : [],
                        wikiLinks: items[i].wikiLinks,
                        fontStyle: items[i].fontStyle,
                        fontSize: items[i].fontSize,
                        fontWeight: items[i].fontWeight,
                    })

                } else if (items[i].type == 5) {
                    sections[lastSectionId].questions.push({
                        id: items[i].id,
                        type: items[i].type,
                        name: items[i].name,
                        conditions: items[i].conditions ? items[i].conditions : [],
                        wikiLinks: items[i].wikiLinks,
                        displayOnReferral: true,
                        fontStyle: items[i].fontStyle,
                        fontSize: items[i].fontSize,
                        fontWeight: items[i].fontWeight,
                        score: items[i].score
                    })

                } else if (items[i].type == 6) {
                    sections[lastSectionId].questions.push({
                        id: items[i].id,
                        type: items[i].type,
                        name: items[i].name,
                        conditions: items[i].conditions ? items[i].conditions : [],
                        wikiLinks: items[i].wikiLinks,
                        displayOnReferral: true,
                        fontStyle: items[i].fontStyle,
                        fontSize: items[i].fontSize,
                        fontWeight: items[i].fontWeight,
                        score: items[i].score
                    })

                } else if (items[i].type == 7) {
                    sections[lastSectionId].questions.push({
                        id: items[i].id,
                        type: items[i].type,
                        name: items[i].name,
                        conditions: items[i].conditions ? items[i].conditions : [],
                        wikiLinks: items[i].wikiLinks,
                        displayOnReferral: true,
                        fontStyle: items[i].fontStyle,
                        fontSize: items[i].fontSize,
                        fontWeight: items[i].fontWeight,
                    })

                } else if (items[i].type == 8) {
                    sections[lastSectionId].questions.push({
                        id: items[i].id,
                        type: items[i].type,
                        name: items[i].name,
                        conditions: items[i].conditions ? items[i].conditions : [],
                        wikiLinks: items[i].wikiLinks,
                        displayOnReferral: true,
                        fontStyle: items[i].fontStyle,
                        fontSize: items[i].fontSize,
                        fontWeight: items[i].fontWeight,
                    })

                } else if (items[i].type == 9) {
                    sections[lastSectionId].questions.push({
                        id: items[i].id,
                        type: items[i].type,
                        name: items[i].name,
                        conditions: items[i].conditions ? items[i].conditions : [],
                        wikiLinks: items[i].wikiLinks,
                        displayOnReferral: true,
                        fontStyle: items[i].fontStyle,
                        fontSize: items[i].fontSize,
                        fontWeight: items[i].fontWeight,
                    })

                } else if (items[i].type == 10) {
                    sections[lastSectionId].questions.push({
                        id: items[i].id,
                        type: items[i].type,
                        name: items[i].name,
                        userInput: items[i].userInput,
                        conditions: items[i].conditions ? items[i].conditions : [],
                        wikiLinks: items[i].wikiLinks,
                        displayOnReferral: true,
                        fontStyle: items[i].fontStyle,
                        fontSize: items[i].fontSize,
                        fontWeight: items[i].fontWeight,
                    })
                } else if (items[i].type == 12) {
                    sections[lastSectionId].questions.push({
                        id: items[i].id,
                        type: items[i].type,
                        name: items[i].name,
                        conditions: items[i].conditions ? items[i].conditions : [],
                        wikiLinks: items[i].wikiLinks,
                        displayOnReferral: true,
                        fontStyle: items[i].fontStyle,
                        fontSize: items[i].fontSize,
                        fontWeight: items[i].fontWeight,
                        score: items[i].score
                    })
                } else if (items[i].type == 14) {
                    sections[lastSectionId].questions.push({
                        id: items[i].id,
                        type: items[i].type,
                        name: items[i].name,
                        conditions: items[i].conditions ? items[i].conditions : [],
                        wikiLinks: items[i].wikiLinks,
                        displayOnReferral: true,
                        fontStyle: items[i].fontStyle,
                        fontSize: items[i].fontSize,
                        fontWeight: items[i].fontWeight,
                        score: items[i].score
                    })

                } else if (items[i].type == 13) {
                    sections[lastSectionId].questions.push({
                        id: items[i].id,
                        type: items[i].type,
                        name: items[i].name,
                        conditions: items[i].conditions ? items[i].conditions : [],
                        wikiLinks: items[i].wikiLinks,
                        displayOnReferral: true,
                        fontStyle: items[i].fontStyle,
                        fontSize: items[i].fontSize,
                        fontWeight: items[i].fontWeight,
                        score: items[i].score
                    })

                } else if (items[i].type == 16) {
                    sections[lastSectionId].questions.push({
                        id: items[i].id,
                        type: items[i].type,
                        name: items[i].name,
                        conditions: items[i].conditions ? items[i].conditions : [],
                        wikiLinks: items[i].wikiLinks,
                        displayOnReferral: true,
                        fontStyle: items[i].fontStyle,
                        fontSize: items[i].fontSize,
                        fontWeight: items[i].fontWeight,
                        blocks: items[i].blocks,
                    })

                } else if (items[i].type == 17) {
                    sections[lastSectionId].questions.push({
                        id: items[i].id,
                        type: items[i].type,
                        name: items[i].name,
                        conditions: items[i].conditions ? items[i].conditions : [],
                        wikiLinks: items[i].wikiLinks,
                        displayOnReferral: true,
                        fontStyle: items[i].fontStyle,
                        fontSize: items[i].fontSize,
                        fontWeight: items[i].fontWeight,
                        blocks: items[i].blocks,

                    })

                }
            }
        }

        return Object.values(sections);
    }

    /**
    * Delete free event on calendar
    * @author   Stefan Pantic
    * @Objectparam    {String} date                 date when event start
    * @Objectparam    {String} endDate              date when event end
    * @Objectparam    {Number} startTime            start time for event
    * @Objectparam    {Number} endTime              start time for event
    * @Objectparam    {String} doctor               doctor id
    * @Objectparam    {String} title                event title
    * @Objectparam    {String} group                group id
    * @Objectparam    {String} clinic               clinic id
    * @Objectparam    {String} referral             referral id
    */
    deletePatientTime = (data) => {
        let date = data.date;
        if (data.recurring) {
            if (data.frequencyType == 'day') {
                date = data.frequencyDayScheduledDateFrom;
            } else if (data.frequencyType == 'week') {
                date = data.frequencyWeekScheduledDateFrom;
            } else if (data.frequencyType == 'month') {
                date = data.frequencyMonthScheduledDateFrom;
            }
        }
        let checkDate = new Date(date.replace(/-/g, "/") + ' ' + data.startTime);
        let today = new Date();

        if (data && data.recurring) {
            data = this.checkRecurring(data);
        }

        this.setState({
            loadingDelete: true
        }, () => {

            fetch(API_ENDPOINT + '/working-hours/patient-time/delete/v2', {
                method: 'POST',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
                body: JSON.stringify({
                    startEventReservedDateOriginal: data.date + ' ' + data.startTime,
                    endEventReservedDateOriginal: data.date + ' ' + data.endTime,
                    startEventReservedDate: this.props.getStringDateInverseTs(Math.floor(new Date(data.date + ' ' + data.startTime).getTime() / 1000), `${this.props.dateFormat} HH:mm`),
                    endEventReservedDate: this.props.getStringDateInverseTs(Math.floor(new Date(data.date + ' ' + data.endTime).getTime() / 1000), `${this.props.dateFormat} HH:mm`),
                    doctor: this.props.uData._id,
                    group: this.props.selectedGroup,
                    clinic: this.props.selectedClinic,
                    recurring: data.recurring,
                    recurringObj: data.recurringObj,
                })
            }).then(res => res.json()).then((result) => {
                if (result.error) {
                    this.setState({
                        error: result.error.translate(this.props.lang),
                        loadingDelete: false
                    })
                } else {
                    // this.get()
                    this.setState({
                        scheduleModal: null,
                        schedulePatientTime: null,
                        loadingDelete: false
                        // view: 'week',
                        // day: new Date(data.date.replace(/-/g, "/") + ' ' + data.startTime)

                    }, () => {
                        this.get()
                    })
                }

            })
        })



        // this.setState({
        //     loadingDelete: true
        // }, () => {

        //     fetch(API_ENDPOINT + '/working-hours/patient-time/delete', {
        //         method: 'POST',
        //         headers: {
        //             'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
        //             'content-type': 'application/json'
        //         },
        //         body: JSON.stringify({ startEventReservedDateOriginal: data.date + ' ' + data.startTime, endEventReservedDateOriginal: data.date + ' ' + data.endTime,startEventReservedDate: this.props.getStringDateInverseTs(Math.floor(new Date(data.date + ' ' + data.startTime).getTime() / 1000), `${this.props.dateFormat} HH:mm`), endEventReservedDate: this.props.getStringDateInverseTs(Math.floor(new Date(data.date + ' ' + data.endTime).getTime() / 1000), `${this.props.dateFormat} HH:mm`), lastDaydate: data.endDate + ' ' + data.startTime, doctor: this.props.uData._id, title: data.title, group: this.props.selectedGroup, clinic: this.props.selectedClinic, referral: this.state.referralId, monday: data.monday, tuesday: data.tuesday, wednesday: data.wednesday, thursday: data.thursday, friday: data.friday, saturday: data.saturday, sunday: data.sunday })
        //     }).then(res => res.json()).then((result) => {
        //         if (result.error) {
        //             this.setState({
        //                 error: result.error.translate(this.props.lang),
        //                 loadingDelete: false
        //             })
        //         } else {
        //             this.get()
        //             this.setState({
        //                 scheduleModal: null,
        //                 schedulePatientTime: null,
        //                 loadingDelete: false
        //                 // view: 'week',
        //                 // day: new Date(data.date.replace(/-/g, "/") + ' ' + data.startTime)

        //             })
        //         }

        //     })
        // })


    }

    /**
    * Schedule free event just for selected time range on calendar
    * @author   Stefan Pantic
    * @Objectparam    {String} date                 date when event start
    * @Objectparam    {String} endDate              date when event end
    * @Objectparam    {Number} startTime            start time for event
    * @Objectparam    {Number} endTime              start time for event
    * @Objectparam    {String} doctor               doctor id
    * @Objectparam    {String} title                event title
    * @Objectparam    {String} group                group id
    * @Objectparam    {String} clinic               clinic id
    * @Objectparam    {String} referral             referral id
    */
    // schedulePatientTimeOneDay = (data) => {
    //     let checkDate = new Date(data.date.replace(/-/g, "/") + ' ' + data.startTime);
    //     let today = new Date();

    //     if (checkDate >= today) {
    //         this.setState({
    //             loading: true
    //         }, () => {

    //             fetch(API_ENDPOINT + '/working-hours/patient-time/new', {
    //                 method: 'POST',
    //                 headers: {
    //                     'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
    //                     'content-type': 'application/json'
    //                 },
    //                 body: JSON.stringify({ startEventReservedDateOriginal: data.date + ' ' + data.startTime, endEventReservedDateOriginal: data.date + ' ' + data.endTime,startEventReservedDate: this.props.getStringDateInverseTs(Math.floor(new Date(data.date + ' ' + data.startTime).getTime() / 1000), `${this.props.dateFormat} HH:mm`), endEventReservedDate: this.props.getStringDateInverseTs(Math.floor(new Date(data.date + ' ' + data.endTime).getTime() / 1000), `${this.props.dateFormat} HH:mm`), lastDaydate: data.endDate + ' ' + data.startTime, doctor: this.props.uData._id, title: data.title, group: this.props.selectedGroup, clinic: this.props.selectedClinic, referral: this.state.referralId })
    //             }).then(res => res.json()).then((result) => {
    //                 if (result.error) {
    //                     this.setState({
    //                         error: result.error.translate(this.props.lang),
    //                         loading: false,
    //                     })
    //                 } else {
    //                     this.get()
    //                     this.setState({
    //                         scheduleModal: null,
    //                         // schedulePatientTime: null
    //                         // view: 'week',
    //                         day: new Date(data.date.replace(/-/g, "/") + ' ' + data.startTime),
    //                         scroll: true,
    //                         loading: false

    //                     })
    //                 }

    //             })
    //         })
    //     } else {
    //         this.setState({
    //             wrongDate: true
    //         })
    //     }
    // }

    patientTime = () => {
        this.setState({
            loading: true
        }, () => {
            fetch(API_ENDPOINT + '/working-hours/patient-time', {
                method: 'POST',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
                body: JSON.stringify({ startTime: this.state.freeMin, endTime: this.state.freeMax, doctor: this.props.uData._id })
            }).then(res => res.json()).then((result) => {
                if (result.error) {
                    this.setState({ error: result.error.translate(this.props.lang) })
                } else {
                    this.get();
                    this.setState({
                        patientTimeModal: false
                    })
                }

            })
        })
    }

    /**
    * Submit note for event with referral
    * @author   Aleksandar Dabic
    * @Objectparam    {String} note                 note for event
    */
    submitNote = () => {

        if (this.state.patientNote) {
            fetch(API_ENDPOINT + '/referrals/update/patient-note/' + this.state.referralNote, {
                method: 'POST',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
                body: JSON.stringify({
                    note: this.state.patientNote
                })
            }).then(res => res.json()).then((result) => {
                this.setState({
                    referralNote: null
                })
                this.get()
            })
        }

    }
    /**
    * Submit note for event
    * @author   Aleksandar Dabic
    * @Objectparam    {String} note                 note for event
    */
    submitControlNote = () => {

        if (this.state.patientNote) {
            fetch(API_ENDPOINT + '/working-hours/events/update/patient-note/' + this.state.controlNote, {
                method: 'POST',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
                body: JSON.stringify({
                    note: this.state.patientNote
                })
            }).then(res => res.json()).then((result) => {
                this.setState({
                    controlNote: null
                })
                this.get()
            })
        }

    }

    countItems = (data) => {
        let item = this.state.items.filter(item => item.patientProfile).sort((a, b) => b.startTime - a.startTime)
        let count = 0;
        for (let i = 0; i < item.length; i++) {
            if (item && item.length && item[i] && item[i].patientProfile && item[i].patientProfile.name && item[i].patientProfile.socialSecurityNumber) {

                if (item[i].patientProfile.name.toLowerCase().trim().indexOf(data.toLowerCase().trim()) != -1 || item[i].patientProfile.socialSecurityNumber.indexOf(data) != -1) {

                    count++
                }
            }
        }
        return count
    }

    /**
    * Search in calendar
    * @author   Stefan Pantic
    * @Objectparam    {Array} data                 events
    */
    searchEvents = (data) => {
        let count = this.countItems(data)
        if (count == 0) {
            this.setState({ noSearchResults: 'No matches found'.translate(this.props.lang), day: new Date() })
        }
        let newIndex = this.state.searchItemNumber >= count ? 0 : this.state.searchItemNumber
        this.setState({
            searchItemNumber: newIndex
        })
        let item = this.state.items.filter(item => item.patientProfile).sort((a, b) => b.startTime - a.startTime)

        if (item.length <= 1) {
            this.setState({
                disabledNext: true,
                disabledBack: true
            })
        }

        if (newIndex <= 0) {
            this.setState({
                disabledBack: true,
            })
        } else {
            this.setState({
                disabledBack: false

            })
        }

        let found = false;
        let c1 = 0
        for (let i = 0; i < item.length; i++) {
            if (item && item.length && item[i] && item[i].patientProfile && item[i].patientProfile.name && item[i].patientProfile.socialSecurityNumber) {
                if (item[i].patientProfile.name.toLowerCase().trim().indexOf(data.toLowerCase().trim()) != -1 || item[i].patientProfile.socialSecurityNumber.indexOf(data) != -1) {
                    if (c1 == newIndex) {
                        this.setState({
                            view: 'day',
                            day: new Date(item[i].startEventReservedDate.replace(/-/g, "/")),
                            showFromTo: true
                        })
                        found = true
                        break;

                    }
                    c1++
                    found = true;
                    // break;
                }

            }
        }

        if (!found) {
            this.setState({

                showFromTo: false
            })

        }

    }
    conversation = (id, data, startCall = false) => {
        this.setState({
            loadingConversation: true
        }, () => {
            fetch(API_ENDPOINT + '/referrals/conversation/recpient/' + id, {
                method: 'POST',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
                body: JSON.stringify(data)
            }).then(res => res.json()).then((result) => {
                if (startCall) {
                    this.props.startVideoCall(result.conversation)
                } else {
                    this.props[0].history.push(`/chat?conversation=${result.conversation}`)

                }

            })

        })
    }
    controlConversation = (id, data) => {
        this.setState({
            loading: true
        }, () => {
            fetch(API_ENDPOINT + '/controls/conversation/recpient/' + id, {
                method: 'POST',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
                body: JSON.stringify(data)
            }).then(res => res.json()).then((result) => {
                this.props[0].history.push(`/chat?conversation=${result.conversation}`)
            })

        })
    }
    openConveration = (item) => {
        // console.log(item)
        if (item.id) {
            fetch(API_ENDPOINT + '/working-hours/get-referral/' + item.id, {
                method: 'GET',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                }
            }).then(res => res.json()).then((result) => {
                if (result.error) {
                    this.setState({
                        error: result.error.translate(this.props.lang)
                    })
                } else {
                    if (result.conversations && result.conversations.length) {
                        this.props[0].history.push(`/chat?conversation=${result.conversations[0]._id}`)
                    } else {
                        this.setState({
                            conversationForm: { name: result.patient.referralNumber + '-' + pad(result.conversations.length + 1, 2) }
                        }, () => {
                            this.conversation(result._id, { ...this.state.conversationForm, patient: true });

                        })
                    }
                    // this.setState({ eventReferral: result }, () => {
                    //     let item = this.state.eventReferral;
                    //     this.setState({ updateEvent: !this.state.updateEvent, doctorsList: [], conversationModal: item, conversationForm: { name: item.patient.referralNumber + '-' + pad(item.conversations.length + 1, 2) } })
                    // })

                }

            })

        }
        // else if (item.type == 'revisit' && item.id) {
        //     this.setState({ updateEvent: !this.state.updateEvent, doctorsList: [], conversationControlModal: item, conversationForm: { name: item && item.patientProfile ? (item.patientProfile.name) : '', socialSecurityNumber: item && item.patientProfile ? item.patientProfile.socialSecurityNumber : '' } })
        // }
    }
    startVideoCall = (item) => {
        if (item.id) {
            fetch(API_ENDPOINT + '/working-hours/get-referral/' + item.id, {
                method: 'GET',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                }
            }).then(res => res.json()).then((result) => {
                if (result.error) {
                    this.setState({
                        error: result.error.translate(this.props.lang)
                    })
                } else {
                    if (result.conversations && result.conversations.length) {
                        // this.props[0].history.push(`/chat?conversation=${result.conversations[0]._id}`)
                        this.props.startVideoCall(result.conversations[0]._id)
                    } else {
                        this.setState({
                            conversationForm: { name: result.patient.referralNumber + '-' + pad(result.conversations.length + 1, 2) }
                        }, () => {
                            this.conversation(result._id, { ...this.state.conversationForm, patient: true }, true);

                        })
                    }
                    // this.setState({ eventReferral: result }, () => {
                    //     let item = this.state.eventReferral;
                    //     this.setState({ updateEvent: !this.state.updateEvent, doctorsList: [], conversationModal: item, conversationForm: { name: item.patient.referralNumber + '-' + pad(item.conversations.length + 1, 2) } })
                    // })

                }

            })

        }
    }

    checkDoctorsAvailable = (doctorsList, modal, callback) => {
        let event;

        if (modal == 1) {
            event = this.state.updateEvent;
        } else if (modal == 2) {
            event = this.state.scheduleReferral;
        } else if (modal == 3) {
            event = this.state.scheduleControl;
        } else if (modal == 4) {
            event = doctorsList;
            doctorsList = doctorsList.additionalDoctors;
        }

        if (doctorsList) {
            let data = {
                doctorsList: doctorsList,
                startEventReservedDateOriginal: event.date + ' ' + event.startTime,
                endEventReservedDateOriginal: event.date + ' ' + event.endTime,
                startEventReservedDate: this.props.getStringDateInverseTs(Math.floor(new Date(event.date + ' ' + event.startTime).getTime() / 1000), `${this.props.dateFormat} HH:mm`),
                endEventReservedDate: this.props.getStringDateInverseTs(Math.floor(new Date(event.date + ' ' + event.endTime).getTime() / 1000), `${this.props.dateFormat} HH:mm`),
                eventId: event.id
            }
            fetch(API_ENDPOINT + '/working-hours/check/doctors', {
                method: 'POST',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
                body: JSON.stringify(data)
            }).then(res => res.json()).then((result) => {
                if (result.error) {
                    this.setState({
                        errorAdditionalDoctors: result.doctorsList
                    })
                } else {
                    this.setState({ doctorsModal: false, successfullyAdditionalDoctors: modal != 4 ? true : false }, () => {
                        if (modal == 1) {
                            let updateEvent = this.state.updateEvent;
                            updateEvent.additionalDoctors = this.state.doctorsList;
                            this.setState({ updateEvent })
                        } else if (modal == 2) {
                            let scheduleReferral = this.state.scheduleReferral;
                            scheduleReferral.additionalDoctors = this.state.doctorsList;
                            this.setState({ scheduleReferral })
                        } else if (modal == 3) {
                            let scheduleControl = this.state.scheduleControl;
                            scheduleControl.additionalDoctors = this.state.doctorsList;
                            this.setState({ scheduleControl })
                        }


                    })
                }
                if (callback) {
                    callback();
                }


            })
        }


    }

    patientAge = (data) => {

        fetch(API_ENDPOINT + '/users/users/get-age/' + data, {
            method: 'POST',
            headers: {
                'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                'content-type': 'application/json'
            },
            body: JSON.stringify({ socialSecurityNumber: this.state.patientSocialSecurityNumber })
        }).then(res => res.json()).then((result) => {
            this.setState({
                age: result.age
            })
        })
    }
    leaveCase = (data) => {
        this.setState({ leaveCaseModal: null })
        let additionalDoctors = [];
        if (data.additionalDoctors && data.additionalDoctors.length) {
            for (let i = 0; i < data.additionalDoctors.length; i++) {
                if (data.additionalDoctors[i] != this.props.uData._id)
                    additionalDoctors.push(data.additionalDoctors[i])
            }
        }
        // console.log(additionalDoctors)

        fetch(API_ENDPOINT + '/working-hours/update/' + data.id, {
            method: 'POST',
            headers: {
                'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                'content-type': 'application/json'
            },
            body: JSON.stringify({ additionalDoctors: additionalDoctors, updateOnlyAD: true })
        }).then(res => res.json()).then((result) => {
            if (result.error) {
                this.setState({
                    error: result.error.translate(this.props.lang),
                    loading: false
                })
            } else {
                // this.get()
                this.setState({
                    updateEvent: null,
                    prevUpdateEvent: null,
                    // view: 'week',
                    day: new Date(data.date.replace(/-/g, "/") + ' ' + data.startTime),
                    scroll: true,
                    loading: false,
                    doctorsList: []
                }, () => {
                    this.get()
                })
            }

        })
    }

    findPatient = () => {

        if (this.state.patientSocialSecurityNumber) {
            this.checkSSN(this.state.patientSocialSecurityNumber)
        }

        this.setState({ patient: null, age: null }, () => {
            fetch(API_ENDPOINT + '/users/find/patient', {
                method: 'POST',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
                body: JSON.stringify({ group: this.props.selectedGroup, clinic: this.props.selectedClinic, socialSecurityNumber: this.state.patientSocialSecurityNumber })
            }).then(res => res.json()).then((result) => {
                if (result.error) {
                    this.setState({
                        error: result.error.translate(this.props.lang),
                        loading: false
                    })
                } else {
                    this.get()
                    if (result && result.name && result.socialSecurityNumber) {
                        this.setState({
                            patient: result
                        }, () => {
                            this.patientAge(this.state.patient._id)
                        })
                    } else {
                        let selectedGroupIdx = -1;
                        if (this.props.uData && this.props.selectedGroup && this.props.uData?.clinicGroups?.length) {
                            for (let i = 0; i < this.props.uData.clinicGroups.length; i++) {
                                if (this.props.uData.clinicGroups[i]._id == this.props.selectedGroup) {
                                    selectedGroupIdx = i;
                                    break;
                                }
                            }
                        }
                        let enterprise = this.props.uData && this.props.uData.clinicGroups && this.props.uData.clinicGroups[selectedGroupIdx] && this.props.uData.clinicGroups[selectedGroupIdx].enterprise;
                        let canSearchPatients = this.props.uData && this.props.uData.clinicGroups && this.props.uData.clinicGroups[selectedGroupIdx] && this.props.uData.clinicGroups[selectedGroupIdx].sarchPatients;

                        if (enterprise || canSearchPatients) {
                            this.setState({
                                errorPatientNotFound: 'Check digits or register a new patient'.translate(this.props.lang)
                            }, () => {
                                this.patientAge(this.state.patientSocialSecurityNumber)
                            })
                        } else {
                            this.setState({
                                error: 'The patient you are searching can not be found in your clinic'.translate(this.props.lang)
                            })
                        }

                    }

                }

            })
        })

    }

    checkSSN = (ssn) => {
        fetch(API_ENDPOINT + '/users/patient/check/ssn', {
            method: 'POST',
            headers: {
                'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                'content-type': 'application/json'
            },
            body: JSON.stringify({
                group: this.props.selectedGroup,
                clinic: this.props.selectedClinic,
                socialSecurityNumber: ssn
            })
        }).then(res => res.json()).then((result) => {
            if (result) {
                this.setState({ socialSecurityNumberExist: result.exist, ssnExistInThisGroup: result.ssnExistInThisGroup }, () => {
                    if (this.state.socialSecurityNumberExist && !this.state.ssnExistInThisGroup) {
                        fetch(API_ENDPOINT + '/users/subaccounts/get', {
                            method: 'POST',
                            headers: {
                                'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                                'content-type': 'application/json'
                            },
                            body: JSON.stringify({ socialSecurityNumber: ssn })
                        }).then(parseJSON).then(({ result, status }) => {
                            if (status == 200 && result && result.userData) {

                                this.props.changeName(result.userData.name);
                                this.props.changeSex(result.userData.sex);
                                this.props.changeEmail(result.email)
                                this.props.changePhone(result.phone)

                                if (result.parentObj) {
                                    this.props.changeSocialSecurityNumberParent(result.parentObj.socialSecurityNumber)
                                    this.props.changeNameParent(result.parentObj.name);
                                    this.props.changeEmail(result.parentObj.email);
                                    if (result.parentObj.userData.sex)
                                        this.props.changeSexParent(result.parentObj.userData.sex);
                                    this.props.changeLanguage(result.parentObj.language);
                                    this.props.changePhone(result.parentObj.phone);
                                }

                            }
                        })
                    }
                })
            }

        })
    }

    checkPatientValidations = (data) => {

        if (!data || (!this.state.clinicGroupSettings?.twoFactorAuthentication && data.twoFactorAuthenticationEnabled)) return this.setState({ error: 'Something went wrong! Please refresh your browser and try again!'.translate(this.props.lang) });
        if (this.state.age && (this.props.country === 'denmark' ? this.state.age >= CHILD_AGE_LIMIT.denmark : this.props.country === 'sweden' ? this.state.age >= CHILD_AGE_LIMIT.sweden : this.state.age >= 18) && data) {
            if (data.socialSecurityNumberParent) delete data.socialSecurityNumberParent;
            if (data.socialSecurityNumberParent2) delete data.socialSecurityNumberParent2;
            if (data.dateOfBirthParent) delete data.dateOfBirthParent;
            if (data.dateOfBirthParent2) delete data.dateOfBirthParent2;
            if (data.nameParent) delete data.nameParent;
            if (data.nameParent2) delete data.nameParent2;
            if (data.sexParent) delete data.sexParent;
            if (data.sexParent2) delete data.sexParent2;
            if (data.email2) delete data.email2;
            if (data.phone2) delete data.phone2;
        }

        if (this.state.clinicGroupSettings?.twoFactorAuthentication && data.twoFactorAuthenticationEnabled && (!data.email?.trim?.() || !data.phone?.trim?.())) return this.setState({ error: 'When two factor authentication is enabled, both email and phone number are required!'.translate(this.props.lang) });

        if (!(data.email || data.phone) && !this.state.socialSecurityNumberExist) {
            this.setState({ error: 'Mandatory to enter either email or phone.'.translate(this.props.lang) })
        } else {
            if (data.socialSecurityNumberParent) {
                let check = validateSocialSecurityNumber(data.socialSecurityNumberParent, true, this.props.country);

                if (check.error) {
                    this.setState({ error: check.error.translate(this.props.lang) })
                } else {
                    this.setState({
                        saveModal: data
                    })
                }

            } else if (data.socialSecurityNumber && !data.socialSecurityNumberParent) {
                let check = validateSocialSecurityNumber(data.socialSecurityNumber, false, this.props.country);

                if (check.error) {
                    this.setState({ error: check.error.translate(this.props.lang) })
                } else {
                    if (data.socialSecurityNumberParent2 && this.state.parent2Required) {
                        let check = validateSocialSecurityNumber(data.socialSecurityNumberParent2, true, this.props.country)

                        if (check.error) {
                            this.setState({ error: check.error.translate(this.props.lang) })
                        } else {

                            this.setState({
                                saveModal: data
                            })
                        }
                    } else {
                        this.setState({
                            saveModal: data
                        })
                    }
                }
            }
        }

    }

    addPatient = (data) => {
        if (this.props.country === 'uk' && !this.state.parent2Required && data) {
            if (data.socialSecurityNumberParent2) delete data.socialSecurityNumberParent2;
            if (data.dateOfBirthParent2) delete data.dateOfBirthParent2;
            if (data.nameParent2) delete data.nameParent2;
            if (data.sexParent2) delete data.sexParent2;
            if (data.email2) delete data.email2;
            if (data.phone2) delete data.phone2;
        }
        fetch(API_ENDPOINT + '/users/patient/add/new', {
            method: 'POST',
            headers: {
                'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                'content-type': 'application/json'
            },
            body: JSON.stringify({
                group: this.props.selectedGroup,
                clinic: this.props.selectedClinic,
                ...data
            })
        }).then(res => res.json()).then((result) => {
            if (result.error) {
                this.setState({ error: result.error })
            } else {
                this.setState({ patientSocialSecurityNumber: result.responseSSN ?? this.state.patientSocialSecurityNumber, succesMessage: 'You have successfully added a patient'.translate(this.props.lang), form: null, registerNewPatientModal: null }, () => {
                    this.findPatient()
                })
            }
            this.setState({ saveModal: null })

        })
    }

    getTakeCareAppointments = async () => {
        return;
        if (!this.props.hasTakeCare || !this.props?.selectedGroup || !this.props?.selectedClinic || !this.props?.uData) return;

        const response = await fetch(API_ENDPOINT + '/take-care/get-bookings-for-doctor', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
            },
            body: JSON.stringify({
                groupId: this.props?.selectedGroup,
                clinicId: this.props?.selectedClinic,
                doctorId: this.props?.uData?._id
            })
        });

        if (response && response.ok) {
            const result = await response.json();
            if (result?.error && this.props?.hasTakeCare) this.setState({ error: result.error?.translate?.(this.props?.lang) });
            if (result?.appointments && Array.isArray(result.appointments) && result.appointments?.length) {
                this.setState({ takeCareAppointments: result?.appointments, daysWithDuplicate: [] }, () => {
                    if (this.state.view == 'month' && this.state.items && this.state.items.length) {
                        let ITEMS = [...(this.state.items || [])];
                        if (this.props.hasTakeCare && this.state.takeCareAppointments && Array.isArray(this.state.takeCareAppointments) && this.state.takeCareAppointments?.length) ITEMS = [...ITEMS, ...this.state.takeCareAppointments];
                        let items = ITEMS?.filter(item => item.group.toString() == this.props.selectedGroup.toString() && (item.type != 'temporaryEvent' || (item.type == 'temporaryEvent' && this.state.view == 'month')));
                        let daysWithDuplicate = []
                        for (let i = 0; i < items.length; i++) {
                            if (items[i] && items[i].startTime && !items[i].reserved) {
                                let date = moment.unix(items[i].startTime).format('MM-DD-YYYY')
                                if (daysWithDuplicate.filter(el => el == date).length == 0) {
                                    if (items.filter(el => el._id != items[i]._id &&
                                        (
                                            (this.getTsWithZeroSec(items[i].startTime) <= this.getTsWithZeroSec(el.startTime) && this.getTsWithZeroSec(items[i].endTime) >= this.getTsWithZeroSec(el.endTime))
                                            || (this.getTsWithZeroSec(items[i].startTime) <= this.getTsWithZeroSec(el.startTime) && this.getTsWithZeroSec(items[i].endTime) > this.getTsWithZeroSec(el.startTime) && this.getTsWithZeroSec(items[i].endTime) <= this.getTsWithZeroSec(el.endTime))
                                            || (this.getTsWithZeroSec(items[i].startTime) >= this.getTsWithZeroSec(el.startTime) && this.getTsWithZeroSec(items[i].startTime) < this.getTsWithZeroSec(el.endTime) && this.getTsWithZeroSec(items[i].endTime) >= this.getTsWithZeroSec(el.endTime))
                                        )
                                    ).length) {
                                        daysWithDuplicate.push(date)
                                    }
                                }

                            }
                        }
                        if (daysWithDuplicate && daysWithDuplicate.length) {
                            this.setState({ daysWithDuplicate })
                        }

                    }
                });
            }
        }
    }

    getClinicGroupSettings = () => {
        this.setState({ clinicGroupSettings: null }, async () => {
            if (!this.props?.selectedGroup) return;
            const response = await fetch(API_ENDPOINT + '/data/clinic-group-settings/' + this.props.selectedGroup);
            if (!response || !response.ok) return;
            const result = await response.json();
            if (!result || result.error || !result.clinicGroupSettings) return;
            this.setState({ clinicGroupSettings: result.clinicGroupSettings });
        })
    }

    searchPatientWithRedirect = (SSN) => {
        if (!this.state.clinicGroupSettings) return this.setState({ error: 'User not found in this clinic group' });

        const { enterprise, canSearchPatients } = this.state.clinicGroupSettings;
        if (!enterprise && !canSearchPatients) return this.setState({ error: 'User not found in this clinic group' });

        let obj = {
            socialSecurityNumber: SSN,
            group: this.props.selectedGroup,
            clinic: this.props.selectedClinic,
            openOnWebDoc: false,
        }
        enterprise && (obj.openOnWebDoc = true);

        fetch(API_ENDPOINT + '/users/find/patient', {
            method: 'POST',
            headers: {
                'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                'content-type': 'application/json'
            },
            body: JSON.stringify(obj)
        }).then(res => res.json()).then((result) => {

            if (result && result?._id) {
                const path = enterprise ? '/patients/search-enterprise' : '/patients/search';
                this.props.registerClinicGroupLogs({
                    type: 'patientProfile',
                    patient: SSN,
                });

                let state = {
                    TYPE: 'redirect',
                    redirectPatient: result,
                    redirectTabIndex: 1
                }
                enterprise && (state.redirectTabActive = 1);

                this.props?.[0]?.history?.push?.(path, state);

            } else {
                this.setState({ error: 'User not found in this clinic group' })
            }
        })
    }

    closeReferral = (data) => {
        if (!data) data = {};
        data.selectedGroup = this.props.selectedGroup;
        data.closeConversation = true;

        this.setState({
            loading: true
        }, () => {
            fetch(API_ENDPOINT + '/referrals/close/' + this.state.previewReferral._id, {
                method: 'POST',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
                body: JSON.stringify(data)
            }).then(res => res.json()).then((result) => {
                this.get();
                if (result?.error) {
                    this.setState({
                        error: result.error?.translate(this.props.lang),
                        loading: false
                    })
                } else {
                    this.setState({
                        previewReferral: null,
                        openPreviewReferral: false,
                        updateEvent: null,
                        prevUpdateEvent: null,
                    })
                }

            })
        })
    }
    deleteReferral = () => {
        if (!this.state.previewReferral?._id) {
            return;
        }
        this.setState({
            loading: true
        }, () => {
            fetch(API_ENDPOINT + '/referrals/delete/' + this.state.previewReferral._id, {
                method: 'POST',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
                body: JSON.stringify({})
            }).then(res => res.json()).then((result) => {
                this.get();
                if (result?.error) {
                    this.setState({
                        error: result.error?.translate(this.props.lang),
                        loading: false
                    })
                } else {
                    this.setState({
                        previewReferral: null,
                        openPreviewReferral: false,
                        updateEvent: null,
                        prevUpdateEvent: null,
                    })
                }

            })
        })
    }

    checkForChanges = () => {
        if (this.state.updateEvent && this.state.prevUpdateEvent) {
            if (JSON.stringify(this.state.updateEvent) != JSON.stringify(this.state.prevUpdateEvent)) {
                this.setState({
                    changesNotSaved: true
                })
            } else {
                this.setState({
                    updateEvent: null,
                    prevUpdateEvent: null,
                    doctorsList: []
                })
            }
        } else {
            this.setState({
                updateEvent: null,
                prevUpdateEvent: null,
                doctorsList: []
            })
        }
    }

    showWeekAndMonthReccuringOptions = () => {
        // Function to check on whether today is greater than or equeal to 31st of March 2024
        // If it is, then show the recurring options for week and month
        // If it is not, then hide the recurring options for week and month
        let today = new Date();
        let march2024 = new Date('2024-03-31');
        if (today >= march2024) return true;
        return false;
    }

    calculateMaxDateHotfix = (minDateString) => {
        try {
            if (minDateString && minDateString !== 'Invalid date' && minDateString !== 'NaN-NaN-NaN' && minDateString !== 'Invalid Date') {
                let minDate = new Date(minDateString);
                minDate.setHours(12, 0, 0, 0);
                if (minDate.getFullYear() === 2024 && minDate.getMonth() === 2) {
                    // March 2024
                    if (minDate.getDate() < 31) {
                        const maxDate = new Date('2024-03-30T12:00:00.000Z');
                        // const maxDateString = this.props.getStringDateTs(Math.floor(maxDate.getTime() / 1000));
                        return maxDate;
                    }
                }
            }
        } catch (err) {
            console.log('calculateMaxDateHotfix error: ', err);
        }
        return undefined;
    }

    getSembleEvent = (id, _event) => {
        if (!id) return;
        this.setState({ loading: true }, async () => {
            const response = await fetch(`${API_ENDPOINT}/working-hours/semble/data/${id}`, {
                method: 'POST',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
            });
            if (!response || !response.ok) return this.setState({ error: 'Something went wrong! Please try again!', loading: false });

            const result = await response.json();
            if (!result.event) return this.setState({ error: 'Something went wrong! Please try again!', loading: false });

            const event = result.event;
            this.setState({
                sembleEvent: {
                    ...event,
                    startTimeNum: event.startTime,
                    endTimeNum: event.endTime,
                    date: moment.unix(Math.floor(_event.start.getTime() / 1000)).format('YYYY-MM-DD'),
                    startTime: moment.unix(Math.floor(_event.start.getTime() / 1000)).format('HH:mm'),
                    endTime: moment.unix(Math.floor(_event.end.getTime() / 1000)).format("HH:mm")
                },
                loading: false
            });
        });
    }

    cancelSembleEvent = () => {
        if (!this.state.sembleEventCancel || !this.state.sembleEventCancel._id) return;
        this.setState({ loading: true }, async () => {
            const response = await fetch(`${API_ENDPOINT}/working-hours/semble/cancel/${this.state.sembleEventCancel._id}`, {
                method: 'POST',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
                body: JSON.stringify({
                    groupId: this.props.selectedGroup,
                    departmentId: this.props.selectedClinic,
                })
            });
            if (!response || !response.ok) return this.setState({ error: 'Something went wrong! Please try again!', loading: false, sembleEventCancel: null });

            const result = await response.json();
            if (result?.error) return this.setState({ error: result.error, loading: false, sembleEventCancel: null });

            this.setState({ sembleEventCancel: null, loading: false, sembleEvent: null }, () => {
                this.get();
            });
        });
    }

    prepareSembleUpdate = () => {
        if (!this.state.sembleEvent?._id || !this.state.sembleEvent.type || this.state.sembleEvent.type !== 'semble') return;
        if (this.state._sembleUpdateData) {
            const sembleEvent = this.state.sembleEvent;
            const values = {
                doctor: sembleEvent.doctor._id,
                location: sembleEvent.clinic,
                date: sembleEvent.date,
                startTime: sembleEvent.startTime,
                endTime: sembleEvent.endTime,
                startTimeNum: sembleEvent.startTimeNum,
                endTimeNum: sembleEvent.endTimeNum,
            };
            const availableDoctors = this.state._sembleUpdateData.doctors;
            if (!availableDoctors?.find?.(obj => obj._id === values.doctor)) availableDoctors.push({
                _id: values.doctor,
                name: sembleEvent.doctor.name,
            });

            const availableLocations = this.state._sembleUpdateData.departments;
            if (!availableLocations?.find?.(obj => obj._id === values.location)) availableLocations.push({
                _id: values.location,
                name: sembleEvent.sembleData?.location?.name || '',
            });
            return this.setState({
                sembleEventUpdate: {
                    eventId: sembleEvent._id,
                    values,
                    oldValues: values,
                    availableDoctors,
                    availableLocations,
                }
            });
        }

        this.setState({ loading: true }, async () => {
            const response = await fetch(`${API_ENDPOINT}/working-hours/semble/prepare-update/${this.state.sembleEvent._id}`, {
                method: 'POST',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
                body: JSON.stringify({
                    groupId: this.props.selectedGroup,
                    departmentId: this.props.selectedClinic,
                })
            });

            if (!response || !response.ok) return this.setState({ error: 'Something went wrong! Please try again!', loading: false });

            const result = await response.json();
            if (!result?.sembleUpdateData) return this.setState({ error: 'Something went wrong! Please try again!', loading: false });

            this.setState({ loading: false, _sembleUpdateData: result.sembleUpdateData }, () => {
                const sembleEvent = this.state.sembleEvent;
                const values = {
                    doctor: sembleEvent.doctor._id,
                    location: sembleEvent.clinic,
                    date: sembleEvent.date,
                    startTime: sembleEvent.startTime,
                    endTime: sembleEvent.endTime,
                    startTimeNum: sembleEvent.startTimeNum,
                    endTimeNum: sembleEvent.endTimeNum,
                };
                const availableDoctors = result.sembleUpdateData.doctors;
                if (!availableDoctors?.find?.(obj => obj._id === values.doctor)) availableDoctors.push({
                    _id: values.doctor,
                    name: sembleEvent.doctor.name,
                });

                const availableLocations = result.sembleUpdateData.departments;
                if (!availableLocations?.find?.(obj => obj._id === values.location)) availableLocations.push({
                    _id: values.location,
                    name: sembleEvent.sembleData?.location?.name || '',
                });

                this.setState({
                    sembleEventUpdate: {
                        eventId: sembleEvent._id,
                        values,
                        oldValues: values,
                        availableDoctors,
                        availableLocations,
                    }
                });
            });
        });
    }

    updateSembleEvent = () => {
        if (!this.state.sembleEventUpdate?.eventId || !this.state.sembleEventUpdate?.values || typeof this.state.sembleEventUpdate?.values !== 'object') return;
        const values = this.state.sembleEventUpdate.values;

        this.setState({ loading: true }, async () => {
            const response = await fetch(`${API_ENDPOINT}/working-hours/semble/update/${this.state.sembleEventUpdate.eventId}`, {
                method: 'POST',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
                body: JSON.stringify({
                    groupId: this.props.selectedGroup,
                    departmentId: this.props.selectedClinic,
                    values,
                })
            });

            if (!response || !('json' in response)) return this.setState({ error: 'Something went wrong! Please try again!', loading: false });

            const result = await response.json();
            if (!result || result.error) return this.setState({ error: result.error || 'Something went wrong! Please try again!', loading: false });

            this.setState({ loading: false, sembleEventUpdate: null, sembleEvent: null }, () => {
                this.get();
            });
        });
    }

    /**
     * 
     * @param {any} dt 
     * @param {Intl.DateTimeFormatOptions} options 
     * @returns 
     */
    getGBDate = (dt, options) => {
        try {
            if (!dt) return '';
            if (!options) options = {
                dateStyle: 'short',
            }
            const ts = new Date(dt).getTime();
            if (ts && !isNaN(ts)) {
                const date = new Intl.DateTimeFormat('en-GB', options).format(ts);

                if (date) return date;
            }
        } catch (err) {
        }
        return dt;
    }

    render() {
        const showRecurringOptions = this.showWeekAndMonthReccuringOptions();

        /**
         * @type {undefined | {
         *  _id: string;
         *  type: 'semble';
         *  semble: true;
         *  sembleId: string;
         *  title: string;
         *  doctor: string;
         *  clinic: string;
         *  group: string;
         *  sembleData: {
         *      id: string;
         *      deleted: boolean;
         *      doctor: {id: string; email: string; fullName: string;};
         *      location: {id: string; name: string;};
         *      appointment: {title: string; id: string; duration: number; price: number;};
         *      start: string;
         *      end: string;
         *      patient: SemblePatient;
         *      comments: string;
         *  };
         *  patientData: string | SemblePatient;
         *  patient?: undefined | {
         *      _id: string;
         *      name: string;
         *      ssn: string;
         *      dob?: string;
         *      email: string;
         *      phone: string;
         *      gender: string;
         *      address: {
         *          street?: string;
         *          city?: string;
         *          zip?: string;
         *      }
         *  };
         *  date: string;
         *  startTime: string;
         *  endTime: string;
         *  startTimeNum: number;
         *  endTimeNum: number;
         *  canEdit?: boolean;
         * }}
         */
        let _sembleEvent = undefined;
        let _semblePatient = undefined;
        if (this.state.sembleEvent) {
            _sembleEvent = this.state.sembleEvent;
            if (_sembleEvent?.patient) _semblePatient = _sembleEvent.patient;
            else if (_sembleEvent?.patientData && typeof _sembleEvent.patientData === 'object') {
                _semblePatient = {
                    _id: _sembleEvent.patientData.id,
                    name: _sembleEvent.patientData.fullName,
                    dob: _sembleEvent.patientData.dob,
                    ssn: null,
                    email: _sembleEvent.patientData.email,
                    phone: null,
                    gender: _sembleEvent.patientData.gender,
                    address: {
                        city: _sembleEvent.patientData.address?.city || '',
                        zip: _sembleEvent.patientData.address?.postcode || '',
                        street: _sembleEvent.patientData.address?.address || '',
                    }
                };
                if (_sembleEvent.patientData.phones?.length) {
                    const mobile = _sembleEvent.patientData.phones.find(p => p.phoneType === 'Mobile');
                    if (mobile && mobile.phoneNumber) _semblePatient.phone = mobile.phoneNumber;
                    else _semblePatient.phone = _sembleEvent.patientData.phones[0]?.phoneNumber;
                }
            }
            // Check if start datetime of event is in the future - if is allow editing
            _sembleEvent.canEdit = _sembleEvent.startTimeNum > Math.floor(new Date().getTime() / 1000);
        }

        let hasSembleUpdate = false;
        if (this.state.sembleEventUpdate) {
            if (this.state.sembleEventUpdate.values && this.state.sembleEventUpdate.oldValues) {
                if (this.state.sembleEventUpdate.values.doctor !== this.state.sembleEventUpdate.oldValues.doctor) hasSembleUpdate = true;
                if (this.state.sembleEventUpdate.values.location !== this.state.sembleEventUpdate.oldValues.location) hasSembleUpdate = true;
                if (this.state.sembleEventUpdate.values.date !== this.state.sembleEventUpdate.oldValues.date) hasSembleUpdate = true;
                if (this.state.sembleEventUpdate.values.startTime !== this.state.sembleEventUpdate.oldValues.startTime) hasSembleUpdate = true;
                if (this.state.sembleEventUpdate.values.endTime !== this.state.sembleEventUpdate.oldValues.endTime) hasSembleUpdate = true;
            }
        }

        let params = {};
        if (this.state.useState) {
            params = this.state.params;
        } else {
            params = getSearchParams(this.props[0].location.search, { entries: 10, page: 0 })
        }
        // console.log()
        // let calendarConf = { min: this.state.min, max: this.state.max };
        let calendarConf = {
            min: new Date(this.props.getStringDateTs(Math.floor(new Date(this.state.min).getTime() / 1000), 'MM/DD/YYYY HH:mm')),
            max: new Date(this.props.getStringDateTs(Math.floor(new Date(this.state.max).getTime() / 1000), 'MM/DD/YYYY HH:mm'))
        };
        if (this.state.day) {
            // min: 8
            // hocu: 10
            // razlika: 2
            // scrollToTimeSat:  2

            let newDay = new Date(this.state.day.getTime() - this.state.min.getHours() * 60 * 60 * 1000 - this.state.min.getMinutes() * 60 * 1000 - 10 * 60 * 1000)
            calendarConf.scrollToTime = newDay;
        }

        if (this.state.searchIsOn || this.state.scroll) {
            // delete calendarConf.min
            // delete calendarConf.max
        } else {
            delete calendarConf.scrollToTime

        }
        // console.warn(calendarConf)
        const humanReadable = {
            'waiting-for-approval': 'Waiting for approval',
            'disallowed': 'Disallowed',
            'allowed': 'Allowed',
            'return-for-update': 'Return for update',
            'patient-requested-another-time': 'Patient requested anoother time',
            'patient-rejected': 'Rejected by patient',
            'patient-accepted': 'Patient accepted',
            'clinic-accepted-requested-time': 'Clinic accepted requested time',
            'clinic-rejected-requested-time': 'Clinic rejected requested time',
            'approved-by-region-manager': 'Approved by region manager',
            'rejected-by-region-manager': 'Rejected by region manager',
            'scheduled': 'Scheduled',
            'canceled-by-clinic': 'Canceled by clinic',
            'not-booked': 'Not booked',
            'closed': 'Closed',
            'requested-new-appointment': 'Requested new appointment'.translate(this.props.lang),
            'visit-rescheduled': 'Visit rescheduled'.translate(this.props.lang),
            'external': 'External transfer'.translate(this.props.lang),

        }
        let searchCount = 0;

        if (this.state.searchParam) searchCount = this.countItems(this.state.searchParam);

        let selectedGroupIdx = -1;
        if (this.props.uData && this.props.selectedGroup && this.props.uData?.clinicGroups?.length) {
            for (let i = 0; i < this.props.uData.clinicGroups.length; i++) {
                if (this.props.uData.clinicGroups[i]._id == this.props.selectedGroup) {
                    selectedGroupIdx = i;
                    break;
                }
            }
        }
        let enterprise = this.props.uData && this.props.uData.clinicGroups && this.props.uData.clinicGroups[selectedGroupIdx] && this.props.uData.clinicGroups[selectedGroupIdx].enterprise;
        let canSearchPatients = this.props.uData && this.props.uData.clinicGroups && this.props.uData.clinicGroups[selectedGroupIdx] && this.props.uData.clinicGroups[selectedGroupIdx].sarchPatients;

        let canOpenProfile = false;
        if (this.state.clinicGroupSettings?.enterprise || this.state.clinicGroupSettings?.canSearchPatients) canOpenProfile = true;

        const isChild = this.state.age && (this.props.country === 'denmark' ? this.state.age < CHILD_AGE_LIMIT.denmark : this.props.country === 'sweden' ? this.state.age < CHILD_AGE_LIMIT.sweden : this.state.age < 18) ? true : false;
        return (
            <div className={this.props.autoHideSidebar ? "dashboard dashboard-auto-hide" : "dashboard"}>
                <Container fluid>
                    {/*
                    <Row>
                      <Col xl="9" lg="7">
                           <Search />
                      </Col>
                      <Col xl="3" lg="5">
                      <div className='component notifications'>
                            <Isvg src={notification} />
                            <div className='profile'>
                            <span className='name'>{this.props.uData && this.props.uData.username}</span>
                            <img src={this.props.uData && this.props.uData.profilePicture ? API_ENDPOINT + this.props.uData.profilePicture : profileUser} className="profile-image" />
                            <Isvg src={moreIcon} />
                            </div>
                        </div>
                      </Col>
                    </Row>
                    */}
                    <Row>
                        <Col lg='12'>
                            <div className='panel'>
                                <h5 className="component-header">
                                    {this.props.linksMeta && this.props.linksMeta[this.props[0].location.pathname] ? this.props.linksMeta[this.props[0].location.pathname][this.props.lang] : ''}
                                </h5>
                                <div className="schedule-working-hours calendar-mobile-height" style={{ height: this.state.view == 'month' ? 'auto' : 600 }}>
                                    <div className="schedule-content">
                                        <div className="schedule-container">

                                            <div className="schedule-step">
                                                <Label>{'Step:'.translate(this.props.lang)}</Label>
                                                <Input type='select'
                                                    value={this.state.step}
                                                    className="schedule-container-input"
                                                    onChange={(e) => this.setState({ step: e.target.value })}>

                                                    {/* <option value={5}>{'None'.translate(this.props.lang)}</option> */}
                                                    <option value={1}>{'1 minute'.translate(this.props.lang)}</option>
                                                    <option value={5}>{'5 minutes'.translate(this.props.lang)}</option>
                                                    <option value={10}>{'10 minutes'.translate(this.props.lang)}</option>
                                                    <option value={15}>{'15 minutes'.translate(this.props.lang)}</option>

                                                </Input>
                                            </div>

                                            <div className="timepicker-container remove-mt" style={{ display: 'flex', alignItems: 'center' }}>
                                                <div class="timepicker-container-text">
                                                    {'Working from:'.translate(this.props.lang)}
                                                </div>
                                                {/* &nbsp; */}
                                                <TimePickerV2
                                                    getStringDateTs={this.props.getStringDateTs}
                                                    format={'HH:mm'}
                                                    widthInput={typeof window !== 'undefined' && window.innerWidth > 768 && window.innerWidth < 991 ? 125 : 146}
                                                    value={this.props.getStringDateTs(Math.floor(new Date(this.state.min).getTime() / 1000), 'HH:mm')}
                                                    flag={2}
                                                    onChange={(time1, timeString) => {
                                                        let oldDate = this.state.min;
                                                        let currentDateStr = this.props.getStringDateTs(Math.floor(new Date().getTime() / 1000), 'MM/DD/YYYY');
                                                        let newDate = new Date(`${currentDateStr} ${timeString}`);

                                                        let maxDateStr = this.props.getStringDateTs(Math.floor(new Date(this.state.max).getTime() / 1000), 'MM/DD/YYYY HH:mm');
                                                        let max = new Date(maxDateStr);

                                                        if (newDate.getTime() < max.getTime() && isValidDate(newDate)) {
                                                            this.setState({ min: newDate });
                                                        } else {
                                                            this.setState({ min: oldDate });
                                                        }
                                                    }}
                                                />

                                                <span className='calendar-title-to-text'>{"to:".translate(this.props.lang)}</span>

                                                <TimePickerV2
                                                    getStringDateTs={this.props.getStringDateTs}
                                                    format={'HH:mm'}
                                                    widthInput={typeof window !== 'undefined' && window.innerWidth > 768 && window.innerWidth < 991 ? 125 : 146}
                                                    value={this.props.getStringDateTs(Math.floor(new Date(this.state.max).getTime() / 1000), 'HH:mm')}
                                                    flag={2}
                                                    onChange={(time1, timeString) => {
                                                        let oldDate = this.state.max;
                                                        let newDate = new Date(this.props.getStringDateTs(Math.floor(new Date().getTime() / 1000), 'MM/DD/YYYY HH:mm'))
                                                        let time = timeString.split(':')
                                                        newDate.setHours(time[0], time[1], 0)
                                                        let min = new Date(`${this.props.getStringDateTs(Math.floor(new Date().getTime() / 1000), 'MM/DD/YYYY')} ${this.props.getStringDateTs(Math.floor(new Date(this.state.min).getTime() / 1000), 'HH:mm')}`)

                                                        if (newDate.getTime() > min.getTime() && isValidDate(newDate))
                                                            this.setState({ max: new Date(this.props.getStringDateInverseTsV1(Math.floor(new Date(newDate).getTime() / 1000), 'MM/DD/YYYY HH:mm')) })
                                                        // else {
                                                        //     this.setState({ max: newDate }, () => { this.setState({ max: oldDate }) })
                                                        // }
                                                    }}
                                                />
                                                {/* <TimePicker
                                                    getStringDateTs={this.props.getStringDateTs}
                                                    format={'HH:mm'}
                                                    value={moment(this.state.min)}
                                                    flag={2}
                                                    onChange={(time1, timeString) => {
                                                        let oldDate = this.state.min;
                                                        let newDate = new Date()
                                                        let time = timeString.split(':')
                                                        newDate.setHours(time[0], time[1], 0)

                                                        if (newDate.getTime() < this.state.max.getTime() && isValidDate(newDate))
                                                            this.setState({ min: newDate })
                                                        // else {
                                                        //     this.setState({ min: newDate }, () => { this.setState({ min: oldDate }) })
                                                        // }
                                                    }}
                                                    allowClear={false}

                                                />
                                                <span>{"to:".translate(this.props.lang)}</span>

                                                <TimePicker
                                                    getStringDateTs={this.props.getStringDateTs}
                                                    format={'HH:mm'}
                                                    value={moment(this.state.max)}
                                                    flag={2}
                                                    onChange={(time1, timeString) => {
                                                        let oldDate = this.state.max;
                                                        let newDate = new Date()
                                                        let time = timeString.split(':')
                                                        newDate.setHours(time[0], time[1], 0)
                                                        if (newDate.getTime() > this.state.min.getTime() && isValidDate(newDate))
                                                            this.setState({ max: newDate })
                                                        // else {
                                                        //     this.setState({ max: newDate }, () => { this.setState({ max: oldDate }) })
                                                        // }
                                                    }}
                                                    allowClear={false}
                                                /> */}


                                                <Button color='primary' className="custom-button" style={{ paddingTop: 12, paddingBottom: 12, paddingLeft: 21, paddingRight: 21 }} onClick={() => this.update()}>
                                                    {/* {'Save'.translate(this.props.lang).translate(this.props.lang)} */}
                                                    {
                                                        this.state.showPlayer ? (
                                                            <Player
                                                                onEvent={(event) => {
                                                                    if (event === "load" && this.player && this.player.current) this.stopAnimation();
                                                                }}
                                                                onStateChange={this.toggleVisibility}
                                                                ref={this.player} // set the ref to your class instance
                                                                autoplay={true}
                                                                loop={true}
                                                                controls={true}
                                                                src="https://assets8.lottiefiles.com/packages/lf20_ZSRKCF.json"
                                                                style={{ height: "auto", width: "30px" }}
                                                            ></Player>

                                                        ) : this.state.showPlayer2 ? (
                                                            <div style={{ display: 'flex', alignItems: 'center' }}><Player
                                                                onStateChange={this.toggleVisibility}
                                                                ref={this.player2} // set the ref to your class instance
                                                                autoplay={true}
                                                                loop={true}
                                                                controls={true}
                                                                src="https://assets10.lottiefiles.com/packages/lf20_CxyxLk.json"
                                                                style={{ height: "auto", width: "30px" }}
                                                            ></Player>
                                                                {'Saved'.translate(this.props.lang)}
                                                            </div>
                                                        ) : (
                                                            "Save".translate(this.props.lang)
                                                        )
                                                    }
                                                </Button>
                                            </div>
                                            {/* Vrati
                                            <div style={{ padding: 12 }}>
                                                <Button color='primary' style={{ paddingTop: 12, paddingBottom: 12, paddingLeft: 21, paddingRight: 21 }} onClick={() => this.update()}>{'Save'.translate(this.props.lang).translate(this.props.lang)}</Button>
                                            </div>
                                            */}


                                        </div>
                                        <div style={{ padding: 12, paddingRight: 0 }}>
                                            <Button className="schedule-button" color='primary' style={{ paddingTop: 12, paddingBottom: 12, paddingLeft: 21, paddingRight: 21 }} onClick={() => {
                                                this.handleSelectFree()
                                            }}>{'Enter availability'.translate(this.props.lang).translate(this.props.lang)}</Button>
                                        </div>
                                    </div>
                                    <div style={{ display: 'flex', alignItems: 'center', width: '100%', position: 'relative', justifyContent: 'space-between' }} className="mob-flex-col">
                                        <div style={{ alignItems: 'center', display: 'flex' }} className="flex search-mob-calendar">
                                            <Label>{'Search:'.translate(this.props.lang)}</Label>
                                            <Input style={{ margin: 10 }} type='text' onChange={e => {
                                                if (e.target.value == '') {
                                                    this.setState({
                                                        showFromTo: false,
                                                        searchItemNumber: 0,
                                                        day: new Date(),
                                                        searchIsOn: false,
                                                        searchParam: '',
                                                    })
                                                } else {
                                                    this.setState({
                                                        searchParam: e.target.value,
                                                        searchIsOn: true,
                                                    }, () => this.searchEvents(this.state.searchParam))
                                                }


                                            }} />
                                        </div>
                                        {this.state.showFromTo ?
                                            <div style={{ display: 'flex' }}>
                                                <Button onClick={() => {

                                                    this.setState({
                                                        searchItemNumber: this.state.searchItemNumber - 1
                                                    }, () => this.searchEvents(this.state.searchParam))


                                                }} color='primary' style={{ margin: 10 }} disabled={this.state.disabledBack}>{'Back'.translate(this.props.lang)}</Button>
                                                <p style={{ alignItems: 'center', display: 'flex', paddingTop: 15 }}>{this.state.searchItemNumber + 1} / {this.countItems(this.state.searchParam)}</p>

                                                <Button onClick={() => {

                                                    this.setState({
                                                        searchItemNumber: this.state.searchItemNumber + 1
                                                    }, () => this.searchEvents(this.state.searchParam))


                                                }} color='primary' style={{ margin: 10 }} disabled={searchCount - 1 == this.state.searchItemNumber}>{'Next'.translate(this.props.lang)}</Button>
                                            </div>
                                            : null}
                                    </div>



                                    <Calendar
                                        culture={this.props.culture}
                                        localizer={localizer}
                                        tooltipAccessor={null}
                                        views={typeof window != 'undefined' && window.innerWidth < 768 ? ['day', 'agenda'] : ['month', 'week', 'work_week', 'day', 'agenda']}
                                        messages={{
                                            noEventsInRange: 'There are no events in this range'.translate(this.props.lang),
                                            date: 'Date'.translate(this.props.lang),
                                            time: 'Time'.translate(this.props.lang),
                                            event: 'Event'.translate(this.props.lang),
                                            agenda: 'Agenda'.translate(this.props.lang),
                                            allDay: 'All day'.translate(this.props.lang),
                                            month: 'Month'.translate(this.props.lang),
                                            day: 'Day'.translate(this.props.lang),
                                            today: 'Today'.translate(this.props.lang),
                                            previous: 'Previous'.translate(this.props.lang),
                                            next: 'Next'.translate(this.props.lang),
                                            tomorrow: 'Tomorrow'.translate(this.props.lang),
                                            week: 'Week'.translate(this.props.lang),
                                            work_week: 'Work week'.translate(this.props.lang),
                                            yesterday: 'Yesterday'.translate(this.props.lang),
                                            showMore: (total) => `+${total} ${'more'.translate(this.props.lang)}`

                                        }}
                                        events={this.state.items.filter(item => item.group.toString() == this.props.selectedGroup.toString() && (item.type != 'temporaryEvent' || (item.type == 'temporaryEvent' && this.state.view == 'month'))).map(item => {
                                            let start = new Date(this.props.getStringDateTs(item.startTime, 'MM/DD/YYYY HH:mm'));
                                            let end = new Date(this.props.getStringDateTs(item.endTime, 'MM/DD/YYYY HH:mm'));
                                            // if ((new Date(item.endTime * 1000).getHours() > new Date(this.state.min).getHours() || (new Date(item.endTime * 1000).getHours() == new Date(this.state.min).getHours() && new Date(item.endTime * 1000).getMinutes() > new Date(this.state.min).getMinutes())) || this.state.view == 'agenda') // old condition
                                            if ((end?.getHours() > new Date(this.state.min).getHours() || (end?.getHours() == new Date(this.state.min).getHours() && end?.getMinutes() > new Date(this.state.min).getMinutes())) || this.state.view == 'agenda') // new condition
                                                if (!item.reserved)
                                                    return {
                                                        id: item._id,
                                                        title: (item?.type === 'take-care' && item.participants) ? (
                                                            item?.participants?.filter?.(obj => obj?.role === 'patient')?.length > 1 ? `Take Care - ${item?.participants?.filter?.(obj => obj?.role === 'patient')?.length} ${'Patients'.translate(this.props.lang)}${item?.healthcare_services?.length ? (' - ' + item?.healthcare_services?.[0]?.name || '') : ''}` : item.title
                                                        ) : item?.titleDoctor ? (
                                                            item.title?.translate(this.props.lang) + ',' + item.titleDoctor
                                                        ) : item.title.translate(this.props.lang),
                                                        titleDoctor: item.titleDoctor,
                                                        visitReasonName: item.visitReasonName,
                                                        visitReasonPrice: item?.visitReasonPrice,
                                                        questionaryPrice: item?.questionaryPrice,
                                                        baseTitle: item.title.translate(this.props.lang),
                                                        // start: new Date(item.startTime * 1000),
                                                        // end: new Date(item.endTime * 1000),
                                                        start: new Date(this.props.getStringDateTs(item.startTime, 'MM/DD/YYYY HH:mm')),
                                                        end: new Date(this.props.getStringDateTs(item.endTime, 'MM/DD/YYYY HH:mm')),
                                                        referral: item.referral,
                                                        additionalDoctors: item.additionalDoctors,
                                                        patientId: item.patient,
                                                        clinic: item.clinic,
                                                        scheduledBy: item.scheduledBy,
                                                        type: item.type,
                                                        allActiveForms: item.allActiveForms ? item.allActiveForms : false,
                                                        eventForms: item.eventForms ? item.eventForms : [],
                                                        appointmentPaid: item.appointmentPaid,
                                                        createOnWebDoc: item.createOnWebDoc,
                                                        patientNote: item.patientNote,
                                                        onlineVisit: item.onlineVisit,
                                                        patientProfile: item.patientProfile,
                                                        additionalDoctor: item.additionalDoctor,
                                                        closeCaseManually: item.closeCaseManually,
                                                        patientControlNote: item.patientControlNote,
                                                        _2fa: item?._2fa ? true : false,
                                                        takeCareData: item?.type === 'take-care' ? {
                                                            healthcare_services: item.healthcare_services || null,
                                                            participants: item.participants || null,
                                                            title: item?.healthcare_services?.[0]?.name || 'Take Care Event'.translate?.(this.props.lang),
                                                            status: item.status || null,
                                                            description: item.description || null,
                                                            comment: item.comment || null,
                                                            arbitraryTime: item.arbitraryTime
                                                        } : null,
                                                        sembleData: item?.type === 'semble' ? item.sembleData : null,
                                                        tooltip: item.title && item.patientNote ? item.title + ' | ' + 'Note'.translate(this.props.lang) + ': ' + item.patientNote :
                                                            item.title && item.patientControlNote ? item.title + ' | ' + 'Note'.translate(this.props.lang) + ': ' + item.patientControlNote :
                                                                item.title ? item.title.translate(this.props.lang) : ""
                                                    }
                                        })}
                                        selectable={this.state.view == 'month' ? false : true}
                                        onNavigate={day => {
                                            // console.log(this.state.day, day)

                                            let setObj = {
                                                day: new Date(this.props.getStringDateTs(Math.floor(new Date(day).getTime() / 1000) + 60 * 60, 'MM/DD/YYYY HH:mm')),
                                                scroll: false
                                            }
                                            // this.setState(setObj, () => this.get())
                                            this.setState({
                                                day,
                                                scroll: false
                                            }, () => this.get())

                                        }}
                                        // date={new Date(this.props.getStringDateTs(Math.floor(new Date(this.state.day).getTime() / 1000), 'MM/DD/YYYY'))}
                                        // defaultDate={new Date(this.props.getStringDateTs(Math.floor(new Date(this.state.day).getTime() / 1000), 'MM/DD/YYYY'))}
                                        date={this.state.day}
                                        defaultDate={this.state.day}
                                        onView={(view) => {
                                            let setObj = {
                                                day: new Date(this.props.getStringDateTs(Math.floor(new Date().getTime() / 1000), 'MM/DD/YYYY')),
                                                view
                                            }
                                            this.setState(setObj, () => this.get())

                                        }}
                                        view={this.state.view}
                                        step={this.state.step}
                                        timeslots={4}
                                        onSelectSlot={(event) => this.handleSelect(event)}
                                        onSelectEvent={(event) => this.selectedEvent(event)}
                                        {...calendarConf}


                                        components={{
                                            toolbar: props => (<CustomToolbar {...props} lang={this.props.lang} />),
                                            event: props => (<EventComponent {...props}
                                                getStringDateTs={this.props.getStringDateTs}
                                                daysWithDuplicate={this.state.daysWithDuplicate}
                                                setPatientProfile={(patient) => this.setState({ patientProfile: patient })}
                                                setReferral={(ref, note, title) => this.setState({ referralNote: ref, patientNote: note, noteTitle: title })}
                                                setControl={(ref, note, title) => this.setState({ controlNote: ref, patientNote: note, noteTitle: title })}
                                                view={this.state.view} lang={this.props.lang} setView={(view, date) => {
                                                    this.setState({
                                                        view,
                                                        day: date
                                                    }, () => this.get())
                                                }} />)

                                        }}

                                        eventPropGetter={event => {
                                            let backgroundColor = !event.allActiveForms && event.type === 'free' && event.eventForms && event.eventForms.length < 1 && this.props.selectedClinic === event.clinic ? 'rgba(86, 200, 138, 0.5)' : this.props.selectedClinic != event.clinic ? 'rgba(86, 138, 200, 0.5)' : event.scheduledBy == 'patient' ? '#B256C8' : event.additionalDoctor ? '#FDAB94' : event.createOnWebDoc ? '#f06565' : event.type == 'event' ? '#DBD233' : event.type == 'referral' ? event.onlineVisit ? '#3A3F56' : '#568AC8' : event.type == 'free' ? 'green' : event.onlineVisit ? '#3A3F56' : '#60BEBB';
                                            // TAKECARE
                                            if (event?.type && event.type === 'take-care' || event.type === 'semble') {
                                                backgroundColor = '#E30B5C';
                                                if (this.props.selectedClinic != event.clinic) backgroundColor = 'rgba(227, 11, 92, 0.5)';
                                            }
                                            let eventStyle = { backgroundColor, fontSize: 14, opacity: event.type == 'temporaryEvent' ? 0 : 1 };
                                            if (this.props.selectedClinic !== event?.clinic) {
                                                eventStyle.cursor = 'default';
                                            }
                                            return { style: eventStyle }
                                        }}
                                        dayPropGetter={(date) => {
                                            let dateString = moment.unix(new Date(date).getTime() / 1000).format('YYYY-MM-DD')

                                            let isToday = false;
                                            if (this.props.getStringDateTs(Math.floor(new Date().getTime() / 1000), 'YYYY-MM-DD') === dateString) {
                                                isToday = true;
                                            }

                                            date = moment.unix(new Date(date).getTime() / 1000).format('YYYY-MM-DD')
                                            return { className: `date_${dateString}_date ${isToday ? 'rbc-today' : ''}` }

                                        }}


                                    />
                                </div>
                                <div className="calendar-legend-wrap calendar-legend-mobile">
                                    <div className="legend-item">
                                        <div className="legend-color" style={{ backgroundColor: '#dbd233' }}></div>
                                        <h6>{'Event'.translate(this.props.lang)}</h6>
                                    </div>
                                    <div className="legend-item">
                                        <div className="legend-color" style={{ backgroundColor: '#568ac8' }}></div>
                                        <h6>{'Visit by referral'.translate(this.props.lang)}</h6>
                                    </div>
                                    <div className="legend-item">
                                        <div className="legend-color" style={{ backgroundColor: '#3a3f56' }}></div>
                                        <h6>{'All online visits'.translate(this.props.lang)}</h6>
                                    </div>
                                    <div className="legend-item">
                                        <div className="legend-color" style={{ backgroundColor: '#60bebb' }}></div>
                                        <h6>{'Visit without referral'.translate(this.props.lang)}</h6>
                                    </div>
                                    <div className="legend-item">
                                        <div className="legend-color" style={{ backgroundColor: 'green' }}></div>
                                        <h6>{'Available for online booking'.translate(this.props.lang)}</h6>
                                    </div>
                                    <div className="legend-item">
                                        <div className="legend-color" style={{ backgroundColor: '#fdab94' }}></div>
                                        <h6>{'Assisting to other caregiver'.translate(this.props.lang)}</h6>
                                    </div>
                                    <div className="legend-item">
                                        <div className="legend-color" style={{ backgroundColor: '#B256C8' }}></div>
                                        <h6>{'All Web booking events'.translate(this.props.lang)}</h6>
                                    </div>
                                    <div className="legend-item">
                                        <div className="legend-color" style={{ backgroundColor: 'rgba(86, 138, 200, 0.5)' }}></div>
                                        <h6>{'The term is not available'.translate(this.props.lang)}</h6>
                                    </div>
                                    {/* TAKECARE */}
                                    {this.props.hasTakeCare ? <div className="legend-item">
                                        <div className="legend-color" style={{ backgroundColor: '#E30B5C' }}></div>
                                        <h6>{'Event in Take Care Calendar'.translate(this.props.lang)}</h6>
                                    </div> : null}
                                    {/* SEMBLE */}
                                    {this.props.hasSemble ? <>
                                        <div className="legend-item">
                                            <div className="legend-color" style={{ backgroundColor: '#E30B5C' }}></div>
                                            <h6>{'Event from Semble'.translate(this.props.lang)}</h6>
                                        </div>
                                        <div className="legend-item">
                                            <div className="legend-color" style={{ backgroundColor: 'rgba(227, 11, 92, 0.5)' }}></div>
                                            <h6>{'Event from Semble in other department'.translate(this.props.lang)}</h6>
                                        </div>
                                    </> : null}
                                </div>

                            </div>

                        </Col>
                    </Row>

                </Container>

                {
                    this.state.referralNote && this.state.view == 'agenda' ?
                        <Modal isOpen={this.state.referralNote} centered>
                            <ModalHeader toggle={() => this.setState({ referralNote: null })} close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => this.setState({ referralNote: null })}>&times;</button>}>{'Add note'.translate(this.props.lang)}</ModalHeader>

                            <ModalBody>
                                <FormGroup>
                                    <Label>{this.state.noteTitle}</Label>
                                </FormGroup>
                                <FormGroup>
                                    <Label>{'Note'.translate(this.props.lang)}</Label>
                                    <Input type='textarea'
                                        name="note"
                                        style={{ height: '150px' }}
                                        onChange={(e) => {

                                            this.setState({
                                                patientNote: striptags(e.target.value)
                                            })
                                        }}
                                        value={this.state.patientNote}
                                    />
                                    <div style={{ display: 'flex', 'margin-top': '10px', 'margin-left': '-5px' }}>
                                        <Button color='primary' style={{ margin: 5 }} onClick={this.submitNote}>{'Save'.translate(this.props.lang)}</Button>
                                    </div>
                                </FormGroup>

                            </ModalBody>
                        </Modal>
                        :
                        null
                }
                {
                    this.state.controlNote && this.state.view == 'agenda' ?
                        <Modal isOpen={this.state.controlNote} centered>
                            <ModalHeader toggle={() => this.setState({ controlNote: null })} close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => this.setState({ controlNote: null })}>&times;</button>}>{'Add note'.translate(this.props.lang)}</ModalHeader>

                            <ModalBody>
                                <FormGroup>
                                    <Label>{this.state.noteTitle}</Label>
                                </FormGroup>
                                <FormGroup>
                                    <Label>{'Note'.translate(this.props.lang)}</Label>
                                    <Input type='textarea'
                                        name="note"
                                        style={{ height: '150px' }}
                                        onChange={(e) => {

                                            this.setState({
                                                patientNote: striptags(e.target.value)
                                            })
                                        }}
                                        value={this.state.patientNote}
                                    />
                                    <div style={{ display: 'flex', 'margin-top': '10px', 'margin-left': '-5px' }}>
                                        <Button color='primary' style={{ margin: 5 }} onClick={this.submitControlNote}>{'Save'.translate(this.props.lang)}</Button>
                                    </div>
                                </FormGroup>

                            </ModalBody>
                        </Modal>
                        :
                        null
                }

                {this.state.patientProfile ?
                    <Modal isOpen={this.state.patientProfile} centered>
                        <ModalHeader toggle={() => this.setState({ patientProfile: null })} close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => this.setState({ patientProfile: null })}>&times;</button>}>
                            <h4>{'Patient profile'.translate(this.props.lang)}</h4>
                        </ModalHeader>
                        <ModalBody>
                            <FormGroup>
                                <Label>{'Social security number'.translate(this.props.lang)}</Label>
                                <Input type='text' disabled
                                    value={this.state.patientProfile.socialSecurityNumber} />
                            </FormGroup>
                            <FormGroup>
                                <Label>{'Name'.translate(this.props.lang)}</Label>
                                <Input type='text' disabled
                                    value={this.state.patientProfile.name} />
                            </FormGroup>
                            <FormGroup>
                                <Label>{'Email'.translate(this.props.lang)}</Label>
                                <Input type='text' disabled
                                    value={this.state.patientProfile.email} />
                            </FormGroup>
                            <FormGroup>
                                <Label>{'Phone'.translate(this.props.lang)}</Label>
                                <Input type='text' disabled
                                    value={this.state.patientProfile.phone} />
                            </FormGroup>
                            <FormGroup>
                                <Label>{'Address'.translate(this.props.lang)}</Label>
                                <Input type='text' disabled
                                    value={this.state.patientProfile.address} />
                            </FormGroup>

                            <Button color="primary" onClick={() => this.setState({ patientProfile: !this.state.patientProfile })}>{'Close'.translate(this.props.lang)}</Button>

                        </ModalBody>
                    </Modal>

                    : null}
                {this.state.patientTimeModal ?
                    <Modal isOpen={this.state.patientTimeModal} centered>
                        <ModalHeader toggle={() => this.setState({ patientTimeModal: !this.state.patientTimeModal })} close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => this.setState({ patientTimeModal: !this.state.patientTimeModal })}>&times;</button>}>{'Pick time when you can take patients.'.translate(this.props.lang)}</ModalHeader>
                        <ModalBody>
                            <FormGroup className="timepicker-container timepicker-wide">
                                <Label>{'Start time'.translate(this.props.lang)}</Label>
                                &nbsp;
                                <TimePicker
                                    getStringDateTs={this.props.getStringDateTs}
                                    format={'HH:mm'}
                                    value={moment(this.state.freeMin)}
                                    flag={2}
                                    onChange={(time1, timeString) => {
                                        let oldDate = this.state.freeMin;
                                        let newDate = new Date()
                                        let time = timeString.split(':')
                                        newDate.setHours(time[0], time[1], 0)
                                        if (newDate.getTime() < this.state.freeMax.getTime())
                                            this.setState({ freeMin: newDate })
                                        else {
                                            this.setState({ freeMin: newDate }, () => { this.setState({ freeMin: oldDate }) })
                                        }
                                    }}
                                    allowClear={false}

                                />
                            </FormGroup>
                            <FormGroup className="timepicker-container timepicker-wide">
                                <Label>{'End time'.translate(this.props.lang)}</Label>
                                &nbsp;
                                <TimePicker
                                    getStringDateTs={this.props.getStringDateTs}
                                    format={'HH:mm'}
                                    value={moment(this.state.freeMax)}
                                    flag={2}
                                    onChange={(time1, timeString) => {
                                        let oldDate = this.state.freeMax;
                                        let newDate = new Date()
                                        let time = timeString.split(':')
                                        newDate.setHours(time[0], time[1], 0)
                                        if (newDate.getTime() > this.state.freeMin.getTime())
                                            this.setState({ freeMax: newDate })
                                        else {
                                            this.setState({ freeMax: newDate }, () => { this.setState({ freeMax: oldDate }) })
                                        }
                                    }}
                                    allowClear={false}
                                />
                            </FormGroup>

                            <Button onClick={() => this.patientTime()}>{'Schedule'.translate(this.props.lang)}</Button>
                            <Button onClick={() => this.setState({ patientTimeModal: !this.state.patientTimeModal })}>{'Cancel'.translate(this.props.lang)}</Button>
                        </ModalBody>
                    </Modal>
                    : null}

                {this.state.scheduleModal ?
                    <Modal isOpen={this.state.scheduleModal} centered>
                        <ModalHeader toggle={() => this.setState({ scheduleModal: !this.state.scheduleModal, doctorsList: [] })}
                            close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => this.setState({ scheduleModal: !this.state.scheduleModal, doctorsList: [] })}>&times;</button>}>
                            {'Select the way to schedule'.translate(this.props.lang)}
                        </ModalHeader>

                        <ModalBody>
                            <div className='schedule-modal-body-wrap'>
                                <Button color='primary'
                                    onClick={() => {
                                        this.setState({ /*scheduleEvent: this.state.scheduleModal,*/schedulePatientTime: { ...this.state.scheduleModal, type: 'event' }, scheduleModal: !this.state.scheduleModal })
                                    }}
                                >
                                    {'Schedule event'.translate(this.props.lang)}
                                </Button>
                                <Button color='primary'
                                    onClick={() => {
                                        this.setState({ /*markAsAvailable: this.state.scheduleModal,*/schedulePatientTime: { ...this.state.scheduleModal, type: 'free' }, scheduleModal: !this.state.scheduleModal })
                                    }}
                                >
                                    {'Mark as available'.translate(this.props.lang)}
                                </Button>
                                <Button color='primary'
                                    onClick={() => {
                                        this.setState({ scheduleReferral: this.state.scheduleModal, scheduleModal: !this.state.scheduleModal })
                                    }}
                                >
                                    {'Schedule approved case'.translate(this.props.lang)}
                                </Button>
                                <Button color='primary'
                                    onClick={() => {
                                        this.setState({ scheduleControl: this.state.scheduleModal, scheduleModal: !this.state.scheduleModal })
                                    }}
                                >
                                    {'Schedule a new visit / revisit'.translate(this.props.lang)}
                                </Button>

                            </div>

                        </ModalBody>
                    </Modal>

                    : null}

                {
                    this.state.markAsAvailable ?
                        <Modal isOpen={this.state.markAsAvailable} centered>
                            <ModalHeader toggle={() => this.setState({ markAsAvailable: !this.state.markAsAvailable, doctorsList: [] })} close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => this.setState({ markAsAvailable: !this.state.markAsAvailable, doctorsList: [] })}>&times;</button>}>{'Mark as available'.translate(this.props.lang)}</ModalHeader>

                            <ModalBody>


                                <DatePicker
                                    label={'Date'.translate(this.props.lang)}
                                    value={this.state.markAsAvailable.date}
                                    onChange={(e) => {
                                        let markAsAvailable = this.state.markAsAvailable;
                                        markAsAvailable.date = e;
                                        this.setState({
                                            markAsAvailable
                                        })
                                        if (Math.floor(new Date(this.state.markAsAvailable.date + ' ' + this.state.markAsAvailable.startTime) / 1000) > Math.floor(new Date(this.state.markAsAvailable.endDate + ' ' + this.state.markAsAvailable.endTime) / 1000)) {
                                            markAsAvailable.endDate = this.state.markAsAvailable.date
                                            this.setState({
                                                markAsAvailable
                                            })
                                        }
                                    }}
                                />
                                <FormGroup className="timepicker-container timepicker-wide">
                                    <Label>{'Start time'.translate(this.props.lang)}</Label>
                                    &nbsp;
                                    <TimePickerV2
                                        getStringDateTs={this.props.getStringDateTs}
                                        format={'HH:mm'}
                                        value={this.state.markAsAvailable.startTime}
                                        minValue={this.props.getStringDateTs(Math.floor(this.state.min.getTime() / 1000), 'HH:mm')}
                                        maxValue={this.props.getStringDateTs(Math.floor(this.state.max.getTime() / 1000 - 300), 'HH:mm')}
                                        flag={0}
                                        onChange={(time1, timeString) => {

                                            let markAsAvailable = this.state.markAsAvailable;
                                            markAsAvailable.startTime = timeString

                                            let startTimeSplit = markAsAvailable.startTime.split(":");
                                            let endTimeSplit = markAsAvailable.endTime.split(":");


                                            let startTimeHourMinute = Number(startTimeSplit[0]) * 60 + Number(startTimeSplit[1]);
                                            let endTimeHourMinute = Number(endTimeSplit[0]) * 60 + Number(endTimeSplit[1]);

                                            // this.setState({ markAsAvailable })
                                            if (startTimeHourMinute < endTimeHourMinute) {
                                                this.setState({ markAsAvailable })
                                            } else {
                                                markAsAvailable.endTime = Math.trunc((Number(this.state.markAsAvailable.startTime.split(':')[0]) * 60 + Number(this.state.markAsAvailable.startTime.split(':')[1]) + 5) / 60) + ":" + (Number(this.state.markAsAvailable.startTime.split(':')[0]) * 60 + Number(this.state.markAsAvailable.startTime.split(':')[1]) + 5) % 60
                                                // markAsAvailable.endTime = validateTime(markAsAvailable.endTime);
                                                const padded = markAsAvailable?.endTime?.split?.(':');
                                                if (padded && Array.isArray(padded) && padded.length && isStringAValidNumber(padded[0]) && isStringAValidNumber(padded[1])) {
                                                    const h = padded[0]?.padStart?.(2, '0');
                                                    const m = padded[1]?.padStart?.(2, '0');
                                                    if (h && m) markAsAvailable.endTime = `${h}:${m}`;
                                                }
                                                this.setState({
                                                    markAsAvailable
                                                })
                                            }


                                        }}
                                    />
                                    {/* <TimePicker
                                        log={true}
                                        getStringDateTs={this.props.getStringDateTs}
                                        format={'HH:mm'}
                                        value={moment(new Date(this.state.markAsAvailable.date.replace(/-/g, "/") + " " + this.state.markAsAvailable.startTime))}
                                        // maxValue={this.state.markAsAvailable.endTime}
                                        minValue={this.props.getStringDateTs(Math.floor(this.state.min.getTime() / 1000), 'HH:mm')} // moment.unix(this.state.min.getTime() / 1000).format("HH:mm")
                                        maxValue={this.props.getStringDateTs(Math.floor(this.state.max.getTime() / 1000), 'HH:mm')} // moment.unix(this.state.max.getTime() / 1000).format("HH:mm")
                                        flag={0}
                                        onChange={(time1, timeString) => {

                                            let markAsAvailable = this.state.markAsAvailable;
                                            markAsAvailable.startTime = timeString

                                            let startTimeSplit = markAsAvailable.startTime.split(":");
                                            let endTimeSplit = markAsAvailable.endTime.split(":");


                                            let startTimeHourMinute = Number(startTimeSplit[0]) * 60 + Number(startTimeSplit[1]);
                                            let endTimeHourMinute = Number(endTimeSplit[0]) * 60 + Number(endTimeSplit[1]);

                                            this.setState({ markAsAvailable })


                                        }}
                                        allowClear={false}
                                    /> */}
                                </FormGroup>
                                <FormGroup className="timepicker-container timepicker-wide">
                                    <Label>{'End time'.translate(this.props.lang)}</Label>
                                    &nbsp;
                                    <TimePickerV2
                                        getStringDateTs={this.props.getStringDateTs}
                                        format={'HH:mm'}
                                        value={this.state.markAsAvailable.endTime}
                                        minValue={Math.trunc((Number(this.state.markAsAvailable.startTime.split(':')[0]) * 60 + Number(this.state.markAsAvailable.startTime.split(':')[1]) + 5) / 60) + ":" + (Number(this.state.markAsAvailable.startTime.split(':')[0]) * 60 + Number(this.state.markAsAvailable.startTime.split(':')[1]) + 5) % 60}
                                        maxValue={this.props.getStringDateTs(Math.floor(this.state.max.getTime() / 1000), 'HH:mm')}
                                        flag={0}
                                        onChange={(time1, timeString) => {

                                            let markAsAvailable = this.state.markAsAvailable;
                                            markAsAvailable.endTime = timeString

                                            let startTimeSplit = markAsAvailable.startTime.split(":");
                                            let endTimeSplit = markAsAvailable.endTime.split(":");

                                            let startTimeHourMinute = Number(startTimeSplit[0]) * 60 + Number(startTimeSplit[1]);
                                            let endTimeHourMinute = Number(endTimeSplit[0]) * 60 + Number(endTimeSplit[1]);

                                            if (startTimeHourMinute < endTimeHourMinute) {
                                                this.setState({ markAsAvailable })

                                            } else {
                                            }

                                        }}
                                    />
                                    {/* <TimePicker
                                        getStringDateTs={this.props.getStringDateTs}
                                        format={'HH:mm'}
                                        value={moment(new Date(this.state.markAsAvailable.date.replace(/-/g, "/") + " " + this.state.markAsAvailable.endTime))}
                                        minValue={Math.trunc((Number(this.state.markAsAvailable.startTime.split(':')[0]) * 60 + Number(this.state.markAsAvailable.startTime.split(':')[1]) + 5) / 60) + ":" + (Number(this.state.markAsAvailable.startTime.split(':')[0]) * 60 + Number(this.state.markAsAvailable.startTime.split(':')[1]) + 5) % 60}
                                        maxValue={this.props.getStringDateTs(Math.floor(this.state.max.getTime() / 1000), 'HH:mm')} // moment.unix(this.state.max.getTime() / 1000).format("HH:mm")
                                        flag={0}
                                        // maxValue={this.state.markAsAvailable.startTime}
                                        // flag={1}
                                        onChange={(time1, timeString) => {

                                            let markAsAvailable = this.state.markAsAvailable;
                                            markAsAvailable.endTime = timeString

                                            let startTimeSplit = markAsAvailable.startTime.split(":");
                                            let endTimeSplit = markAsAvailable.endTime.split(":");

                                            let startTimeHourMinute = Number(startTimeSplit[0]) * 60 + Number(startTimeSplit[1]);
                                            let endTimeHourMinute = Number(endTimeSplit[0]) * 60 + Number(endTimeSplit[1]);

                                            if (startTimeHourMinute < endTimeHourMinute) {
                                                this.setState({ markAsAvailable })

                                            } else {
                                            }

                                        }}
                                        allowClear={false}
                                    /> */}
                                </FormGroup>
                                <FormGroup>
                                    <Checkbox
                                        onChange={(e) => {
                                            let markAsAvailable = this.state.markAsAvailable;
                                            markAsAvailable.recurring = e.target.checked;
                                            this.setState({
                                                markAsAvailable
                                            })
                                        }}
                                        checked={this.state.markAsAvailable.recurring}
                                        label={'Recurring event'.translate(this.props.lang)}
                                    />
                                </FormGroup>
                                <div style={{ display: 'flex' }}>
                                    <Button color='primary' onClick={() => {
                                        if (this.state.markAsAvailable.recurring) {
                                            this.setState({ recurringModal: { ...this.state.markAsAvailable, typeOfEvent: 2 }, markAsAvailable: null })
                                        } else {
                                            this.markAsAvailable(this.state.markAsAvailable)
                                        }

                                    }}>{'Mark as available'.translate(this.props.lang)}</Button>


                                </div>

                            </ModalBody>
                        </Modal>
                        :
                        null
                }
                {
                    this.state.scheduleEvent ?
                        <Modal isOpen={this.state.scheduleEvent} centered>
                            <ModalHeader toggle={() => this.setState({ scheduleEvent: !this.state.scheduleEvent, doctorsList: [] })} close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => this.setState({ scheduleEvent: !this.state.scheduleEvent, doctorsList: [] })}>&times;</button>}>{'Schedule event'.translate(this.props.lang)}</ModalHeader>

                            <ModalBody>
                                <FormGroup>
                                    <Label>{'Title'.translate(this.props.lang)}</Label>
                                    <Input type='text' onChange={(e) => {
                                        let scheduleEvent = this.state.scheduleEvent;
                                        scheduleEvent.title = striptags(e.target.value);
                                        this.setState({
                                            scheduleEvent
                                        })
                                    }}
                                        value={this.state.scheduleEvent.title} />
                                </FormGroup>

                                <DatePicker
                                    label={'Date'.translate(this.props.lang)}
                                    value={this.state.scheduleEvent.date}
                                    onChange={(e) => {
                                        let scheduleEvent = this.state.scheduleEvent;
                                        scheduleEvent.date = e;
                                        this.setState({
                                            scheduleEvent
                                        })
                                        if (Math.floor(new Date(this.state.scheduleEvent.date + ' ' + this.state.scheduleEvent.startTime) / 1000) > Math.floor(new Date(this.state.scheduleEvent.endDate + ' ' + this.state.scheduleEvent.endTime) / 1000)) {
                                            scheduleEvent.endDate = this.state.scheduleEvent.date
                                            this.setState({
                                                scheduleEvent
                                            })
                                        }
                                    }}
                                />
                                <FormGroup className="timepicker-container timepicker-wide">
                                    <Label>{'Start time'.translate(this.props.lang)}</Label>
                                    &nbsp;
                                    <TimePickerV2
                                        getStringDateTs={this.props.getStringDateTs}
                                        format={'HH:mm'}
                                        value={this.state.scheduleEvent.startTime}
                                        minValue={this.props.getStringDateTs(Math.floor(this.state.min.getTime() / 1000), 'HH:mm')}
                                        maxValue={this.props.getStringDateTs(Math.floor(this.state.max.getTime() / 1000 - 300), 'HH:mm')}
                                        flag={0}
                                        onChange={(time1, timeString) => {

                                            let scheduleEvent = this.state.scheduleEvent;
                                            scheduleEvent.startTime = timeString

                                            let startTimeSplit = scheduleEvent.startTime.split(":");
                                            let endTimeSplit = scheduleEvent.endTime.split(":");


                                            let startTimeHourMinute = Number(startTimeSplit[0]) * 60 + Number(startTimeSplit[1]);
                                            let endTimeHourMinute = Number(endTimeSplit[0]) * 60 + Number(endTimeSplit[1]);

                                            // this.setState({ scheduleEvent })
                                            if (startTimeHourMinute < endTimeHourMinute) {
                                                this.setState({ scheduleEvent })
                                            } else {
                                                scheduleEvent.endTime = Math.trunc((Number(this.state.scheduleEvent.startTime.split(':')[0]) * 60 + Number(this.state.scheduleEvent.startTime.split(':')[1]) + 5) / 60) + ":" + (Number(this.state.scheduleEvent.startTime.split(':')[0]) * 60 + Number(this.state.scheduleEvent.startTime.split(':')[1]) + 5) % 60
                                                // scheduleEvent.endTime = validateTime(scheduleEvent.endTime);
                                                const padded = scheduleEvent?.endTime?.split?.(':');
                                                if (padded && Array.isArray(padded) && padded.length && isStringAValidNumber(padded[0]) && isStringAValidNumber(padded[1])) {
                                                    const h = padded[0]?.padStart?.(2, '0');
                                                    const m = padded[1]?.padStart?.(2, '0');
                                                    if (h && m) scheduleEvent.endTime = `${h}:${m}`;
                                                }
                                                this.setState({
                                                    scheduleEvent
                                                })
                                            }


                                        }}
                                    />
                                    {/* <TimePicker
                                        getStringDateTs={this.props.getStringDateTs}
                                        format={'HH:mm'}
                                        value={moment(new Date(this.state.scheduleEvent.date.replace(/-/g, "/") + " " + this.state.scheduleEvent.startTime))}
                                        // maxValue={this.state.scheduleEvent.endTime}
                                        minValue={this.props.getStringDateTs(Math.floor(this.state.min.getTime() / 1000), 'HH:mm')} // moment.unix(this.state.min.getTime() / 1000).format("HH:mm")
                                        maxValue={this.props.getStringDateTs(Math.floor(this.state.max.getTime() / 1000), 'HH:mm')} // moment.unix(this.state.max.getTime() / 1000).format("HH:mm")
                                        flag={0}
                                        onChange={(time1, timeString) => {

                                            let scheduleEvent = this.state.scheduleEvent;
                                            scheduleEvent.startTime = timeString

                                            let startTimeSplit = scheduleEvent.startTime.split(":");
                                            let endTimeSplit = scheduleEvent.endTime.split(":");


                                            let startTimeHourMinute = Number(startTimeSplit[0]) * 60 + Number(startTimeSplit[1]);
                                            let endTimeHourMinute = Number(endTimeSplit[0]) * 60 + Number(endTimeSplit[1]);

                                            this.setState({ scheduleEvent })


                                        }}
                                        allowClear={false}
                                    /> */}
                                </FormGroup>
                                <FormGroup className="timepicker-container timepicker-wide">
                                    <Label>{'End time'.translate(this.props.lang)}</Label>
                                    &nbsp;
                                    <TimePickerV2
                                        getStringDateTs={this.props.getStringDateTs}
                                        format={'HH:mm'}
                                        value={this.state.scheduleEvent.endTime}
                                        minValue={Math.trunc((Number(this.state.scheduleEvent.startTime.split(':')[0]) * 60 + Number(this.state.scheduleEvent.startTime.split(':')[1]) + 5) / 60) + ":" + (Number(this.state.scheduleEvent.startTime.split(':')[0]) * 60 + Number(this.state.scheduleEvent.startTime.split(':')[1]) + 5) % 60}
                                        maxValue={this.props.getStringDateTs(Math.floor(this.state.max.getTime() / 1000), 'HH:mm')}
                                        flag={0}
                                        onChange={(time1, timeString) => {

                                            let scheduleEvent = this.state.scheduleEvent;
                                            scheduleEvent.endTime = timeString

                                            let startTimeSplit = scheduleEvent.startTime.split(":");
                                            let endTimeSplit = scheduleEvent.endTime.split(":");

                                            let startTimeHourMinute = Number(startTimeSplit[0]) * 60 + Number(startTimeSplit[1]);
                                            let endTimeHourMinute = Number(endTimeSplit[0]) * 60 + Number(endTimeSplit[1]);

                                            if (startTimeHourMinute < endTimeHourMinute) {
                                                this.setState({ scheduleEvent })

                                            } else {
                                            }

                                        }}
                                    />
                                    {/* <TimePicker
                                        getStringDateTs={this.props.getStringDateTs}
                                        format={'HH:mm'}
                                        value={moment(new Date(this.state.scheduleEvent.date.replace(/-/g, "/") + " " + this.state.scheduleEvent.endTime))}
                                        minValue={Math.trunc((Number(this.state.scheduleEvent.startTime.split(':')[0]) * 60 + Number(this.state.scheduleEvent.startTime.split(':')[1]) + 5) / 60) + ":" + (Number(this.state.scheduleEvent.startTime.split(':')[0]) * 60 + Number(this.state.scheduleEvent.startTime.split(':')[1]) + 5) % 60}
                                        maxValue={this.props.getStringDateTs(Math.floor(this.state.max.getTime() / 1000), 'HH:mm')} // moment.unix(this.state.max.getTime() / 1000).format("HH:mm")
                                        flag={0}
                                        // maxValue={this.state.scheduleEvent.startTime}
                                        // flag={1}
                                        onChange={(time1, timeString) => {

                                            let scheduleEvent = this.state.scheduleEvent;
                                            scheduleEvent.endTime = timeString

                                            let startTimeSplit = scheduleEvent.startTime.split(":");
                                            let endTimeSplit = scheduleEvent.endTime.split(":");

                                            let startTimeHourMinute = Number(startTimeSplit[0]) * 60 + Number(startTimeSplit[1]);
                                            let endTimeHourMinute = Number(endTimeSplit[0]) * 60 + Number(endTimeSplit[1]);

                                            if (startTimeHourMinute < endTimeHourMinute) {
                                                this.setState({ scheduleEvent })

                                            } else {
                                            }

                                        }}
                                        allowClear={false}
                                    /> */}
                                </FormGroup>
                                <FormGroup>
                                    <Checkbox
                                        onChange={(e) => {
                                            let scheduleEvent = this.state.scheduleEvent;
                                            scheduleEvent.recurring = e.target.checked;
                                            this.setState({
                                                scheduleEvent
                                            })
                                        }}
                                        checked={this.state.scheduleEvent.recurring}
                                        label={'Recurring event'.translate(this.props.lang)}
                                    />
                                </FormGroup>
                                <div style={{ display: 'flex' }}>
                                    <Button color='primary' disabled={this.state.scheduleEvent.title ? false : true} onClick={() => {
                                        if (this.state.scheduleEvent.recurring) {
                                            this.setState({ recurringModal: { ...this.state.scheduleEvent, typeOfEvent: 1 }, scheduleEvent: null })
                                        } else {
                                            this.scheduleEvent(this.state.scheduleEvent)
                                        }

                                    }}>{'Schedule event'.translate(this.props.lang)}</Button>


                                </div>

                            </ModalBody>
                        </Modal>
                        :
                        null
                }
                {
                    this.state.recurringModal ?
                        <Modal isOpen={this.state.recurringModal} centered>
                            <ModalHeader style={{ marginLeft: 0 }} close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => this.setState({ recurringModal: null, insertFormFormular: null })}><Isvg src={xIcon} /></button>}>
                                {'Recurring event'.translate(this.props.lang)}
                            </ModalHeader>
                            <ModalBody className="form-feedback-wrap-display-block" style={{ paddingTop: 0 }}>

                                <div style={{ marginTop: 10 }}>
                                    <Row>
                                        <Col lg="12">
                                            <Label >{'Frequency'.translate(this.props.lang) + '*'}</Label>
                                            <Input type='select' onChange={(e) => {
                                                let val = null;
                                                if (e.target.value != -1) {
                                                    val = e.target.value;
                                                }
                                                if (!showRecurringOptions && ['week', 'month'].includes(val)) return;
                                                let recurringModal = this.state.recurringModal;
                                                recurringModal.frequencyType = e.target.value;
                                                let date = moment(new Date()).format(this.props.dateFormat);
                                                if (recurringModal.date) {
                                                    date = moment(new Date(recurringModal.date)).format(this.props.dateFormat);
                                                }
                                                if (val == 'day') {
                                                    recurringModal.frequencyDayScheduledDateFrom = date;
                                                    recurringModal.frequencyDayScheduledDateTo = date
                                                } else if (val == 'week') {
                                                    recurringModal.frequencyWeekScheduledDateFrom = date;
                                                    recurringModal.frequencyWeekScheduledDateTo = moment.unix(Math.floor(new Date(date).getTime() / 1000) + 7 * 24 * 60 * 60).format(this.props.dateFormat);
                                                } else if (val == 'month') {
                                                    recurringModal.frequencyMonthScheduledDateFrom = date;
                                                    recurringModal.frequencyMonthScheduledDateTo = moment.unix(Math.floor(new Date(date).getTime() / 1000) + 30 * 24 * 60 * 60).format(this.props.dateFormat);
                                                }
                                                this.setState({
                                                    recurringModal,
                                                    errorEvent: null
                                                })
                                            }}>
                                                <option value={-1}>
                                                    {'Choose frequency'.translate(this.props.lang)}
                                                </option>
                                                <option value={'day'}>
                                                    {'Day'.translate(this.props.lang)}
                                                </option>
                                                {showRecurringOptions ? <>
                                                    <option value={'week'}>
                                                        {'Week'.translate(this.props.lang)}
                                                    </option>
                                                    <option value={'month'}>
                                                        {'Month'.translate(this.props.lang)}
                                                    </option>
                                                </> : null}
                                            </Input>
                                        </Col>
                                    </Row>
                                    {this.state.errorEvent ? <FormFeedback >{this.state.errorEvent}</FormFeedback> : null}
                                </div>

                                {this.state.recurringModal.frequencyType == 'day' ?
                                    <div>
                                        <Row>
                                            <Col lg="12">
                                                <div style={{ display: 'flex', textAlign: 'center', alignItems: 'center', marginBottom: 5, marginTop: 10 }}>
                                                    <Label style={{ marginRight: 5 }}>{'Every'.translate(this.props.lang)}</Label>
                                                    <Input style={{ width: '25%' }} type='text' value={this.state.recurringModal.frequencyDayText} onChange={(e) => {
                                                        let value = e.target.value
                                                        value = value.replace(/[^0-9]/g, '')

                                                        let recurringModal = this.state.recurringModal;
                                                        recurringModal.frequencyDayText = value;
                                                        this.setState({
                                                            recurringModal
                                                        })
                                                    }} />
                                                    <Label style={{ marginLeft: 5 }}>{'day(s)'.translate(this.props.lang) + '*'}</Label>
                                                </div>
                                                {this.state.errorEvent ? <FormFeedback >{this.state.errorEvent}</FormFeedback> : null}
                                                <DatePicker
                                                    label={'Start date'.translate(this.props.lang) + '*'}
                                                    minDate={new Date()}
                                                    dateFormat={this.props.dateFormat}
                                                    value={this.state.recurringModal.frequencyDayScheduledDateFrom ? this.state.recurringModal.frequencyDayScheduledDateFrom : moment(new Date()).format(this.props.dateFormat)}
                                                    onChange={(e) => {
                                                        let recurringModal = this.state.recurringModal;
                                                        recurringModal.frequencyDayScheduledDateFrom = e;
                                                        this.setState({
                                                            recurringModal
                                                        })

                                                        if (this.state.recurringModal.frequencyDayScheduledDateTo && this.state.recurringModal.frequencyDayScheduledDateFrom) {
                                                            let dateTo = new Date(this.state.recurringModal.frequencyDayScheduledDateTo)
                                                            let dateFrom = new Date(this.state.recurringModal.frequencyDayScheduledDateFrom)
                                                            if (dateFrom.getTime() > dateTo.getTime() || this.calculateMaxDateHotfix(this.state.recurringModal.frequencyDayScheduledDateFrom)) {
                                                                let recurringModal = this.state.recurringModal;
                                                                recurringModal.frequencyDayScheduledDateTo = this.state.recurringModal.frequencyDayScheduledDateFrom;
                                                                this.setState({
                                                                    recurringModal
                                                                })

                                                            }
                                                        } else if (!this.state.recurringModal.frequencyDayScheduledDateTo && this.state.recurringModal.frequencyDayScheduledDateFrom) {
                                                            let recurringModal = this.state.recurringModal;
                                                            recurringModal.frequencyDayScheduledDateTo = this.state.recurringModal.frequencyDayScheduledDateFrom;
                                                            this.setState({
                                                                recurringModal
                                                            })
                                                        }

                                                    }}
                                                />
                                                {this.state.errorEvent ? <FormFeedback >{this.state.errorEvent}</FormFeedback> : null}
                                            </Col>
                                            <Col lg="12">
                                                <div style={{ marginTop: 5 }}>
                                                    <DatePicker
                                                        label={'End date'.translate(this.props.lang) + '*'}
                                                        dateFormat={this.props.dateFormat}
                                                        minDate={new Date(this.state.recurringModal.frequencyDayScheduledDateFrom) != 'Invalid Date' ? new Date(this.state.recurringModal.frequencyDayScheduledDateFrom) : new Date()}
                                                        maxDate={this.calculateMaxDateHotfix(this.state.recurringModal.frequencyDayScheduledDateFrom)}
                                                        value={this.state.recurringModal.frequencyDayScheduledDateTo ? this.state.recurringModal.frequencyDayScheduledDateTo : moment(new Date()).format(this.props.dateFormat)}
                                                        onChange={(e) => {
                                                            let recurringModal = this.state.recurringModal;
                                                            recurringModal.frequencyDayScheduledDateTo = e;
                                                            this.setState({
                                                                recurringModal
                                                            })

                                                        }}
                                                    />
                                                </div>
                                                {this.state.errorEvent ? <FormFeedback >{this.state.errorEvent}</FormFeedback> : null}
                                            </Col>
                                        </Row>

                                    </div>
                                    : null}


                                {this.state.recurringModal.frequencyType == 'week' ?
                                    <div style={{ marginTop: 10, marginBottom: 15 }}>
                                        <Row>
                                            <Col lg="12">
                                                <Checkbox
                                                    onChange={(e) => {
                                                        let recurringModal = this.state.recurringModal;
                                                        recurringModal.checkedMonday = e.target.checked
                                                        this.setState({
                                                            recurringModal
                                                        })
                                                    }}
                                                    checked={this.state.recurringModal.checkedMonday}
                                                    label={'Monday'.translate(this.props.lang)}
                                                />
                                                <Checkbox
                                                    onChange={(e) => {
                                                        let recurringModal = this.state.recurringModal;
                                                        recurringModal.checkedTuesday = e.target.checked
                                                        this.setState({
                                                            recurringModal
                                                        })
                                                    }}
                                                    checked={this.state.recurringModal.checkedTuesday}
                                                    label={'Tuesday'.translate(this.props.lang)}
                                                />
                                                <Checkbox
                                                    onChange={(e) => {
                                                        let recurringModal = this.state.recurringModal;
                                                        recurringModal.checkedWednesday = e.target.checked
                                                        this.setState({
                                                            recurringModal
                                                        })
                                                    }}
                                                    checked={this.state.recurringModal.checkedWednesday}
                                                    label={'Wednesday'.translate(this.props.lang)}
                                                />
                                                <Checkbox
                                                    onChange={(e) => {
                                                        let recurringModal = this.state.recurringModal;
                                                        recurringModal.checkedThursday = e.target.checked
                                                        this.setState({
                                                            recurringModal
                                                        })
                                                    }}
                                                    checked={this.state.recurringModal.checkedThursday}
                                                    label={'Thursday'.translate(this.props.lang)}
                                                />
                                                <Checkbox
                                                    onChange={(e) => {
                                                        let recurringModal = this.state.recurringModal;
                                                        recurringModal.checkedFriday = e.target.checked
                                                        this.setState({
                                                            recurringModal
                                                        })
                                                    }}
                                                    checked={this.state.recurringModal.checkedFriday}
                                                    label={'Friday'.translate(this.props.lang)}
                                                />
                                                <Checkbox
                                                    onChange={(e) => {
                                                        let recurringModal = this.state.recurringModal;
                                                        recurringModal.checkedSaturday = e.target.checked
                                                        this.setState({
                                                            recurringModal
                                                        })
                                                    }}
                                                    checked={this.state.recurringModal.checkedSaturday}
                                                    label={'Saturday'.translate(this.props.lang)}
                                                />
                                                <Checkbox
                                                    onChange={(e) => {
                                                        let recurringModal = this.state.recurringModal;
                                                        recurringModal.checkedSunday = e.target.checked
                                                        this.setState({
                                                            recurringModal
                                                        })
                                                    }}
                                                    checked={this.state.recurringModal.checkedSunday}
                                                    label={'Sunday'.translate(this.props.lang)}
                                                />
                                                {this.state.errorEvent ? <FormFeedback >{this.state.errorEvent}</FormFeedback> : null}
                                                <DatePicker
                                                    label={'Start date'.translate(this.props.lang) + '*'}
                                                    minDate={new Date()}
                                                    dateFormat={this.props.dateFormat}
                                                    // completeStaeInput={this.state.recurringModal}
                                                    value={this.state.recurringModal.frequencyWeekScheduledDateFrom ? this.state.recurringModal.frequencyWeekScheduledDateFrom : moment(new Date()).format(this.props.dateFormat)}
                                                    onChange={(e) => {
                                                        let recurringModal = this.state.recurringModal;
                                                        recurringModal.frequencyWeekScheduledDateFrom = e;
                                                        this.setState({
                                                            recurringModal
                                                        })

                                                        if (this.state.recurringModal.frequencyWeekScheduledDateTo && this.state.recurringModal.frequencyWeekScheduledDateFrom) {
                                                            let dateTo = new Date(this.state.recurringModal.frequencyWeekScheduledDateTo)
                                                            let dateFrom = new Date(this.state.recurringModal.frequencyWeekScheduledDateFrom)
                                                            if (dateFrom.getTime() + 1000 * 60 * 60 * 24 * 7 > dateTo.getTime()) {
                                                                let recurringModal = this.state.recurringModal;
                                                                let date = new Date(this.state.recurringModal.frequencyWeekScheduledDateFrom)
                                                                date.setDate(date.getDate() + 7)
                                                                recurringModal.frequencyWeekScheduledDateTo = moment(new Date(date)).format(this.props.dateFormat ? this.props.dateFormat : 'YYYY-MM-DD');
                                                                this.setState({
                                                                    recurringModal
                                                                })

                                                            }
                                                        } else if (!this.state.recurringModal.frequencyWeekScheduledDateTo && this.state.recurringModal.frequencyWeekScheduledDateFrom) {
                                                            let recurringModal = this.state.recurringModal;
                                                            let date = new Date(this.state.recurringModal.frequencyWeekScheduledDateFrom)
                                                            date.setDate(date.getDate() + 7)
                                                            recurringModal.frequencyWeekScheduledDateTo = moment(new Date(date)).format(this.props.dateFormat ? this.props.dateFormat : 'YYYY-MM-DD');
                                                            this.setState({
                                                                recurringModal
                                                            })
                                                        }

                                                    }}
                                                />
                                                {this.state.errorEvent ? <FormFeedback >{this.state.errorEvent}</FormFeedback> : null}
                                            </Col>
                                            <Col lg="12">
                                                <DatePicker
                                                    label={'End date'.translate(this.props.lang) + '*'}
                                                    dateFormat={this.props.dateFormat}
                                                    minDate={new Date(this.state.recurringModal.frequencyWeekScheduledDateFrom) != 'Invalid Date' ? new Date(this.state.recurringModal.frequencyWeekScheduledDateFrom) : new Date(this.state.weekDate)}
                                                    // completeStaeInput={this.state.recurringModal}
                                                    value={this.state.recurringModal.frequencyWeekScheduledDateTo ? this.state.recurringModal.frequencyWeekScheduledDateTo : moment(this.state.weekDate).format(this.props.dateFormat)}
                                                    onChange={(e) => {
                                                        let recurringModal = this.state.recurringModal;
                                                        recurringModal.frequencyWeekScheduledDateTo = e;
                                                        this.setState({
                                                            recurringModal
                                                        })

                                                    }}
                                                />
                                                {this.state.errorEvent ? <FormFeedback >{this.state.errorEvent}</FormFeedback> : null}
                                            </Col>
                                        </Row>
                                    </div>
                                    : null}
                                {this.state.recurringModal.frequencyType == 'month' ?
                                    <div style={{ marginBottom: 5 }}>
                                        <Row>
                                            <Col lg="12">
                                                <div style={{ display: 'flex', textAlign: 'center', alignItems: 'center', marginBottom: 5, marginTop: 10 }}>
                                                    <Label style={{ marginRight: 5 }}>{'Every'.translate(this.props.lang)}</Label>
                                                    <Input style={{ width: '30%' }} type='select' onChange={(e) => {
                                                        let val = null;
                                                        if (e.target.value != '-1') {
                                                            val = e.target.value;
                                                        }
                                                        let recurringModal = this.state.recurringModal;
                                                        recurringModal.frequencyMonthScheduled = val;
                                                        this.setState({
                                                            recurringModal
                                                        })
                                                    }}>
                                                        <option value={'-1'}>

                                                        </option>
                                                        <option value={'1'}>
                                                            {'1st'.translate(this.props.lang)}
                                                        </option>
                                                        <option value={'2'}>
                                                            {'2nd'.translate(this.props.lang)}
                                                        </option>
                                                        <option value={'3'}>
                                                            {'3rd'.translate(this.props.lang)}
                                                        </option>
                                                        <option value={'4'}>
                                                            {'4th'.translate(this.props.lang)}
                                                        </option>
                                                        <option value={'5'}>
                                                            {'5th'.translate(this.props.lang)}
                                                        </option>
                                                        <option value={'6'}>
                                                            {'6th'.translate(this.props.lang)}
                                                        </option>
                                                        <option value={'7'}>
                                                            {'7th'.translate(this.props.lang)}
                                                        </option>
                                                        <option value={'8'}>
                                                            {'8th'.translate(this.props.lang)}
                                                        </option>
                                                        <option value={'9'}>
                                                            {'9th'.translate(this.props.lang)}
                                                        </option>
                                                        <option value={'10'}>
                                                            {'10th'.translate(this.props.lang)}
                                                        </option>
                                                        <option value={'11'}>
                                                            {'11th'.translate(this.props.lang)}
                                                        </option>
                                                        <option value={'12'}>
                                                            {'12th'.translate(this.props.lang)}
                                                        </option>
                                                        <option value={'13'}>
                                                            {'13th'.translate(this.props.lang)}
                                                        </option>
                                                        <option value={'14'}>
                                                            {'14th'.translate(this.props.lang)}
                                                        </option>
                                                        <option value={'15'}>
                                                            {'15th'.translate(this.props.lang)}
                                                        </option>
                                                        <option value={'16'}>
                                                            {'16th'.translate(this.props.lang)}
                                                        </option>
                                                        <option value={'17'}>
                                                            {'17th'.translate(this.props.lang)}
                                                        </option>
                                                        <option value={'18'}>
                                                            {'18th'.translate(this.props.lang)}
                                                        </option>
                                                        <option value={'19'}>
                                                            {'19th'.translate(this.props.lang)}
                                                        </option>
                                                        <option value={'20'}>
                                                            {'20th'.translate(this.props.lang)}
                                                        </option>
                                                        <option value={'21'}>
                                                            {'21st'.translate(this.props.lang)}
                                                        </option>
                                                        <option value={'22'}>
                                                            {'22nd'.translate(this.props.lang)}
                                                        </option>
                                                        <option value={'23'}>
                                                            {'23rd'.translate(this.props.lang)}
                                                        </option>
                                                        <option value={'24'}>
                                                            {'24th'.translate(this.props.lang)}
                                                        </option>
                                                        <option value={'25'}>
                                                            {'25th'.translate(this.props.lang)}
                                                        </option>
                                                        <option value={'26'}>
                                                            {'26th'.translate(this.props.lang)}
                                                        </option>
                                                        <option value={'27'}>
                                                            {'27th'.translate(this.props.lang)}
                                                        </option>
                                                        <option value={'28'}>
                                                            {'28th'.translate(this.props.lang)}
                                                        </option>
                                                        <option value={'29'}>
                                                            {'29th'.translate(this.props.lang)}
                                                        </option>
                                                        <option value={'30'}>
                                                            {'30th'.translate(this.props.lang)}
                                                        </option>
                                                        <option value={'31'}>
                                                            {'31st'.translate(this.props.lang)}
                                                        </option>

                                                    </Input>
                                                    <Label style={{ marginLeft: 5 }}>{'day of the month'.translate(this.props.lang) + '*'}</Label>
                                                </div>
                                                {this.state.errorEvent ? <FormFeedback >{this.state.errorEvent}</FormFeedback> : null}
                                                <DatePicker
                                                    label={'Start date'.translate(this.props.lang) + '*'}
                                                    minDate={new Date()}
                                                    dateFormat={this.props.dateFormat}
                                                    value={this.state.recurringModal.frequencyMonthScheduledDateFrom ? this.state.recurringModal.frequencyMonthScheduledDateFrom : moment(new Date()).format(this.props.dateFormat)}
                                                    onChange={(e) => {
                                                        let recurringModal = this.state.recurringModal;
                                                        recurringModal.frequencyMonthScheduledDateFrom = e;
                                                        this.setState({
                                                            recurringModal
                                                        })

                                                        if (this.state.recurringModal.frequencyMonthScheduledDateTo && this.state.recurringModal.frequencyMonthScheduledDateFrom) {
                                                            let dateTo = new Date(this.state.recurringModal.frequencyMonthScheduledDateTo)
                                                            let dateFrom = new Date(this.state.recurringModal.frequencyMonthScheduledDateFrom)
                                                            if (dateFrom.getTime() + 1000 * 60 * 60 * 24 * 31 > dateTo.getTime()) {
                                                                let recurringModal = this.state.recurringModal;
                                                                let date = new Date(this.state.recurringModal.frequencyMonthScheduledDateFrom)
                                                                date.setDate(date.getDate() + 31)
                                                                recurringModal.frequencyMonthScheduledDateTo = moment(new Date(date)).format(this.props.dateFormat ? this.props.dateFormat : 'YYYY-MM-DD');
                                                                this.setState({
                                                                    recurringModal
                                                                })

                                                            }
                                                        } else if (!this.state.recurringModal.frequencyMonthScheduledDateTo && this.state.recurringModal.frequencyMonthScheduledDateFrom) {
                                                            let recurringModal = this.state.recurringModal;
                                                            let date = new Date(this.state.recurringModal.frequencyMonthScheduledDateFrom)
                                                            date.setDate(date.getDate() + 31)
                                                            recurringModal.frequencyMonthScheduledDateTo = moment(new Date(date)).format(this.props.dateFormat ? this.props.dateFormat : 'YYYY-MM-DD');
                                                            this.setState({
                                                                recurringModal
                                                            })
                                                        }
                                                    }}
                                                />
                                                {this.state.errorEvent ? <FormFeedback >{this.state.errorEvent}</FormFeedback> : null}
                                            </Col>
                                            <Col lg="12">
                                                <DatePicker
                                                    label={'End date'.translate(this.props.lang) + '*'}
                                                    dateFormat={this.props.dateFormat}
                                                    minDate={new Date(this.state.recurringModal.frequencyMonthScheduledDateFrom) != 'Invalid Date' ? new Date(this.state.recurringModal.frequencyMonthScheduledDateFrom) : new Date(this.state.monthDate)}
                                                    value={this.state.recurringModal.frequencyMonthScheduledDateTo ? this.state.recurringModal.frequencyMonthScheduledDateTo : moment(this.state.monthDate).format(this.props.dateFormat)}
                                                    onChange={(e) => {
                                                        let recurringModal = this.state.recurringModal;
                                                        recurringModal.frequencyMonthScheduledDateTo = e;
                                                        this.setState({
                                                            recurringModal
                                                        })

                                                    }}
                                                />
                                                {this.state.errorEvent ? <FormFeedback >{this.state.errorEvent}</FormFeedback> : null}
                                            </Col>
                                        </Row>
                                    </div>
                                    : null}

                            </ModalBody>
                            <ModalFooter style={{ justifyContent: 'left', marginBottom: 20 }}>
                                <Button

                                    color='primary'
                                    disabled={!this.state.loadingScheduleEvent && this.state.recurringModal && this.state.recurringModal.frequencyType && (
                                        (this.state.recurringModal.frequencyType == 'day' && this.state.recurringModal.frequencyDayText && this.state.recurringModal.frequencyDayScheduledDateFrom && this.state.recurringModal.frequencyDayScheduledDateTo)
                                        || (this.state.recurringModal.frequencyType == 'month' && this.state.recurringModal.frequencyMonthScheduled && this.state.recurringModal.frequencyMonthScheduledDateFrom && this.state.recurringModal.frequencyMonthScheduledDateTo)
                                        || (this.state.recurringModal.frequencyType == 'week' && (this.state.recurringModal.checkedMonday || this.state.recurringModal.checkedTuesday || this.state.recurringModal.checkedThursday || this.state.recurringModal.checkedWednesday || this.state.recurringModal.checkedFriday || this.state.recurringModal.checkedSaturday || this.state.recurringModal.checkedSunday) && this.state.recurringModal.frequencyWeekScheduledDateFrom && this.state.recurringModal.frequencyWeekScheduledDateTo)
                                    )
                                        ? false : true}
                                    onClick={() => {
                                        if (this.state.recurringModal.typeOfEvent === 1) {
                                            this.scheduleEvent(this.state.recurringModal)
                                        } else if (this.state.recurringModal.typeOfEvent === 2) {
                                            this.markAsAvailable(this.state.recurringModal)
                                        }


                                    }}
                                >
                                    {this.state.loadingScheduleEvent ? /*'Processing...'.translate(this.props.lang)*/

                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <Player
                                                onEvent={() => {
                                                    if (!this.state.loadingScheduleEvent) this.stopAnimation();
                                                }}
                                                onStateChange={this.toggleVisibility}
                                                ref={this.player} // set the ref to your class instance
                                                autoplay={true}
                                                loop={true}
                                                controls={true}
                                                src="https://assets8.lottiefiles.com/packages/lf20_ZSRKCF.json"
                                                style={{ height: "20px", width: "30px" }}
                                            ></Player>
                                            {'Processing...'.translate(this.props.lang)}
                                        </div>

                                        : 'Confirm'.translate(this.props.lang)}
                                    {/* {'Confirm'.translate(this.props.lang)} */}
                                </Button>
                            </ModalFooter>
                        </Modal>
                        :
                        null
                }

                {this.state.scheduleReferral ?
                    <Modal isOpen={this.state.scheduleReferral} centered>
                        <ModalHeader toggle={() => this.setState({ scheduleReferral: !this.state.scheduleReferral, doctorsList: [] })} close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => this.setState({ scheduleReferral: !this.state.scheduleReferral, doctorsList: [] })}>&times;</button>}>{'Schedule referral'.translate(this.props.lang)}</ModalHeader>

                        <ModalBody>
                            <FormGroup>
                                <Label>{'Patient'.translate(this.props.lang)}</Label>
                                {/* <Input type='select' onChange={e => this.setState({ referralId: e.target.value != -1 ? e.target.value : null }, () => {
                                    let index = this.state.referrals.findIndex(ref => ref._id.toString() == this.state.referralId.toString())
                                    if (this.state.referrals[index] && this.state.referrals[index].status == 'canceled-by-clinic') {
                                        let scheduleReferral = this.state.scheduleReferral;
                                        scheduleReferral.controlSchedule = this.state.referrals[index];
                                        this.setState({
                                            scheduleReferral
                                        })
                                    }
                                })}>
                                    <option value={-1}>{'None'.translate(this.props.lang)}</option>
                                    {this.state.referrals.filter(item => (item.status == 'allowed' || item.status == 'not-booked' || item.status == 'canceled-by-clinic'  || item.status === 'requested-new-appointment') && item.recipientClinic == this.props.selectedClinic).map(item => {

                                        return <option value={item._id}>

                                            {item.patient && item.patient.referralNumber} - {item.patient.name}{" " + humanReadable[item.status].translate(this.props.lang)}{item.onlineVisit ? (' - ' + 'Online'.translate(this.props.lang)) : ''}

                                        </option>
                                    })}

                                </Input> */}

                                <div className={this.state.selectOpen ? 'custom-select-with-search-open' : 'custom-select-with-search-closed'} onClick={() => {
                                    this.setState({
                                        selectOpen: !this.state.selectOpen
                                    })
                                }}>
                                    {this.state.referrals.filter(item => (item.status == 'allowed' || item.status == 'not-booked' || item.status == 'canceled-by-clinic' || item.status === 'requested-new-appointment') && item.recipientClinic == this.props.selectedClinic).map(item => {
                                        if (item._id === this.state.referralId && this.state.referralId)
                                            return <div>

                                                {item.patient && item.patient.referralNumber} - {item.patient.name}{" " + humanReadable[item.status].translate(this.props.lang)}{item.onlineVisit ? (' - ' + 'Online'.translate(this.props.lang)) : ''}

                                            </div>
                                    })}

                                </div>

                                {
                                    this.state.selectOpen ?
                                        <div className='custom-select-with-search-items'>
                                            <CustomSelectWithSearch
                                                lang={this.props.lang}
                                                items={this.state.referrals.filter(item => (item.status == 'allowed' || item.status == 'not-booked' || item.status == 'canceled-by-clinic' || item.status === 'requested-new-appointment') && item.recipientClinic == this.props.selectedClinic && this.state.doctor != item.patientId).map((item, idx) => {
                                                    return {
                                                        name: item.patient && item.patient.referralNumber + ' - ' + item.patient.name + " " + humanReadable[item.status].translate(this.props.lang) + (item.onlineVisit ? (' - ' + 'Online'.translate(this.props.lang)) : ''),
                                                        value: item._id
                                                    }
                                                })}
                                                value={this.state.referralId}
                                                maxHeight={'250px'}
                                                onChange={e => this.setState({ referralId: e.value != -1 ? e.value : null }, () => {
                                                    let index = this.state.referrals.findIndex(ref => ref._id.toString() == this.state.referralId.toString())
                                                    if (this.state.referrals[index] && /*this.state.referrals[index].status == 'canceled-by-clinic'*/ this.state.referrals[index].remissType === 'Revisit') {
                                                        let scheduleReferral = this.state.scheduleReferral;
                                                        scheduleReferral.controlSchedule = this.state.referrals[index];
                                                        this.setState({
                                                            scheduleReferral
                                                        })
                                                    }

                                                    this.setState({
                                                        selectOpen: false
                                                    })
                                                })}
                                            />
                                        </div>
                                        :
                                        null
                                }


                            </FormGroup>

                            <DatePicker
                                label={'Date'.translate(this.props.lang)}
                                value={this.state.scheduleReferral.date}
                                onChange={(e) => {
                                    let scheduleReferral = this.state.scheduleReferral;
                                    scheduleReferral.date = e;
                                    this.setState({
                                        scheduleReferral
                                    })
                                    if (Math.floor(new Date(this.state.scheduleReferral.date + ' ' + this.state.scheduleReferral.startTime) / 1000) > Math.floor(new Date(this.state.scheduleReferral.endDate + ' ' + this.state.scheduleReferral.endTime) / 1000)) {
                                        scheduleReferral.endDate = this.state.scheduleReferral.date
                                        this.setState({
                                            scheduleReferral
                                        })
                                    }
                                }}
                            />
                            <FormGroup className="timepicker-container timepicker-wide">
                                <Label>{'Start time'.translate(this.props.lang)}</Label>
                                &nbsp;
                                <TimePickerV2
                                    getStringDateTs={this.props.getStringDateTs}
                                    format={'HH:mm'}
                                    value={this.state.scheduleReferral.startTime}
                                    minValue={this.props.getStringDateTs(Math.floor(this.state.min.getTime() / 1000), 'HH:mm')} // moment.unix(this.state.min.getTime() / 1000).format("HH:mm")
                                    maxValue={this.props.getStringDateTs(Math.floor(this.state.max.getTime() / 1000 - 300), 'HH:mm')} // moment.unix(this.state.max.getTime() / 1000).format("HH:mm")
                                    flag={0}
                                    onChange={(time1, timeString) => {

                                        let scheduleReferral = this.state.scheduleReferral;
                                        scheduleReferral.startTime = timeString

                                        let startTimeSplit = scheduleReferral.startTime.split(":");
                                        let endTimeSplit = scheduleReferral.endTime.split(":");

                                        let startTimeHourMinute = Number(startTimeSplit[0]) * 60 + Number(startTimeSplit[1]);
                                        let endTimeHourMinute = Number(endTimeSplit[0]) * 60 + Number(endTimeSplit[1]);

                                        // this.setState({ scheduleReferral })
                                        if (startTimeHourMinute < endTimeHourMinute) {
                                            this.setState({ scheduleReferral })
                                        } else {
                                            scheduleReferral.endTime = Math.trunc((Number(this.state.scheduleReferral.startTime.split(':')[0]) * 60 + Number(this.state.scheduleReferral.startTime.split(':')[1]) + 5) / 60) + ":" + (Number(this.state.scheduleReferral.startTime.split(':')[0]) * 60 + Number(this.state.scheduleReferral.startTime.split(':')[1]) + 5) % 60
                                            // scheduleReferral.endTime = validateTime(scheduleReferral.endTime);
                                            const padded = scheduleReferral?.endTime?.split?.(':');
                                            if (padded && Array.isArray(padded) && padded.length && isStringAValidNumber(padded[0]) && isStringAValidNumber(padded[1])) {
                                                const h = padded[0]?.padStart?.(2, '0');
                                                const m = padded[1]?.padStart?.(2, '0');
                                                if (h && m) scheduleReferral.endTime = `${h}:${m}`;
                                            }
                                            this.setState({
                                                scheduleReferral
                                            })
                                        }
                                    }}
                                />
                                {/* <TimePicker
                                    getStringDateTs={this.props.getStringDateTs}
                                    format={'HH:mm'}
                                    value={moment(new Date(this.state.scheduleReferral.date.replace(/-/g, "/") + " " + this.state.scheduleReferral.startTime))}
                                    minValue={this.props.getStringDateTs(Math.floor(this.state.min.getTime() / 1000), 'HH:mm')} // moment.unix(this.state.min.getTime() / 1000).format("HH:mm")
                                    maxValue={this.props.getStringDateTs(Math.floor(this.state.max.getTime() / 1000), 'HH:mm')} // moment.unix(this.state.max.getTime() / 1000).format("HH:mm")
                                    flag={0}
                                    onChange={(time1, timeString) => {

                                        let scheduleReferral = this.state.scheduleReferral;
                                        scheduleReferral.startTime = timeString

                                        let startTimeSplit = scheduleReferral.startTime.split(":");
                                        let endTimeSplit = scheduleReferral.endTime.split(":");

                                        let startTimeHourMinute = Number(startTimeSplit[0]) * 60 + Number(startTimeSplit[1]);
                                        let endTimeHourMinute = Number(endTimeSplit[0]) * 60 + Number(endTimeSplit[1]);

                                        this.setState({ scheduleReferral })
                                    }}
                                    allowClear={false}
                                /> */}
                            </FormGroup>
                            <FormGroup className="timepicker-container timepicker-wide">
                                <Label>{'End time'.translate(this.props.lang)}</Label>
                                &nbsp;
                                <TimePickerV2
                                    getStringDateTs={this.props.getStringDateTs}
                                    format={'HH:mm'}
                                    value={this.state.scheduleReferral.endTime}
                                    minValue={Math.trunc((Number(this.state.scheduleReferral.startTime.split(':')[0]) * 60 + Number(this.state.scheduleReferral.startTime.split(':')[1]) + 5) / 60) + ":" + (Number(this.state.scheduleReferral.startTime.split(':')[0]) * 60 + Number(this.state.scheduleReferral.startTime.split(':')[1]) + 5) % 60}
                                    maxValue={this.props.getStringDateTs(Math.floor(this.state.max.getTime() / 1000), 'HH:mm')} // moment.unix(this.state.max.getTime() / 1000).format("HH:mm")
                                    flag={0}
                                    onChange={(time1, timeString) => {

                                        let scheduleReferral = this.state.scheduleReferral;
                                        scheduleReferral.endTime = timeString


                                        let startTimeSplit = scheduleReferral.startTime.split(":");
                                        let endTimeSplit = scheduleReferral.endTime.split(":");

                                        let startTimeHourMinute = Number(startTimeSplit[0]) * 60 + Number(startTimeSplit[1]);
                                        let endTimeHourMinute = Number(endTimeSplit[0]) * 60 + Number(endTimeSplit[1]);

                                        if (startTimeHourMinute < endTimeHourMinute) {
                                            this.setState({ scheduleReferral })
                                        }
                                    }}
                                />
                                {/* <TimePicker
                                    getStringDateTs={this.props.getStringDateTs}
                                    format={'HH:mm'}
                                    value={moment(new Date(this.state.scheduleReferral.date.replace(/-/g, "/") + " " + this.state.scheduleReferral.endTime))}
                                    minValue={Math.trunc((Number(this.state.scheduleReferral.startTime.split(':')[0]) * 60 + Number(this.state.scheduleReferral.startTime.split(':')[1]) + 5) / 60) + ":" + (Number(this.state.scheduleReferral.startTime.split(':')[0]) * 60 + Number(this.state.scheduleReferral.startTime.split(':')[1]) + 5) % 60}
                                    maxValue={this.props.getStringDateTs(Math.floor(this.state.max.getTime() / 1000), 'HH:mm')} // moment.unix(this.state.max.getTime() / 1000).format("HH:mm")
                                    flag={0}
                                    onChange={(time1, timeString) => {

                                        let scheduleReferral = this.state.scheduleReferral;
                                        scheduleReferral.endTime = timeString


                                        let startTimeSplit = scheduleReferral.startTime.split(":");
                                        let endTimeSplit = scheduleReferral.endTime.split(":");

                                        let startTimeHourMinute = Number(startTimeSplit[0]) * 60 + Number(startTimeSplit[1]);
                                        let endTimeHourMinute = Number(endTimeSplit[0]) * 60 + Number(endTimeSplit[1]);

                                        if (startTimeHourMinute < endTimeHourMinute) {
                                            this.setState({ scheduleReferral })
                                        }
                                    }}
                                    allowClear={false}
                                /> */}
                            </FormGroup>
                            {
                                this.state.referralId ?
                                    <FormGroup>
                                        <Label>{'Note'.translate(this.props.lang)}</Label>
                                        <Input type='textarea'
                                            name="note"
                                            style={{ height: '150px' }}
                                            onChange={(e) => {
                                                let scheduleReferral = this.state.scheduleReferral;
                                                scheduleReferral.patientNote = striptags(e.target.value);
                                                this.setState({
                                                    scheduleReferral
                                                })
                                            }}
                                            value={this.state.scheduleReferral.patientNote}
                                        />
                                    </FormGroup>
                                    :
                                    null
                            }
                            {
                                this.state.referralId ?
                                    <FormGroup>
                                        <Button onClick={() => this.setState({
                                            doctorsModal: 2
                                        })} color="primary">
                                            {'Add caregiver'.translate(this.props.lang)}
                                        </Button>
                                    </FormGroup>

                                    :

                                    null
                            }

                            <Button color='primary' disabled={this.state.referralId && !this.state.creatingEvent ? false : true} onClick={() => {
                                this.setState({
                                    creatingEvent: true
                                }, () => {
                                    if (this.state.referrals) {
                                        let index = this.state.referrals.findIndex(ref => ref._id.toString() == this.state.referralId.toString())
                                        if (this.state.referrals[index] && /*this.state.referrals[index].status == 'canceled-by-clinic'*/ this.state.referrals[index].remissType === 'Revisit') {
                                            this.scheduleControl({ ...this.state.scheduleReferral, rescheduleRevisit: true })
                                        } else {
                                            if (this.state.referrals[index] && this.state.referrals[index].status === 'requested-new-appointment') {
                                                this.scheduleReferral({ ...this.state.scheduleReferral, rescheduleReferral: true })
                                            } else {
                                                this.scheduleReferral({ ...this.state.scheduleReferral, rescheduleReferral: false })
                                            }

                                        }
                                    }
                                })


                            }}>{'Schedule referral'.translate(this.props.lang)}</Button>

                        </ModalBody>
                    </Modal>
                    : null}
                {
                    this.state.schedulePatientTimeOneDay ?
                        <Modal isOpen={this.state.schedulePatientTimeOneDay} centered>
                            <ModalHeader toggle={() => this.setState({ schedulePatientTimeOneDay: !this.state.schedulePatientTimeOneDay })} close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => this.setState({ schedulePatientTimeOneDay: !this.state.schedulePatientTimeOneDay })}>&times;</button>}>{'Set available terms'.translate(this.props.lang)}</ModalHeader>

                            <ModalBody>

                                {/* <FormGroup>
                                    <Label>{'Date'.translate(this.props.lang)}</Label>
                                    <Input type='date' onChange={(e) => {
                                        let schedulePatientTimeOneDay = this.state.schedulePatientTimeOneDay;
                                        schedulePatientTimeOneDay.date = e.target.value;
                                        this.setState({
                                            schedulePatientTimeOneDay
                                        })
                                    }}
                                        value={this.state.schedulePatientTimeOneDay.date} />
                                </FormGroup> */}
                                <DatePicker
                                    label={'Date'.translate(this.props.lang)}
                                    value={this.state.schedulePatientTimeOneDay.date}
                                    onChange={(e) => {
                                        let schedulePatientTimeOneDay = this.state.schedulePatientTimeOneDay;
                                        schedulePatientTimeOneDay.date = e;
                                        this.setState({
                                            schedulePatientTimeOneDay
                                        })
                                        if (Math.floor(new Date(this.state.schedulePatientTimeOneDay.date + ' ' + this.state.schedulePatientTimeOneDay.startTime) / 1000) > Math.floor(new Date(this.state.schedulePatientTimeOneDay.endDate + ' ' + this.state.schedulePatientTimeOneDay.endTime) / 1000)) {
                                            schedulePatientTimeOneDay.endDate = this.state.schedulePatientTimeOneDay.date
                                            this.setState({
                                                schedulePatientTimeOneDay
                                            })
                                        }
                                    }}
                                />

                                <FormGroup className="timepicker-container timepicker-wide">
                                    <Label>{'Start time'.translate(this.props.lang)}</Label>
                                    &nbsp;
                                    <TimePickerV2
                                        getStringDateTs={this.props.getStringDateTs}
                                        format={'HH:mm'}
                                        value={this.state.schedulePatientTimeOneDay.startTime}
                                        minValue={this.props.getStringDateTs(Math.floor(this.state.min.getTime() / 1000), 'HH:mm')} // moment.unix(this.state.min.getTime() / 1000).format("HH:mm")
                                        maxValue={this.props.getStringDateTs(Math.floor(this.state.max.getTime() / 1000 - 300), 'HH:mm')} // moment.unix(this.state.max.getTime() / 1000).format("HH:mm")
                                        flag={0}
                                        onChange={(time1, timeString) => {

                                            let schedulePatientTimeOneDay = this.state.schedulePatientTimeOneDay;
                                            schedulePatientTimeOneDay.startTime = timeString

                                            let startTimeSplit = schedulePatientTimeOneDay.startTime.split(":");
                                            let endTimeSplit = schedulePatientTimeOneDay.endTime.split(":");

                                            let startTimeHourMinute = Number(startTimeSplit[0]) * 60 + Number(startTimeSplit[1]);
                                            let endTimeHourMinute = Number(endTimeSplit[0]) * 60 + Number(endTimeSplit[1]);

                                            // this.setState({ schedulePatientTimeOneDay })
                                            if (startTimeHourMinute < endTimeHourMinute) {
                                                this.setState({ schedulePatientTimeOneDay })
                                            } else {
                                                schedulePatientTimeOneDay.endTime = Math.trunc((Number(this.state.schedulePatientTimeOneDay.startTime.split(':')[0]) * 60 + Number(this.state.schedulePatientTimeOneDay.startTime.split(':')[1]) + 5) / 60) + ":" + (Number(this.state.schedulePatientTimeOneDay.startTime.split(':')[0]) * 60 + Number(this.state.schedulePatientTimeOneDay.startTime.split(':')[1]) + 5) % 60
                                                const padded = schedulePatientTimeOneDay?.endTime?.split?.(':');
                                                if (padded && Array.isArray(padded) && padded.length && isStringAValidNumber(padded[0]) && isStringAValidNumber(padded[1])) {
                                                    const h = padded[0]?.padStart?.(2, '0');
                                                    const m = padded[1]?.padStart?.(2, '0');
                                                    if (h && m) schedulePatientTimeOneDay.endTime = `${h}:${m}`;
                                                }
                                                this.setState({
                                                    schedulePatientTimeOneDay
                                                })
                                            }
                                        }}
                                    />
                                    {/* <TimePicker
                                        getStringDateTs={this.props.getStringDateTs}
                                        format={'HH:mm'}
                                        value={moment(new Date(this.state.schedulePatientTimeOneDay.date.replace(/-/g, "/") + " " + this.state.schedulePatientTimeOneDay.startTime))}
                                        // maxValue={this.state.scheduleReferral.endTime}
                                        minValue={this.props.getStringDateTs(Math.floor(this.state.min.getTime() / 1000), 'HH:mm')} // moment.unix(this.state.min.getTime() / 1000).format("HH:mm")
                                        maxValue={this.props.getStringDateTs(Math.floor(this.state.max.getTime() / 1000), 'HH:mm')} // moment.unix(this.state.max.getTime() / 1000).format("HH:mm")
                                        flag={0}
                                        onChange={(time1, timeString) => {

                                            let schedulePatientTimeOneDay = this.state.schedulePatientTimeOneDay;
                                            schedulePatientTimeOneDay.startTime = timeString

                                            let startTimeSplit = schedulePatientTimeOneDay.startTime.split(":");
                                            let endTimeSplit = schedulePatientTimeOneDay.endTime.split(":");

                                            let startTimeHourMinute = Number(startTimeSplit[0]) * 60 + Number(startTimeSplit[1]);
                                            let endTimeHourMinute = Number(endTimeSplit[0]) * 60 + Number(endTimeSplit[1]);

                                            // if (startTimeHourMinute < endTimeHourMinute) {
                                            //     this.setState({ scheduleReferral })
                                            // } else {
                                            // this.setState({ wrongInput: true })
                                            // }

                                            this.setState({ schedulePatientTimeOneDay })
                                        }}
                                        allowClear={false}
                                    /> */}
                                </FormGroup>
                                <FormGroup className="timepicker-container timepicker-wide">
                                    <Label>{'End time'.translate(this.props.lang)}</Label>
                                    &nbsp;
                                    <TimePickerV2
                                        getStringDateTs={this.props.getStringDateTs}
                                        format={'HH:mm'}
                                        value={this.state.schedulePatientTimeOneDay.endTime}
                                        minValue={Math.trunc((Number(this.state.schedulePatientTimeOneDay.startTime.split(':')[0]) * 60 + Number(this.state.schedulePatientTimeOneDay.startTime.split(':')[1]) + 5) / 60) + ":" + (Number(this.state.schedulePatientTimeOneDay.startTime.split(':')[0]) * 60 + Number(this.state.schedulePatientTimeOneDay.startTime.split(':')[1]) + 5) % 60}
                                        maxValue={this.props.getStringDateTs(Math.floor(this.state.max.getTime() / 1000), 'HH:mm')} // moment.unix(this.state.max.getTime() / 1000).format("HH:mm")
                                        flag={0}
                                        onChange={(time1, timeString) => {

                                            let schedulePatientTimeOneDay = this.state.schedulePatientTimeOneDay;
                                            schedulePatientTimeOneDay.endTime = timeString


                                            let startTimeSplit = schedulePatientTimeOneDay.startTime.split(":");
                                            let endTimeSplit = schedulePatientTimeOneDay.endTime.split(":");

                                            let startTimeHourMinute = Number(startTimeSplit[0]) * 60 + Number(startTimeSplit[1]);
                                            let endTimeHourMinute = Number(endTimeSplit[0]) * 60 + Number(endTimeSplit[1]);

                                            if (startTimeHourMinute < endTimeHourMinute) {
                                                this.setState({ schedulePatientTimeOneDay })
                                            } else {
                                                // this.setState({ wrongInput: true })
                                            }
                                        }}
                                    />
                                    {/* <TimePicker
                                        getStringDateTs={this.props.getStringDateTs}
                                        format={'HH:mm'}
                                        value={moment(new Date(this.state.schedulePatientTimeOneDay.date.replace(/-/g, "/") + " " + this.state.schedulePatientTimeOneDay.endTime))}
                                        minValue={Math.trunc((Number(this.state.schedulePatientTimeOneDay.startTime.split(':')[0]) * 60 + Number(this.state.schedulePatientTimeOneDay.startTime.split(':')[1]) + 5) / 60) + ":" + (Number(this.state.schedulePatientTimeOneDay.startTime.split(':')[0]) * 60 + Number(this.state.schedulePatientTimeOneDay.startTime.split(':')[1]) + 5) % 60}
                                        maxValue={this.props.getStringDateTs(Math.floor(this.state.max.getTime() / 1000), 'HH:mm')} // moment.unix(this.state.max.getTime() / 1000).format("HH:mm")
                                        flag={0}
                                        // maxValue={this.state.scheduleReferral.startTime}
                                        // flag={1}
                                        onChange={(time1, timeString) => {

                                            let schedulePatientTimeOneDay = this.state.schedulePatientTimeOneDay;
                                            schedulePatientTimeOneDay.endTime = timeString


                                            let startTimeSplit = schedulePatientTimeOneDay.startTime.split(":");
                                            let endTimeSplit = schedulePatientTimeOneDay.endTime.split(":");

                                            let startTimeHourMinute = Number(startTimeSplit[0]) * 60 + Number(startTimeSplit[1]);
                                            let endTimeHourMinute = Number(endTimeSplit[0]) * 60 + Number(endTimeSplit[1]);

                                            if (startTimeHourMinute < endTimeHourMinute) {
                                                this.setState({ schedulePatientTimeOneDay })
                                            } else {
                                                // this.setState({ wrongInput: true })
                                            }
                                        }}
                                        allowClear={false}
                                    /> */}
                                </FormGroup>


                                <Button color='primary' onClick={() => this.schedulePatientTimeOneDay(this.state.schedulePatientTimeOneDay)}>{'Set'.translate(this.props.lang)}</Button>





                            </ModalBody>
                        </Modal>
                        : null
                }
                {this.state.schedulePatientTime ?
                    <Modal isOpen={this.state.schedulePatientTime} centered>
                        <ModalHeader toggle={() => this.setState({ schedulePatientTime: !this.state.schedulePatientTime, doctorsList: [] })} close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => this.setState({ schedulePatientTime: !this.state.schedulePatientTime, doctorsList: [] })}>&times;</button>}>
                            {'Enter availability'.translate(this.props.lang)}
                        </ModalHeader>
                        <ModalBody>
                            <FormGroup>
                                <Label>{'Type'.translate(this.props.lang) + '*'}</Label>
                                <Input type='select' value={this.state.schedulePatientTime.type} onChange={(e) => {
                                    let val = false
                                    if (e.target.value != -1) {
                                        val = e.target.value
                                    }
                                    let schedulePatientTime = this.state.schedulePatientTime
                                    schedulePatientTime.type = val
                                    // if (val === 'event') {
                                    //     schedulePatientTime.title = 'Event'
                                    // } else if (val === 'free') {
                                    //     schedulePatientTime.title = 'Available for booking'
                                    // } else {
                                    //     schedulePatientTime.title = ''
                                    // }
                                    this.setState({
                                        schedulePatientTime
                                    })
                                }}>
                                    <option value={-1}>{'Select event type'.translate(this.props.lang)}</option>
                                    <option value={'event'}>{'Schedule event'.translate(this.props.lang)}</option>
                                    <option value={'free'}>{'Mark as available'.translate(this.props.lang)}</option>
                                </Input>
                            </FormGroup>
                            <FormGroup>
                                <Label>{'Title'.translate(this.props.lang)}</Label>
                                <Input type='text' value={this.state.schedulePatientTime.title} onChange={(e) => {
                                    let schedulePatientTime = this.state.schedulePatientTime
                                    schedulePatientTime.title = e.target.value
                                    this.setState({
                                        schedulePatientTime
                                    })
                                }} />
                            </FormGroup>
                            {
                                this.state.schedulePatientTime.type === 'free' ?
                                    <FormGroup>
                                        <Label>
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <div>
                                                    {'Enable booking by patient'.translate(this.props.lang)}
                                                </div>
                                                <div className="modal-header-tooltip"> <Isvg src={infoIcon} className="info-text" /> <div className="chat-icons-tooltip">{'Leave empty if time-slot should not be made available for patients to book'.translate(this.props.lang)}</div> </div>

                                            </div>

                                        </Label>
                                        <Checkbox label={'All active forms'.translate(this.props.lang)} checked={this.state.schedulePatientTime.allActiveForms} onChange={(e) => {
                                            let schedulePatientTime = this.state.schedulePatientTime
                                            schedulePatientTime.allActiveForms = e.target.checked
                                            this.setState({
                                                schedulePatientTime
                                            })
                                        }} />
                                        <SelectWithCheckbox disabled={this.state.schedulePatientTime.allActiveForms} checkAll={this.state.schedulePatientTime.allActiveForms} placeHolder={'Search reason'.translate(this.props.lang)} values={this.state.webBookingForms.map(item => { return { name: item.name, value: item._id } })} value={this.state.schedulePatientTime.eventForms}
                                            onChange={(value) => {
                                                if (!this.state.schedulePatientTime.allActiveForms) {
                                                    let schedulePatientTime = this.state.schedulePatientTime
                                                    schedulePatientTime.eventForms = value

                                                    this.setState({
                                                        schedulePatientTime
                                                    })
                                                }

                                            }} />
                                    </FormGroup>
                                    :
                                    null
                            }
                            {
                                !this.state.schedulePatientTime.frequencyType ?
                                    <DatePicker
                                        label={'Date'.translate(this.props.lang)}
                                        value={this.state.schedulePatientTime.date}
                                        onChange={(e) => {
                                            let schedulePatientTime = this.state.schedulePatientTime;
                                            schedulePatientTime.date = e;
                                            this.setState({
                                                schedulePatientTime
                                            })
                                            if (Math.floor(new Date(this.state.schedulePatientTime.date + ' ' + this.state.schedulePatientTime.startTime) / 1000) > Math.floor(new Date(this.state.schedulePatientTime.endDate + ' ' + this.state.schedulePatientTime.endTime) / 1000)) {
                                                schedulePatientTime.endDate = this.state.schedulePatientTime.date
                                                this.setState({
                                                    schedulePatientTime
                                                })
                                            }
                                        }}
                                    />
                                    :
                                    null
                            }

                            <FormGroup className="timepicker-container timepicker-wide">
                                <Label>{'Start time'.translate(this.props.lang)}</Label>
                                &nbsp;
                                <TimePickerV2
                                    getStringDateTs={this.props.getStringDateTs}
                                    format={'HH:mm'}
                                    value={this.state.schedulePatientTime.startTime}
                                    minValue={this.props.getStringDateTs(Math.floor(this.state.min.getTime() / 1000), 'HH:mm')} // moment.unix(this.state.min.getTime() / 1000).format("HH:mm")
                                    maxValue={this.props.getStringDateTs(Math.floor(this.state.max.getTime() / 1000 - 300), 'HH:mm')} // moment.unix(this.state.max.getTime() / 1000).format("HH:mm")
                                    flag={0}
                                    onChange={(time1, timeString) => {

                                        let schedulePatientTime = this.state.schedulePatientTime;
                                        schedulePatientTime.startTime = timeString

                                        let startTimeSplit = schedulePatientTime.startTime.split(":");
                                        let endTimeSplit = schedulePatientTime.endTime.split(":");


                                        let startTimeHourMinute = Number(startTimeSplit[0]) * 60 + Number(startTimeSplit[1]);
                                        let endTimeHourMinute = Number(endTimeSplit[0]) * 60 + Number(endTimeSplit[1]);

                                        // this.setState({ schedulePatientTime })
                                        if (startTimeHourMinute < endTimeHourMinute) {
                                            this.setState({ schedulePatientTime })
                                        } else {
                                            schedulePatientTime.endTime = Math.trunc((Number(this.state.schedulePatientTime.startTime.split(':')[0]) * 60 + Number(this.state.schedulePatientTime.startTime.split(':')[1]) + 5) / 60) + ":" + (Number(this.state.schedulePatientTime.startTime.split(':')[0]) * 60 + Number(this.state.schedulePatientTime.startTime.split(':')[1]) + 5) % 60
                                            // schedulePatientTime.endTime = validateTime(schedulePatientTime.endTime);
                                            const padded = schedulePatientTime?.endTime?.split?.(':');
                                            if (padded && Array.isArray(padded) && padded.length && isStringAValidNumber(padded[0]) && isStringAValidNumber(padded[1])) {
                                                const h = padded[0]?.padStart?.(2, '0');
                                                const m = padded[1]?.padStart?.(2, '0');
                                                if (h && m) schedulePatientTime.endTime = `${h}:${m}`;
                                            }
                                            this.setState({
                                                schedulePatientTime
                                            })
                                        }


                                    }}
                                />
                                {/* <TimePicker
                                    getStringDateTs={this.props.getStringDateTs}
                                    format={'HH:mm'}
                                    value={moment(new Date(this.state.schedulePatientTime.date.replace(/-/g, "/") + " " + this.state.schedulePatientTime.startTime))}
                                    // maxValue={this.state.schedulePatientTime.endTime}
                                    minValue={this.props.getStringDateTs(Math.floor(this.state.min.getTime() / 1000), 'HH:mm')} // moment.unix(this.state.min.getTime() / 1000).format("HH:mm")
                                    maxValue={this.props.getStringDateTs(Math.floor(this.state.max.getTime() / 1000), 'HH:mm')} // moment.unix(this.state.max.getTime() / 1000).format("HH:mm")
                                    flag={0}
                                    onChange={(time1, timeString) => {

                                        let schedulePatientTime = this.state.schedulePatientTime;
                                        schedulePatientTime.startTime = timeString

                                        let startTimeSplit = schedulePatientTime.startTime.split(":");
                                        let endTimeSplit = schedulePatientTime.endTime.split(":");


                                        let startTimeHourMinute = Number(startTimeSplit[0]) * 60 + Number(startTimeSplit[1]);
                                        let endTimeHourMinute = Number(endTimeSplit[0]) * 60 + Number(endTimeSplit[1]);

                                        this.setState({ schedulePatientTime })


                                    }}
                                    allowClear={false}
                                /> */}
                            </FormGroup>
                            <FormGroup className="timepicker-container timepicker-wide">
                                <Label>{'End time'.translate(this.props.lang)}</Label>
                                &nbsp;
                                <TimePickerV2
                                    getStringDateTs={this.props.getStringDateTs}
                                    format={'HH:mm'}
                                    value={this.state.schedulePatientTime.endTime}
                                    minValue={Math.trunc((Number(this.state.schedulePatientTime.startTime.split(':')[0]) * 60 + Number(this.state.schedulePatientTime.startTime.split(':')[1]) + 5) / 60) + ":" + (Number(this.state.schedulePatientTime.startTime.split(':')[0]) * 60 + Number(this.state.schedulePatientTime.startTime.split(':')[1]) + 5) % 60}
                                    maxValue={this.props.getStringDateTs(Math.floor(this.state.max.getTime() / 1000), 'HH:mm')} // moment.unix(this.state.max.getTime() / 1000).format("HH:mm")
                                    flag={0}
                                    onChange={(time1, timeString) => {

                                        let schedulePatientTime = this.state.schedulePatientTime;
                                        schedulePatientTime.endTime = timeString

                                        let startTimeSplit = schedulePatientTime.startTime.split(":");
                                        let endTimeSplit = schedulePatientTime.endTime.split(":");

                                        let startTimeHourMinute = Number(startTimeSplit[0]) * 60 + Number(startTimeSplit[1]);
                                        let endTimeHourMinute = Number(endTimeSplit[0]) * 60 + Number(endTimeSplit[1]);

                                        if (startTimeHourMinute < endTimeHourMinute) {
                                            this.setState({ schedulePatientTime })

                                        } else {
                                        }

                                    }}
                                />
                                {/* <TimePicker
                                    getStringDateTs={this.props.getStringDateTs}
                                    format={'HH:mm'}
                                    value={moment(new Date(this.state.schedulePatientTime.date.replace(/-/g, "/") + " " + this.state.schedulePatientTime.endTime))}
                                    minValue={Math.trunc((Number(this.state.schedulePatientTime.startTime.split(':')[0]) * 60 + Number(this.state.schedulePatientTime.startTime.split(':')[1]) + 5) / 60) + ":" + (Number(this.state.schedulePatientTime.startTime.split(':')[0]) * 60 + Number(this.state.schedulePatientTime.startTime.split(':')[1]) + 5) % 60}
                                    maxValue={this.props.getStringDateTs(Math.floor(this.state.max.getTime() / 1000), 'HH:mm')} // moment.unix(this.state.max.getTime() / 1000).format("HH:mm")
                                    flag={0}
                                    // maxValue={this.state.schedulePatientTime.startTime}
                                    // flag={1}
                                    onChange={(time1, timeString) => {

                                        let schedulePatientTime = this.state.schedulePatientTime;
                                        schedulePatientTime.endTime = timeString

                                        let startTimeSplit = schedulePatientTime.startTime.split(":");
                                        let endTimeSplit = schedulePatientTime.endTime.split(":");

                                        let startTimeHourMinute = Number(startTimeSplit[0]) * 60 + Number(startTimeSplit[1]);
                                        let endTimeHourMinute = Number(endTimeSplit[0]) * 60 + Number(endTimeSplit[1]);

                                        if (startTimeHourMinute < endTimeHourMinute) {
                                            this.setState({ schedulePatientTime })

                                        } else {
                                        }

                                    }}
                                    allowClear={false}
                                /> */}
                            </FormGroup>
                            {
                                !this.state.schedulePatientTime.id ?
                                    <FormGroup>
                                        <Label >{'Frequency'.translate(this.props.lang)}</Label>
                                        <Input type='select'
                                            value={this.state.schedulePatientTime.frequencyType}

                                            onChange={(e) => {
                                                let val = null;
                                                if (e.target.value != -1) {
                                                    val = e.target.value;
                                                }
                                                if (!showRecurringOptions && ['week', 'month'].includes(val)) return;
                                                let schedulePatientTime = this.state.schedulePatientTime;
                                                schedulePatientTime.frequencyType = val;
                                                let date = moment(new Date()).format(this.props.dateFormat);
                                                if (schedulePatientTime.date) {
                                                    date = moment(new Date(schedulePatientTime.date)).format(this.props.dateFormat);
                                                }
                                                if (val == 'day') {
                                                    schedulePatientTime.frequencyDayScheduledDateFrom = date;
                                                    schedulePatientTime.frequencyDayScheduledDateTo = date
                                                    schedulePatientTime.recurring = true
                                                } else if (val == 'week') {
                                                    schedulePatientTime.frequencyWeekScheduledDateFrom = date;
                                                    schedulePatientTime.frequencyWeekScheduledDateTo = moment.unix(Math.floor(new Date(date).getTime() / 1000) + 7 * 24 * 60 * 60).format(this.props.dateFormat);
                                                    schedulePatientTime.recurring = true

                                                } else if (val == 'month') {
                                                    schedulePatientTime.frequencyMonthScheduledDateFrom = date;
                                                    schedulePatientTime.frequencyMonthScheduledDateTo = moment.unix(Math.floor(new Date(date).getTime() / 1000) + 30 * 24 * 60 * 60).format(this.props.dateFormat);
                                                    schedulePatientTime.recurring = true

                                                } else {
                                                    schedulePatientTime.recurring = false

                                                }
                                                this.setState({
                                                    schedulePatientTime,
                                                    errorEvent: null
                                                })
                                            }}>
                                            <option value={-1}>
                                                {'Choose frequency'.translate(this.props.lang)}
                                            </option>
                                            <option value={'day'}>
                                                {'Day'.translate(this.props.lang)}
                                            </option>
                                            {showRecurringOptions ? <>
                                                <option value={'week'}>
                                                    {'Week'.translate(this.props.lang)}
                                                </option>
                                                <option value={'month'}>
                                                    {'Month'.translate(this.props.lang)}
                                                </option>
                                            </> : null}
                                        </Input>

                                    </FormGroup>
                                    :
                                    null
                            }

                            {this.state.errorEvent ? <FormFeedback >{this.state.errorEvent}</FormFeedback> : null}
                            {this.state.schedulePatientTime.frequencyType == 'day' ?
                                <div>
                                    <Row>
                                        <Col lg="12">
                                            <div style={{ display: 'flex', textAlign: 'center', alignItems: 'center', marginBottom: 5, marginTop: 10 }}>
                                                <Label style={{ marginRight: 5 }}>{'Every'.translate(this.props.lang)}</Label>
                                                <Input style={{ width: '25%' }} type='text' value={this.state.schedulePatientTime.frequencyDayText} onChange={(e) => {
                                                    let value = e.target.value
                                                    value = value.replace(/[^0-9]/g, '')

                                                    let schedulePatientTime = this.state.schedulePatientTime;
                                                    schedulePatientTime.frequencyDayText = value;
                                                    this.setState({
                                                        schedulePatientTime
                                                    })
                                                }} />
                                                <Label style={{ marginLeft: 5 }}>{'day(s)'.translate(this.props.lang) + '*'}</Label>
                                            </div>
                                            {this.state.errorEvent ? <FormFeedback >{this.state.errorEvent}</FormFeedback> : null}
                                            <DatePicker
                                                label={'Start date'.translate(this.props.lang) + '*'}
                                                minDate={new Date()}
                                                dateFormat={this.props.dateFormat}
                                                value={this.state.schedulePatientTime.frequencyDayScheduledDateFrom ? this.state.schedulePatientTime.frequencyDayScheduledDateFrom : moment(new Date()).format(this.props.dateFormat)}
                                                onChange={(e) => {
                                                    let schedulePatientTime = this.state.schedulePatientTime;
                                                    schedulePatientTime.frequencyDayScheduledDateFrom = e;
                                                    this.setState({
                                                        schedulePatientTime
                                                    })

                                                    if (this.state.schedulePatientTime.frequencyDayScheduledDateTo && this.state.schedulePatientTime.frequencyDayScheduledDateFrom) {
                                                        let dateTo = new Date(this.state.schedulePatientTime.frequencyDayScheduledDateTo)
                                                        let dateFrom = new Date(this.state.schedulePatientTime.frequencyDayScheduledDateFrom)
                                                        if (dateFrom.getTime() > dateTo.getTime() || this.calculateMaxDateHotfix(this.state.schedulePatientTime.frequencyDayScheduledDateFrom)) {
                                                            let schedulePatientTime = this.state.schedulePatientTime;
                                                            schedulePatientTime.frequencyDayScheduledDateTo = this.state.schedulePatientTime.frequencyDayScheduledDateFrom;
                                                            this.setState({
                                                                schedulePatientTime
                                                            })
                                                        }
                                                    } else if (!this.state.schedulePatientTime.frequencyDayScheduledDateTo && this.state.schedulePatientTime.frequencyDayScheduledDateFrom) {
                                                        let schedulePatientTime = this.state.schedulePatientTime;
                                                        schedulePatientTime.frequencyDayScheduledDateTo = this.state.schedulePatientTime.frequencyDayScheduledDateFrom;
                                                        this.setState({
                                                            schedulePatientTime
                                                        })
                                                    }

                                                }}
                                            />
                                            {this.state.errorEvent ? <FormFeedback >{this.state.errorEvent}</FormFeedback> : null}
                                        </Col>
                                        <Col lg="12">
                                            <div style={{ marginTop: 5 }}>
                                                <DatePicker
                                                    label={'End date'.translate(this.props.lang) + '*'}
                                                    dateFormat={this.props.dateFormat}
                                                    minDate={new Date(this.state.schedulePatientTime.frequencyDayScheduledDateFrom) != 'Invalid Date' ? new Date(this.state.schedulePatientTime.frequencyDayScheduledDateFrom) : new Date()}
                                                    maxDate={this.calculateMaxDateHotfix(this.state.schedulePatientTime.frequencyDayScheduledDateFrom)}
                                                    value={this.state.schedulePatientTime.frequencyDayScheduledDateTo ? this.state.schedulePatientTime.frequencyDayScheduledDateTo : moment(new Date()).format(this.props.dateFormat)}
                                                    onChange={(e) => {
                                                        let schedulePatientTime = this.state.schedulePatientTime;
                                                        schedulePatientTime.frequencyDayScheduledDateTo = e;
                                                        this.setState({
                                                            schedulePatientTime
                                                        })

                                                    }}
                                                />
                                            </div>
                                            {this.state.errorEvent ? <FormFeedback >{this.state.errorEvent}</FormFeedback> : null}
                                        </Col>
                                    </Row>

                                </div>
                                : null}


                            {this.state.schedulePatientTime.frequencyType == 'week' ?
                                <div style={{ marginTop: 10, marginBottom: 15 }}>
                                    <Row>
                                        <Col lg="12">
                                            <Checkbox
                                                onChange={(e) => {
                                                    let schedulePatientTime = this.state.schedulePatientTime;
                                                    schedulePatientTime.checkedMonday = e.target.checked
                                                    this.setState({
                                                        schedulePatientTime
                                                    })
                                                }}
                                                checked={this.state.schedulePatientTime.checkedMonday}
                                                label={'Monday'.translate(this.props.lang)}
                                            />
                                            <Checkbox
                                                onChange={(e) => {
                                                    let schedulePatientTime = this.state.schedulePatientTime;
                                                    schedulePatientTime.checkedTuesday = e.target.checked
                                                    this.setState({
                                                        schedulePatientTime
                                                    })
                                                }}
                                                checked={this.state.schedulePatientTime.checkedTuesday}
                                                label={'Tuesday'.translate(this.props.lang)}
                                            />
                                            <Checkbox
                                                onChange={(e) => {
                                                    let schedulePatientTime = this.state.schedulePatientTime;
                                                    schedulePatientTime.checkedWednesday = e.target.checked
                                                    this.setState({
                                                        schedulePatientTime
                                                    })
                                                }}
                                                checked={this.state.schedulePatientTime.checkedWednesday}
                                                label={'Wednesday'.translate(this.props.lang)}
                                            />
                                            <Checkbox
                                                onChange={(e) => {
                                                    let schedulePatientTime = this.state.schedulePatientTime;
                                                    schedulePatientTime.checkedThursday = e.target.checked
                                                    this.setState({
                                                        schedulePatientTime
                                                    })
                                                }}
                                                checked={this.state.schedulePatientTime.checkedThursday}
                                                label={'Thursday'.translate(this.props.lang)}
                                            />
                                            <Checkbox
                                                onChange={(e) => {
                                                    let schedulePatientTime = this.state.schedulePatientTime;
                                                    schedulePatientTime.checkedFriday = e.target.checked
                                                    this.setState({
                                                        schedulePatientTime
                                                    })
                                                }}
                                                checked={this.state.schedulePatientTime.checkedFriday}
                                                label={'Friday'.translate(this.props.lang)}
                                            />
                                            <Checkbox
                                                onChange={(e) => {
                                                    let schedulePatientTime = this.state.schedulePatientTime;
                                                    schedulePatientTime.checkedSaturday = e.target.checked
                                                    this.setState({
                                                        schedulePatientTime
                                                    })
                                                }}
                                                checked={this.state.schedulePatientTime.checkedSaturday}
                                                label={'Saturday'.translate(this.props.lang)}
                                            />
                                            <Checkbox
                                                onChange={(e) => {
                                                    let schedulePatientTime = this.state.schedulePatientTime;
                                                    schedulePatientTime.checkedSunday = e.target.checked
                                                    this.setState({
                                                        schedulePatientTime
                                                    })
                                                }}
                                                checked={this.state.schedulePatientTime.checkedSunday}
                                                label={'Sunday'.translate(this.props.lang)}
                                            />
                                            {this.state.errorEvent ? <FormFeedback >{this.state.errorEvent}</FormFeedback> : null}
                                            <DatePicker
                                                label={'Start date'.translate(this.props.lang) + '*'}
                                                minDate={new Date()}
                                                dateFormat={this.props.dateFormat}
                                                // completeStaeInput={this.state.schedulePatientTime}
                                                value={this.state.schedulePatientTime.frequencyWeekScheduledDateFrom ? this.state.schedulePatientTime.frequencyWeekScheduledDateFrom : moment(new Date()).format(this.props.dateFormat)}
                                                onChange={(e) => {
                                                    let schedulePatientTime = this.state.schedulePatientTime;
                                                    schedulePatientTime.frequencyWeekScheduledDateFrom = e;
                                                    this.setState({
                                                        schedulePatientTime
                                                    })

                                                    if (this.state.schedulePatientTime.frequencyWeekScheduledDateTo && this.state.schedulePatientTime.frequencyWeekScheduledDateFrom) {
                                                        let dateTo = new Date(this.state.schedulePatientTime.frequencyWeekScheduledDateTo)
                                                        let dateFrom = new Date(this.state.schedulePatientTime.frequencyWeekScheduledDateFrom)
                                                        if (dateFrom.getTime() + 1000 * 60 * 60 * 24 * 7 > dateTo.getTime()) {
                                                            let schedulePatientTime = this.state.schedulePatientTime;
                                                            let date = new Date(this.state.schedulePatientTime.frequencyWeekScheduledDateFrom)
                                                            date.setDate(date.getDate() + 7)
                                                            schedulePatientTime.frequencyWeekScheduledDateTo = moment(new Date(date)).format(this.props.dateFormat ? this.props.dateFormat : 'YYYY-MM-DD');
                                                            this.setState({
                                                                schedulePatientTime
                                                            })

                                                        }
                                                    } else if (!this.state.schedulePatientTime.frequencyWeekScheduledDateTo && this.state.schedulePatientTime.frequencyWeekScheduledDateFrom) {
                                                        let schedulePatientTime = this.state.schedulePatientTime;
                                                        let date = new Date(this.state.schedulePatientTime.frequencyWeekScheduledDateFrom)
                                                        date.setDate(date.getDate() + 7)
                                                        schedulePatientTime.frequencyWeekScheduledDateTo = moment(new Date(date)).format(this.props.dateFormat ? this.props.dateFormat : 'YYYY-MM-DD');
                                                        this.setState({
                                                            schedulePatientTime
                                                        })
                                                    }

                                                }}
                                            />
                                            {this.state.errorEvent ? <FormFeedback >{this.state.errorEvent}</FormFeedback> : null}
                                        </Col>
                                        <Col lg="12">
                                            <DatePicker
                                                label={'End date'.translate(this.props.lang) + '*'}
                                                dateFormat={this.props.dateFormat}
                                                minDate={new Date(this.state.schedulePatientTime.frequencyWeekScheduledDateFrom) != 'Invalid Date' ? new Date(this.state.schedulePatientTime.frequencyWeekScheduledDateFrom) : new Date(this.state.weekDate)}
                                                // completeStaeInput={this.state.schedulePatientTime}
                                                value={this.state.schedulePatientTime.frequencyWeekScheduledDateTo ? this.state.schedulePatientTime.frequencyWeekScheduledDateTo : moment(this.state.weekDate).format(this.props.dateFormat)}
                                                onChange={(e) => {
                                                    let schedulePatientTime = this.state.schedulePatientTime;
                                                    schedulePatientTime.frequencyWeekScheduledDateTo = e;
                                                    this.setState({
                                                        schedulePatientTime
                                                    })

                                                }}
                                            />
                                            {this.state.errorEvent ? <FormFeedback >{this.state.errorEvent}</FormFeedback> : null}
                                        </Col>
                                    </Row>
                                </div>
                                : null}
                            {this.state.schedulePatientTime.frequencyType == 'month' ?
                                <div style={{ marginBottom: 5 }}>
                                    <Row>
                                        <Col lg="12">
                                            <div style={{ display: 'flex', textAlign: 'center', alignItems: 'center', marginBottom: 5, marginTop: 10 }}>
                                                <Label style={{ marginRight: 5 }}>{'Every'.translate(this.props.lang)}</Label>
                                                <Input style={{ width: '30%' }} type='select' onChange={(e) => {
                                                    let val = null;
                                                    if (e.target.value != '-1') {
                                                        val = e.target.value;
                                                    }
                                                    let schedulePatientTime = this.state.schedulePatientTime;
                                                    schedulePatientTime.frequencyMonthScheduled = val;
                                                    this.setState({
                                                        schedulePatientTime
                                                    })
                                                }}>
                                                    <option value={'-1'}>

                                                    </option>
                                                    <option value={'1'}>
                                                        {'1st'.translate(this.props.lang)}
                                                    </option>
                                                    <option value={'2'}>
                                                        {'2nd'.translate(this.props.lang)}
                                                    </option>
                                                    <option value={'3'}>
                                                        {'3rd'.translate(this.props.lang)}
                                                    </option>
                                                    <option value={'4'}>
                                                        {'4th'.translate(this.props.lang)}
                                                    </option>
                                                    <option value={'5'}>
                                                        {'5th'.translate(this.props.lang)}
                                                    </option>
                                                    <option value={'6'}>
                                                        {'6th'.translate(this.props.lang)}
                                                    </option>
                                                    <option value={'7'}>
                                                        {'7th'.translate(this.props.lang)}
                                                    </option>
                                                    <option value={'8'}>
                                                        {'8th'.translate(this.props.lang)}
                                                    </option>
                                                    <option value={'9'}>
                                                        {'9th'.translate(this.props.lang)}
                                                    </option>
                                                    <option value={'10'}>
                                                        {'10th'.translate(this.props.lang)}
                                                    </option>
                                                    <option value={'11'}>
                                                        {'11th'.translate(this.props.lang)}
                                                    </option>
                                                    <option value={'12'}>
                                                        {'12th'.translate(this.props.lang)}
                                                    </option>
                                                    <option value={'13'}>
                                                        {'13th'.translate(this.props.lang)}
                                                    </option>
                                                    <option value={'14'}>
                                                        {'14th'.translate(this.props.lang)}
                                                    </option>
                                                    <option value={'15'}>
                                                        {'15th'.translate(this.props.lang)}
                                                    </option>
                                                    <option value={'16'}>
                                                        {'16th'.translate(this.props.lang)}
                                                    </option>
                                                    <option value={'17'}>
                                                        {'17th'.translate(this.props.lang)}
                                                    </option>
                                                    <option value={'18'}>
                                                        {'18th'.translate(this.props.lang)}
                                                    </option>
                                                    <option value={'19'}>
                                                        {'19th'.translate(this.props.lang)}
                                                    </option>
                                                    <option value={'20'}>
                                                        {'20th'.translate(this.props.lang)}
                                                    </option>
                                                    <option value={'21'}>
                                                        {'21st'.translate(this.props.lang)}
                                                    </option>
                                                    <option value={'22'}>
                                                        {'22nd'.translate(this.props.lang)}
                                                    </option>
                                                    <option value={'23'}>
                                                        {'23rd'.translate(this.props.lang)}
                                                    </option>
                                                    <option value={'24'}>
                                                        {'24th'.translate(this.props.lang)}
                                                    </option>
                                                    <option value={'25'}>
                                                        {'25th'.translate(this.props.lang)}
                                                    </option>
                                                    <option value={'26'}>
                                                        {'26th'.translate(this.props.lang)}
                                                    </option>
                                                    <option value={'27'}>
                                                        {'27th'.translate(this.props.lang)}
                                                    </option>
                                                    <option value={'28'}>
                                                        {'28th'.translate(this.props.lang)}
                                                    </option>
                                                    <option value={'29'}>
                                                        {'29th'.translate(this.props.lang)}
                                                    </option>
                                                    <option value={'30'}>
                                                        {'30th'.translate(this.props.lang)}
                                                    </option>
                                                    <option value={'31'}>
                                                        {'31st'.translate(this.props.lang)}
                                                    </option>

                                                </Input>
                                                <Label style={{ marginLeft: 5 }}>{'day of the month'.translate(this.props.lang) + '*'}</Label>
                                            </div>
                                            {this.state.errorEvent ? <FormFeedback >{this.state.errorEvent}</FormFeedback> : null}
                                            <DatePicker
                                                label={'Start date'.translate(this.props.lang) + '*'}
                                                minDate={new Date()}
                                                dateFormat={this.props.dateFormat}
                                                value={this.state.schedulePatientTime.frequencyMonthScheduledDateFrom ? this.state.schedulePatientTime.frequencyMonthScheduledDateFrom : moment(new Date()).format(this.props.dateFormat)}
                                                onChange={(e) => {
                                                    let schedulePatientTime = this.state.schedulePatientTime;
                                                    schedulePatientTime.frequencyMonthScheduledDateFrom = e;
                                                    this.setState({
                                                        schedulePatientTime
                                                    })

                                                    if (this.state.schedulePatientTime.frequencyMonthScheduledDateTo && this.state.schedulePatientTime.frequencyMonthScheduledDateFrom) {
                                                        let dateTo = new Date(this.state.schedulePatientTime.frequencyMonthScheduledDateTo)
                                                        let dateFrom = new Date(this.state.schedulePatientTime.frequencyMonthScheduledDateFrom)
                                                        if (dateFrom.getTime() + 1000 * 60 * 60 * 24 * 31 > dateTo.getTime()) {
                                                            let schedulePatientTime = this.state.schedulePatientTime;
                                                            let date = new Date(this.state.schedulePatientTime.frequencyMonthScheduledDateFrom)
                                                            date.setDate(date.getDate() + 31)
                                                            schedulePatientTime.frequencyMonthScheduledDateTo = moment(new Date(date)).format(this.props.dateFormat ? this.props.dateFormat : 'YYYY-MM-DD');
                                                            this.setState({
                                                                schedulePatientTime
                                                            })

                                                        }
                                                    } else if (!this.state.schedulePatientTime.frequencyMonthScheduledDateTo && this.state.schedulePatientTime.frequencyMonthScheduledDateFrom) {
                                                        let schedulePatientTime = this.state.schedulePatientTime;
                                                        let date = new Date(this.state.schedulePatientTime.frequencyMonthScheduledDateFrom)
                                                        date.setDate(date.getDate() + 31)
                                                        schedulePatientTime.frequencyMonthScheduledDateTo = moment(new Date(date)).format(this.props.dateFormat ? this.props.dateFormat : 'YYYY-MM-DD');
                                                        this.setState({
                                                            schedulePatientTime
                                                        })
                                                    }
                                                }}
                                            />
                                            {this.state.errorEvent ? <FormFeedback >{this.state.errorEvent}</FormFeedback> : null}
                                        </Col>
                                        <Col lg="12">
                                            <DatePicker
                                                label={'End date'.translate(this.props.lang) + '*'}
                                                dateFormat={this.props.dateFormat}
                                                minDate={new Date(this.state.schedulePatientTime.frequencyMonthScheduledDateFrom) != 'Invalid Date' ? new Date(this.state.schedulePatientTime.frequencyMonthScheduledDateFrom) : new Date(this.state.monthDate)}
                                                value={this.state.schedulePatientTime.frequencyMonthScheduledDateTo ? this.state.schedulePatientTime.frequencyMonthScheduledDateTo : moment(this.state.monthDate).format(this.props.dateFormat)}
                                                onChange={(e) => {
                                                    let schedulePatientTime = this.state.schedulePatientTime;
                                                    schedulePatientTime.frequencyMonthScheduledDateTo = e;
                                                    this.setState({
                                                        schedulePatientTime
                                                    })

                                                }}
                                            />
                                            {this.state.errorEvent ? <FormFeedback >{this.state.errorEvent}</FormFeedback> : null}
                                        </Col>
                                    </Row>
                                </div>
                                : null}

                            <div style={{ display: 'flex', marginTop: 15 }}>
                                <Button style={{ margin: 10 }}
                                    disabled={this.state.schedulePatientTime && this.state.schedulePatientTime.type && (!this.state.schedulePatientTime.frequencyType || (this.state.schedulePatientTime.frequencyType &&
                                        (this.state.schedulePatientTime.frequencyType == 'day' && this.state.schedulePatientTime.frequencyDayText && this.state.schedulePatientTime.frequencyDayScheduledDateFrom && this.state.schedulePatientTime.frequencyDayScheduledDateTo)
                                        || (this.state.schedulePatientTime.frequencyType == 'month' && this.state.schedulePatientTime.frequencyMonthScheduled && this.state.schedulePatientTime.frequencyMonthScheduledDateFrom && this.state.schedulePatientTime.frequencyMonthScheduledDateTo)
                                        || (this.state.schedulePatientTime.frequencyType == 'week' && (this.state.schedulePatientTime.checkedMonday || this.state.schedulePatientTime.checkedTuesday || this.state.schedulePatientTime.checkedThursday || this.state.schedulePatientTime.checkedWednesday || this.state.schedulePatientTime.checkedFriday || this.state.schedulePatientTime.checkedSaturday || this.state.schedulePatientTime.checkedSunday) && this.state.schedulePatientTime.frequencyWeekScheduledDateFrom && this.state.schedulePatientTime.frequencyWeekScheduledDateTo)
                                    )) && !this.state.creatingEvent
                                        ? false : true}
                                    color='primary' onClick={() => {
                                        this.setState({
                                            creatingEvent: true
                                        }, () => {
                                            if (this.state.schedulePatientTime.id) {
                                                this.updateEvent(this.state.schedulePatientTime)
                                            } else {
                                                if (this.state.schedulePatientTime.type === 'event') {
                                                    this.scheduleEvent(this.state.schedulePatientTime)
                                                } else {
                                                    this.markAsAvailable(this.state.schedulePatientTime)
                                                }
                                            }
                                        })


                                    }}>
                                    {this.state.loading ?

                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <Player
                                                onEvent={() => {
                                                    if (!this.state.loading) this.stopAnimation();
                                                }}
                                                onStateChange={this.toggleVisibility}
                                                ref={this.player} // set the ref to your class instance
                                                autoplay={true}
                                                loop={true}
                                                controls={true}
                                                src="https://assets8.lottiefiles.com/packages/lf20_ZSRKCF.json"
                                                style={{ height: "20px", width: "30px" }}
                                            ></Player>
                                            {'Processing...'.translate(this.props.lang)}
                                        </div>

                                        : 'Set'.translate(this.props.lang)}
                                </Button>
                                <Button style={{ margin: 10 }}
                                    disabled={this.state.schedulePatientTime && this.state.schedulePatientTime.type && (!this.state.schedulePatientTime.frequencyType || (this.state.schedulePatientTime.frequencyType &&
                                        (this.state.schedulePatientTime.frequencyType == 'day' && this.state.schedulePatientTime.frequencyDayText && this.state.schedulePatientTime.frequencyDayScheduledDateFrom && this.state.schedulePatientTime.frequencyDayScheduledDateTo)
                                        || (this.state.schedulePatientTime.frequencyType == 'month' && this.state.schedulePatientTime.frequencyMonthScheduled && this.state.schedulePatientTime.frequencyMonthScheduledDateFrom && this.state.schedulePatientTime.frequencyMonthScheduledDateTo)
                                        || (this.state.schedulePatientTime.frequencyType == 'week' && (this.state.schedulePatientTime.checkedMonday || this.state.schedulePatientTime.checkedTuesday || this.state.schedulePatientTime.checkedThursday || this.state.schedulePatientTime.checkedWednesday || this.state.schedulePatientTime.checkedFriday || this.state.schedulePatientTime.checkedSaturday || this.state.schedulePatientTime.checkedSunday) && this.state.schedulePatientTime.frequencyWeekScheduledDateFrom && this.state.schedulePatientTime.frequencyWeekScheduledDateTo)
                                    ))
                                        ? false : true}
                                    color='primary' onClick={() => this.deletePatientTime(this.state.schedulePatientTime)}>
                                    {this.state.loadingDelete ?

                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <Player
                                                onEvent={() => {
                                                    if (!this.state.loadingDelete) this.stopAnimation();
                                                }}
                                                onStateChange={this.toggleVisibility}
                                                ref={this.player} // set the ref to your class instance
                                                autoplay={true}
                                                loop={true}
                                                controls={true}
                                                src="https://assets8.lottiefiles.com/packages/lf20_ZSRKCF.json"
                                                style={{ height: "20px", width: "30px" }}
                                            ></Player>
                                            {'Processing...'.translate(this.props.lang)}
                                        </div>

                                        : 'Cancel time'.translate(this.props.lang)}
                                </Button>
                                {
                                    this.state.schedulePatientTime.id ?
                                        <Button style={{ margin: 10 }} color='primary' onClick={() => this.setState({
                                            schedulePatientTime: null
                                        })}>{'Cancel'.translate(this.props.lang)}</Button>
                                        :
                                        null
                                }

                            </div>

                        </ModalBody >
                    </Modal >
                    // <Modal isOpen={this.state.schedulePatientTime} centered size="lg">
                    //     <ModalHeader toggle={() => this.setState({ schedulePatientTime: !this.state.schedulePatientTime })} close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => this.setState({ schedulePatientTime: !this.state.schedulePatientTime })}>&times;</button>}>{'Set available terms'.translate(this.props.lang)}</ModalHeader>

                    //     <ModalBody>
                    //         <Row>
                    //             <Col lg='8'>
                    //                 <DatePicker
                    //                     label={'Start date'.translate(this.props.lang)}
                    //                     value={this.state.schedulePatientTime.date}
                    //                     onChange={(e) => {
                    //                         let schedulePatientTime = this.state.schedulePatientTime;

                    //                         schedulePatientTime.date = e;
                    //                         this.setState({
                    //                             schedulePatientTime
                    //                         })
                    //                         if (Math.floor(new Date(this.state.schedulePatientTime.date + ' ' + this.state.schedulePatientTime.startTime) / 1000) > Math.floor(new Date(this.state.schedulePatientTime.endDate + ' ' + this.state.schedulePatientTime.endTime) / 1000)) {
                    //                             schedulePatientTime.endDate = this.state.schedulePatientTime.date
                    //                             this.setState({
                    //                                 schedulePatientTime
                    //                             })
                    //                         }
                    //                     }}
                    //                 />
                    //                 <DatePicker
                    //                     label={'End date'.translate(this.props.lang)}
                    //                     value={this.state.schedulePatientTime.endDate}
                    //                     onChange={(e) => {
                    //                         let schedulePatientTime = this.state.schedulePatientTime;
                    //                         schedulePatientTime.endDate = e;
                    //                         this.setState({
                    //                             schedulePatientTime
                    //                         })
                    //                         if (Math.floor(new Date(this.state.schedulePatientTime.date + ' ' + this.state.schedulePatientTime.startTime) / 1000) > Math.floor(new Date(this.state.schedulePatientTime.endDate + ' ' + this.state.schedulePatientTime.endTime) / 1000)) {
                    //                             schedulePatientTime.endDate = this.state.schedulePatientTime.date
                    //                             this.setState({
                    //                                 schedulePatientTime
                    //                             })
                    //                         }
                    //                     }}
                    //                 />
                    //                 <div className="flex mob-flex-col" >
                    //                     <FormGroup className="timepicker-container timepicker-wide fullwidth-mobile" style={{ marginRight: '20px' }}>
                    //                         <Label>{'Start time'.translate(this.props.lang)}</Label>
                    //                         &nbsp;
                    //                         <TimePicker
                    //                             getStringDateTs={this.props.getStringDateTs}
                    //                             format={'HH:mm'}
                    //                             value={moment(new Date(this.state.schedulePatientTime.date.replace(/-/g, "/") + " " + this.state.schedulePatientTime.startTime))}
                    //                             // maxValue={this.state.scheduleReferral.endTime}
                    //                              minValue={this.props.getStringDateTs(Math.floor(this.state.min.getTime() / 1000), 'HH:mm')/*moment.unix(this.state.min.getTime() / 1000).format("HH:mm")*/}
                    //                             maxValue={this.props.getStringDateTs(Math.floor(this.state.max.getTime() / 1000), 'HH:mm')/*moment.unix(this.state.max.getTime() / 1000).format("HH:mm")*/}
                    //                             flag={0}
                    //                             onChange={(time1, timeString) => {

                    //                                 let schedulePatientTime = this.state.schedulePatientTime;
                    //                                 schedulePatientTime.startTime = timeString

                    //                                 let startTimeSplit = schedulePatientTime.startTime.split(":");
                    //                                 let endTimeSplit = schedulePatientTime.endTime.split(":");

                    //                                 let startTimeHourMinute = Number(startTimeSplit[0]) * 60 + Number(startTimeSplit[1]);
                    //                                 let endTimeHourMinute = Number(endTimeSplit[0]) * 60 + Number(endTimeSplit[1]);

                    //                                 // if (startTimeHourMinute < endTimeHourMinute) {
                    //                                 //     this.setState({ scheduleReferral })
                    //                                 // } else {
                    //                                 // this.setState({ wrongInput: true })
                    //                                 // }

                    //                                 this.setState({ schedulePatientTime })
                    //                             }}
                    //                             allowClear={false}
                    //                         />
                    //                     </FormGroup>
                    //                     <FormGroup className="timepicker-container timepicker-wide fullwidth-mobile">
                    //                         <Label>{'End time'.translate(this.props.lang)}</Label>
                    //                         &nbsp;
                    //                         <TimePicker
                    //                              getStringDateTs={this.props.getStringDateTs}
                    //                             format={'HH:mm'}
                    //                             value={moment(new Date(this.state.schedulePatientTime.date.replace(/-/g, "/") + " " + this.state.schedulePatientTime.endTime))}
                    //                             minValue={Math.trunc((Number(this.state.schedulePatientTime.startTime.split(':')[0]) * 60 + Number(this.state.schedulePatientTime.startTime.split(':')[1]) + 5) / 60) + ":" + (Number(this.state.schedulePatientTime.startTime.split(':')[0]) * 60 + Number(this.state.schedulePatientTime.startTime.split(':')[1]) + 5) % 60}
                    //                             maxValue={this.props.getStringDateTs(Math.floor(this.state.max.getTime() / 1000), 'HH:mm')/*moment.unix(this.state.max.getTime() / 1000).format("HH:mm")*/}
                    //                             flag={0}
                    //                             // maxValue={this.state.scheduleReferral.startTime}
                    //                             // flag={1}
                    //                             onChange={(time1, timeString) => {

                    //                                 let schedulePatientTime = this.state.schedulePatientTime;
                    //                                 schedulePatientTime.endTime = timeString


                    //                                 let startTimeSplit = schedulePatientTime.startTime.split(":");
                    //                                 let endTimeSplit = schedulePatientTime.endTime.split(":");

                    //                                 let startTimeHourMinute = Number(startTimeSplit[0]) * 60 + Number(startTimeSplit[1]);
                    //                                 let endTimeHourMinute = Number(endTimeSplit[0]) * 60 + Number(endTimeSplit[1]);

                    //                                 if (startTimeHourMinute < endTimeHourMinute) {
                    //                                     this.setState({ schedulePatientTime })
                    //                                 } else {
                    //                                     // this.setState({ wrongInput: true })
                    //                                 }
                    //                             }}
                    //                             allowClear={false}
                    //                         />
                    //                     </FormGroup>
                    //                 </div>
                    //             </Col>
                    //             <Col lg='4'>
                    //                 <FormGroup>
                    //                     <Checkbox
                    //                         onChange={(e) => {
                    //                             let schedulePatientTime = this.state.schedulePatientTime;
                    //                             schedulePatientTime.monday = e.target.checked;
                    //                             this.setState({
                    //                                 schedulePatientTime
                    //                             })
                    //                         }}
                    //                         checked={this.state.schedulePatientTime.monday}
                    //                         label={'Monday'.translate(this.props.lang)}
                    //                     />
                    //                 </FormGroup>
                    //                 <FormGroup>
                    //                     <Checkbox
                    //                         onChange={(e) => {
                    //                             let schedulePatientTime = this.state.schedulePatientTime;
                    //                             schedulePatientTime.tuesday = e.target.checked;
                    //                             this.setState({
                    //                                 schedulePatientTime
                    //                             })
                    //                         }}
                    //                         checked={this.state.schedulePatientTime.tuesday}
                    //                         label={'Tuesday'.translate(this.props.lang)}
                    //                     />
                    //                 </FormGroup>
                    //                 <FormGroup>
                    //                     <Checkbox
                    //                         onChange={(e) => {
                    //                             let schedulePatientTime = this.state.schedulePatientTime;
                    //                             schedulePatientTime.wednesday = e.target.checked;
                    //                             this.setState({
                    //                                 schedulePatientTime
                    //                             })
                    //                         }}
                    //                         checked={this.state.schedulePatientTime.wednesday}
                    //                         label={'Wednesday'.translate(this.props.lang)}
                    //                     />
                    //                 </FormGroup>
                    //                 <FormGroup>
                    //                     <Checkbox
                    //                         onChange={(e) => {
                    //                             let schedulePatientTime = this.state.schedulePatientTime;
                    //                             schedulePatientTime.thursday = e.target.checked;
                    //                             this.setState({
                    //                                 schedulePatientTime
                    //                             })
                    //                         }}
                    //                         checked={this.state.schedulePatientTime.thursday}
                    //                         label={'Thursday'.translate(this.props.lang)}
                    //                     />
                    //                 </FormGroup>
                    //                 <FormGroup>
                    //                     <Checkbox
                    //                         onChange={(e) => {
                    //                             let schedulePatientTime = this.state.schedulePatientTime;
                    //                             schedulePatientTime.friday = e.target.checked;
                    //                             this.setState({
                    //                                 schedulePatientTime
                    //                             })
                    //                         }}
                    //                         checked={this.state.schedulePatientTime.friday}
                    //                         label={'Friday'.translate(this.props.lang)}
                    //                     />
                    //                 </FormGroup>
                    //                 <FormGroup>
                    //                     <Checkbox
                    //                         onChange={(e) => {
                    //                             let schedulePatientTime = this.state.schedulePatientTime;
                    //                             schedulePatientTime.saturday = e.target.checked;
                    //                             this.setState({
                    //                                 schedulePatientTime
                    //                             })
                    //                         }}
                    //                         checked={this.state.schedulePatientTime.saturday}
                    //                         label={'Saturday'.translate(this.props.lang)}
                    //                     />
                    //                 </FormGroup>
                    //                 <FormGroup>
                    //                     <Checkbox
                    //                         onChange={(e) => {
                    //                             let schedulePatientTime = this.state.schedulePatientTime;
                    //                             schedulePatientTime.sunday = e.target.checked;
                    //                             this.setState({
                    //                                 schedulePatientTime
                    //                             })
                    //                         }}
                    //                         checked={this.state.schedulePatientTime.sunday}
                    //                         label={'Sunday'.translate(this.props.lang)}
                    //                     />
                    //                 </FormGroup>
                    //             </Col>
                    //             <Col lg="12">
                    //                 <Button style={{ margin: 10 }} color='primary' onClick={() => this.schedulePatientTime(this.state.schedulePatientTime)}>
                    //                     {/* {'Set'.translate(this.props.lang)} */}
                    //                     {this.state.loading ? /*'Processing...'.translate(this.props.lang)*/

                    //                         <div style={{ display: 'flex', alignItems: 'center' }}>
                    //                             <Player
                    //                                 onEvent={() => {
                    //                                     if (!this.state.loading) this.stopAnimation();
                    //                                 }}
                    //                                 onStateChange={this.toggleVisibility}
                    //                                 ref={this.player} // set the ref to your class instance
                    //                                 autoplay={true}
                    //                                 loop={true}
                    //                                 controls={true}
                    //                                 src="https://assets8.lottiefiles.com/packages/lf20_ZSRKCF.json"
                    //                                 style={{ height: "20px", width: "30px" }}
                    //                             ></Player>
                    //                             {'Processing...'.translate(this.props.lang)}
                    //                         </div>

                    //                         : 'Set'.translate(this.props.lang)}
                    //                 </Button>
                    //                 <Button style={{ margin: 10 }} color='primary' onClick={() => this.deletePatientTime(this.state.schedulePatientTime)}>
                    //                     {/* {'Delete'.translate(this.props.lang)} */}
                    //                     {this.state.loadingDelete ? /*'Processing...'.translate(this.props.lang)*/

                    //                         <div style={{ display: 'flex', alignItems: 'center' }}>
                    //                             <Player
                    //                                 onEvent={() => {
                    //                                     if (!this.state.loadingDelete) this.stopAnimation();
                    //                                 }}
                    //                                 onStateChange={this.toggleVisibility}
                    //                                 ref={this.player} // set the ref to your class instance
                    //                                 autoplay={true}
                    //                                 loop={true}
                    //                                 controls={true}
                    //                                 src="https://assets8.lottiefiles.com/packages/lf20_ZSRKCF.json"
                    //                                 style={{ height: "20px", width: "30px" }}
                    //                             ></Player>
                    //                             {'Processing...'.translate(this.props.lang)}
                    //                         </div>

                    //                         : 'Delete'.translate(this.props.lang)}
                    //                 </Button>

                    //             </Col>
                    //         </Row>






                    //     </ModalBody>
                    // </Modal>
                    : null
                }
                {
                    this.state.addSecondGuardian ?
                        <AddSecondParentModal
                            {...this.props}
                            languages={this.state.languages}
                            data={this.state.addSecondGuardian}
                            close={(getPatientData) => this.setState({ addSecondGuardian: null }, () => {
                                if (getPatientData) {
                                    this.findPatient()
                                }
                            })}
                        />
                        :
                        null
                }

                {
                    this.state.scheduleControl ?
                        <Modal isOpen={this.state.scheduleControl} centered >
                            <ModalHeader toggle={() => this.setState({ scheduleControl: !this.state.scheduleControl, age: null, patient: null, doctorsList: [], revisitFormsSelected: [], revisitFormsSelect: [], journalNoteFormSelected: null })} close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => this.setState({ scheduleControl: !this.state.scheduleControl, age: null, patient: null, doctorsList: [], revisitFormsSelected: [], revisitFormsSelect: [], journalNoteFormSelected: null })}>&times;</button>}>{'Schedule control'.translate(this.props.lang)}</ModalHeader>

                            <ModalBody>
                                <FormGroup>
                                    <Button color='primary' onClick={() => this.setState({ patientModal: this.state.scheduleControl, scheduleControl: !this.state.scheduleControl, doctorsList: [], patient: null, patientSocialSecurityNumber: '', age: null })}>{'Find patient'.translate(this.props.lang)}</Button>
                                </FormGroup>

                                {
                                    isChild ?
                                        <FormGroup>
                                            <Label style={{ color: 'red' }}>{"Child is under 13 years of age - Appointments are handled by parent or legal guardian.".translate(this.props.lang)}</Label>
                                        </FormGroup>
                                        :
                                        null
                                }
                                {
                                    isChild ?
                                        <FormGroup>
                                            <Label style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>{"Parent/legal guardian added to appointment".translate(this.props.lang)} <div className="parent-tooltip"> <Isvg src={infoIcon} className="info-text" /> <div className="chat-icons-tooltip">{'Appointment can be managed by one or two parents/legal guardians. Selected persons will receive notification and can participate in chat and videocalls'.translate(this.props.lang)}</div> </div></Label>
                                            {
                                                this.state.patient?.parentObj?.socialSecurityNumber && this.state.patient?.parentObj2?.socialSecurityNumber ?
                                                    <div className='table-parents-wrap'>
                                                        <table className='table-parents'>
                                                            <tr>
                                                                <td>
                                                                    <Checkbox
                                                                        checked={this.state.scheduleControl?.selectedParents?.indexOf(this.state.patient?.parentObj?._id) !== -1 ? true : false} onChange={(e) => {
                                                                            let scheduleControl = this.state.scheduleControl;
                                                                            let selectedParents = scheduleControl.selectedParents ?? [];
                                                                            let parentId;
                                                                            if (this.state.patient?.parentObj?._id) {
                                                                                parentId = this.state.patient.parentObj._id;
                                                                            }
                                                                            if (e.target.checked) {
                                                                                if (parentId && selectedParents.indexOf(parentId) === -1) {
                                                                                    selectedParents.push(parentId)
                                                                                }
                                                                            } else {
                                                                                if (parentId && selectedParents.indexOf(parentId) !== -1 && selectedParents.length > 1) {
                                                                                    let index = selectedParents.indexOf(parentId);
                                                                                    selectedParents.splice(index, 1)
                                                                                }
                                                                            }
                                                                            scheduleControl.selectedParents = selectedParents;
                                                                            let parents = [];

                                                                            for (let i = 0; i < selectedParents.length; i++) {
                                                                                if (this.state.patient?.parentObj?._id === selectedParents[i] && this.state.patient?.parentObj?.name) {
                                                                                    parents.push(this.state.patient.parentObj.name.trim())
                                                                                }
                                                                                if (this.state.patient?.parentObj2?._id === selectedParents[i] && this.state.patient?.parentObj2?.name) {
                                                                                    parents.push(this.state.patient.parentObj2.name.trim())
                                                                                }
                                                                            }

                                                                            scheduleControl.title = this.state.patient.userData.name.trim() + ' ' + 'Parent/legal guardian:'.translate(this.props.lang) + ` ${parents.join(', ')}`;

                                                                            this.setState({ scheduleControl })

                                                                        }} />
                                                                </td>
                                                                <td>{this.state.patient && this.state.patient.parentObj && this.state.patient.parentObj.name}</td>
                                                                <td>{this.state.patient && this.state.patient.parentObj && this.state.patient.parentObj.socialSecurityNumber}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    <Checkbox
                                                                        checked={this.state.scheduleControl?.selectedParents?.indexOf(this.state.patient?.parentObj2?._id) !== -1 ? true : false} onChange={(e) => {
                                                                            let scheduleControl = this.state.scheduleControl;
                                                                            let selectedParents = scheduleControl.selectedParents ?? [];
                                                                            let parentId;
                                                                            if (this.state.patient?.parentObj2?._id) {
                                                                                parentId = this.state.patient.parentObj2._id;
                                                                            }
                                                                            if (e.target.checked) {
                                                                                if (parentId && selectedParents.indexOf(parentId) === -1) {
                                                                                    selectedParents.push(parentId)
                                                                                }
                                                                            } else {
                                                                                if (parentId && selectedParents.indexOf(parentId) !== -1 && selectedParents.length > 1) {
                                                                                    let index = selectedParents.indexOf(parentId);
                                                                                    selectedParents.splice(index, 1)
                                                                                }
                                                                            }
                                                                            scheduleControl.selectedParents = selectedParents;
                                                                            let parents = [];

                                                                            for (let i = 0; i < selectedParents.length; i++) {
                                                                                if (this.state.patient?.parentObj?._id === selectedParents[i] && this.state.patient?.parentObj?.name) {
                                                                                    parents.push(this.state.patient.parentObj.name.trim())
                                                                                }
                                                                                if (this.state.patient?.parentObj2?._id === selectedParents[i] && this.state.patient?.parentObj2?.name) {
                                                                                    parents.push(this.state.patient.parentObj2.name.trim())
                                                                                }
                                                                            }

                                                                            scheduleControl.title = this.state.patient.userData.name.trim() + ' ' + 'Parent/legal guardian:'.translate(this.props.lang) + ` ${parents.join(', ')}`;


                                                                            this.setState({ scheduleControl })
                                                                        }} />
                                                                </td>
                                                                <td>{this.state.patient && this.state.patient.parentObj2 && this.state.patient.parentObj2.name}</td>
                                                                <td>{this.state.patient && this.state.patient.parentObj2 && this.state.patient.parentObj2.socialSecurityNumber}</td>
                                                            </tr>
                                                        </table>
                                                    </div>
                                                    :
                                                    <div>
                                                        <div style={{ display: 'flex', justifyContent: 'space-evenly', alignItems: 'center' }}>
                                                            <p>{this.state.patient && this.state.patient.parentObj && this.state.patient.parentObj.name}</p>
                                                            <p>{this.state.patient && this.state.patient.parentObj && this.state.patient.parentObj.socialSecurityNumber}</p>
                                                        </div>
                                                        <div className='add-second-guardian-btn'>
                                                            <Button color='primary' onClick={() => {
                                                                let patientObj = JSON.parse(JSON.stringify(this.state.patient))
                                                                this.setState({ addSecondGuardian: patientObj })
                                                            }}>{'Add second parent/legal guardian'.translate(this.props.lang)}</Button>
                                                        </div>
                                                    </div>
                                            }


                                        </FormGroup>
                                        :
                                        null
                                }
                                {
                                    isChild && this.state.scheduleControl && this.state.scheduleControl.title ?
                                        <FormGroup>
                                            <Label>{'Title'.translate(this.props.lang)}</Label>

                                            <Input disabled type='textarea'

                                                value={this.state.scheduleControl.titleDoctor && this.state.scheduleControl.titleDoctor != '' ?
                                                    `Patient: ${this.state.scheduleControl.title}, ${this.props.uData.name}, ${this.state.scheduleControl.visitReasonName && this.state.scheduleControl.visitReasonName != '' ? ', (' + this.state.scheduleControl.visitReasonName + ')' : ''}` + ', ' + this.state.scheduleControl.titleDoctor :
                                                    `Patient: ${this.state.scheduleControl.title}, ${this.props.uData.name}, ${this.state.scheduleControl.visitReasonName && this.state.scheduleControl.visitReasonName != '' ? ', (' + this.state.scheduleControl.visitReasonName + ')' : ''}`} />
                                            <Input type='text' style={{ marginTop: 5 }} onChange={(e) => {
                                                let scheduleControl = this.state.scheduleControl;
                                                scheduleControl.titleDoctor = striptags(e.target.value);

                                                this.setState({
                                                    scheduleControl
                                                })
                                            }}
                                                value={this.state.scheduleControl.titleDoctor} />

                                        </FormGroup>

                                        :

                                        this.state.scheduleControl && this.state.scheduleControl.title ?
                                            <FormGroup>
                                                <Label>{'Title'.translate(this.props.lang)}</Label>

                                                <Input disabled type='textarea'

                                                    value={this.state.scheduleControl.titleDoctor && this.state.scheduleControl.titleDoctor != '' ? `Patient: ${this.state.scheduleControl.title}, ${this.props.uData.name}${this.state.scheduleControl.visitReasonName && this.state.scheduleControl.visitReasonName != '' ? ', (' + this.state.scheduleControl.visitReasonName + ')' : ''}` + ', ' + this.state.scheduleControl.titleDoctor : `Patient: ${this.state.scheduleControl.title}, ${this.props.uData.name}${this.state.scheduleControl.visitReasonName && this.state.scheduleControl.visitReasonName != '' ? ', (' + this.state.scheduleControl.visitReasonName + ')' : ''}`} />
                                                <Input type='text' style={{ marginTop: 5 }} onChange={(e) => {
                                                    let scheduleControl = this.state.scheduleControl;
                                                    scheduleControl.titleDoctor = striptags(e.target.value);

                                                    this.setState({
                                                        scheduleControl
                                                    })
                                                }}
                                                    value={this.state.scheduleControl.titleDoctor} />

                                            </FormGroup>
                                            :
                                            null

                                }
                                {/* {
                                    this.state.scheduleControl && this.state.scheduleControl.title ?
                                        <FormGroup>
                                            <Label>{'Title'.translate(this.props.lang)}</Label>

                                            <Input disabled type='textarea'

                                                value={this.state.scheduleControl.titleDoctor && this.state.scheduleControl.titleDoctor != '' ? `Patient: ${this.state.scheduleControl.title}, ${this.props.uData.name}${this.state.scheduleControl.visitReasonName && this.state.scheduleControl.visitReasonName != '' ? ', (' + this.state.scheduleControl.visitReasonName + ')' : ''}` + ', ' + this.state.scheduleControl.titleDoctor : `Patient: ${this.state.scheduleControl.title}, ${this.props.uData.name}${this.state.scheduleControl.visitReasonName && this.state.scheduleControl.visitReasonName != '' ? ', (' + this.state.scheduleControl.visitReasonName + ')' : ''}`} />
                                            <Input type='text' style={{ marginTop: 5 }} onChange={(e) => {
                                                let scheduleControl = this.state.scheduleControl;
                                                scheduleControl.titleDoctor = striptags(e.target.value);

                                                this.setState({
                                                    scheduleControl
                                                })
                                            }}
                                                value={this.state.scheduleControl.titleDoctor} />

                                        </FormGroup>
                                        :
                                        null
                                } */}

                                <DatePicker
                                    label={'Date'.translate(this.props.lang)}
                                    value={this.state.scheduleControl.date}
                                    onChange={(e) => {
                                        let scheduleControl = this.state.scheduleControl;
                                        scheduleControl.date = e;
                                        this.setState({
                                            scheduleControl
                                        })
                                        if (Math.floor(new Date(this.state.scheduleControl.date + ' ' + this.state.scheduleControl.startTime) / 1000) > Math.floor(new Date(this.state.scheduleControl.endDate + ' ' + this.state.scheduleControl.endTime) / 1000)) {
                                            scheduleControl.endDate = this.state.scheduleControl.date
                                            this.setState({
                                                scheduleControl
                                            })
                                        }
                                    }}
                                />
                                <FormGroup className="timepicker-container timepicker-wide">
                                    <Label>{'Start time'.translate(this.props.lang)}</Label>
                                    &nbsp;
                                    <TimePickerV2
                                        getStringDateTs={this.props.getStringDateTs}
                                        format={'HH:mm'}
                                        value={this.state.scheduleControl.startTime}
                                        minValue={this.props.getStringDateTs(Math.floor(this.state.min.getTime() / 1000), 'HH:mm')} // moment.unix(this.state.min.getTime() / 1000).format("HH:mm")
                                        maxValue={this.props.getStringDateTs(Math.floor(this.state.max.getTime() / 1000 - 300), 'HH:mm')} // moment.unix(this.state.max.getTime() / 1000).format("HH:mm")
                                        flag={0}
                                        onChange={(time1, timeString) => {

                                            let scheduleControl = this.state.scheduleControl;
                                            scheduleControl.startTime = timeString


                                            let startTimeSplit = scheduleControl.startTime.split(":");
                                            let endTimeSplit = scheduleControl.endTime.split(":");

                                            let startTimeHourMinute = Number(startTimeSplit[0]) * 60 + Number(startTimeSplit[1]);
                                            let endTimeHourMinute = Number(endTimeSplit[0]) * 60 + Number(endTimeSplit[1]);
                                            // this.setState({ scheduleControl })

                                            if (startTimeHourMinute < endTimeHourMinute) {
                                                this.setState({ scheduleControl })
                                            } else {
                                                scheduleControl.endTime = Math.trunc((Number(this.state.scheduleControl.startTime.split(':')[0]) * 60 + Number(this.state.scheduleControl.startTime.split(':')[1]) + 5) / 60) + ":" + (Number(this.state.scheduleControl.startTime.split(':')[0]) * 60 + Number(this.state.scheduleControl.startTime.split(':')[1]) + 5) % 60
                                                // scheduleControl.endTime = validateTime(scheduleControl.endTime);
                                                const padded = scheduleControl?.endTime?.split?.(':');
                                                if (padded && Array.isArray(padded) && padded.length && isStringAValidNumber(padded[0]) && isStringAValidNumber(padded[1])) {
                                                    const h = padded[0]?.padStart?.(2, '0');
                                                    const m = padded[1]?.padStart?.(2, '0');
                                                    if (h && m) scheduleControl.endTime = `${h}:${m}`;
                                                }
                                                this.setState({
                                                    scheduleControl
                                                })
                                            }
                                            // if (startTimeHourMinute < endTimeHourMinute) {
                                            //     this.setState({ scheduleControl })
                                            // } else {
                                            // this.setState({ wrongInput: true })
                                            // }
                                        }}
                                    />
                                    {/* <TimePicker
                                        getStringDateTs={this.props.getStringDateTs}
                                        format={'HH:mm'}
                                        value={moment(new Date(this.state.scheduleControl.date.replace(/-/g, "/") + " " + this.state.scheduleControl.startTime))}
                                        // maxValue={this.state.scheduleControl.endTime}
                                        minValue={this.props.getStringDateTs(Math.floor(this.state.min.getTime() / 1000), 'HH:mm')} // moment.unix(this.state.min.getTime() / 1000).format("HH:mm")
                                        maxValue={this.props.getStringDateTs(Math.floor(this.state.max.getTime() / 1000), 'HH:mm')} // moment.unix(this.state.max.getTime() / 1000).format("HH:mm")
                                        flag={0}
                                        onChange={(time1, timeString) => {

                                            let scheduleControl = this.state.scheduleControl;
                                            scheduleControl.startTime = timeString


                                            let startTimeSplit = scheduleControl.startTime.split(":");
                                            let endTimeSplit = scheduleControl.endTime.split(":");

                                            let startTimeHourMinute = Number(startTimeSplit[0]) * 60 + Number(startTimeSplit[1]);
                                            let endTimeHourMinute = Number(endTimeSplit[0]) * 60 + Number(endTimeSplit[1]);
                                            this.setState({ scheduleControl })
                                            // if (startTimeHourMinute < endTimeHourMinute) {
                                            //     this.setState({ scheduleControl })
                                            // } else {
                                            // this.setState({ wrongInput: true })
                                            // }
                                        }}
                                        allowClear={false}
                                    /> */}
                                </FormGroup>
                                <FormGroup className="timepicker-container timepicker-wide">
                                    <Label>{'End time'.translate(this.props.lang)}</Label>
                                    &nbsp;
                                    <TimePickerV2
                                        getStringDateTs={this.props.getStringDateTs}
                                        format={'HH:mm'}
                                        value={this.state.scheduleControl.endTime}
                                        minValue={Math.trunc((Number(this.state.scheduleControl.startTime.split(':')[0]) * 60 + Number(this.state.scheduleControl.startTime.split(':')[1]) + 5) / 60) + ":" + (Number(this.state.scheduleControl.startTime.split(':')[0]) * 60 + Number(this.state.scheduleControl.startTime.split(':')[1]) + 5) % 60}
                                        maxValue={this.props.getStringDateTs(Math.floor(this.state.max.getTime() / 1000), 'HH:mm')} // moment.unix(this.state.max.getTime() / 1000).format("HH:mm")
                                        flag={0}
                                        onChange={(time1, timeString) => {

                                            let scheduleControl = this.state.scheduleControl;
                                            scheduleControl.endTime = timeString

                                            let startTimeSplit = scheduleControl.startTime.split(":");
                                            let endTimeSplit = scheduleControl.endTime.split(":");

                                            let startTimeHourMinute = Number(startTimeSplit[0]) * 60 + Number(startTimeSplit[1]);
                                            let endTimeHourMinute = Number(endTimeSplit[0]) * 60 + Number(endTimeSplit[1]);

                                            if (startTimeHourMinute < endTimeHourMinute) {
                                                this.setState({ scheduleControl })
                                            } else {
                                                // this.setState({ wrongInput: true })
                                            }
                                        }}
                                    />
                                    {/* <TimePicker
                                        getStringDateTs={this.props.getStringDateTs}
                                        format={'HH:mm'}
                                        value={moment(new Date(this.state.scheduleControl.date.replace(/-/g, "/") + " " + this.state.scheduleControl.endTime))}
                                        minValue={Math.trunc((Number(this.state.scheduleControl.startTime.split(':')[0]) * 60 + Number(this.state.scheduleControl.startTime.split(':')[1]) + 5) / 60) + ":" + (Number(this.state.scheduleControl.startTime.split(':')[0]) * 60 + Number(this.state.scheduleControl.startTime.split(':')[1]) + 5) % 60}
                                        maxValue={this.props.getStringDateTs(Math.floor(this.state.max.getTime() / 1000), 'HH:mm')} // moment.unix(this.state.max.getTime() / 1000).format("HH:mm")
                                        flag={0}
                                        // maxValue={this.state.scheduleControl.startTime}
                                        // flag={1}
                                        onChange={(time1, timeString) => {

                                            let scheduleControl = this.state.scheduleControl;
                                            scheduleControl.endTime = timeString

                                            let startTimeSplit = scheduleControl.startTime.split(":");
                                            let endTimeSplit = scheduleControl.endTime.split(":");

                                            let startTimeHourMinute = Number(startTimeSplit[0]) * 60 + Number(startTimeSplit[1]);
                                            let endTimeHourMinute = Number(endTimeSplit[0]) * 60 + Number(endTimeSplit[1]);

                                            if (startTimeHourMinute < endTimeHourMinute) {
                                                this.setState({ scheduleControl })
                                            } else {
                                                // this.setState({ wrongInput: true })
                                            }
                                        }}
                                        allowClear={false}
                                    /> */}
                                </FormGroup>
                                {
                                    this.state.scheduleControl.title ?
                                        <FormGroup>
                                            <Label>{'Note'.translate(this.props.lang)}</Label>
                                            <Input type='textarea'
                                                name="note"
                                                style={{ height: '150px' }}
                                                onChange={(e) => {
                                                    let scheduleControl = this.state.scheduleControl;
                                                    scheduleControl.patientControlNote = striptags(e.target.value);
                                                    this.setState({
                                                        scheduleControl
                                                    })
                                                }}
                                                value={this.state.scheduleControl.patientControlNote}
                                            />
                                        </FormGroup>
                                        :
                                        null
                                }
                                {
                                    this.state.scheduleControl.title && enterprise ?
                                        <FormGroup style={{ display: 'flex', alignItems: 'center' }}>
                                            <Checkbox
                                                onChange={(e) => {
                                                    let scheduleControl = this.state.scheduleControl;
                                                    scheduleControl.hideTimeFromThePatient = e.target.checked;
                                                    this.setState({
                                                        scheduleControl
                                                    })
                                                }}
                                                checked={this.state.scheduleControl.hideTimeFromThePatient}
                                                label={'Hide time from the patient'.translate(this.props.lang)}
                                            />
                                            <div className='button-elements-tooltip' style={{ marginLeft: 8 }}>
                                                <Isvg src={infoIcon} style={{ width: 25 }} />
                                                <div className='tooltip-text' style={{ minWidth: 180, bottom: 29 }}>{'Time is not displayed to the patient.'.translate(this.props.lang)}</div>
                                            </div>
                                        </FormGroup>
                                        :
                                        null
                                }
                                {
                                    this.state.scheduleControl.title && enterprise ?
                                        <FormGroup style={{ display: 'flex', alignItems: 'center' }}>
                                            <Checkbox
                                                onChange={(e) => {
                                                    let scheduleControl = this.state.scheduleControl;
                                                    scheduleControl.closeCaseManually = e.target.checked;
                                                    this.setState({
                                                        scheduleControl
                                                    })
                                                }}
                                                checked={this.state.scheduleControl.closeCaseManually}
                                                label={'Close case manually'.translate(this.props.lang)}
                                            />

                                        </FormGroup>
                                        :
                                        null
                                }

                                {/* <FormGroup>
                                <div >
                                    <Checkbox checked={this.state.scheduleControl.onlineVisit} onChange={(e) => {
                                        let scheduleControl = this.state.scheduleControl;
                                        scheduleControl.onlineVisit = e.target.checked;
                                        this.setState({
                                            scheduleControl
                                        })
                                    }} label={'Online visit'.translate(this.props.lang)} />
                                </div>
                            </FormGroup> */}
                                {
                                    this.state.scheduleControl.title ?
                                        <FormGroup>
                                            <Button onClick={() => this.setState({
                                                selectVisitReason: true
                                            })} color="primary">
                                                {'Select a visit reason'.translate(this.props.lang)}
                                            </Button>
                                            {
                                                enterprise ?
                                                    <Button disabled={!this.state.scheduleControl?.visiReason} style={{ marginLeft: 10 }}
                                                        onClick={() => {

                                                            this.setState({
                                                                selectRevisitForm: true,
                                                                revisitFormsSelect: this.state.revisitFormsSelected ? [...this.state.revisitFormsSelected] : [],
                                                                journalNoteForm: this.state.journalNoteFormSelected ? this.state.journalNoteFormSelected : null
                                                            })
                                                        }}
                                                        color="primary"
                                                    >
                                                        {'Insert form'.translate(this.props.lang)}
                                                    </Button>
                                                    :
                                                    null
                                            }

                                        </FormGroup>
                                        :
                                        null
                                }
                                {
                                    this.state.scheduleControl.title && enterprise && this.state.revisitFormsSelected?.length ?
                                        <FormGroup className='attached-forms-wrap'>
                                            <Label>{'Attached forms'.translate(this.props.lang)}</Label>
                                            <ul>
                                                {
                                                    this.state.revisitFormsSelected.map((item, idx) => {
                                                        let revisitForms = this.state.revisitForms?.items ? this.state.revisitForms.items : [];
                                                        let formName = revisitForms.filter(el => el && el._id === item).length ? revisitForms.filter(el => el._id === item)[0].name : null;
                                                        if (formName)
                                                            return (
                                                                <li className="attached-forms-item">
                                                                    {formName}
                                                                </li>
                                                            )
                                                    })
                                                }
                                            </ul>

                                        </FormGroup>
                                        :
                                        null
                                }


                                {
                                    this.state.scheduleControl.title ?
                                        <FormGroup>
                                            <Label>{'Visit reason'.translate(this.props.lang)}</Label>
                                            <Input disabled type='text' value={this.state.scheduleControl.visitReasonName ? this.state.scheduleControl.visitReasonName : ''} />
                                        </FormGroup>
                                        :
                                        null
                                }
                                {
                                    this.state.scheduleControl.title ?
                                        <FormGroup>
                                            <Label>{'Price'.translate(this.props.lang)}</Label>
                                            <Input disabled type='number' value={this.state.scheduleControl.visitReasonPrice} />
                                        </FormGroup>
                                        :
                                        null
                                }

                                {
                                    this.state.scheduleControl.title ?
                                        <FormGroup>
                                            <Button onClick={() => this.setState({
                                                doctorsModal: 3
                                            })} color="primary">
                                                {'Add caregiver'.translate(this.props.lang)}
                                            </Button>
                                        </FormGroup>
                                        :
                                        null
                                }


                                <Button color='primary' disabled={this.state.scheduleControl.title && this.state.scheduleControl.visitReasonName && !this.state.creatingEvent ? false : true} onClick={() => this.setState({
                                    creatingEvent: true
                                }, () => this.scheduleControl(this.state.scheduleControl))}>{'Schedule control'.translate(this.props.lang)}</Button>

                            </ModalBody>
                        </Modal>
                        : null
                }

                {
                    this.state.patientModal ?
                        <Modal isOpen={this.state.patientModal} size={!enterprise && !canSearchPatients ? 'lg' : ''} centered>
                            <ModalHeader toggle={() => this.setState({ patientModal: !this.state.patientModal, patientSocialSecurityNumber: '', patient: null, age: null })} close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => this.setState({ patientModal: !this.state.patientModal, patientSocialSecurityNumber: '', patient: null, age: null })}>&times;</button>}>{'Find patient'.translate(this.props.lang)}</ModalHeader>

                            <ModalBody>
                                {
                                    !enterprise && !canSearchPatients ?
                                        <PatientFilter
                                            {...this.props}
                                            hideUserLevel={true}
                                            hideForUser={this.props.uData._id}
                                            value={this.state.patientModal.title}
                                            onChange={(val) => {
                                                let patientModal = this.state.patientModal;
                                                patientModal.title = val.userData.name.trim();
                                                patientModal.socialSecurityNumber = val.socialSecurityNumber;
                                                patientModal.patient = val._id;

                                                this.setState({ scheduleControl: this.state.patientModal, patientModal: !this.state.patientModal, patientSocialSecurityNumber: '' }, () => {
                                                    this.patientAge(val._id)
                                                })
                                            }}>
                                        </PatientFilter>
                                        :
                                        <div>
                                            <div style={this.state.searchByNameAndEmail ? { marginBottom: 90 } : this.props.country === 'uk' ? { alignItems: 'flex-end' } : {}} className='patient-search-modal-calendar'>
                                                <SocialSecurityNumber
                                                    filterFields={['email']}
                                                    includeChildren={true}
                                                    hideFindChildren={true}
                                                    // hideDateOfBirth={true}
                                                    // inThisGroup={true}
                                                    disableNewPatientBtn={true}
                                                    selectedGroup={this.props.selectedGroup}
                                                    selectedClinic={this.props.selectedClinic}
                                                    lang={this.props.lang}
                                                    searchByNameAndEmail={this.state.searchByNameAndEmail || true}
                                                    searchByNameAndEmailShow={(show) => {
                                                        this.setState({
                                                            searchByNameAndEmail: show
                                                        })
                                                    }}
                                                    // label={this.props.country === 'uk' ? "Date of birth".translate(this.props.lang) : "Social security number".translate(this.props.lang)}
                                                    // label={this.props.country === 'uk' ? "Search patient by email".translate(this.props.lang) : ''}
                                                    error={isSocialSecurityNumberValid(this.state.patientSocialSecurityNumber, this.props.country) ? null : 'Social security number is incorrect'.translate(this.props.lang)}
                                                    type={'text'}
                                                    // labelInfo={'Searches are logged'.translate(this.props.lang)}
                                                    country={this.props.country}
                                                    placeholder={this.props.country === 'denmark' ? 'DDMMYYNNNNN' : this.props.lang === 'en' ? 'YYYYMMDDNNNN' : 'ÅÅÅÅMMDDNNNN'}
                                                    value={this.state.patientSocialSecurityNumber}
                                                    onKeyUp={(e) => {
                                                        if (e.keyCode == 13 && this.state.patientSocialSecurityNumber && isSocialSecurityNumberValid(this.state.patientSocialSecurityNumber, this.props.country)) {
                                                            this.findPatient()
                                                        }
                                                    }}
                                                    onChange={(value, callback) => {
                                                        this.setState({
                                                            patientSocialSecurityNumber: value
                                                        }, () => {
                                                            if (callback) {
                                                                callback()
                                                            }
                                                        })
                                                    }}
                                                    newUserCallback={() => {
                                                        if (!(!this.state.patientSocialSecurityNumber || !isSocialSecurityNumberValid(this.state.patientSocialSecurityNumber, this.props.country))) {
                                                            this.findPatient()
                                                        }
                                                    }}
                                                    selectUserCallback={() => {
                                                        if (!(!this.state.patientSocialSecurityNumber || !isSocialSecurityNumberValid(this.state.patientSocialSecurityNumber, this.props.country))) {
                                                            this.findPatient()
                                                        }
                                                    }}

                                                ></SocialSecurityNumber>
                                                {/* {
                                                    this.props.country === 'uk' ?
                                                        <div className={this.state.searchByNameAndEmail ? 'little-dropdown-icon-down little-dropdown-icon-down-hide' : 'little-dropdown-icon-down arrow-icon-info'} onClick={() => {
                                                            this.setState({
                                                                searchByNameAndEmail: !this.state.searchByNameAndEmail
                                                            })
                                                        }}>
                                                            <div>{'Show additional search options'.translate(this.props.lang)}</div>
                                                        </div>
                                                        :
                                                        null
                                                } */}
                                                <Button
                                                    style={this.props.country === 'uk' ? { marginBottom: 8 } : {}}
                                                    disabled={!this.state.patientSocialSecurityNumber || !isSocialSecurityNumberValid(this.state.patientSocialSecurityNumber, this.props.country)}
                                                    className='patient-search-modal-calendar-button'
                                                    color='primary'
                                                    onClick={() => {
                                                        this.findPatient()
                                                    }}>
                                                    {'Search'.translate(this.props.lang)}
                                                </Button>
                                            </div>
                                            {
                                                this.state.patient ?
                                                    <FormGroup>
                                                        <Label>{'Search results'.translate(this.props.lang)}</Label>
                                                        <div className='patient-search-modal-result' onClick={() => {
                                                            if (this.state.patient?.twoFactorAuthentication?.[this.props.selectedGroup]?.createAccount && !this.state.patient?.twoFactorAuthentication?.[this.props.selectedGroup]?.infoIsVerified) return;

                                                            let patientModal = this.state.patientModal;
                                                            if ((this.props.country === 'denmark' ? this.state.age < CHILD_AGE_LIMIT.denmark : this.props.country === 'sweden' ? this.state.age < CHILD_AGE_LIMIT.sweden : this.state.age < 18)) {
                                                                patientModal.title = this.state.patient.userData.name.trim() + ' ' + 'Parent/legal guardian:'.translate(this.props.lang) + ` ${this.state.patient && this.state.patient.parentObj && this.state.patient.parentObj.name}`;

                                                            } else {
                                                                patientModal.title = this.state.patient.userData.name.trim();

                                                            }
                                                            patientModal.socialSecurityNumber = this.state.patient.socialSecurityNumber;
                                                            patientModal.patient = this.state.patient._id;

                                                            let selectedParents = null;
                                                            if (this.state.patient?.parentObj?._id) {
                                                                selectedParents = [this.state.patient.parentObj._id]
                                                            }

                                                            this.setState({ scheduleControl: { ...this.state.patientModal, selectedParents }, patientModal: !this.state.patientModal, }, () => {
                                                                // this.patientAge(this.state.patient._id)
                                                            })
                                                        }}>
                                                            <div>
                                                                <p>{'Name'.translate(this.props.lang)}</p>
                                                                <p className='patient-search-modal-result-text'>{this.state.patient.name}</p>
                                                            </div>
                                                            <div>
                                                                <p>{'Social security number'.translate(this.props.lang)}</p>
                                                                <p className='patient-search-modal-result-text'>{this.state.patient.socialSecurityNumber}</p>
                                                            </div>
                                                            {this.state.patient?.twoFactorAuthentication?.[this.props.selectedGroup]?.createAccount && !this.state.patient?.twoFactorAuthentication?.[this.props.selectedGroup]?.infoIsVerified ? <div>
                                                                <p>{'Info'.translate(this.props.lang)}</p>
                                                                <p className='patient-search-modal-result-text' style={{ textAlign: 'center' }}>
                                                                    <UserIsNotVerifiedTooltip lang={this.props.lang} />
                                                                </p>
                                                            </div> : (this.state.patient?.twoFactorAuthentication && Object.keys(this.state.patient?.twoFactorAuthentication)?.find(key => this.state.patient?.twoFactorAuthentication?.[key]?.createAccount)) ? <div>
                                                                <p>{'Info'.translate(this.props.lang)}</p>
                                                                <p className='patient-search-modal-result-text' style={{ textAlign: 'center' }}>
                                                                    <UserIsNotVerifiedTooltip lang={this.props.lang} warning />
                                                                </p>
                                                            </div> : null}
                                                        </div>
                                                        {
                                                            isChild ?
                                                                <p style={{ color: 'red' }}>{'Child is under 13 years of age - Appointments are handled by parent or legal guardian.'.translate(this.props.lang)}</p>
                                                                :
                                                                null
                                                        }

                                                    </FormGroup>
                                                    :
                                                    null
                                            }
                                        </div>
                                }





                            </ModalBody>
                        </Modal>
                        : null
                }

                {
                    this.state.takeCareEvent
                        ? <Modal isOpen={this.state.takeCareEvent} size='lg' centered toggle={() => this.setState({ takeCareEvent: null, doctorsList: [] })} backdrop={true}>
                            <ModalHeader close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => this.setState({ takeCareEvent: null, doctorsList: [] })}>&times;</button>}>
                                {'Take Care Event'.translate?.(this.props?.lang)}
                            </ModalHeader>
                            <ModalBody>
                                {this.state.takeCareEvent.takeCareData
                                    ? <>
                                        <h6 style={{ marginTop: 20, fontWeight: '600', fontSize: 20 }}>{'Appointment information'.translate?.(this.props?.lang)}</h6>
                                        <Row>
                                            <Col lg={8}>
                                                <FormGroup>
                                                    <Label>
                                                        {'Event Name'.translate?.(this.props?.lang)}
                                                    </Label>
                                                    <Input type='text' value={this.state.takeCareEvent.takeCareData._title || this.state.takeCareEvent.takeCareData.title || ''} disabled={true} />
                                                </FormGroup>
                                            </Col>
                                            <Col lg={4}>
                                                <FormGroup>
                                                    <Label>
                                                        {'Status'.translate?.(this.props?.lang)}
                                                    </Label>
                                                    <Input type='text' value={this.state.takeCareEvent.takeCareData.status || ''} disabled={true} />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col lg={6}>
                                                <FormGroup>
                                                    <Label>
                                                        {'Description'.translate?.(this.props?.lang)}
                                                    </Label>
                                                    <Input type='textarea' value={this.state.takeCareEvent.takeCareData.description || ''} disabled={true} />
                                                </FormGroup>
                                            </Col>
                                            <Col lg={6}>
                                                <FormGroup>
                                                    <Label>
                                                        {'Comment'.translate?.(this.props?.lang)}
                                                    </Label>
                                                    <Input type='textarea' value={this.state.takeCareEvent.takeCareData.comment || ''} disabled={true} />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <h6 style={{ marginTop: 20, fontWeight: '600', fontSize: 20 }}>{'Patient information'.translate?.(this.props?.lang)}</h6>
                                        {this.state.takeCareEvent.takeCareData?.participants?.map((obj, i) => {
                                            if (!obj || tcDoctorTitles.includes(obj?.role)) return null;
                                            return <Row key={obj?.emr_id ? `${obj.emr_id}-${i}` : i} style={{ marginBottom: (i === this.state?.takeCareEvent?.takeCareData?.participants?.length - 1) ? 10 : null, alignItems: 'center' }}>
                                                <Col lg={canOpenProfile && (obj?.emr_id || obj?.id) ? 5 : 6}>
                                                    <FormGroup>
                                                        <Label>
                                                            {'Name'.translate?.(this.props?.lang)}
                                                        </Label>
                                                        <Input type='text' value={obj?.name || ''} disabled={true} />
                                                    </FormGroup>
                                                </Col>
                                                <Col lg={canOpenProfile && (obj?.emr_id || obj?.id) ? 5 : 6}>
                                                    <FormGroup>
                                                        <Label>
                                                            {'Social security number'.translate?.(this.props?.lang)}
                                                        </Label>
                                                        <Input type='text' value={obj?.emr_id || obj?.id || ''} disabled={true} />
                                                    </FormGroup>
                                                </Col>
                                                {canOpenProfile && (obj?.emr_id || obj?.id) ? <Col lg={2} style={{ position: 'relative', top: 6 }}>
                                                    <Button color='primary' size='lg' onClick={() => this.searchPatientWithRedirect(obj?.emr_id ? obj?.emr_id : obj?.id)}>{'Open'.translate(this.props.lang)}</Button>
                                                </Col> : null}
                                            </Row>
                                        })}
                                    </>
                                    : <Row>
                                        <Col>
                                            {'No Details'.translate?.(this.props?.lang)}
                                        </Col>
                                    </Row>
                                }
                                <h6 style={{ marginTop: 20, fontWeight: '600', fontSize: 20 }}>{'Schedule information'.translate?.(this.props?.lang)}</h6>
                                <Row className='take-care-modal-date-container'>
                                    <Col sm={12} lg={6}>
                                        {this.state.takeCareEvent.date ? <DatePicker
                                            label={'Start date'.translate(this.props.lang)}
                                            value={this.state.takeCareEvent.date}
                                            onChange={e => { return; }}
                                            disabled={true}
                                        /> : null}
                                    </Col>
                                    {this.state.takeCareEvent.date && this.state.takeCareEvent.startTime ? <Col sm={6} lg={3}><FormGroup className="timepicker-container timepicker-wide">
                                        <Label>{'Start time'.translate(this.props.lang)}</Label>
                                        &nbsp;
                                        <TimePicker
                                            format={'HH:mm'}
                                            value={moment(new Date(this.state.takeCareEvent.date.replace(/-/g, "/") + " " + this.state.takeCareEvent.startTime))}
                                            // minValue={moment.unix(this.state.min.getTime() / 1000).format("HH:mm")}
                                            // maxValue={moment.unix(this.state.max.getTime() / 1000).format("HH:mm")}
                                            // maxValue={this.state.updateEvent.endTime}
                                            flag={0}
                                            onChange={() => { return; }}
                                            allowClear={false}
                                            disabled={true}
                                        />
                                    </FormGroup></Col>
                                        : null}
                                    {this.state.takeCareEvent.date && this.state.takeCareEvent.endTime ? <Col sm={6} lg={3}>
                                        <FormGroup className="timepicker-container timepicker-wide">
                                            <Label>{'End time'.translate(this.props.lang)}</Label>
                                            &nbsp;
                                            <TimePicker
                                                format={'HH:mm'}
                                                value={moment(new Date(this.state.takeCareEvent.date.replace(/-/g, "/") + " " + this.state.takeCareEvent.endTime))}
                                                // minValue={Math.trunc((Number(this.state.updateEvent.startTime.split(':')[0]) * 60 + Number(this.state.updateEvent.startTime.split(':')[1]) + 5) / 60) + ":" + (Number(this.state.updateEvent.startTime.split(':')[0]) * 60 + Number(this.state.updateEvent.startTime.split(':')[1]) + 5) % 60}
                                                // maxValue={moment.unix(this.state.max.getTime() / 1000).format("HH:mm")}
                                                flag={0}
                                                // maxValue={this.state.updateEvent.startTime}
                                                // flag={1}
                                                onChange={() => { return; }}
                                                allowClear={false}
                                                disabled={true}
                                            />
                                        </FormGroup>
                                    </Col> : null}
                                </Row>
                                {this.state.takeCareEvent?.takeCareData?.arbitraryTime
                                    ? <Row>
                                        <Col>
                                            <p style={{ fontWeight: 400, fontSize: 14, color: '#E30B5C' }}>* {'End time for this event was not provided by TakeCare, so we added an arbitrary duration of 30 minutes, which might not be accurate! Please check the real duration and end time in TakeCare Calendar!'.translate?.(this.props?.lang)}</p>
                                        </Col>
                                    </Row>
                                    : null}
                            </ModalBody>
                            <ModalFooter style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <Button color='primary' onClick={() => this.setState({ takeCareEvent: null, doctorsList: [] })} style={{ minWidth: 100 }}>
                                    {'Close'.translate?.(this.props?.lang)}
                                </Button>
                            </ModalFooter>
                        </Modal>
                        : null
                }

                {
                    _sembleEvent ? <Modal
                        isOpen={_sembleEvent}
                        size='lg'
                        centered
                        toggle={() => this.setState({ sembleEvent: null, doctorsList: [] })}
                        backdrop={true}
                    >
                        <ModalHeader close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => this.setState({ sembleEvent: null, doctorsList: [] })}>&times;</button>}>
                            {'Semble Event'.translate?.(this.props?.lang)}
                        </ModalHeader>
                        <ModalBody>
                            {/* Appointment info */}
                            <h6 key='appo' style={{ marginTop: 20, fontWeight: '600', fontSize: 20 }}>{'Appointment information'.translate?.(this.props?.lang)}</h6>
                            <Row key='appo1'>
                                <Col lg={7}>
                                    <FormGroup>
                                        <Label>
                                            {'Event Name'.translate?.(this.props?.lang)}
                                        </Label>
                                        <Input type='text' value={_sembleEvent.sembleData?.appointment?.title || ''} disabled={true} />
                                    </FormGroup>
                                </Col>
                                <Col lg={3}>
                                    <FormGroup>
                                        <Label>
                                            {'Status'.translate?.(this.props?.lang)}
                                        </Label>
                                        <Input type='text' value={_sembleEvent.sembleData?.deleted ? 'deleted' : 'booked'} disabled={true} />
                                    </FormGroup>
                                </Col>
                                <Col lg={2}>
                                    <FormGroup>
                                        <Label>
                                            {'Price'.translate?.(this.props?.lang)}
                                        </Label>
                                        <Input type='text' value={_sembleEvent.sembleData?.appointment?.price} disabled={true} />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row key='appo2'>
                                <Col>
                                    <FormGroup>
                                        <Label>{'Comment'.translate?.(this.props?.lang)}</Label>
                                        <Input type='textarea' value={_sembleEvent.sembleData?.comments || ''} disabled={true} />
                                    </FormGroup>
                                </Col>
                            </Row>

                            {/* Patient info */}
                            <div style={{
                                display: 'flex',
                                alignItems: 'flex-end',
                                justifyContent: 'space-between',
                                gap: 10,
                                marginBottom: 15
                            }}>
                                <h6 key='pati' style={{ marginTop: 20, fontWeight: '600', fontSize: 20 }}>{'Patient information'.translate?.(this.props?.lang)}</h6>
                                {canOpenProfile && _semblePatient?.ssn ? <Button color='primary' onClick={() => this.searchPatientWithRedirect(_semblePatient?.ssn)}>{'Open Patient Profile'.translate(this.props.lang)}</Button> : null}
                            </div>

                            {_semblePatient ? <Fragment key='pati_f_1'>
                                <Row key='pati1'>
                                    <Col lg={6}>
                                        <FormGroup>
                                            <Label>{'Name'.translate?.(this.props.lang)}</Label>
                                            <Input type='text' value={_semblePatient.name || ''} disabled={true} />
                                        </FormGroup>
                                    </Col>
                                    <Col lg={3}>
                                        <FormGroup>
                                            <Label>{'Date of birth'.translate(this.props.lang)}</Label>
                                            <Input type='text' value={this.getGBDate(_semblePatient.dob)} disabled={true} />
                                        </FormGroup>
                                    </Col>
                                    <Col lg={3}>
                                        <FormGroup>
                                            <Label>{'Sex'.translate(this.props.lang)}</Label>
                                            <Input type='text' value={_semblePatient.gender} disabled={true} />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row key='pati2'>
                                    <Col lg={6}>
                                        <FormGroup>
                                            <Label>{'Email'.translate?.(this.props.lang)}</Label>
                                            <Input type='text' value={_semblePatient.email || ''} disabled={true} />
                                        </FormGroup>
                                    </Col>
                                    <Col lg={6}>
                                        <FormGroup>
                                            <Label>{'Phone'.translate(this.props.lang)}</Label>
                                            <PhoneField
                                                countryTwoLetter={this.props.countryTwoLetter}
                                                country={this.props.country}
                                                value={_semblePatient.phone || ''}
                                                disabled={true}
                                                onChange={() => { return; }}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                {_semblePatient.address?.city || _semblePatient.address?.street || _semblePatient.address?.zip ? <Row key='pati3'>
                                    <Col lg={4}>
                                        <FormGroup>
                                            <Label>{'City'.translate?.(this.props.lang)}</Label>
                                            <Input type='text' value={_semblePatient.address.city || ''} disabled={true} />
                                        </FormGroup>
                                    </Col>
                                    <Col lg={4}>
                                        <FormGroup>
                                            <Label>{'Postal code'.translate(this.props.lang)}</Label>
                                            <Input type='text' value={_semblePatient.address.zip || ''} disabled={true} />
                                        </FormGroup>
                                    </Col>
                                    <Col lg={4}>
                                        <FormGroup>
                                            <Label>{'Street'.translate(this.props.lang)}</Label>
                                            <Input type='text' value={_semblePatient.address.street || ''} disabled={true} />
                                        </FormGroup>
                                    </Col>
                                </Row> : null}
                            </Fragment> : <Fragment key='pati_f_2'>
                                <Row>
                                    <Col>
                                        {'No Details'.translate?.(this.props?.lang)}
                                    </Col>
                                </Row>
                            </Fragment>}

                            {/* Schedule info */}
                            <h6 style={{ marginTop: 20, fontWeight: '600', fontSize: 20 }}>{'Schedule information'.translate?.(this.props?.lang)}</h6>
                            <Row className='take-care-modal-date-container' key='sche'>
                                <Col sm={12} lg={6}>
                                    {_sembleEvent.date ? <DatePicker
                                        label={'Start date'.translate(this.props.lang)}
                                        value={_sembleEvent.date}
                                        onChange={e => { return; }}
                                        disabled={true}
                                    /> : null}
                                </Col>
                                {_sembleEvent.date && _sembleEvent.startTime ? <Col sm={6} lg={3}><FormGroup className="timepicker-container timepicker-wide">
                                    <Label>{'Start time'.translate(this.props.lang)}</Label>
                                    &nbsp;
                                    <TimePicker
                                        format={'HH:mm'}
                                        value={moment(new Date(_sembleEvent.date.replace(/-/g, "/") + " " + _sembleEvent.startTime))}
                                        flag={0}
                                        onChange={() => { return; }}
                                        allowClear={false}
                                        disabled={true}
                                    />
                                </FormGroup></Col>
                                    : null}
                                {_sembleEvent.date && _sembleEvent.endTime ? <Col sm={6} lg={3}>
                                    <FormGroup className="timepicker-container timepicker-wide">
                                        <Label>{'End time'.translate(this.props.lang)}</Label>
                                        &nbsp;
                                        <TimePicker
                                            format={'HH:mm'}
                                            value={moment(new Date(_sembleEvent.date.replace(/-/g, "/") + " " + _sembleEvent.endTime))}
                                            flag={0}
                                            onChange={() => { return; }}
                                            allowClear={false}
                                            disabled={true}
                                        />
                                    </FormGroup>
                                </Col> : null}
                            </Row>
                        </ModalBody>
                        <ModalFooter style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <button style={{ display: 'none', visibility: 'none' }} hidden aria-hidden='true' />
                            <Button color='primary' disabled={!_sembleEvent?.canEdit || _sembleEvent.sembleData?.deleted} onClick={() => {
                                if (!_sembleEvent?.canEdit || _sembleEvent.sembleData?.deleted) return;
                                this.prepareSembleUpdate();
                            }} style={{ minWidth: 100 }}>
                                {'Update Event'.translate?.(this.props?.lang)}
                            </Button>
                            <Button color='danger' disabled={!_sembleEvent?.canEdit || _sembleEvent.sembleData?.deleted} onClick={() => {
                                if (!_sembleEvent?.canEdit || _sembleEvent.sembleData?.deleted) return;
                                this.setState({ sembleEventCancel: _sembleEvent })
                            }} style={{ minWidth: 100 }}>
                                {'Cancel Event'.translate?.(this.props?.lang)}
                            </Button>
                            <Button color='primary' onClick={() => this.setState({ sembleEvent: null, doctorsList: [] })} style={{ minWidth: 100 }}>
                                {'Close'.translate?.(this.props?.lang)}
                            </Button>
                            <button style={{ display: 'none', visibility: 'none' }} hidden aria-hidden='true' />
                        </ModalFooter>
                    </Modal> : null
                }

                {
                    this.state.sembleEventCancel ? <Modal
                        isOpen={this.state.sembleEventCancel}
                        size='md'
                        centered
                        toggle={() => this.setState({ sembleEventCancel: null })}
                        backdrop={true}
                    >
                        <ModalHeader close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => this.setState({ sembleEventCancel: null, doctorsList: [] })}>&times;</button>}>
                            {'Cancel Event'.translate?.(this.props?.lang)}
                        </ModalHeader>
                        <ModalBody>
                            <p style={{ width: '75%', margin: 'auto', textAlign: 'center' }}>{"Are you sure you want to cancel this event? This action cannot be reversed!".translate?.(this.props.lang)}</p>
                        </ModalBody>
                        <ModalFooter style={{ display: 'flex', justifyContent: 'center' }}>
                            <button style={{ display: 'none', visibility: 'none' }} hidden aria-hidden='true' />
                            <Button
                                color='danger'
                                onClick={this.cancelSembleEvent}
                                style={{ minWidth: 100 }}
                            >
                                {'Cancel Event'.translate?.(this.props?.lang)}
                            </Button>
                            <Button
                                color='primary'
                                onClick={() => this.setState({ sembleEventCancel: null })}
                                style={{ minWidth: 100 }}
                            >
                                {'Abort'.translate?.(this.props?.lang)}
                            </Button>
                            <button style={{ display: 'none', visibility: 'none' }} hidden aria-hidden='true' />
                        </ModalFooter>
                    </Modal> : null
                }

                {
                    this.state.sembleEventUpdate ? <Modal
                        isOpen={this.state.sembleEventUpdate}
                        size='md'
                        centered
                        toggle={() => this.setState({ sembleEventUpdate: null })}
                        backdrop={true}
                    >
                        <ModalHeader close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => this.setState({ sembleEventUpdate: null, doctorsList: [] })}>&times;</button>}>
                            {'Update Event'.translate?.(this.props?.lang)}
                        </ModalHeader>
                        <ModalBody>
                            <FormGroup key='doc'>
                                <Label>{'Doctor'.translate(this.props.lang)}</Label>
                                <Input
                                    type='select'
                                    value={this.state.sembleEventUpdate?.values?.doctor || ''}
                                    onChange={e => {
                                        const value = e.target.value;

                                        this.setState(prev => ({
                                            ...prev,
                                            sembleEventUpdate: {
                                                ...prev.sembleEventUpdate,
                                                values: {
                                                    ...prev.sembleEventUpdate.values,
                                                    doctor: value
                                                },
                                            }
                                        }))
                                    }}
                                >
                                    {this.state.sembleEventUpdate?.availableDoctors?.map(doc => <option
                                        key={doc._id}
                                        value={doc._id}
                                    >
                                        {doc.name}
                                    </option>)}
                                </Input>
                            </FormGroup>
                            <FormGroup key='loc'>
                                <Label>{'Location'.translate(this.props.lang)}</Label>
                                <Input
                                    type='select'
                                    value={this.state.sembleEventUpdate?.values?.location || ''}
                                    onChange={e => {
                                        const value = e.target.value;

                                        this.setState(prev => ({
                                            ...prev,
                                            sembleEventUpdate: {
                                                ...prev.sembleEventUpdate,
                                                values: {
                                                    ...prev.sembleEventUpdate.values,
                                                    location: value
                                                },
                                            }
                                        }))
                                    }}
                                >
                                    {this.state.sembleEventUpdate?.availableLocations?.map(loc => <option
                                        key={loc._id}
                                        value={loc._id}
                                    >
                                        {loc.name}
                                    </option>)}
                                </Input>
                            </FormGroup>
                            <Row key='dte'>
                                <Col key='dte-dte'>
                                    <DatePicker
                                        label={'Date'.translate(this.props.lang)}
                                        value={this.state.sembleEventUpdate?.values?.date || ''}
                                        onChange={date => {
                                            this.setState(prev => ({
                                                ...prev,
                                                sembleEventUpdate: {
                                                    ...prev.sembleEventUpdate,
                                                    values: {
                                                        ...prev.sembleEventUpdate.values,
                                                        date
                                                    },
                                                }
                                            }))
                                        }}
                                    />
                                </Col>
                            </Row>
                            <Row key='dte'>
                                <Col md={6} key='dte-st'>
                                    <FormGroup className="timepicker-container timepicker-wide">
                                        <Label>{'Start time'.translate(this.props.lang)}</Label>
                                        &nbsp;
                                        <TimePickerV2
                                            getStringDateTs={this.props.getStringDateTs}
                                            format={'HH:mm'}
                                            value={this.state.sembleEventUpdate?.values?.startTime || ''}
                                            flag={0}
                                            minValue='00:00'
                                            maxValue='23:59'
                                            onChange={(_, startTime) => {
                                                const newStartTime = new Date(`${this.state.sembleEventUpdate?.values?.date} ${startTime}`);
                                                const oldEndTime = new Date(`${this.state.sembleEventUpdate?.values?.date} ${this.state.sembleEventUpdate?.values?.endTime}`);
                                                const fiveMinutesAfterNewStartTime = new Date(newStartTime.getTime() + 5 * 60000);
                                                const _fiveMinutesAfter = `${String(fiveMinutesAfterNewStartTime.getHours()).padStart(2, '0')}:${String(fiveMinutesAfterNewStartTime.getMinutes()).padStart(2, '0')}`;
                                                this.setState(prev => ({
                                                    ...prev,
                                                    sembleEventUpdate: {
                                                        ...prev.sembleEventUpdate,
                                                        values: {
                                                            ...prev.sembleEventUpdate.values,
                                                            startTime,
                                                            endTime: (newStartTime > oldEndTime) ? _fiveMinutesAfter : prev.sembleEventUpdate?.values?.endTime
                                                        },
                                                    }
                                                }));
                                            }}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md={6} key='dte-et'>
                                    <FormGroup className="timepicker-container timepicker-wide">
                                        <Label>{'End time'.translate(this.props.lang)}</Label>
                                        &nbsp;
                                        <TimePickerV2
                                            getStringDateTs={this.props.getStringDateTs}
                                            format={'HH:mm'}
                                            value={this.state.sembleEventUpdate?.values?.endTime || ''}
                                            flag={0}
                                            minValue={this.state.sembleEventUpdate?.values?.startTime || '00:00'}
                                            maxValue='23:59'
                                            onChange={(_, endTime) => {
                                                this.setState(prev => ({
                                                    ...prev,
                                                    sembleEventUpdate: {
                                                        ...prev.sembleEventUpdate,
                                                        values: {
                                                            ...prev.sembleEventUpdate.values,
                                                            endTime
                                                        },
                                                    }
                                                }));
                                            }}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                        </ModalBody>
                        <ModalFooter style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <button style={{ display: 'none', visibility: 'none' }} hidden aria-hidden='true' />
                            <Button
                                color='primary'
                                onClick={() => {
                                    if (!hasSembleUpdate) return;
                                    this.updateSembleEvent();
                                }}
                                style={{ minWidth: 100 }}
                                disabled={!this.state.sembleEventUpdate?.values?.doctor || !this.state.sembleEventUpdate?.values?.location || !this.state.sembleEventUpdate?.values?.date || !this.state.sembleEventUpdate?.values?.startTime || !this.state.sembleEventUpdate?.values?.endTime || !hasSembleUpdate}
                            >
                                {'Update Event'.translate?.(this.props?.lang)}
                            </Button>
                            <Button
                                color='primary'
                                onClick={() => this.setState({ sembleEventUpdate: null })}
                                style={{ minWidth: 100 }}
                            >
                                {'Abort'.translate?.(this.props?.lang)}
                            </Button>
                            <button style={{ display: 'none', visibility: 'none' }} hidden aria-hidden='true' />
                        </ModalFooter>
                    </Modal> : null
                }

                {
                    this.state.updateEvent ?

                        <Modal isOpen={this.state.updateEvent} size={this.state.updateEvent.patientProfile ? 'lg' : ''} centered>
                            <ModalHeader toggle={() => /*this.setState({ updateEvent: !this.state.updateEvent, doctorsList: [] }, () => */this.checkForChanges()/*)*/} close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => /*this.setState({ updateEvent: !this.state.updateEvent, doctorsList: [] }, () => */this.checkForChanges()/*)*/}>&times;</button>}>{'Update event'.translate(this.props.lang)}</ModalHeader>

                            <ModalBody>
                                <Row>
                                    <Col lg={this.state.updateEvent.patientProfile ? '6' : '12'}>
                                        {this.state.updateEvent.type == 'referral' || this.state.updateEvent.type == 'revisit' ?
                                            <FormGroup>
                                                <Label>{'Title'.translate(this.props.lang)}</Label>

                                                <Input disabled type='textarea'
                                                    // onChange={(e) => {
                                                    //     let updateEvent = this.state.updateEvent;
                                                    //     updateEvent.title = striptags(e.target.value);
                                                    //     this.setState({
                                                    //         updateEvent
                                                    //     })
                                                    // }}
                                                    value={this.state.updateEvent.titleDoctor && this.state.updateEvent.titleDoctor != '' ? this.state.updateEvent.baseTitle + ', ' + this.state.updateEvent.titleDoctor : this.state.updateEvent.title} />
                                                <Input type='text' style={{ marginTop: 5 }} onChange={(e) => {
                                                    let updateEvent = this.state.updateEvent;
                                                    updateEvent.titleDoctor = striptags(e.target.value);

                                                    this.setState({
                                                        updateEvent
                                                    })
                                                }}
                                                    value={this.state.updateEvent.titleDoctor} />

                                            </FormGroup>
                                            :
                                            <FormGroup>
                                                <Label>{'Title'.translate(this.props.lang)}</Label>

                                                <Input type='text'
                                                    onChange={(e) => {
                                                        let updateEvent = this.state.updateEvent;
                                                        updateEvent.title = striptags(e.target.value);
                                                        this.setState({
                                                            updateEvent
                                                        })
                                                    }}
                                                    value={this.state.updateEvent.title} />


                                            </FormGroup>
                                        }

                                        <DatePicker
                                            label={'Start date'.translate(this.props.lang)}
                                            value={this.state.updateEvent.date}
                                            onChange={(e) => {
                                                let updateEvent = this.state.updateEvent;
                                                updateEvent.date = e;
                                                this.setState({
                                                    updateEvent
                                                })
                                                if (Math.floor(new Date(this.state.updateEvent.date + ' ' + this.state.updateEvent.startTime) / 1000) > Math.floor(new Date(this.state.updateEvent.endDate + ' ' + this.state.updateEvent.endTime) / 1000)) {
                                                    updateEvent.endDate = this.state.updateEvent.date
                                                    this.setState({
                                                        updateEvent
                                                    })
                                                }
                                            }}
                                        />
                                        <FormGroup className="timepicker-container timepicker-wide">
                                            <Label>{'Start time'.translate(this.props.lang)}</Label>
                                            &nbsp;
                                            <TimePickerV2
                                                getStringDateTs={this.props.getStringDateTs}
                                                format={'HH:mm'}
                                                value={this.state.updateEvent.startTime}
                                                minValue={this.props.getStringDateTs(Math.floor(this.state.min.getTime() / 1000), 'HH:mm')} // moment.unix(this.state.min.getTime() / 1000).format("HH:mm")
                                                maxValue={this.props.getStringDateTs(Math.floor(this.state.max.getTime() / 1000 - 300), 'HH:mm')} // moment.unix(this.state.max.getTime() / 1000).format("HH:mm")
                                                flag={0}
                                                onChange={(time1, timeString) => {

                                                    let updateEvent = this.state.updateEvent;
                                                    updateEvent.startTime = timeString

                                                    let startTimeSplit = updateEvent.startTime.split(":");
                                                    let endTimeSplit = updateEvent.endTime.split(":");

                                                    let startTimeHourMinute = Number(startTimeSplit[0]) * 60 + Number(startTimeSplit[1]);
                                                    let endTimeHourMinute = Number(endTimeSplit[0]) * 60 + Number(endTimeSplit[1]);

                                                    if (startTimeHourMinute < endTimeHourMinute) {
                                                        this.setState({ updateEvent })
                                                    } else {
                                                        updateEvent.endTime = Math.trunc((Number(this.state.updateEvent.startTime.split(':')[0]) * 60 + Number(this.state.updateEvent.startTime.split(':')[1]) + 5) / 60) + ":" + (Number(this.state.updateEvent.startTime.split(':')[0]) * 60 + Number(this.state.updateEvent.startTime.split(':')[1]) + 5) % 60
                                                        // updateEvent.endTime = validateTime(updateEvent.endTime);
                                                        const padded = updateEvent?.endTime?.split?.(':');
                                                        if (padded && Array.isArray(padded) && padded.length && isStringAValidNumber(padded[0]) && isStringAValidNumber(padded[1])) {
                                                            const h = padded[0]?.padStart?.(2, '0');
                                                            const m = padded[1]?.padStart?.(2, '0');
                                                            if (h && m) updateEvent.endTime = `${h}:${m}`;
                                                        }
                                                        this.setState({
                                                            updateEvent
                                                        })
                                                    }

                                                    // if (startTimeHourMinute < endTimeHourMinute) {
                                                    //     this.setState({ updateEvent })
                                                    // } else {
                                                    // this.setState({ wrongInput: true })
                                                    // }
                                                }}
                                            />
                                            {/* <TimePicker
                                                getStringDateTs={this.props.getStringDateTs}
                                                format={'HH:mm'}
                                                value={moment(new Date(this.state.updateEvent.date.replace(/-/g, "/") + " " + this.state.updateEvent.startTime))}
                                                minValue={this.props.getStringDateTs(Math.floor(this.state.min.getTime() / 1000), 'HH:mm')} // moment.unix(this.state.min.getTime() / 1000).format("HH:mm")
                                                maxValue={this.props.getStringDateTs(Math.floor(this.state.max.getTime() / 1000), 'HH:mm')} // moment.unix(this.state.max.getTime() / 1000).format("HH:mm")
                                                // maxValue={this.state.updateEvent.endTime}
                                                flag={0}
                                                onChange={(time1, timeString) => {

                                                    let updateEvent = this.state.updateEvent;
                                                    updateEvent.startTime = timeString

                                                    let startTimeSplit = updateEvent.startTime.split(":");
                                                    let endTimeSplit = updateEvent.endTime.split(":");

                                                    let startTimeHourMinute = Number(startTimeSplit[0]) * 60 + Number(startTimeSplit[1]);
                                                    let endTimeHourMinute = Number(endTimeSplit[0]) * 60 + Number(endTimeSplit[1]);
                                                    this.setState({ updateEvent })

                                                    // if (startTimeHourMinute < endTimeHourMinute) {
                                                    //     this.setState({ updateEvent })
                                                    // } else {
                                                    // this.setState({ wrongInput: true })
                                                    // }
                                                }}
                                                allowClear={false}
                                            /> */}
                                        </FormGroup>
                                        <FormGroup className="timepicker-container timepicker-wide">
                                            <Label>{'End time'.translate(this.props.lang)}</Label>
                                            &nbsp;
                                            <TimePickerV2
                                                getStringDateTs={this.props.getStringDateTs}
                                                format={'HH:mm'}
                                                value={this.state.updateEvent.endTime}
                                                minValue={Math.trunc((Number(this.state.updateEvent.startTime.split(':')[0]) * 60 + Number(this.state.updateEvent.startTime.split(':')[1]) + 5) / 60) + ":" + (Number(this.state.updateEvent.startTime.split(':')[0]) * 60 + Number(this.state.updateEvent.startTime.split(':')[1]) + 5) % 60}
                                                maxValue={this.props.getStringDateTs(Math.floor(this.state.max.getTime() / 1000), 'HH:mm')} // moment.unix(this.state.max.getTime() / 1000).format("HH:mm")
                                                flag={0}
                                                onChange={(time1, timeString) => {

                                                    let updateEvent = this.state.updateEvent;
                                                    updateEvent.endTime = timeString

                                                    let startTimeSplit = updateEvent.startTime.split(":");
                                                    let endTimeSplit = updateEvent.endTime.split(":");

                                                    let startTimeHourMinute = Number(startTimeSplit[0]) * 60 + Number(startTimeSplit[1]);
                                                    let endTimeHourMinute = Number(endTimeSplit[0]) * 60 + Number(endTimeSplit[1]);

                                                    if (startTimeHourMinute < endTimeHourMinute) {
                                                        this.setState({ updateEvent })
                                                    } else {
                                                        // this.setState({ wrongInput: true })
                                                    }
                                                }}
                                            />
                                            {/* <TimePicker
                                                getStringDateTs={this.props.getStringDateTs}
                                                format={'HH:mm'}
                                                value={moment(new Date(this.state.updateEvent.date.replace(/-/g, "/") + " " + this.state.updateEvent.endTime))}
                                                minValue={Math.trunc((Number(this.state.updateEvent.startTime.split(':')[0]) * 60 + Number(this.state.updateEvent.startTime.split(':')[1]) + 5) / 60) + ":" + (Number(this.state.updateEvent.startTime.split(':')[0]) * 60 + Number(this.state.updateEvent.startTime.split(':')[1]) + 5) % 60}
                                                maxValue={this.props.getStringDateTs(Math.floor(this.state.max.getTime() / 1000), 'HH:mm')} // moment.unix(this.state.max.getTime() / 1000).format("HH:mm")
                                                flag={0}
                                                // maxValue={this.state.updateEvent.startTime}
                                                // flag={1}
                                                onChange={(time1, timeString) => {

                                                    let updateEvent = this.state.updateEvent;
                                                    updateEvent.endTime = timeString

                                                    let startTimeSplit = updateEvent.startTime.split(":");
                                                    let endTimeSplit = updateEvent.endTime.split(":");

                                                    let startTimeHourMinute = Number(startTimeSplit[0]) * 60 + Number(startTimeSplit[1]);
                                                    let endTimeHourMinute = Number(endTimeSplit[0]) * 60 + Number(endTimeSplit[1]);

                                                    if (startTimeHourMinute < endTimeHourMinute) {
                                                        this.setState({ updateEvent })
                                                    } else {
                                                        // this.setState({ wrongInput: true })
                                                    }
                                                }}
                                                allowClear={false}
                                            /> */}
                                        </FormGroup>
                                        {
                                            this.state.updateEvent.type == "referral" ?
                                                <FormGroup>
                                                    <Label>{'Note'.translate(this.props.lang)}</Label>
                                                    <Input type='textarea'
                                                        name="note"
                                                        style={{ height: '150px' }}
                                                        onChange={(e) => {
                                                            let updateEvent = this.state.updateEvent;
                                                            updateEvent.patientNote = striptags(e.target.value);
                                                            this.setState({
                                                                updateEvent
                                                            })
                                                        }}
                                                        value={this.state.updateEvent.patientNote}
                                                    />
                                                </FormGroup>
                                                :
                                                null
                                        }
                                        {
                                            this.state.updateEvent.type == 'revisit' ?
                                                <FormGroup>
                                                    <Label>{'Note'.translate(this.props.lang)}</Label>
                                                    <Input type='textarea'
                                                        name="note"
                                                        style={{ height: '150px' }}
                                                        onChange={(e) => {
                                                            let updateEvent = this.state.updateEvent;
                                                            updateEvent.patientControlNote = striptags(e.target.value);
                                                            this.setState({
                                                                updateEvent
                                                            })
                                                        }}
                                                        value={this.state.updateEvent.patientControlNote}
                                                    />
                                                </FormGroup>
                                                :
                                                null
                                        }
                                        {
                                            this.state.updateEvent.type == 'revisit' && enterprise ?
                                                <FormGroup style={{ display: 'flex', alignItems: 'center' }}>
                                                    <Checkbox
                                                        onChange={(e) => {
                                                            let updateEvent = this.state.updateEvent;
                                                            updateEvent.closeCaseManually = e.target.checked;
                                                            this.setState({
                                                                updateEvent
                                                            })
                                                        }}
                                                        checked={this.state.updateEvent.closeCaseManually}
                                                        label={'Close case manually'.translate(this.props.lang)}
                                                    />

                                                </FormGroup>
                                                :
                                                null
                                        }


                                        {
                                            this.state.updateEvent.patientProfile && !this.state.updateEvent.additionalDoctor ?
                                                <Button onClick={() => this.setState({
                                                    doctorsModal: 1
                                                })} color="primary">
                                                    {'Add caregiver'.translate(this.props.lang)}
                                                </Button>
                                                :
                                                null
                                        }


                                    </Col>

                                    {
                                        this.state.updateEvent.patientProfile ?
                                            <>
                                                <Col lg="6">
                                                    <FormGroup>
                                                        <Label>{'Case ID'.translate(this.props.lang)}</Label>
                                                        <Input type='text' disabled
                                                            value={this.state.updateEvent.patientProfile.referralNumber} />
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Label>{'Social security number'.translate(this.props.lang)} {this.state.updateEvent?._2fa ? <UserIsNotVerifiedTooltip warning lang={this.props.lang} /> : null}</Label>
                                                        <Input type='text' disabled
                                                            value={this.state.updateEvent.patientProfile.socialSecurityNumber} />
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Label>{'Name'.translate(this.props.lang)}</Label>
                                                        <Input type='text' disabled
                                                            value={this.state.updateEvent.patientProfile.name} />
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Label>{'Email'.translate(this.props.lang)}</Label>
                                                        <Input type='text' disabled
                                                            value={this.state.updateEvent.patientProfile.email} />
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Label>{'Phone'.translate(this.props.lang)}</Label>
                                                        <Input type='text' disabled
                                                            value={this.state.updateEvent.patientProfile.phone} />
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Label>{'Address'.translate(this.props.lang)}</Label>
                                                        <Input type='text' disabled
                                                            value={this.state.updateEvent.patientProfile.address} />
                                                    </FormGroup>
                                                </Col>
                                            </>
                                            :
                                            null
                                    }
                                    <Col lg="12" className='align-buttons-footer'>

                                        <div className='update-event-btns-wrap'>
                                            {
                                                this.state.updateEvent.patientProfile && (!this.state.updateEvent.additionalDoctors || (this.state.updateEvent.additionalDoctors && !this.state.updateEvent.additionalDoctors.filter(item => item == this.props.uData._id).length)) ?
                                                    <Button color='primary' style={{ marginRight: 10 }} onClick={() => {
                                                        this.openConveration(this.state.updateEvent)

                                                    }}>{'Open conversation'.translate(this.props.lang)}</Button>
                                                    :
                                                    null
                                            }
                                            {
                                                this.state.updateEvent.patientProfile && (!this.state.updateEvent.additionalDoctors || (this.state.updateEvent.additionalDoctors && !this.state.updateEvent.additionalDoctors.filter(item => item == this.props.uData._id).length)) ?
                                                    <div className='start-video-call-btn-wrap'>
                                                        <button className={this.state.waitingRoom?.patientId === this.state.updateEvent?.patientId && this.state.waitingRoom?.showInfoWaitingRoom ? 'start-video-btn patient-waiting-room' : 'start-video-btn'} style={{ margin: 10 }} onClick={() => {
                                                            this.startVideoCall(this.state.updateEvent)

                                                        }}>{'Start video chat'.translate(this.props.lang)}</button>
                                                        {/* {
                                                            this.state.waitingRoom?.patientId === this.state.updateEvent?.patientId && this.state.waitingRoom?.showInfoWaitingRoom ?
                                                                <div>
                                                                    <Isvg src={waitingRoomIcon} />
                                                                </div>
                                                                :
                                                                null
                                                        } */}
                                                    </div>
                                                    :
                                                    null
                                            }
                                            {
                                                this.state.updateEvent.additionalDoctors && this.state.updateEvent.additionalDoctors.filter(item => item == this.props.uData._id).length ?
                                                    <Button color='primary' style={{}} onClick={() => {
                                                        this.setState({ leaveCaseModal: this.state.updateEvent })

                                                    }}>{'Leave case'.translate(this.props.lang)}</Button>
                                                    :
                                                    null
                                            }
                                            {
                                                !this.state.updateEvent.additionalDoctor ?
                                                    <Button color='primary' style={{ margin: 10 }} onClick={() => this.setState({ warningModal: this.state.updateEvent, updateEvent: !this.state.updateEvent, prevUpdateEvent: !this.state.prevUpdateEvent, doctorsList: [] })}>{'Cancel time'.translate(this.props.lang)}</Button>

                                                    :
                                                    null
                                            }
                                            {
                                                this.state.previewReferral ?
                                                    <Button color='primary' style={{ margin: 10 }} onClick={() => this.setState({ openPreviewReferral: true })}>{'Case preview'.translate(this.props.lang)}</Button>

                                                    :
                                                    null
                                            }
                                        </div>
                                        <div>
                                            {
                                                !this.state.updateEvent.additionalDoctor ?
                                                    <Button color='danger' style={{ marginRight: 10 }} onClick={() => /*this.setState({ updateEvent: !this.state.updateEvent, doctorsList: [] }, () => */this.checkForChanges()/*)*/}>{'Cancel'.translate(this.props.lang)}</Button>

                                                    :
                                                    null
                                            }
                                            {
                                                !this.state.updateEvent.additionalDoctor ?
                                                    <Button color='success' style={{ marginRight: 10 }} onClick={() => this.updateEvent(this.state.updateEvent)}>
                                                        {/* {'Update'.translate(this.props.lang)} */}
                                                        {this.state.loading ? /*'Processing...'.translate(this.props.lang)*/

                                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                <Player
                                                                    onEvent={() => {
                                                                        if (!this.state.loading) this.stopAnimation();
                                                                    }}
                                                                    onStateChange={this.toggleVisibility}
                                                                    ref={this.player} // set the ref to your class instance
                                                                    autoplay={true}
                                                                    loop={true}
                                                                    controls={true}
                                                                    src="https://assets8.lottiefiles.com/packages/lf20_ZSRKCF.json"
                                                                    style={{ height: "20px", width: "30px" }}
                                                                ></Player>
                                                                {'Processing...'.translate(this.props.lang)}
                                                            </div>

                                                            : 'Save changes'.translate(this.props.lang)}
                                                    </Button>
                                                    :
                                                    null
                                            }
                                        </div>


                                    </Col>

                                </Row>
                            </ModalBody>
                        </Modal>
                        : null
                }

                {
                    this.state.selectRevisitForm ?
                        <Modal isOpen={this.state.selectRevisitForm} size="lg" centered >
                            <ModalHeader toggle={() => this.setState({ selectRevisitForm: !this.state.selectRevisitForm })} close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => this.setState({ selectRevisitForm: !this.state.selectRevisitForm })}>&times;</button>}>{'Revisit forms'.translate(this.props.lang)}</ModalHeader>

                            <ModalBody>
                                <div className="search-box-wrap" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <FormGroup>
                                        <Label>{'Search'.translate(this.props.lang)}</Label>
                                        <Input value={this.state.searchRevisitForm}
                                            onChange={(e) => {
                                                this.setState({ searchRevisitForm: striptags(e.target.value) }, () => this.getRevisitQuestionary())
                                            }}
                                        />
                                    </FormGroup>
                                    <Button color="primary" onClick={() => {
                                        if (this.state.revisitForms && this.state.revisitForms.items && this.state.revisitForms.items.length) {
                                            let revisitFormsSelect = [];
                                            let journalNoteForm = this.state.journalNoteForm ? this.state.journalNoteForm : null;
                                            for (let i = 0; i < this.state.revisitForms.items.length; i++) {
                                                revisitFormsSelect.push(this.state.revisitForms.items[i]._id)
                                                if (!journalNoteForm) {
                                                    journalNoteForm = this.state.revisitForms.items[i]._id;
                                                }
                                            }
                                            this.setState({ revisitFormsSelect, journalNoteForm })
                                        }
                                    }}>{'Mark all'.translate(this.props.lang)}</Button>
                                </div>
                                <div className="general-health-care-wrap">
                                    {
                                        this.state.revisitForms && this.state.revisitForms.items && this.state.revisitForms.items.map((item, idx) => {
                                            return (
                                                <div className="health-care-wrap">
                                                    <div className="content">
                                                        <div className="title">
                                                            {item.name}
                                                            <span>
                                                                &nbsp;&nbsp;/&nbsp;&nbsp;
                                                                {item.description}</span>
                                                        </div>

                                                    </div>
                                                    <div className='checkbox-wrap'>
                                                        <Checkbox
                                                            onChange={(e) => {
                                                                let val = e.target.checked;
                                                                let revisitFormsSelect = this.state.revisitFormsSelect;
                                                                let journalNoteForm = this.state.journalNoteForm ? this.state.journalNoteForm : null;
                                                                if (val && revisitFormsSelect.filter(el => el == item._id).length == 0) {
                                                                    revisitFormsSelect.push(item._id)
                                                                    if (!journalNoteForm) {
                                                                        journalNoteForm = item._id;
                                                                    }
                                                                } else if (!val && revisitFormsSelect.filter(el => el == item._id).length) {
                                                                    let index = revisitFormsSelect.findIndex(el => el == item._id)
                                                                    if (index > -1) {
                                                                        revisitFormsSelect.splice(index, 1)
                                                                    }
                                                                    if (journalNoteForm && journalNoteForm == item._id) {
                                                                        let check = null;
                                                                        if (revisitFormsSelect && revisitFormsSelect.filter(el => el != item._id).length) {
                                                                            check = revisitFormsSelect.filter(el => el != item._id)[0];
                                                                        }
                                                                        journalNoteForm = check;
                                                                    }
                                                                }
                                                                this.setState({
                                                                    revisitFormsSelect,
                                                                    journalNoteForm
                                                                })
                                                            }}
                                                            checked={this.state.revisitFormsSelect.filter(el => el == item._id).length ? true : false}
                                                        />
                                                        <div className='use-in-journal-notes-checkbox'>
                                                            {
                                                                this.state.revisitFormsSelect.filter(el => el == item._id).length ?
                                                                    <Checkbox
                                                                        label={'Use in Journal notes'.translate(this.props.lang)}
                                                                        onChange={(e) => {
                                                                            let val = e.target.checked;
                                                                            let journalNoteForm = this.state.journalNoteForm;
                                                                            if (val) {
                                                                                journalNoteForm = item._id;
                                                                            }
                                                                            this.setState({
                                                                                journalNoteForm
                                                                            })
                                                                        }}
                                                                        checked={this.state.journalNoteForm == item._id ? true : false}
                                                                    />
                                                                    :
                                                                    null
                                                            }
                                                        </div>


                                                    </div>


                                                </div>
                                            )
                                        })
                                    }
                                </div>


                            </ModalBody>

                            <ModalFooter style={{ display: 'flex', justifyContent: 'flex-end' }} className='buttons-right-blue'>
                                <Button color="primary" onClick={() => {
                                    let revisitFormsSelect = this.state.revisitFormsSelect;
                                    let journalNoteForm = this.state.journalNoteForm;
                                    this.setState({ selectRevisitForm: null, revisitFormsSelected: revisitFormsSelect, revisitFormsSelect: [], journalNoteFormSelected: journalNoteForm, journalNoteForm: null })
                                }}> {'Insert'.translate(this.props.lang)} </Button>
                                <Button color="primary" onClick={() => this.setState({ selectRevisitForm: null, revisitFormsSelect: [] })}> {'Cancel'.translate(this.props.lang)} </Button>

                            </ModalFooter>



                        </Modal>
                        :
                        null
                }
                {
                    this.state.selectVisitReason ?
                        <Modal isOpen={this.state.selectVisitReason} size="lg" centered >
                            <ModalHeader toggle={() => this.setState({ selectVisitReason: !this.state.selectVisitReason })} close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => this.setState({ selectVisitReason: !this.state.selectVisitReason })}>&times;</button>}>{'Visit reasons'.translate(this.props.lang)}</ModalHeader>

                            <ModalBody>
                                <div className="search-box-wrap">
                                    <FormGroup>
                                        <Label>{'Search'.translate(this.props.lang)}</Label>
                                        <Input value={this.state.searchVisitReason}
                                            onChange={(e) => {
                                                this.setState({ searchVisitReason: striptags(e.target.value) }, () => this.getPriceList())
                                            }}
                                        />
                                    </FormGroup>
                                </div>
                                <div className="general-health-care-wrap">
                                    {
                                        this.state.visitReasons && this.state.visitReasons.items && this.state.visitReasons.items.sort((a, b) => {
                                            const nameA = a.name;
                                            const nameB = b.name;
                                            if (nameA < nameB) {
                                                return -1
                                            }
                                            if (nameA > nameB) {
                                                return 1
                                            }
                                        }).map((item, idx) => {
                                            return (
                                                <div className="health-care-wrap">
                                                    <div className="content">
                                                        <div className="title">
                                                            {item.name}
                                                            <span>
                                                                &nbsp;&nbsp;/&nbsp;&nbsp;
                                                                {item.description}</span>
                                                        </div>

                                                    </div>
                                                    <Button color='primary' onClick={() => {
                                                        let scheduleControl = this.state.scheduleControl;
                                                        scheduleControl.visitReasonName = item.name;
                                                        if (this.state.age && item.under18 && this.state.age < 18) {
                                                            scheduleControl.visitReasonPrice = 0;
                                                            scheduleControl.freePayment = 4
                                                        } else if (this.state.age && item.under20 && this.state.age < 20) {
                                                            scheduleControl.visitReasonPrice = 0;
                                                            scheduleControl.freePayment = 3
                                                        } else if (this.state.age && item.under23 && this.state.age < 23) {
                                                            scheduleControl.visitReasonPrice = 0;
                                                            scheduleControl.freePayment = 2
                                                        } else if (this.state.age && item.under18child && this.state.age < 18) {
                                                            scheduleControl.visitReasonPrice = 0;
                                                            scheduleControl.freePayment = 6
                                                        } else if (this.state.age && item.older85 && this.state.age > 85) {
                                                            scheduleControl.visitReasonPrice = 0;
                                                            scheduleControl.freePayment = 5
                                                        } else {
                                                            scheduleControl.visitReasonPrice = item.price;
                                                            // scheduleControl.paymentMethod = item.onlinePayment ? 3 : item.invoice ? 2 : item.paymentOnSite ? 1 : 0
                                                        }

                                                        scheduleControl.visiReason = item._id;
                                                        scheduleControl.visitReasonOnline = item.onlineVisit;
                                                        scheduleControl.videoCall = item.videoCall;
                                                        let setObj = { selectVisitReason: false, scheduleControl }
                                                        let revisitFormsSelected = [];
                                                        let journalNoteFormSelected = null;

                                                        if (enterprise && item && item.defaultForm && this.state.revisitForms && this.state.revisitForms.items?.filter(el => el._id === item.defaultForm).length) {
                                                            revisitFormsSelected.push(item.defaultForm);
                                                            journalNoteFormSelected = item.defaultForm;

                                                        }
                                                        setObj.journalNoteFormSelected = journalNoteFormSelected;
                                                        setObj.revisitFormsSelected = revisitFormsSelected;
                                                        this.setState(setObj)
                                                    }}>{'Select'.translate(this.props.lang)}</Button>

                                                </div>
                                            )
                                        })
                                    }
                                </div>


                            </ModalBody>

                            <ModalFooter style={{ display: 'flex', justifyContent: 'flex-end' }}>

                                <Button color="primary" onClick={() => this.setState({ selectVisitReason: null })}> {'Close'.translate(this.props.lang)} </Button>

                            </ModalFooter>



                        </Modal>
                        :
                        null
                }
                {
                    this.state.doctorsModal ?
                        <Modal size="sm" isOpen={this.state.doctorsModal} centered toggle={() => this.setState({ doctorsModal: !this.state.doctorsModal })} >
                            <ModalHeader toggle={() => this.setState({ doctorsModal: !this.state.doctorsModal })} close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => this.setState({ doctorsModal: !this.state.doctorsModal })}><Isvg src={xIcon} /></button>}>{'Choose doctors'.translate(this.props.lang)}</ModalHeader>

                            <ModalBody className="run-modal">

                                {
                                    this.state.doctors && this.state.doctors.length && this.state.doctors.filter((item) => {
                                        if (this.props.uData._id && item._id != this.props.uData._id) {
                                            return true;
                                        } else {
                                            return false;
                                        }
                                    }).length ?
                                        <FormGroup >
                                            <Label>{'Search'.translate(this.props.lang)}</Label>
                                            <Input type='text' value={this.state.filterDoctors ? this.state.filterDoctors : ''} onChange={(e) => {
                                                this.setState({
                                                    filterDoctors: e.target.value
                                                })
                                            }} />

                                        </FormGroup>
                                        :
                                        null
                                }

                                <FormGroup /*className="form-group-checkbox"*/>
                                    {
                                        this.state.doctors && this.state.doctors.length && this.state.doctors.filter((item) => {
                                            if (this.props.uData._id && item._id != this.props.uData._id) {
                                                return true;
                                            } else {
                                                return false;
                                            }
                                        }).length ?
                                            <ListCheckBox

                                                value={this.state.doctorsList ? this.state.doctorsList : []}
                                                values={this.state.doctors.filter(el =>
                                                    (!this.state.updateEvent || (this.state.updateEvent && this.state.updateEvent.patientId != el._id))
                                                    && (!this.state.scheduleReferral || (this.state.scheduleReferral && this.state.referralId && this.state.referrals.filter(ref => ref._id == this.state.referralId) && this.state.referrals.filter(ref => ref._id == this.state.referralId)[0] && this.state.referrals.filter(ref => ref._id == this.state.referralId)[0].patientId != el._id))
                                                    && (!this.state.scheduleControl || (this.state.scheduleControl && this.state.scheduleControl.patient != el._id))
                                                ).sort((a, b) => {
                                                    const nameA = a.name;
                                                    const nameB = b.name;
                                                    if (nameA < nameB) {
                                                        return -1
                                                    }
                                                    if (nameA > nameB) {
                                                        return 1
                                                    }
                                                }).filter(doc => doc._id !== this.props.uData._id && doc.name.toLowerCase().indexOf(this.state.filterDoctors ? this.state.filterDoctors.toLowerCase() : ' ') != -1).map(item => {
                                                    return ({
                                                        name: item.name,
                                                        value: item._id
                                                    })
                                                })}
                                                onChange={(item) => {
                                                    let doctorsList = this.state.doctorsList;
                                                    doctorsList = [...item]
                                                    this.setState({ doctorsList })
                                                }}
                                            />
                                            // this.state.doctors.filter(el =>
                                            //     (!this.state.updateEvent || (this.state.updateEvent && this.state.updateEvent.patientId != el._id))
                                            //     && (!this.state.scheduleReferral || (this.state.scheduleReferral && this.state.referralId && this.state.referrals.filter(ref => ref._id == this.state.referralId) && this.state.referrals.filter(ref => ref._id == this.state.referralId)[0] && this.state.referrals.filter(ref => ref._id == this.state.referralId)[0].patientId != el._id))
                                            //     && (!this.state.scheduleControl || (this.state.scheduleControl && this.state.scheduleControl.patient != el._id))
                                            // ).sort((a, b) => {
                                            //     const nameA = a.name;
                                            //     const nameB = b.name;
                                            //     if (nameA < nameB) {
                                            //         return -1
                                            //     }
                                            //     if (nameA > nameB) {
                                            //         return 1
                                            //     }
                                            // }).map((item, idx) => {
                                            //     if (item._id != this.props.uData._id)
                                            //         return (
                                            //             <Checkbox
                                            //                 checked={this.state.doctorsList && this.state.doctorsList.filter(doctor => doctor == item._id).length}
                                            //                 onChange={(e) => {

                                            //                     let doctorsList = this.state.doctorsList;
                                            //                     // console.log(doctorsList)
                                            //                     let exists = false;
                                            //                     for (let i = 0; i < doctorsList.length; i++) {
                                            //                         if (doctorsList[i] == item._id) {
                                            //                             exists = true;
                                            //                         }
                                            //                     }
                                            //                     if (!exists) {
                                            //                         doctorsList.push(item._id)
                                            //                     } else {
                                            //                         let index = doctorsList.indexOf(item._id);
                                            //                         if (index > -1) {
                                            //                             doctorsList.splice(index, 1);
                                            //                         }
                                            //                     }
                                            //                     // console.log(doctorsList)
                                            //                     this.setState({ doctorsList })


                                            //                 }}
                                            //                 label={item.name} />

                                            //         )
                                            // })
                                            :
                                            "The doctor's list is empty".translate(this.props.lang)
                                    }
                                </FormGroup>


                            </ModalBody>
                            <ModalFooter className='buttons-right-blue'>
                                {
                                    this.state.doctors && this.state.doctors.length && this.state.doctors.filter((item) => {
                                        if (this.props.uData._id && item._id != this.props.uData._id) {
                                            return true;
                                        } else {
                                            return false;
                                        }
                                    }).length ?
                                        <Button color='primary'
                                            // disabled={this.state.doctorsList && this.state.doctorsList.length > 0 ? false : true}
                                            onClick={() =>
                                                this.checkDoctorsAvailable(this.state.doctorsList, this.state.doctorsModal)
                                            }>{'Add'.translate(this.props.lang)}</Button>
                                        :
                                        null
                                }
                                <Button color='primary' onClick={() => {
                                    // console.log(this.state.doctorsList)
                                    // console.log(this.state.updateEvent.additionalDoctors)
                                    this.setState({ doctorsModal: false })
                                }
                                }>{"Close".translate(this.props.lang)}</Button>
                            </ModalFooter>
                        </Modal>
                        :
                        null
                }
                {
                    this.state.successfullyAdditionalDoctors ?
                        <InfoModal
                            isOpen={this.state.successfullyAdditionalDoctors}
                            toggle={() => this.setState({ successfullyAdditionalDoctors: !this.state.successfullyAdditionalDoctors })}
                            header={'Info'.translate(this.props.lang)}
                            info={`All doctors were successfully added`.translate(this.props.lang)}
                            buttons={[
                                <Button color="primary" style={{ margin: 10 }} onClick={() => this.setState({ successfullyAdditionalDoctors: false })}>{'Ok'.translate(this.props.lang)}</Button>,
                                // <Button color="primary" style={{ margin: 10 }} onClick={() => this.setState({ cancelCostModal: false })}>{'Cancel'.translate(this.props.lang)}</Button>

                            ]}

                        />
                        :
                        null
                }
                {
                    this.state.noSearchResults ?
                        <InfoModal
                            isOpen={this.state.noSearchResults}
                            toggle={() => this.setState({ noSearchResults: !this.state.noSearchResults })}
                            header={'Info'.translate(this.props.lang)}
                            info={this.state.noSearchResults}
                            buttons={[
                                <Button color="primary" style={{ margin: 10 }} onClick={() => this.setState({ noSearchResults: false })}>{'Ok'.translate(this.props.lang)}</Button>,
                                // <Button color="primary" style={{ margin: 10 }} onClick={() => this.setState({ cancelCostModal: false })}>{'Cancel'.translate(this.props.lang)}</Button>

                            ]}

                        />
                        :
                        null
                }
                {
                    this.state.errorAdditionalDoctors ?

                        <ErrorModal lang={this.props.lang}
                            isOpen={this.state.errorAdditionalDoctors}
                            toggle={() => {
                                // let newDoctorList = this.state.doctorsList;
                                // if (newDoctorList && newDoctorList.length) {
                                //     for (let i = 0; i < newDoctorList.length; i++) {
                                //         if (this.state.errorAdditionalDoctors && this.state.errorAdditionalDoctors.length && this.state.errorAdditionalDoctors.filter(item => item.doctorId == newDoctorList[i] && !item.available).length) {
                                //             let index = newDoctorList.indexOf(newDoctorList[i]);
                                //             if (index > -1) {
                                //                 newDoctorList.splice(index, 1);
                                //             }
                                //         }
                                //     }
                                // }

                                // this.setState({ doctorsList: newDoctorList }, () => this.setState({ errorAdditionalDoctors: null }))

                                this.setState({ errorAdditionalDoctors: null })
                            }}

                        >
                            {
                                this.state.errorAdditionalDoctors && this.state.errorAdditionalDoctors.map((item, idx) => {
                                    return (
                                        <div>
                                            <span style={{ fontWeight: 600 }}>
                                                {item.doctorName}  {' - '}
                                            </span>

                                            {
                                                item.available ?
                                                    <span style={{ color: 'green' }}>{'Available'.translate(this.props.lang)}</span>
                                                    :
                                                    <span style={{ color: 'red' }}>{'Unavailable'.translate(this.props.lang)}</span>
                                            }

                                        </div>
                                    )
                                })

                            }
                        </ErrorModal>
                        :
                        null
                }
                {
                    this.state.conversationModal ?
                        <Modal isOpen={this.state.conversationModal} centered toggle={() => this.setState({ conversationModal: !this.state.conversationModal })} >
                            <ModalHeader>
                                <h4>{'Start conversation or enter existing conversation'.translate(this.props.lang)}</h4>
                            </ModalHeader>
                            <ModalBody>
                                {this.state.conversationModal.conversations.length ?
                                    <>
                                        <h6>{'Existing conversations'.translate(this.props.lang)}</h6>
                                        <Table responsive>
                                            <thead>
                                                <tr>
                                                    <th>{'Conversation name'.translate(this.props.lang)}</th>
                                                    <th>{'Clinic doctor'.translate(this.props.lang)}</th>
                                                    <th>{'Patient'.translate(this.props.lang)}</th>
                                                    <th>{'Action'.translate(this.props.lang)}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    this.state.conversationModal.conversations.map((item, idx) => {
                                                        return (
                                                            <tr>
                                                                <td>{item.name}</td>

                                                                <td><Checkbox checked={item._doctorIncludedInConversation} /></td>
                                                                <td><Checkbox checked={item._patientIncludedInConversation} /></td>
                                                                <td>
                                                                    <Link to={`/chat?conversation=${item._id}`}>< Isvg src={openChat} style={{ width: 21 }} /></Link>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                }

                                            </tbody>
                                        </Table>
                                    </>
                                    :
                                    null
                                }

                                <h6>{'New conversation:'.translate(this.props.lang)}</h6>

                                <FormGroup>
                                    <Button size="sm" color="primary" onClick={() => {
                                        this.conversation(this.state.conversationModal._id, { ...this.state.conversationForm, patient: true });
                                    }}>{'Start new conversation'.translate(this.props.lang)}</Button>
                                </FormGroup>

                            </ModalBody>
                        </Modal>
                        :
                        null
                }
                {
                    this.state.conversationControlModal ?
                        <Modal isOpen={this.state.conversationControlModal} centered toggle={() => this.setState({ conversationControlModal: !this.state.conversationControlModal })} >
                            <ModalHeader>
                                <h4>{'Start conversation'.translate(this.props.lang)}</h4>
                            </ModalHeader>
                            <ModalBody>


                                <h6>{'New conversation:'.translate(this.props.lang)}</h6>

                                <FormGroup>
                                    <Button size="sm" color="primary" onClick={() => {
                                        this.controlConversation(this.state.conversationControlModal.id, { ...this.state.conversationForm, patient: true });
                                    }}>{'Start new conversation'.translate(this.props.lang)}</Button>
                                </FormGroup>

                            </ModalBody>
                        </Modal>
                        :
                        null
                }

                {
                    this.state.leaveCaseModal ?
                        <Modal isOpen={this.state.leaveCaseModal} centered >
                            {/* <ModalHeader style={{ margin: 'auto' }}>{'Warning'.translate(this.props.lang)}</ModalHeader> */}
                            <ModalBody className="delete-modal">
                                {/* <img src={warrning} /> */}
                                <div className='message'>
                                    {'Do you want to leave the case'.translate(this.props.lang)}
                                </div>



                            </ModalBody>
                            <ModalFooter className='buttons-right-blue'>
                                <Button color='primary' style={{ margin: 10, minWidth: 100 }} onClick={() => this.leaveCase(this.state.updateEvent)}>
                                    {'Yes'.translate(this.props.lang)}
                                </Button>
                                <Button color='primary' style={{ margin: 10, minWidth: 100 }} onClick={() => this.setState({ leaveCaseModal: !this.state.leaveCaseModal })}>{'No'.translate(this.props.lang)}</Button>
                            </ModalFooter>
                        </Modal>

                        : null
                }

                {
                    this.state.warningModal ?
                        <Modal isOpen={this.state.warningModal} centered >
                            {/* <ModalHeader style={{ margin: 'auto' }}>{'Warning'.translate(this.props.lang)}</ModalHeader> */}
                            <ModalBody className="delete-modal">
                                {/* <img src={warrning} /> */}
                                {this.state.warningModal.title == 'Free time'.translate(this.props.lang) ?
                                    <div className='message'>{'Do you really want to delete the scheduled time? In that case, do not forget to book a new one!'.translate(this.props.lang)}</div>
                                    :
                                    <div className='message'>{'Do you really want to delete the booked time? In that case, do not forget to book a new one!'.translate(this.props.lang)}</div>
                                }



                            </ModalBody>
                            <ModalFooter className='buttons-right-blue'>
                                <Button color='primary' style={{ margin: 10, minWidth: 100 }} onClick={() => this.deleteEvent(this.state.warningModal)}>
                                    {this.state.loading ? /*'Processing...'.translate(this.props.lang)*/

                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <Player
                                                onEvent={() => {
                                                    if (!this.state.loading) this.stopAnimation();
                                                }}
                                                onStateChange={this.toggleVisibility}
                                                ref={this.player} // set the ref to your class instance
                                                autoplay={true}
                                                loop={true}
                                                controls={true}
                                                src="https://assets8.lottiefiles.com/packages/lf20_ZSRKCF.json"
                                                style={{ height: "20px", width: "30px" }}
                                            ></Player>
                                            {'Processing...'.translate(this.props.lang)}
                                        </div>

                                        : 'Cancel time'.translate(this.props.lang)}
                                </Button>
                                <Button color='primary' style={{ margin: 10, minWidth: 100 }} onClick={() => this.setState({ warningModal: !this.state.warningModal })}>{'Cancel'.translate(this.props.lang)}</Button>
                            </ModalFooter>
                        </Modal>

                        : null
                }

                {
                    this.state.wrongDate ?
                        <Modal isOpen={this.state.wrongDate} centered>
                            {/* <ModalHeader style={{ margin: 'auto' }}>
                                {'Warning'.translate(this.props.lang)}
                            </ModalHeader> */}
                            <ModalBody className="delete-modal">
                                {/* <img src={warrning} /> */}
                                <p className='message'>{'You can not pick date form the past'.translate(this.props.lang)}</p>
                            </ModalBody>
                            <ModalFooter className="buttons-right-blue">
                                <Button style={{ minWidth: 100 }} color='primary' onClick={() => this.setState({ wrongDate: !this.state.wrongDate })}>{'Pick again'.translate(this.props.lang)}</Button>
                            </ModalFooter>
                        </Modal>
                        : null
                }

                {
                    this.state.wrongInput ?
                        <Modal isOpen={this.state.wrongInput} toggle={() => this.setState({ wrongInput: !this.state.wrongInput })} centered>
                            {/* <ModalHeader>
                                <h4></h4>
                            </ModalHeader> */}
                            <ModalBody className='delete-modal'>
                                <p>{'Wrong input'.translate(this.props.lang)}</p>
                            </ModalBody>
                            <ModalFooter className='buttons-right-blue'>
                                <Button style={{ minWidth: 100 }} onClick={() => this.setState({ wrongInput: !this.state.wrongInput, scheduleModal: !this.state.scheduleModal })}>{'Pick again'.translate(this.props.lang)}</Button>
                            </ModalFooter>
                        </Modal>

                        : null
                }
                {
                    this.state.errorPatientNotFound ?
                        <Modal isOpen={this.state.errorPatientNotFound} toggle={() => this.setState({ errorPatientNotFound: !this.state.errorPatientNotFound })} centered>
                            <ModalHeader style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                {'Patient not found'.translate(this.props.lang)}
                            </ModalHeader>
                            <ModalBody className='delete-modal'>
                                <p>{this.state.errorPatientNotFound}</p>
                            </ModalBody>
                            <ModalFooter style={{ justifyContent: 'center' }}>
                                <Button style={{ minWidth: 100 }} className={'danger-button'} onClick={() => this.setState({ errorPatientNotFound: !this.state.errorPatientNotFound })}>{'Cancel'.translate(this.props.lang)}</Button>
                                <Button style={{ minWidth: 100 }} color='primary' onClick={() => this.setState({ errorPatientNotFound: !this.state.errorPatientNotFound, registerNewPatientModal: { language: this.state.languages && this.state.languages[0] && this.state.languages[0]._id, socialSecurityNumber: this.state.patientSocialSecurityNumber, twoFactorAuthenticationEnabled: this.props.country === 'uk' ? true : false } }, () => {
                                    this.checkSSN(this.state.patientSocialSecurityNumber)
                                })}>{'Register a new patient'.translate(this.props.lang)}</Button>

                            </ModalFooter>
                        </Modal>

                        : null
                }

                {this.state.registerNewPatientModal ?
                    <FormBuilder
                        selectedGroup={this.props.selectedGroup}
                        selectedClinic={this.props.selectedClinic}
                        countryTwoLetter={this.props.countryTwoLetter}
                        country={this.props.country} dateFormat={this.props.dateFormat}
                        getStringDateTs={this.props.getStringDateTs}
                        onSubmit={(data) => {
                            if (!this.state.ssnExistInThisGroup) {
                                this.checkPatientValidations(data)
                            } else {
                                this.setState({ registerNewPatientModal: null, ssnExistInThisGroup: null, socialSecurityNumberExist: null })
                            }

                        }}
                        initialValues={this.state.registerNewPatientModal}
                        size="lg"
                        isOpen={this.state.registerNewPatientModal}
                        toggle={() => this.setState({ registerNewPatientModal: null, age: null, ssnExistInThisGroup: null, socialSecurityNumberExist: null })}
                        title={this.state.registerNewPatientModal._id ? 'Edit patient'.translate(this.props.lang) : 'Register a new patient'.translate(this.props.lang)}
                        _saving={this.state._saving}
                        buttonConfirmText={'Saved'.translate(this.props.lang)}
                        buttonText={!this.state.ssnExistInThisGroup ? 'Save and send confirmation'.translate(this.props.lang) : 'Close'.translate(this.props.lang)}

                        lang={this.props.lang}
                        fields={[
                            {
                                type: 'row',
                                children: [
                                    {
                                        type: 'col',
                                        width: {
                                            lg: 8,
                                            sm: 12,
                                            xs: 12
                                        },
                                        children: [
                                            {
                                                type: 'socialSecurityNumber',
                                                name: 'socialSecurityNumber',
                                                label: this.props.country === 'uk' ? "Date of birth".translate(this.props.lang) : 'Social security number'.translate(this.props.lang),
                                                disableNewPatientBtn: true,
                                                hideFindChildren: true,
                                                // searchByNameAndEmailFormBuilder: this.props.country === 'uk' ? true : false,
                                                disabled: true,
                                                validate: [required('Social security number is required!'.translate(this.props.lang)), checkSocialSecurityNumber('Social security number is not valid!'.translate(this.props.lang), this.props.country)]

                                            },
                                        ]
                                    },
                                    this.props.showDateOfBirth ?
                                        {
                                            type: 'col',
                                            width: {
                                                lg: 4,
                                                sm: 12,
                                                xs: 12
                                            },
                                            children: [
                                                {
                                                    type: 'date-of-birth',
                                                    dateFormat: this.props.dateFormat,
                                                    name: 'dateOfBirth.date',
                                                    disabled: this.state.disabledDateOfBirth,
                                                    label: 'Date of birth'.translate(this.props.lang),
                                                    validate: [required('Date of birth is required!'.translate(this.props.lang)), dateValidate('Invalid date!'.translate(this.props.lang))]

                                                },
                                            ]
                                        } : null,
                                    this.state.socialSecurityNumberExist && !this.state.ssnExistInThisGroup ?
                                        {
                                            type: 'col',
                                            width: {
                                                lg: 12,
                                                sm: 12,
                                                xs: 12
                                            },
                                            children: [
                                                {
                                                    type: 'h6',
                                                    text: 'The user already exists in the system'.translate(this.props.lang),
                                                    style: { marginBottom: 20 }
                                                },
                                            ]
                                        } : { type: 'empty' },
                                    this.state.socialSecurityNumberExist && this.state.ssnExistInThisGroup ?
                                        {
                                            type: 'col',
                                            width: {
                                                lg: 12,
                                                sm: 12,
                                                xs: 12
                                            },
                                            children: [
                                                {
                                                    type: 'h6',
                                                    text: "The user is already registered within your clinic group".translate(this.props.lang),
                                                    style: { marginBottom: 20 }
                                                },
                                            ]
                                        } : { type: 'empty' },

                                    !this.state.socialSecurityNumberExist /*|| (isChild && this.state.socialSecurityNumberExist)*/ ?
                                        {
                                            type: 'col',
                                            width: {
                                                lg: 4,
                                                sm: 12,
                                                xs: 12
                                            },
                                            children: [
                                                {
                                                    type: 'select',
                                                    name: 'userData.sex',
                                                    labelInfo: "The patient's biological sex is needed for proper treatment".translate(this.props.lang),
                                                    label: 'Sex'.translate(this.props.lang),
                                                    disabled: this.state.socialSecurityNumberExist && !this.state.ssnExistInThisGroup ? true : false,
                                                    values: [{ value: 0, name: 'Select'.translate(this.props.lang) }, { value: 'male', name: 'Male'.translate(this.props.lang) }, { value: 'female', name: 'Female'.translate(this.props.lang) }],
                                                },
                                            ]
                                        } : { type: 'empty' },
                                    !this.state.socialSecurityNumberExist /*|| (isChild && this.state.socialSecurityNumberExist)*/ ?
                                        {
                                            type: 'col',
                                            width: {
                                                lg: this.props.showDateOfBirth ? this.props.secondaryLanguageDisabled ? 6 : 4 : this.props.secondaryLanguageDisabled ? 12 : 8,
                                                sm: 12,
                                                xs: 12
                                            },
                                            children: [
                                                {
                                                    type: 'text',
                                                    name: 'userData.name',
                                                    label: 'First and last name'.translate(this.props.lang),
                                                    disabled: this.state.socialSecurityNumberExist && !this.state.ssnExistInThisGroup ? true : false,
                                                    validate: [required('Name is required!'.translate(this.props.lang))]
                                                },
                                            ]
                                        } : { type: 'empty' },
                                    isChild ?
                                        {
                                            type: 'col',
                                            width: {
                                                lg: 12,
                                                sm: 12,
                                                xs: 12
                                            },
                                            children: [
                                                {
                                                    type: 'h6',
                                                    style: { color: 'red', marginBottom: 20 },
                                                    text: 'Child is under 13 years of age - Account must be connected to a parent- or legal guardian account.'.translate(this.props.lang),
                                                },
                                            ]
                                        } : { type: 'empty' },
                                    !this.state.socialSecurityNumberExist && isChild /*|| (isChild && this.state.socialSecurityNumberExist)*/ ?
                                        {
                                            type: 'col',
                                            width: {
                                                lg: 12,
                                                sm: 12,
                                                xs: 12
                                            },
                                            children: [
                                                {
                                                    type: 'h6',
                                                    style: { marginBottom: 20 },
                                                    text: 'Register parent or legal guardian'.translate(this.props.lang),
                                                },
                                            ]
                                        } : { type: 'empty' },
                                    !this.state.socialSecurityNumberExist && isChild /*|| (isChild && this.state.socialSecurityNumberExist)*/ ?
                                        {
                                            type: 'col',
                                            width: {
                                                lg: 8,
                                                sm: 12,
                                                xs: 12
                                            },
                                            children: [
                                                {
                                                    type: 'socialSecurityNumber',
                                                    name: 'socialSecurityNumberParent',
                                                    hideFindChildren: true,
                                                    disableNewPatientBtn: true,
                                                    searchByNameAndEmailFormBuilder: this.state.socialSecurityNumberExist && !this.state.ssnExistInThisGroup ? false : true,
                                                    label: this.props.country === 'uk' ? "Date of birth".translate(this.props.lang) : 'Social security number'.translate(this.props.lang),
                                                    disabled: this.state.socialSecurityNumberExist && !this.state.ssnExistInThisGroup ? true : false,
                                                    validate: [required('Social security number is required!'.translate(this.props.lang)), checkSocialSecurityNumber('Social security number is not valid!'.translate(this.props.lang), this.props.country)]
                                                },
                                            ]
                                        } : { type: 'empty' },
                                    isChild && this.props.showDateOfBirth ?
                                        {
                                            type: 'col',
                                            width: {
                                                lg: 4,
                                                sm: 12,
                                                xs: 12
                                            },
                                            children: [
                                                {
                                                    type: 'date-of-birth',
                                                    name: 'dateOfBirthParent.date',
                                                    disabled: this.state.disabledDateOfBirthParent,
                                                    label: 'Date of birth'.translate(this.props.lang),
                                                    validate: [required('Date of birth is required!'.translate(this.props.lang)), dateValidate('Invalid date!'.translate(this.props.lang))]

                                                },
                                            ]
                                        } : { type: 'empty' },
                                    !this.state.socialSecurityNumberExist && isChild /*|| (isChild && this.state.socialSecurityNumberExist)*/ ?
                                        {
                                            type: 'col',
                                            width: {
                                                lg: 4,
                                                sm: 12,
                                                xs: 12
                                            },
                                            children: [
                                                {
                                                    type: 'select',
                                                    name: 'sexParent',
                                                    label: 'Sex'.translate(this.props.lang),
                                                    disabled: this.state.socialSecurityNumberExist && !this.state.ssnExistInThisGroup ? true : false,
                                                    values: [{ value: 0, name: 'Select'.translate(this.props.lang) }, { value: 'male', name: 'Male'.translate(this.props.lang) }, { value: 'female', name: 'Female'.translate(this.props.lang) }],
                                                },
                                            ]
                                        } : { type: 'empty' },
                                    !this.state.socialSecurityNumberExist && isChild /*|| (isChild && this.state.socialSecurityNumberExist)*/ ?
                                        {
                                            type: 'col',
                                            width: {
                                                lg: this.props.showDateOfBirth ? this.props.secondaryLanguageDisabled ? 6 : 4 : this.props.secondaryLanguageDisabled ? 12 : 8,
                                                sm: 12,
                                                xs: 12
                                            },
                                            children: [
                                                {
                                                    type: 'text',
                                                    name: 'nameParent',
                                                    label: 'First and last name'.translate(this.props.lang),
                                                    disabled: this.state.socialSecurityNumberExist && !this.state.ssnExistInThisGroup ? true : false,
                                                    validate: [required('Name is required!'.translate(this.props.lang))]


                                                },
                                            ]
                                        } : { type: 'empty' },
                                    (!this.state.socialSecurityNumberExist /*|| (isChild && this.state.socialSecurityNumberExist)*/) && !this.props.secondaryLanguageDisabled ?
                                        {
                                            type: 'col',
                                            width: {
                                                lg: 4,
                                                sm: 12,
                                                xs: 12
                                            },
                                            children: [
                                                {
                                                    type: 'select',
                                                    name: 'language',
                                                    label: 'Language'.translate(this.props.lang),
                                                    disabled: this.state.socialSecurityNumberExist && !this.state.ssnExistInThisGroup ? true : false,
                                                    values: this.state.languages.map(item => { return { name: item.name?.translate(this.props.lang), value: item._id } })
                                                },
                                            ]
                                        } : { type: 'empty' },

                                    !this.state.socialSecurityNumberExist /*|| (isChild && this.state.socialSecurityNumberExist)*/ ?
                                        {
                                            type: 'col',
                                            width: {
                                                lg: 6,
                                                sm: 12,
                                                xs: 12
                                            },
                                            children: [
                                                {
                                                    type: 'text',
                                                    name: 'email',
                                                    label: 'Email address'.translate(this.props.lang),
                                                    disabled: this.state.socialSecurityNumberExist && !this.state.ssnExistInThisGroup ? true : false,
                                                    validate: this.props.twoFactorAuthenticationEnabled ? [required('When two factor authentication is enabled, both email and phone number are required!'.translate(this.props.lang))] : []
                                                },
                                            ]
                                        } : { type: 'empty' },
                                    !this.state.socialSecurityNumberExist /*|| (isChild && this.state.socialSecurityNumberExist)*/ ?
                                        {
                                            type: 'col',
                                            width: {
                                                lg: 6,
                                                sm: 12,
                                                xs: 12
                                            },
                                            children: [
                                                {
                                                    type: 'phone',

                                                    name: 'phone',
                                                    label: 'Phone'.translate(this.props.lang),
                                                    disabled: this.state.socialSecurityNumberExist && !this.state.ssnExistInThisGroup ? true : false,
                                                    validate: this.props.twoFactorAuthenticationEnabled ? [required('When two factor authentication is enabled, both email and phone number are required!'.translate(this.props.lang))] : []
                                                },
                                            ]
                                        } : { type: 'empty' },
                                    // ---

                                    !this.state.socialSecurityNumberExist && isChild /*|| (isChild && this.state.socialSecurityNumberExist)*/ ?
                                        {
                                            type: 'col',
                                            width: {
                                                lg: 12,
                                                sm: 12,
                                                xs: 12
                                            },
                                            children: [
                                                {
                                                    type: 'h6',
                                                    style: { marginBottom: 20 },
                                                    text: 'Register second parent or legal guardian'.translate(this.props.lang),
                                                },
                                            ]
                                        } : { type: 'empty' },
                                    !this.state.socialSecurityNumberExist && isChild/*|| (isChild && this.state.socialSecurityNumberExist)*/ ?
                                        {
                                            type: 'col',
                                            width: {
                                                lg: 8,
                                                sm: 12,
                                                xs: 12
                                            },
                                            children: [
                                                {
                                                    type: 'socialSecurityNumber',
                                                    name: 'socialSecurityNumberParent2',
                                                    hideFindChildren: true,
                                                    searchByNameAndEmailFormBuilder: this.state.socialSecurityNumberExist && !this.state.ssnExistInThisGroup ? false : true,
                                                    disableNewPatientBtn: true,
                                                    label: this.props.country === 'uk' ? "Date of birth".translate(this.props.lang) : 'Social security number'.translate(this.props.lang),
                                                    disabled: this.state.socialSecurityNumberExist && !this.state.ssnExistInThisGroup ? true : false,
                                                    validate: !this.state.parent2Required ? [] : [required('Social security number is required!'.translate(this.props.lang)), checkSocialSecurityNumber('Social security number is not valid!'.translate(this.props.lang), this.props.country)]
                                                },
                                            ]
                                        } : { type: 'empty' },
                                    isChild && this.props.showDateOfBirth ?
                                        {
                                            type: 'col',
                                            width: {
                                                lg: 4,
                                                sm: 12,
                                                xs: 12
                                            },
                                            children: [
                                                {
                                                    type: 'date-of-birth',
                                                    dateFormat: this.props.dateFormat,
                                                    name: 'dateOfBirthParent2.date',
                                                    disabled: this.state.disabledDateOfBirthParent2,
                                                    label: 'Date of birth'.translate(this.props.lang),
                                                    validate: !this.state.parent2Required ? [] : [required('Date of birth is required!'.translate(this.props.lang)), dateValidate('Invalid date!'.translate(this.props.lang))]

                                                },
                                            ]
                                        } : { type: 'empty' },
                                    !this.state.socialSecurityNumberExist && isChild /*|| (isChild && this.state.socialSecurityNumberExist)*/ ?
                                        {
                                            type: 'col',
                                            width: {
                                                lg: 4,
                                                sm: 12,
                                                xs: 12
                                            },
                                            children: [
                                                {
                                                    type: 'select',
                                                    name: 'sexParent2',
                                                    label: 'Sex'.translate(this.props.lang),
                                                    disabled: this.state.socialSecurityNumberExist && !this.state.ssnExistInThisGroup ? true : false,
                                                    values: [{ value: 0, name: 'Select'.translate(this.props.lang) }, { value: 'male', name: 'Male'.translate(this.props.lang) }, { value: 'female', name: 'Female'.translate(this.props.lang) }],
                                                },
                                            ]
                                        } : { type: 'empty' },
                                    !this.state.socialSecurityNumberExist && isChild /*|| (isChild && this.state.socialSecurityNumberExist)*/ ?
                                        {
                                            type: 'col',
                                            width: {
                                                lg: this.props.showDateOfBirth ? this.props.secondaryLanguageDisabled ? 6 : 4 : this.props.secondaryLanguageDisabled ? 12 : 8,
                                                sm: 12,
                                                xs: 12
                                            },
                                            children: [
                                                {
                                                    type: 'text',
                                                    name: 'nameParent2',
                                                    label: 'First and last name'.translate(this.props.lang),
                                                    validate: !this.state.parent2Required ? [] : [required('Name is required!'.translate(this.props.lang))],
                                                    disabled: this.state.socialSecurityNumberExist && !this.state.ssnExistInThisGroup ? true : false,

                                                },
                                            ]
                                        } : { type: 'empty' },
                                    !this.state.socialSecurityNumberExist && isChild /*|| (isChild && this.state.socialSecurityNumberExist)*/ && !this.props.secondaryLanguageDisabled ?
                                        {
                                            type: 'col',
                                            width: {
                                                lg: 4,
                                                sm: 12,
                                                xs: 12
                                            },
                                            children: [
                                                {
                                                    type: 'select',
                                                    name: 'language2',
                                                    label: 'Language'.translate(this.props.lang),
                                                    disabled: this.state.socialSecurityNumberExist && !this.state.ssnExistInThisGroup ? true : false,
                                                    values: this.state.languages.map(item => { return { name: item.name?.translate(this.props.lang), value: item._id } })
                                                },
                                            ]
                                        } : { type: 'empty' },

                                    !this.state.socialSecurityNumberExist && isChild /*|| (isChild && this.state.socialSecurityNumberExist)*/ ?
                                        {
                                            type: 'col',
                                            width: {
                                                lg: 6,
                                                sm: 12,
                                                xs: 12
                                            },
                                            children: [
                                                {
                                                    type: 'text',
                                                    name: 'email2',
                                                    label: 'Email address'.translate(this.props.lang),
                                                    disabled: this.state.socialSecurityNumberExist && !this.state.ssnExistInThisGroup ? true : false,
                                                    validate: !this.state.parent2Required ? [] : this.props.twoFactorAuthenticationEnabled ? [] : []
                                                },
                                            ]
                                        } : { type: 'empty' },
                                    !this.state.socialSecurityNumberExist && isChild /*|| (isChild && this.state.socialSecurityNumberExist)*/ ?
                                        {
                                            type: 'col',
                                            width: {
                                                lg: 6,
                                                sm: 12,
                                                xs: 12
                                            },
                                            children: [
                                                {
                                                    type: 'phone',

                                                    name: 'phone2',
                                                    label: 'Phone'.translate(this.props.lang),
                                                    disabled: this.state.socialSecurityNumberExist && !this.state.ssnExistInThisGroup ? true : false,
                                                    validate: !this.state.parent2Required ? [] : this.props.twoFactorAuthenticationEnabled ? [] : []
                                                },
                                            ]
                                        } : { type: 'empty' },
                                    // ---
                                    !this.state.socialSecurityNumberExist && this.state.clinicGroupSettings?.twoFactorAuthentication ?
                                        {
                                            type: 'col',
                                            width: {
                                                lg: 12,
                                                sm: 12,
                                                xs: 12
                                            },
                                            children: [
                                                {
                                                    type: 'checkbox',
                                                    name: 'twoFactorAuthenticationEnabled',
                                                    label: 'Enable login with two factor authentication'.translate(this.props.lang),
                                                    labelInfo: 'Login link is sent to the users phone number and email. The link is valid for 7 days.'.translate(this.props.lang)
                                                }
                                            ]

                                        }
                                        : { type: 'empty' },
                                    !this.state.ssnExistInThisGroup ?
                                        {
                                            type: 'col',
                                            width: {
                                                lg: 12,
                                                sm: 12,
                                                xs: 12
                                            },
                                            children: [
                                                {
                                                    type: 'checkbox',
                                                    validate: [required('Consent is required!'.translate(this.props.lang))],
                                                    name: 'consentAccepted',
                                                    label: 'Consent has been obtained'.translate(this.props.lang),

                                                },
                                            ]
                                        } : { type: 'empty' },




                                ]
                            }
                        ]}
                    ></FormBuilder>
                    :
                    null
                }
                {
                    this.state.previewAnswersModal ?
                        <Modal isOpen={this.state.previewAnswersModal} centered toggle={() => this.setState({ previewAnswersModal: !this.state.previewAnswersModal })} size="lg">
                            <ModalHeader toggle={() => this.setState({ previewAnswersModal: !this.state.previewAnswersModal })} close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => this.setState({ previewAnswersModal: !this.state.previewAnswersModal })}><Isvg src={xIcon} /></button>}>{'Form preview'.translate(this.props.lang)}</ModalHeader>

                            <ModalBody>

                                <FormPreview {...this.props} referral={{ _id: this.state.previewAnswersModal._id, name: this.state.previewAnswersModal.name, description: this.state.previewAnswersModal.description, intelligentReferral: this.state.previewAnswersModal.intelligentReferral, items: this.state.previewAnswersModal.items, group: this.props.uData?.clinicGroups[selectedGroupIdx]?._id }} sections={this.generateSections(this.state.previewAnswersModal.items)} answers={this.state.previewAnswersModal.answers} disabledForm={true} hidePreview={true}></FormPreview>

                            </ModalBody>
                        </Modal>
                        :
                        null
                }
                {
                    this.state.openPreviewReferral ?
                        <ReferralContent
                            {...this.props}
                            data={this.state.previewReferral}
                            screen={'my-cases'}
                            calendar={true}
                            doctorPage={true}
                            enterprise={enterprise}
                            // adminPermission={this.state.adminPermission}
                            // generateTimeReferral={(referral) => { this.generateTimeReferral(referral) }}
                            // allowReferral={() => { this.allowReferral() }}
                            // disallowReferral={(referral) => { this.setState({ disallowForm: referral }) }}
                            // acceptReferral={() => this.acceptReferral()}
                            // rejectReferral={() => this.rejectReferral()}
                            // acceptRequestedTime={() => this.acceptRequestedTime()}
                            // rejectRequestedTime={(referral) => {
                            //     this.setState({ scheduleForm: referral })
                            // }}
                            // priorityModal={(referral) => {
                            //     this.setState({
                            //         priorityModal: { ...referral }
                            //     })
                            // }}
                            closeCase={() => { this.setState({ closeCase: true }) }}
                            deleteCase={() => { this.setState({ deleteCase: true }) }}

                            previewAnswersRevisit={(form) => {
                                this.setState({ previewAnswersModal: form })
                            }}
                            closeReferralContent={(data) => {
                                if (this.state.openPreviewReferral) {
                                    this.setState({
                                        openPreviewReferral: null
                                    })
                                }
                            }}
                        />
                        :
                        null
                }

                {
                    this.state.closeCase ?
                        <Modal isOpen={this.state.closeCase} centered>
                            {/* <ModalHeader style={{ margin: 'auto' }}>{'Warning'.translate(this.props.lang)}</ModalHeader> */}
                            <ModalBody className="delete-modal">
                                {/* <img src={warrning} /> */}
                                <p className="message">{`Are you sure you want to close the case?`.translate(this.props.lang)}</p>
                            </ModalBody>
                            <ModalFooter className='buttons-right-blue'>
                                <Button color="primary" style={{ margin: 10, minWidth: 100 }} onClick={() => this.setState({ closeCase: false }, () => this.closeReferral())}>{'Yes'.translate(this.props.lang)}</Button>
                                <Button color="primary" style={{ margin: 10, minWidth: 100 }} onClick={() => this.setState({ closeCase: false })}>{'No'.translate(this.props.lang)}</Button>

                            </ModalFooter>
                        </Modal>
                        :
                        null
                }
                {
                    this.state.deleteCase ?
                        <Modal isOpen={this.state.deleteCase} centered>
                            <ModalBody className="delete-modal">
                                <p className="message">{`Are you sure you want to delete the case?`.translate(this.props.lang)}</p>
                            </ModalBody>
                            <ModalFooter className='buttons-right-blue'>
                                <Button color="primary" style={{ margin: 10, minWidth: 100 }} onClick={() => this.setState({ deleteCase: false }, () => this.deleteReferral())}>{'Yes'.translate(this.props.lang)}</Button>
                                <Button color="primary" style={{ margin: 10, minWidth: 100 }} onClick={() => this.setState({ deleteCase: false })}>{'No'.translate(this.props.lang)}</Button>

                            </ModalFooter>
                        </Modal>
                        : null
                }
                {this.state.succesMessage ?
                    <InfoModal
                        isOpen={this.state.succesMessage}
                        onClose={(item) => {
                            this.setState({
                                succesMessage: item
                            })
                        }}
                        // toggle={() => this.setState({ succesMessage: false })}
                        header={'Info'.translate(this.props.lang)}
                        info={this.state.succesMessage.translate(this.props.lang)}
                        buttons={[
                            <Button color='primary' style={{ margin: 10 }} onClick={() => this.setState({ succesMessage: null })}>{'Close'.translate(this.props.lang)}</Button>,

                        ]}
                    />
                    :
                    null
                }
                {this.state.saveModal ?
                    <InfoModal
                        isOpen={this.state.saveModal}
                        onClose={(item) => {
                            this.setState({
                                saveModal: item
                            })
                        }}
                        // toggle={() => this.setState({ saveModal: false })}
                        header={'Warning'.translate(this.props.lang)}
                        info={this.state.saveModal?.twoFactorAuthenticationEnabled
                            ? 'Is the information correct? Incorrect information may lead to the wrong person accessing the account'.translate(this.props.lang)
                            : 'Is it true that consent has been obtained to open the account'.translate(this.props.lang)}
                        buttons={[
                            <button style={{ visibility: 'hidden', display: 'none' }} aria-hidden='true'></button>,
                            <Button
                                color='success'
                                style={{ margin: 10 }}
                                onClick={() => this.addPatient(this.state.saveModal)}
                            >
                                {this.state.saveModal?.twoFactorAuthenticationEnabled
                                    ? 'Confirm'.translate(this.props.lang)
                                    : 'Yes'.translate(this.props.lang)
                                }
                            </Button>,
                            <Button
                                color='danger'
                                style={{ margin: 10 }}
                                onClick={() => this.setState({
                                    saveModal: null
                                })}
                            >
                                {this.state.saveModal?.twoFactorAuthenticationEnabled
                                    ? 'Go back'.translate(this.props.lang)
                                    : 'No'.translate(this.props.lang)
                                }
                            </Button>,
                            <button style={{ visibility: 'hidden', display: 'none' }} aria-hidden='true'></button>
                        ]}
                    />
                    :
                    null
                }

                {
                    this.state.changesNotSaved ?
                        <Modal isOpen={this.state.changesNotSaved} centered>
                            <ModalBody className="delete-modal">
                                <p className="message">{'Some changes are not saved.'.translate(this.props.lang)}</p>
                            </ModalBody>
                            <ModalFooter className='buttons-right-blue'>
                                <Button color="primary" style={{ margin: 10, minWidth: 100 }} onClick={() => {

                                    this.setState({
                                        changesNotSaved: false
                                    }, () => {
                                        this.updateEvent(this.state.updateEvent)
                                    })


                                }}>{'Save and exit'.translate(this.props.lang)}</Button>


                                <Button color="primary" style={{ margin: 10, minWidth: 100 }} onClick={() => {
                                    this.setState({
                                        updateEvent: null,
                                        prevUpdateEvent: null,
                                        changesNotSaved: false,
                                        doctorsList: [],
                                    })
                                }}>{'Exit without saving'.translate(this.props.lang)}</Button>

                            </ModalFooter>
                        </Modal>
                        :
                        null
                }
                {
                    this.state.error ?

                        <ErrorModal lang={this.props.lang}
                            isOpen={this.state.error}
                            toggle={() => this.setState({ error: null })}

                        >
                            {this.state.error.translate(this.props.lang)}
                        </ErrorModal>

                        :
                        null
                }
            </div >
        );
    }
}
const selector = formValueSelector('modalForm');

export default connect(state => {
    return {
        socialSecurityNumber: selector(state, 'socialSecurityNumber'),
        socialSecurityNumberParent: selector(state, 'socialSecurityNumberParent'),
        dateOfBirth: selector(state, 'dateOfBirth.date'),
        dateOfBirthParent: selector(state, 'dateOfBirthParent.date'),
        dateOfBirthParent2: selector(state, 'dateOfBirthParent2.date'),

        socialSecurityNumberParent2: selector(state, 'socialSecurityNumberParent2'),
        email2: selector(state, 'email2'),
        phone2: selector(state, 'phone2'),
        language2: selector(state, 'language2'),
        nameParent2: selector(state, 'nameParent2'),

        twoFactorAuthenticationEnabled: selector(state, 'twoFactorAuthenticationEnabled')

    }


}, {
    changeName: value => change('modalForm', 'userData.name', value),
    changeSex: value => change('modalForm', 'userData.sex', value),
    changeSocialSecurityNumberParent: value => change('modalForm', 'socialSecurityNumberParent', value),

    changeEmail: value => change("modalForm", "email", value),
    changeNameParent: value => change("modalForm", "nameParent", value),
    changeLanguage: value => change("modalForm", "language", value),
    changePhone: value => change("modalForm", "phone", value),
    changeSexParent: value => change("modalForm", "sexParent", value),

    changeEmail2: value => change("modalForm", "email2", value),
    changeNameParent2: value => change("modalForm", "nameParent2", value),
    changeLanguage2: value => change("modalForm", "language2", value),
    changePhone2: value => change("modalForm", "phone2", value),
    changeSexParent2: value => change("modalForm", "sexParent2", value),

    changeDateOfBirth: value => change("modalForm", "dateOfBirth.date", value),
    changeDateOfBirthParent: value => change("modalForm", "dateOfBirthParent.date", value),
    changeDateOfBirthParent2: value => change("modalForm", "dateOfBirthParent2.date", value)


})(Page(Wiki));
