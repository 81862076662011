import React, { Component } from 'react'
import { API_ENDPOINT } from '../../../constants';

import Isvg from 'react-inlinesvg'

import openLinkIcon from '../../../assets/svg/open-link.svg';
import documentIcon from '../../../assets/svg/document.svg';
import trashIcon from '../../../assets/svg/garbage.svg';
import editIcon from '../../../assets/svg/edit.svg';
import downloadIcon from '../../../assets/svg/download.svg';
import eyeIcon from '../../../assets/svg/eye.svg';

import axios from 'axios';

import { Player } from '@lottiefiles/react-lottie-player';
import ErrorModal from '../../../components/errorModal';
import { Button, Container, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

import Switch from '../../../components/forms/fields/switch';

const OPEN_FILES = true;

export const getAvailableDocumentTypesForPatientDocuments = (
    getExtensions = false,
    getUpperCaseToo = false
) => {
    /* .doc .docx .txt .pdf .xls .xlsx .png .jpg .jpeg .json .mp4 .avi .webm .mov */
    if (getExtensions) {
        const arr = [
            '.doc',
            '.docx',
            '.txt',
            '.pdf',
            '.xls',
            '.xlsx',
            '.png',
            '.jpg',
            '.jpeg',
            '.json',
            '.mp4',
            '.avi',
            '.webm',
            '.mov'
        ];
        if (getUpperCaseToo) {
            [...arr].forEach(ext => arr.push(ext.toUpperCase()));
        };

        return arr;
    }

    return [
        'application/msword',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'text/plain',
        'application/pdf',
        'application/vnd.ms-excel',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'image/png',
        'image/jpeg',
        'image/jpg',
        'application/json',
        'video/mp4',
        'video/x-msvideo',
        'video/webm',
        'video/quicktime'
    ]
}

export default class PatientDocuments extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: true,
            documents: [],
            error: null,
            fetching: false,

            modified: 'Not modified'.translate(this.props.lang),
            document: null,
            isDragging: false,

            confirmModal: null,
            openModal: null,
        }
    }

    getDocuments = async () => {
        try {
            const response = await fetch(`${API_ENDPOINT}/users/users/patient/documents/get`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
                body: JSON.stringify({
                    groupAlias: localStorage.getItem('groupAlias'),
                    groupId: this.props._groupId || null,
                    patientId: this.props.patientId || null,
                    view: this.props.view || null
                })
            });

            const data = await response.json();

            if (data.documents) {
                this.setState({
                    loading: false,
                    documents: data.documents
                });

                if (data.tsModified) {
                    const modified = this.props.getStringDateTs?.(data.tsModified, this.props.dateFormat);
                    if (modified) this.setState({ modified: modified.translate(this.props.lang) });
                }
            } else {
                this.setState({ modified: 'Not modified'.translate(this.props.lang) });
                if (data.error) return this.setState({ error: data.error, loading: false });

                this.setState({
                    loading: false
                })
            }
        } catch (err) { }
    }

    componentDidMount() {
        if (this.props.documents) {
            this.setState({
                loading: false,
                documents: this.props.documents
            })
        } else {
            this.getDocuments();
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.patientId !== this.props.patientId || prevProps._groupId !== this.props._groupId || prevProps.view !== this.props.view) {
            this.setState({
                loading: true,
                documents: [],
            }, () => this.getDocuments());
        }
    }

    /**
     * 
     * @param {FileList} files 
     */
    filterFiles = (files) => {
        try {
            if (!files || !files.length) return null;

            const maxFileSize = 50 * 1024 * 1024;

            const availableTypes = getAvailableDocumentTypesForPatientDocuments();
            const arr = Array.from(files);

            for (const file of arr) {
                if (file && file.type && availableTypes.includes(file.type) && file.size && file.size <= maxFileSize) {
                    return file;
                }
            }
        } catch (err) {
            console.log('--- filter files err: ', err);
        }

        return null;
    }

    handleDrop = async (e) => {
        try {
            e?.preventDefault?.();
            e?.stopPropagation?.();

            this.setState({ isDragging: false });

            const file = this.filterFiles(e.dataTransfer.files);
            if (!file || !this.state.document || !this.state.document.id || this.state.document.id !== 'new') return;
            this.setState(prev => ({
                ...prev,
                document: {
                    ...prev.document,
                    name: prev.document?.name?.trim?.() ? prev.document.name : file.name.split('.').slice(0, -1).join('.'),
                    isNameAutomatic: prev.document?.name?.trim?.() ? false : true,
                    file
                }
            }));
        } catch (err) {
            console.log('--- handle drop err: ', err);
        }
    }

    handleClick = async (e) => {
        try {
            e?.preventDefault?.();
            e?.stopPropagation?.();

            this.setState({ isDragging: false });

            const input = document.createElement('input');
            input.type = 'file';
            input.accept = getAvailableDocumentTypesForPatientDocuments().join(',');
            input.addEventListener('cancel', () => {
                input?.remove?.();
            });
            input.onchange = async () => {
                const file = this.filterFiles(input.files);
                if (!file || !this.state.document || !this.state.document.id || this.state.document.id !== 'new') return;
                this.setState(prev => ({
                    ...prev,
                    document: {
                        ...prev.document,
                        name: prev.document?.name?.trim?.() ? prev.document.name : file.name.split('.').slice(0, -1).join('.'),
                        isNameAutomatic: prev.document?.name?.trim?.() ? false : true,
                        file
                    }
                }));
            };
            input.click?.();
        } catch (err) {
            console.log('--- handle click err: ', err);
        }
    }

    uploadFile = async (file) => {
        try {
            if (!file || !this.props._groupId) return null;
            const formData = new FormData();
            formData.append('file', file);
            formData.append('group', this.props._groupId);
            formData.append('fileType', 'patient-documents');
            formData.append('acceptTypes', getAvailableDocumentTypesForPatientDocuments(true, true));

            const response = await axios.post(`${API_ENDPOINT}/data/upload-document`, formData, {
                headers: {
                    Accept: 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
                }
            });

            if (response && response.status && response.status === 200 && response.data) {
                const url = response.data;
                return url;
            }

            return null;
        } catch (err) {
            console.log('--- upload file err: ', err);
            return null;
        }
    }

    /**
     * 
     * @param {('add' | 'update' | 'remove')} action 
     * @param {{[key: string]: any}} [data] 
     * @returns 
     */
    addUpdateOrRemoveDocument = (
        action,
        document,
        sendData = {},
    ) => new Promise((resolve) => {
        try {
            if (!action || !['add', 'update', 'remove'].includes(action)) {
                this.setState({
                    fetching: false
                });

                return resolve(null);
            }
            const patientId = this.props.patientId;
            if (!patientId || !document) {
                this.setState({
                    fetching: false
                });

                return resolve(null);
            }

            this.setState({ fetching: true }, async () => {
                const response = await fetch(`${API_ENDPOINT}/users/users/patient/documents/${action}`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                    },
                    body: JSON.stringify({
                        groupAlias: localStorage.getItem('groupAlias'),
                        groupId: this.props._groupId || null,
                        patientId,
                        view: this.props.view || null,

                        document,
                        ...sendData
                    })
                });

                if (!response) {
                    this.setState({
                        fetching: false,
                        error: 'Something went wrong! Please refresh your browser and try again!'
                    });

                    return resolve(null);
                }

                const data = await response.json();
                if (!data || !data.documents || data.error) {
                    this.setState({
                        fetching: false,
                        error: data.error || 'Something went wrong! Please refresh your browser and try again!'
                    });

                    return resolve(null);
                }

                this.setState({
                    fetching: false,
                    document: null,
                    error: null
                });
                this.getDocuments();

                return resolve(data.documents);
            });
        } catch (err) {
            console.log('--- addUpdateOrRemoveDocument err: ', err);
            return resolve(null);
        }
    });

    handleSave = async () => {
        if (!this.state.document || !this.props.patientId) return this.setState({
            document: null,
            error: 'Something went wrong! Please refresh your browser and try again!',
            fetching: false
        });

        if (this.state.document.id === 'new') {
            if (!this.state.document.file) return;
            this.setState({ fetching: true });
            const url = await this.uploadFile(this.state.document.file);
            if (!url) return this.setState({
                error: 'Could not upload the file! Please try again!',
                fetching: false
            });

            await this.addUpdateOrRemoveDocument('add', {
                id: 'new',
                name: this.state.document.name,
                source: 'Curoflow',
                link: url,
                type: this.state.document.file.type,
                isSharedWithPatient: this.state.document.isSharedWithPatient ? true : false
            }, {
                src: 'patientSearchEnterprise'
            });
        } else {
            await this.addUpdateOrRemoveDocument('update', {
                id: this.state.document.id,
                name: this.state.document.name,
                isSharedWithPatient: this.state.document.isSharedWithPatient ? true : false
            });
        }
    }

    getLinkOrDownloadDocument = async (
        action,
        documentId,
        documentName,
    ) => {
        try {
            if (!action || !['link', 'download', 'open'].includes(action) || !documentId) return null;

            const url = `${API_ENDPOINT}/users/users/patient/documents/2-way/${action}`;
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
                },
                body: JSON.stringify({
                    view: this.props.view || null,
                    groupAlias: localStorage.getItem('groupAlias'),
                    groupId: this.props._groupId || null,
                    patientId: this.props.patientId,
                    documentId
                })
            });

            if (!response) return null;
            if (action === 'link') {
                const data = await response.json();
                if (!data || data.error || !data.link || !data.source) {
                    this.setState({
                        error: data.error || 'Something went wrong! Please refresh your browser and try again!'
                    });

                    return null;
                };

                const aTag = document.createElement('a');
                aTag.href = data.source === 'Semble' ? data.link : `${API_ENDPOINT}${data.link}`;
                aTag.target = '_blank';
                aTag.click();
                aTag.remove();
            } else if (action === 'download') {
                const blob = await response.blob();
                const href = URL.createObjectURL(blob);
                const aTag = document.createElement('a');
                aTag.href = href;

                let extension = blob.type.split('/')[1];
                if (extension === 'vnd.openxmlformats-officedocument.wordprocessingml.document') extension = 'docx';
                if (extension === 'vnd.openxmlformats-officedocument.spreadsheetml.sheet') extension = 'xlsx';
                if (extension === 'vnd.ms-excel') extension = 'xls';
                if (extension === 'msword') extension = 'doc';
                if (extension === 'plain') extension = 'txt';
                if (extension === 'quicktime') extension = 'mov';

                const fileName = documentName ? `${documentName}.${extension}` : `document.${extension}`;
                aTag.download = fileName;
                aTag.click();
                aTag.remove();
            } else if (action === 'open') {
                const data = await response.json();
                if (!data || data.error || !data.link || !data.source || !data.type) {
                    this.setState({
                        error: data.error || 'Something went wrong! Please refresh your browser and try again!'
                    });

                    return null;
                };

                const link = data.source === 'Semble' ? data.link : `${API_ENDPOINT}${data.link}`;
                const type = data.type.includes('video') ? 'video' : data.type.includes('image') ? 'image' : 'file';
                const name = data.name || 'Document';
                this.setState({
                    openModal: {
                        link,
                        type,
                        source: data.source,
                        name,
                    }
                })
            }
        } catch (err) {
            console.log('--- getLinkOrDownloadDocument err: ', err);
        }
    }

    render() {
        const document = this.state.document ? this.state.document : null;

        const humanReadibleTypes = {
            'application/msword': 'MS Word',
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'MS Word',
            'text/plain': 'Text',
            'application/pdf': 'PDF',
            'application/vnd.ms-excel': 'MS Excel',
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'MS Excel',
            'image/png': 'Image',
            'image/jpeg': 'Image',
            'image/jpg': 'Image',
            'application/json': 'JSON',
            'video/mp4': 'Video',
            'video/x-msvideo': 'Video',
            'video/webm': 'Video',
            'video/quicktime': 'Video'
        }

        const openFilesAvailableMimeTypes = ['text/plain', 'application/pdf', 'application/json', 'image/png', 'image/jpeg', 'image/jpg', 'video/mp4', 'video/x-msvideo', 'video/webm', 'video/quicktime'];
        const openFilesAvailableExtensions = ['txt', 'pdf', 'json', 'png', 'jpg', 'jpeg', 'mp4', 'avi', 'webm', 'mov'];

        let openModal;
        if (this.state.openModal && OPEN_FILES) openModal = this.state.openModal;

        if (this.state.loading) return <div className="loader-wrap" >
            <Player
                autoplay={true}
                loop={true}
                src="/lf30_editor_l5cxzdyf.json"
                style={{ height: typeof window != 'undefined' && window.innerWidth < 768 ? "128px" : "256px", width: typeof window != 'undefined' && window.innerWidth < 768 ? "128px" : "256px" }}
            ></Player>
        </div>;

        return (<>
            <div className='patient-documents-container'>
                <Container fluid>
                    {this.props.view === 'doctor' ? <h6 className="component-header daybook-header">
                        <div>
                            <span>{this.props.patient && this.props.patient.userData && this.props.patient.userData.name}</span> <br />
                            <p>{this.props.patient && this.props.patient.socialSecurityNumber}</p>
                        </div>
                        <label>{'Patient Documents updated'.translate(this.props.lang)}: {this.state.modified}</label>
                        <Button color="primary"
                            style={{ marginRight: 15 }}
                            disabled={(this.props.patient?.twoFactorAuthentication?.[this.props.selectedGroup]?.createAccount && !this.props.patient?.twoFactorAuthentication?.[this.props.selectedGroup]?.infoIsVerified)}
                            onClick={() => {
                                if (this.props.patient?.twoFactorAuthentication?.[this.props.selectedGroup]?.createAccount && !this.props.patient?.twoFactorAuthentication?.[this.props.selectedGroup]?.infoIsVerified) return;
                                this.setState({
                                    document: {
                                        id: 'new',
                                        name: '',
                                        source: 'Curoflow',
                                        link: '',
                                        file: null,
                                        isSharedWithPatient: false,
                                    }
                                });
                            }}>{"Add document".translate(this.props.lang)}</Button>
                    </h6> : null}
                    {!this.state.documents.length
                        ? this.state.loading || this.state.fetching
                            ? null
                            : <p style={{ textAlign: 'center' }}>{'No documents for this patient'.translate(this.props.lang)}.</p>
                        : <table className='patient-documents-table'>
                            <thead>
                                <tr>
                                    {this.props.view === 'doctor' ? <th>{'Shared with Patient'.translate(this.props.lang)}</th> : null}
                                    <th>{'Source'.translate(this.props.lang)}</th>
                                    <th>{'Name'.translate(this.props.lang)}</th>
                                    <th>{'Type'.translate(this.props.lang)}</th>
                                    <th>{'Actions'.translate(this.props.lang)}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.documents.map((item, idx) => {
                                    if (!item || !item.link) return null;
                                    const cleanLink = item.link.split('?')[0];
                                    const extension = ('.' + cleanLink).split('.').pop().toLocaleLowerCase();

                                    const openFile = OPEN_FILES && (openFilesAvailableMimeTypes.includes(item.type) || openFilesAvailableExtensions.includes(extension));

                                    return <tr key={item.id ?? item.link ?? idx}>
                                        {this.props.view === 'doctor' ? <td>
                                            <Switch
                                                value={item.isSharedWithPatient ? true : false}
                                                onChange={e => {
                                                    const isSharedWithPatient = e.target.checked;
                                                    this.addUpdateOrRemoveDocument('update', {
                                                        id: item.id,
                                                        isSharedWithPatient
                                                    });
                                                }}
                                            />
                                        </td> : null}
                                        <td>{item.source}</td>
                                        <td>{item.name}</td>
                                        <td>{humanReadibleTypes[item.type] ?? 'N/A'}</td>
                                        <td>
                                            {openFile ? <div className='patient-document-link-container'>
                                                <button
                                                    aria-label='View'
                                                    onClick={() => {
                                                        this.getLinkOrDownloadDocument('open', item.id);
                                                    }}
                                                >
                                                    <Isvg src={eyeIcon} />
                                                </button>
                                                <p>{'View'.translate(this.props.lang)}</p>
                                            </div> : null}
                                            {this.props.view === 'doctor' && item.source === 'Curoflow' ? <div className='patient-document-link-container'>
                                                <button
                                                    aria-label='Edit'
                                                    onClick={() => this.setState({ document: item })}
                                                    className='patient-document-link-container-edit-button'
                                                >
                                                    <Isvg src={editIcon} />
                                                </button>
                                                <p>{'Edit'.translate(this.props.lang)}</p>
                                            </div> : null}
                                            <div className='patient-document-link-container'>
                                                <button
                                                    aria-label='Download'
                                                    onClick={() => {
                                                        this.setState({
                                                            confirmModal: {
                                                                message: "The information you're about to download is confidential. Are you sure you want to proceed?",
                                                                confirmText: 'Download',
                                                                cancelText: 'Cancel',
                                                                onConfirm: async (data) => {
                                                                    this.getLinkOrDownloadDocument('download', data.id, data.name);
                                                                },
                                                                data: { id: item.id, name: item.name }
                                                            }
                                                        })
                                                    }}
                                                >
                                                    <Isvg src={downloadIcon} />
                                                </button>
                                                <p>{'Download'.translate(this.props.lang)}</p>
                                            </div>
                                            {/* <div className='patient-document-link-container'>
                                                <button
                                                    aria-label='Open'
                                                    onClick={() => {
                                                        this.getLinkOrDownloadDocument('link', item.id);
                                                    }}
                                                >
                                                    <Isvg src={openLinkIcon} />
                                                </button>
                                                <p>{'Open'.translate(this.props.lang)}</p>
                                            </div> */}
                                            {this.props.view === 'doctor' && item.source === 'Curoflow' ? <div className='patient-document-link-container'>
                                                <button
                                                    aria-label='Delete'
                                                    onClick={() => {
                                                        this.setState({
                                                            confirmModal: {
                                                                message: 'Are you sure you want to delete this document? This action cannot be undone.',
                                                                confirmText: 'Delete',
                                                                confirmColor: 'danger',
                                                                cancelText: 'Cancel',
                                                                onConfirm: async (data) => {
                                                                    const res = await this.addUpdateOrRemoveDocument('remove', {
                                                                        id: data.id
                                                                    });
                                                                    if (res) this.setState({ document: null });
                                                                },
                                                                data: { id: item.id }
                                                            }
                                                        })
                                                    }}
                                                    className='patient-document-link-container-delete-button'
                                                >
                                                    <Isvg src={trashIcon} />
                                                </button>
                                                <p>{'Delete'.translate(this.props.lang)}</p>
                                            </div> : null}
                                        </td>
                                    </tr>
                                })}
                            </tbody>
                        </table>}
                </Container>

                {document?.id ? <Modal
                    isOpen={document?.id ? true : false}
                    centered
                    size='md'
                    backdrop
                    toggle={() => this.setState({ document: null, isDragging: false })}
                >
                    <ModalHeader>
                        {document?.id === 'new' ? 'Add document'.translate(this.props.lang) : 'Update document data'.translate(this.props.lang)}
                    </ModalHeader>
                    <ModalBody>
                        <FormGroup>
                            <Label>{'Name'.translate(this.props.lang)}</Label>
                            <Input
                                type='text'
                                value={document.name || ''}
                                onChange={e => {
                                    const name = e.target.value;
                                    this.setState(prev => ({
                                        ...prev,
                                        document: {
                                            ...prev.document,
                                            name,
                                            isNameAutomatic: false
                                        }
                                    }))
                                }}
                            />
                        </FormGroup>
                        {document.id !== 'new' ? <FormGroup>
                            <Label>{'Type'.translate(this.props.lang)}</Label>
                            <Input
                                type='text'
                                value={document.type || 'file'}
                                disabled
                            />
                        </FormGroup> : null}
                        {document.id === 'new' ? <FormGroup className='patient-document-upload-dropzone'>
                            <Label>{'File'}</Label>
                            <div
                                role='button'
                                tabIndex={0}
                                onDragEnter={(e) => {
                                    e?.preventDefault?.();
                                    e?.stopPropagation?.();
                                    this.setState({ isDragging: true });
                                }}
                                onDragOver={(e) => {
                                    e?.preventDefault?.();
                                    e?.stopPropagation?.();
                                }}
                                onDragLeave={(e) => {
                                    e?.preventDefault?.();
                                    e?.stopPropagation?.();
                                    this.setState({ isDragging: false });
                                }}
                                onDrop={this.handleDrop}
                                onClick={this.handleClick}
                            >
                                {this.state.isDragging || !document.file ? <div className='patient-document-upload-dropzone-placeholder'>
                                    <p>{this.state.isDragging ? 'Drop files here'.translate(this.props.lang) : 'Drag and drop file here, or click to select file'.translate(this.props.lang)}</p>
                                </div> : <div className='patient-document-upload-dropzone-file'>
                                    <div>
                                        <Isvg src={documentIcon} />
                                        <div>
                                            <p>
                                                <strong>{'Name'.translate(this.props.lang)}:</strong>
                                                {' '}
                                                <span>{document.file.name}</span>
                                            </p>
                                            <p>
                                                <strong>{'Size'.translate(this.props.lang)}:</strong>
                                                {' '}
                                                <span>{formatSize(document.file.size)}</span>
                                            </p>
                                            <p>
                                                <strong>{'Type'.translate(this.props.lang)}:</strong>
                                                {' '}
                                                <span>{document.file.type}</span>
                                            </p>
                                        </div>
                                    </div>

                                    <div>
                                        <button
                                            aria-label='Delete'
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                this.setState(prev => ({
                                                    ...prev,
                                                    document: {
                                                        ...prev.document,
                                                        name: prev.document?.isNameAutomatic ? '' : prev.document.name,
                                                        isNameAutomatic: false,
                                                        file: null
                                                    }
                                                }))
                                            }}
                                            type='button'
                                        >
                                            <Isvg src={trashIcon} />
                                        </button>
                                    </div>
                                </div>}
                            </div>
                            <p>*{
                                `Max file size is 50MB. Allowed file types are: ${getAvailableDocumentTypesForPatientDocuments(true).join(', ')}.`
                                    .translate(this.props.lang)}</p>
                        </FormGroup> : null}
                        <FormGroup>
                            <Switch
                                value={document.isSharedWithPatient ? true : false}
                                onChange={e => {
                                    const isSharedWithPatient = e.target.checked;
                                    this.setState(prev => ({
                                        ...prev,
                                        document: {
                                            ...prev.document,
                                            isSharedWithPatient
                                        }
                                    }))
                                }}
                                label={'Shared with Patient'.translate(this.props.lang)}
                            />
                        </FormGroup>
                    </ModalBody>
                    <ModalFooter style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-end'
                    }}>
                        <button style={{ display: 'none', visibility: 'hidden' }} hidden aria-hidden></button>

                        <Button
                            style={{ minWidth: 100 }}
                            color="primary"
                            onClick={this.handleSave}
                        >
                            {'Save'.translate(this.props.lang)}
                        </Button>

                        {document && document.id && document.id !== 'new' && document.source === 'Curoflow' ? <Button
                            style={{ minWidth: 100 }}
                            color="danger"
                            onClick={() => {
                                this.setState({
                                    confirmModal: {
                                        message: 'Are you sure you want to delete this document? This action cannot be undone.',
                                        confirmText: 'Delete',
                                        confirmColor: 'danger',
                                        cancelText: 'Cancel',
                                        onConfirm: async (data) => {
                                            const res = await this.addUpdateOrRemoveDocument('remove', {
                                                id: data.id
                                            });
                                            if (res) this.setState({ document: null });
                                        },
                                        data: { id: document.id }
                                    }
                                })
                            }}
                        >
                            {'Delete'.translate(this.props.lang)}
                        </Button> : null}

                        <Button
                            style={{ minWidth: 100 }}
                            color="primary"
                            onClick={() => this.setState({ document: null, isDragging: false })}
                        >
                            {'Close'.translate(this.props.lang)}
                        </Button>

                        <button style={{ display: 'none', visibility: 'hidden' }} hidden aria-hidden></button>
                    </ModalFooter>
                </Modal> : null}

                {this.state.confirmModal ? <Modal
                    isOpen={this.state.confirmModal ? true : false}
                    centered
                    size='md'
                    backdrop
                    toggle={() => this.setState({ confirmModal: null })}
                >
                    <ModalBody>
                        <div className='message' style={{ paddingTop: 30, paddingLeft: 20, paddingRight: 20 }}>
                            <p style={{
                                textAlign: 'center',
                                maxWidth: '80%',
                                margin: '0 auto'
                            }}>{(this.state.confirmModal?.message || 'Are you sure you want to proceed').translate(this.props.lang)}</p>
                        </div>
                    </ModalBody>
                    <ModalFooter style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}>
                        <button style={{ display: 'none', visibility: 'hidden' }} hidden aria-hidden></button>

                        <Button
                            color={this.state.confirmModal?.confirmColor || 'primary'}
                            onClick={async () => {
                                await this.state.confirmModal?.onConfirm?.(this.state.confirmModal?.data);
                                this.setState({ confirmModal: null });
                            }}
                        >
                            {(this.state.confirmModal?.confirmText || 'Confirm').translate(this.props.lang)}
                        </Button>
                        <Button
                            color='primary'
                            onClick={async () => {
                                await this.state.confirmModal?.onCancel?.(this.state.confirmModal?.data);
                                this.setState({ confirmModal: null });
                            }}
                        >
                            {(this.state.confirmModal?.cancelText || 'Cancel').translate(this.props.lang)}
                        </Button>

                        <button style={{ display: 'none', visibility: 'hidden' }} hidden aria-hidden></button>
                    </ModalFooter>
                </Modal> : null}

                {this.state.error ? <ErrorModal
                    isOpen={this.state.error}
                    toggle={() => this.setState({ error: null })}
                    lang={this.props.lang}
                >
                    {this.state.error.translate(this.props.lang)}
                </ErrorModal> : null}

                {this.state.fetching ? <div className="loader-wrap" >
                    <Player
                        autoplay={true}
                        loop={true}
                        src="/lf30_editor_l5cxzdyf.json"
                        style={{ height: typeof window != 'undefined' && window.innerWidth < 768 ? "128px" : "256px", width: typeof window != 'undefined' && window.innerWidth < 768 ? "128px" : "256px" }}
                    ></Player>
                </div> : null}
            </div>

            {openModal ? <div className='patient-documents-overlay-container'>
                {openModal.type === 'image' ? (
                    <img
                        src={openModal.link}
                        alt='image'
                        onLoad={(e) => {
                            const isVertical = e.target.naturalHeight > e.target.naturalWidth;
                            if (isVertical) this.setState(prev => ({
                                ...prev,
                                openModal: {
                                    ...openModal,
                                    isVertical: true,
                                }
                            }))
                        }}
                        className={openModal.isVertical ? 'vertical' : ''}
                    />
                ) : openModal.type === 'video' ? (
                    <video
                        src={openModal.link}
                        controls
                        crossOrigin='anonymous'
                        onLoad={(e) => {
                            const isVertical = e.target.videoHeight > e.target.videoWidth;
                            if (isVertical) this.setState(prev => ({
                                ...prev,
                                openModal: {
                                    ...openModal,
                                    isVertical: true,
                                }
                            }))
                        }}
                        className={openModal.isVertical ? 'vertical' : ''}
                    />
                ) : (
                    <iframe
                        src={openModal.link}
                        title={openModal.name || 'Document'}
                    />
                )}

                <button
                    type='button'
                    onClick={() => {
                        this.setState({ openModal: null });
                    }}
                >
                    &times;
                </button>
            </div> : null}
        </>
        )
    }
}

function formatSize(size) {
    if (size < 1024) return size + ' B';
    if (size < 1024 * 1024) return (size / 1024).toFixed(2) + ' KB';
    return (size / 1024 / 1024).toFixed(2) + ' MB';
}