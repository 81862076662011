import React, { Component } from 'react';
import Page from '../../containers/page';

import { Link } from 'react-router-dom';

import {
    Container,
    Row,
    Col,
    Button,
    FormGroup,
    Label,
    Input,
    UncontrolledAlert,
    Modal, ModalHeader, ModalBody, ModalFooter, Form
} from 'reactstrap';


import Isvg from 'react-inlinesvg';

import InfoModal from '../../components/infoModal';
import ErrorModal from '../../components/errorModal';
import DeleteModal from '../../components/deleteModal';
import { getSearchParams, generateSearchLink, generateSearchLinkMultiple } from '../../helpers/linkHelper';
import { API_ENDPOINT } from '../../constants';
import { formValueSelector, change } from 'redux-form';  // ES6
import { connect } from 'react-redux';

import { v4 as uuidv4 } from 'uuid';
import HTML from '../../components/forms/fields/html';
import moment from 'moment';

import ReferralChatHelper from '../../components/referralChatHelper';

import info from '../../assets/svg/info.svg';

import PaysonSvg from '../../assets/svg/payson.svg';
import SwishSvg from '../../assets/svg/swish.svg';
import Card from '../../assets/svg/card.svg';
import backIcon from '../../assets/svg/back-icon.svg'


import xIcon from '../../assets/svg/x.svg';

import Payson from '../../components/payson';
import Swish from '../swish';

// import CalendarView from '../referral/calendarViews/patientCalendarChooseTime'
import CalendarView from '../referral/calendarViews/webBookingCalendar_v3'

import CustomSelectWithSearch from '../../components/forms/fields/customSelectWithSearch';

import PayNowModal from '../../components/payNowModal';
import { Player, Controls } from "@lottiefiles/react-lottie-player";

import AnswerFormComponent from './answerFormComponent';

import { Waypoint } from 'react-waypoint';
import Stripe from '../../components/stripe';
import { calculateAgeFromSSN } from '../../components/forms/fields/socialSecurityNumber';
import PatientMandatoryDataModal from '../../components/patientMandatoryDataModal';

function parseJSON(response) {
    return response.json().then(json => {
        return {
            result: json,
            status: response.status
        }
    })
}


class WebBookingReferral extends Component {
    constructor(props) {
        super(props);

        this.searchResultsWraps = {};

        this.player = React.createRef();
        this.player2 = React.createRef();
        this.state = {
            ...props.initialData,
            articles: [],
            sections: [],
            answers: {},
            patientData: {},
            recipientData: {},
            referralSections: { header: '', footer: '', body: '' },
            selectClinicModal: false,
            version: 0,
            referrals: [],
            referralType: 1,
            regions: [],
            createdReferral: false,
            checkOnlinePayment: false,
            onlinePaymentMethod: null,
            onlinePaymentFreeKort: null,
            ageError: null,
            choosenPayment: null,
            forChild: false,
            exceptionExist: false,
            isDropIn: false,
            age: 25,
            selectedReferral: null,
            successLink: null,
            availableProfessions: [],
            selectReasonPage: 0,
            searchedReferrals: [],
            page: 0,
            checkedSavedForms: false,
            calendarBackOption: false,
            // pingTimeStop: false
        };

        this.sectionConditions = [true];
    }

    showPlayer = () => {
        this.setState({
            showPlayer: true,
        });
    };

    hidePlayer = () => {
        this.setState({
            showPlayer: false,
        });
    };
    stopAnimation = () => {
        this.player.current.play();
    };

    /**
    * Get all personal questionary for selected clinic group
    * @author   Milan Stanojevic
    */
    get = () => {
        this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));

        for (let i = 0; i < this.props.loadData.length; i++) {
            this.props.loadData[i](window.fetch, this.props.lang, this.props[0].match, this.state.useState ? this.state.params : getSearchParams(this.props[0].location.search, { entries: 10, page: 0, alias: localStorage.getItem('groupAlias') })).then((data) => {
                this.setState({
                    ...data,
                    loading: null
                }, () => {
                    this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
                })
            })
        }

        for (let i = 0; i < this.props.loadDataWithQuery.length; i++) {
            this.props.loadDataWithQuery[i](window.fetch, this.props.lang, this.props[0].match, this.state.useState ? this.state.params : getSearchParams(this.props[0].location.search, { entries: 10, page: 0, alias: localStorage.getItem('groupAlias'), group: this.state.groupId, clinic: this.state.clinic })).then((data) => {
                this.setState({
                    ...data,
                    loading: null
                }, () => {
                    this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));

                })
            })

        }
        fetch(API_ENDPOINT + '/data/without-token/professions/all/' + this.props[0].match.params.alias, {
            method: 'POST',
            headers: {
                'content-type': 'application/json'
            },
            body: JSON.stringify({
                isDropIn: this.state.isDropIn,
                uid: this.props.uData && this.props.uData._id ? this.props.uData._id : null
            })
        }).then(res => res.json()).then((result) => {
            if (result) {
                let availableProfessions = [];
                for (let i = 0; i < result.length; i++) {
                    availableProfessions.push({
                        name: this.props.lang == 'en' ? result[i].professionEn : result[i].professionSv,
                        value: result[i]._id
                    })
                }

                this.setState({ availableProfessions }, () => {
                    // this.getPersonalQuestionaries()
                    if (!this.state.calendarBackOption && this.props[0].history.location.state && this.props[0].history.location.state.selectedReferral && this.props[0].history.location.state.referral && this.props[0].history.location.state.clinicGroup) {
                        this.setState({
                            selectedReferral: this.props[0].history.location.state.selectedReferral,
                            referral: this.props[0].history.location.state.referral,
                            clinicGroup: this.props[0].history.location.state.clinicGroup
                        }, () => this.openCalendar())
                    } else {
                        // if (typeof window !== 'undefined' && localStorage.tempSelectedReferral && localStorage.tempReferral && localStorage.tempClinicGroup) {
                        //     this.setState({
                        //         selectedReferral: JSON.parse(localStorage.tempSelectedReferral),
                        //         referral: JSON.parse(localStorage.tempReferral),
                        //         clinicGroup: JSON.parse(localStorage.tempClinicGroup)
                        //     }, () => {
                        //         // if (typeof window !== 'undefined' && !localStorage.bankIdStarted) {
                        //         //     localStorage.removeItem('tempSelectedReferral')
                        //         //     localStorage.removeItem('tempReferral')
                        //         //     localStorage.removeItem('tempClinicGroup')
                        //         // }
                        //         this.openCalendar()
                        //     })
                        // } else {
                        this.getPersonalQuestionaries()
                        // }

                    }
                })

            }



        })

        fetch(API_ENDPOINT + '/landing/page', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ alias: this.props?.[0]?.match?.params?.alias })
        }).then((res) => {
            if (res) {
                res.json().then(response => {
                    if (response && !response.error) {
                        this.setState({ data: response })
                    }
                })
            }
        })

        // fetch(API_ENDPOINT + '/data/personal-questionary/get-all/' + this.props[0].match.params.alias, {
        //     method: 'GET',
        //     headers: {
        //         // 'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
        //         'content-type': 'application/json'
        //     },
        // }).then(res => res.json()).then((result) => {

        //     // if (result && result.clinicGroup) {
        //     //     this.setState({
        //     //         wayOfScheduling: result.clinicGroup.wayOfScheduling,
        //     //     })
        //     // }

        // })

        // CALENDAR


    }


    componentDidMount() {
        if (this.props.uData && this.state.data) {
            this.checkUserAuth();
        }
        if (typeof window !== 'undefined') { window.scrollTo(0, 0); }
        this.props.abortAction()
        if (!this.props.groupSettingsModal) {
            this.props.groupSettingsChanged()
        }
        this.get();

        let params = getSearchParams(this.props[0].location.search);

        this.props.verifyUser(null, null, true)

        if (typeof window != 'undefined' && !localStorage.groupAlias && this.props[0].match.params.alias) {
            localStorage.groupAlias = this.props[0].match.params.alias;
        }



    }
    checkLinkToForm = () => {
        if (this.props[0].location?.state?.formId) {
            let formId = this.props[0].location.state.formId;
            if (this.state.referrals?.filter(item => item._id === formId).length) {
                let refObj = this.state.referrals?.filter(item => item._id === formId)[0];
                if (refObj?._id) {
                    this.setState({ selectedReferral: formId, referral: refObj }, () => {
                        this.openCalendar()
                    })
                }
            }
        }
    }
    setOnlinePaymentMethod = async (method) => {
        this.setState({ onlinePaymentMethod: method, goToFinish: false, choosenPayment: null, goToFinish: false, showHaveCardPrice: false });
        if (method == 1) {
            this.setState({ goToFinish: false, ageError: null })
        }
        if (method != 1 && method != 7) {
            this.setState({ skipOnlinePaymentFreeKort: false })
            let years = -1;
            let checkYears;
            if (this.props.calculateAgeFromDate) {
                checkYears = await this.props.calculateAgeFromDate(this.state.patientData.dateOfBirth)
            }
            if (this.state.patientData.dateOfBirth && checkYears) {
                years = checkYears;
            } else if (this.state.patientData.socialSecurityNumber) {
                let socialSecurityNumber = this.state.patientData.socialSecurityNumber;
                years = calculateAgeFromSSN(socialSecurityNumber, this.props.country)
            }
            if (method == 2 && years > 23) {
                this.setState({ ageError: 'You do not meet the age requirement', goToFinish: false, choosePayment: false })
            } else if (method == 3 && years > 20) {
                this.setState({ ageError: 'You do not meet the age requirement', goToFinish: false, choosePayment: false })
            } else if ((method == 4 || method == 6) && years > 18) {
                this.setState({ ageError: 'You do not meet the age requirement', goToFinish: false, choosePayment: false })
            } else if (method == 5 && years < 85) {
                this.setState({ ageError: 'You do not meet the age requirement', goToFinish: false, choosePayment: false })
            } else {
                this.setState({
                    ageError: null,
                    // goToFinish: true
                    choosePayment: true,
                    goToFinish: true
                })
            }

        }
        if (method == 7) {
            this.setState({ skipOnlinePaymentFreeKort: false, /*goToFinish: true,*/ ageError: null, choosePayment: true })
        }


    }


    componentDidUpdate(prevProps, prevState) {
        if (prevProps?.uData?._id !== this.props.uData?._id || prevState?.data?._id !== this.state.data?._id || prevProps?.[0]?.match?.params?.alias !== this.props?.[0]?.match?.params?.alias) {
            this.checkUserAuth();
        }
        if (prevState.articles.length != this.state.articles.length && this.state.articles.length && !this.state.article) {
            let params = getSearchParams(this.props[0].location.search);
            if (params.article && params.article != 'None') {
                for (let i = 0; i < this.state.articles.length; i++) {
                    if (this.state.articles[i]._id == params.article) {
                        this.setState({
                            article: this.state.articles[i]._id,
                            articleName: this.state.articles[i].title,
                            haveQuestionary: this.state.articles[i].havePreparedQuestionary,

                        })
                    }
                }
            }
        }

        if (this.state.newReferral && this.state._chatDone && prevState._chatDone != this.state._chatDone) {
            fetch(API_ENDPOINT + '/data/questionary/by-id/' + this.state.newReferral.questionaryId, {
                method: 'GET',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
            }).then(parseJSON).then(({ result, status }) => {
                if (status >= 200 && status < 300) {
                    this.setState({
                        questionary: result
                    }, () => {
                        let referral = this.state.questionary;
                        if (!referral.under18 && !referral.under20 && !referral.under23 && !referral.older85 && !referral.haveFreeCard && !referral.under18child) {
                            this.setOnlinePaymentMethod(7);
                            if (referral.freeOfCharge) {
                                this.setState({ goToFinish: true })
                            }

                        } else {
                            if (referral.onlinePayment || referral.paymentOnSite || referral.invoice) {
                                this.setState({ checkOnlinePayment: true })
                            }

                            if (this.state.forChild) {
                                if (referral.under18child) {
                                    this.setState({ exceptionExist: true })
                                    this.setOnlinePaymentMethod(6);
                                }

                            } else if (this.state.age < 18) {
                                if (referral.under18) {
                                    this.setState({ exceptionExist: true })
                                    this.setOnlinePaymentMethod(4);
                                } else if (referral.under20) {
                                    this.setState({ exceptionExist: true })
                                    this.setOnlinePaymentMethod(3);
                                } else if (referral.under23) {
                                    this.setState({ exceptionExist: true })
                                    this.setOnlinePaymentMethod(2);
                                }
                            } else if (this.state.age < 20) {
                                if (referral.under20) {
                                    this.setState({ exceptionExist: true })
                                    this.setOnlinePaymentMethod(3);
                                } else if (referral.under23) {
                                    this.setState({ exceptionExist: true })
                                    this.setOnlinePaymentMethod(2);
                                }
                            } else if (this.state.age < 23) {
                                if (referral.under23) {
                                    this.setState({ exceptionExist: true })
                                    this.setOnlinePaymentMethod(2);
                                }
                            } else if (this.state.age > 85) {
                                if (referral.older85) {
                                    this.setState({ exceptionExist: true })
                                    this.setOnlinePaymentMethod(5);
                                }
                            }
                        }
                    });
                }
            });




        }



        if (prevProps[0].location.search != this.props[0].location.search) {
            this.setState({
                loading: true
            }, () => {
                this.get();

            })
        }



        if (prevState.article != this.state.article) {
            this.getQuestionary();
        }


        if (!prevProps.uData && this.props.uData) {
            this.get()
        }


        if (!this.state.checkedSavedForms && this.state.newReferral && this.state.newReferral.questionaryId && !this.state.calendarModal) {
            this.setState({ checkedSavedForms: true }, () => {
                this.getSavedForms(this.state.newReferral.questionaryId)
            })
        }

    }


    componentWillUnmount() {

        if (this.interval) {
            clearInterval(this.interval);
        }
    }



    openCalendar = () => {
        this.setState({ calendarModal: true })


    }
    backFromCalendar = () => {
        this.setState({
            selectedReferral: null,
            referral: null,
            sections: [],
            calendarModal: null,
            selectedTime: null,
            calendarBackOption: true
        }, () => {
            // this.cleanLocalStorage()
            this.get();
        });
    }
    /**
    * Create new referral after completing personal questionary
    * @author   Milan Stanojevic
    */
    sendReferral = (callback) => {

        let onlinePayment = {};
        if (this.state.onlinePaymentMethod == 1) {
            onlinePayment.haveFreeCard = true;
            if (this.state.onlinePaymentFreeKort && this.state.onlinePaymentFreeKort[0] == '' && this.state.onlinePaymentFreeKort[1] == '' && this.state.onlinePaymentFreeKort[2] == '' && this.state.onlinePaymentFreeKort[3] == '') {
                onlinePayment.haveElectronicFreeKort = true;
            } else if (this.state.onlinePaymentFreeKort) {
                onlinePayment.freeKort = this.state.onlinePaymentFreeKort;
            }
        } else if (this.state.onlinePaymentMethod == 7) {
            onlinePayment.payWithCard = true;
        } else if (this.state.onlinePaymentMethod == 2 || this.state.onlinePaymentMethod == 3 || this.state.onlinePaymentMethod == 4 || this.state.onlinePaymentMethod == 5 || this.state.onlinePaymentMethod == 6) {
            onlinePayment.free = true;
        }



        this.setState({
            _sendingReferral: true,
        }, () => {
            let patientData = this.state.patientData;
            if (!patientData?.email && this.props.uData?.email) {
                patientData.email = this.props.uData.email;
            }
            if (!patientData?.phone && this.props.uData?.phone) {
                patientData.phone = this.props.uData.phone;
            }
            let patient = patientData;
            if (patient.phone) {
                patient.phone = patient.phone.replace(/\+/g, '00').replace(/[^\d]/g, '');
                if (patient.phone.indexOf('00') == -1) {
                    patient.phone = '00' + patient.phone;
                }
            }



            fetch(API_ENDPOINT + `/referrals/update/${this.state.createdReferral}`, {
                method: 'POST',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
                body: JSON.stringify({
                    answers: this.state.answers,
                    // article: this.state.article,
                    patient: patient,
                    reserved: false,
                    questionarySections: this.state.sections,
                    onlinePayment: onlinePayment,
                    freePayment: this.state.onlinePaymentMethod > 0 && this.state.onlinePaymentMethod < 7 ? this.state.onlinePaymentMethod : null,
                    paymentMethod: this.state.onlinePaymentMethod == 7 && this.state.choosenPayment ? this.state.choosenPayment : null,
                    questionaryData: this.state.questionary,
                    referralSections: this.state.referralSections,
                    chooseTime: true,
                    resumeForm: this.state.resumeForm,
                    // version: this.state.version,
                    referralContent: this.state.referralContent,
                    recipientClinicData: {
                        name: this.state.referral.clinic.name,
                        address1: this.state.recipientData.address1,
                        address2: this.state.recipientData.address2,
                        address3: this.state.recipientData.address3,
                    }
                })
            }).then(parseJSON).then(({ result, status }) => {

                if (status >= 200 && status < 300) {
                    this.props.registerClinicGroupLogs({
                        type: 'endCreateReferral',
                        referralType: 'Web booking',
                        referralId: this.state.createdReferral
                    })

                    this.props.verifyUser(null, null, true);
                    if (this.state.referral && this.state.referral.price == 0) {
                        if (callback) {
                            callback()
                        }
                    }
                    this.setState({
                        _sendingReferral: (this.state.referral && this.state.referral.price == 0) ? true : null,
                        signinModal: false,
                        changeSelectedTime: false

                    })




                    if (this.state.referral && this.state.referral.price == 0) {
                        if (this.props.patientCreatedReferral) this.props.patientCreatedReferral();
                        fetch(API_ENDPOINT + '/referrals/generate-invoice/' + this.state.createdReferral, {
                            method: 'POST',
                            headers: {
                                'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                                'content-type': 'application/json'
                            },
                            body: JSON.stringify({ transaction: '', alias: localStorage.getItem('groupAlias') })

                        }).then(res => res.json()).then((result) => {
                            if (this.state.onlinePaymentMethod > 0 && this.state.onlinePaymentMethod < 7) {
                                this.setState({
                                    payNowModal: null,
                                    successModal: 'Based on your age, you are entitled to a free visit.',
                                    _sendingReferral: false
                                })
                            } else {
                                let selectedTime = `${this.state.selectedTime.date ? this.props.getStringDateTs(Math.floor(new Date(this.state.selectedTime.date).getTime() / 1000), this.props.dateFormat)/*moment.unix(new Date(this.state.selectedTime.date).getTime() / 1000).format(this.props.dateFormat)*/ : null}${this.props.lang == 'se' ? ' kl. ' : ' '}${this.state.selectedTime.startTime}`;

                                let successText = `Thanks! We will remind you via notifications before your meeting that takes place`.translate(this.props.lang) + ` ${selectedTime}`;
                                if (!this.state.referral?.onlineVisit) {
                                    let arr = [];
                                    if (this.state.referral?.clinic?.name) { arr.push(this.state.referral?.clinic?.name) }
                                    if (this.state.referral?.clinic?.street) { arr.push(this.state.referral?.clinic?.street) }
                                    if (this.state.referral?.clinic?.zip) { arr.push(this.state.referral?.clinic?.zip) }
                                    if (this.state.referral?.clinic?.city) { arr.push(this.state.referral?.clinic?.city) }

                                    if (!arr.length) { successText += '.'; }
                                    else {
                                        successText += `, ${arr.join(', ')}.`;
                                    }
                                    // successText += `, ${this.state.referral?.clinic?.name}, ${this.state.referral?.clinic?.street ? this.state.referral.clinic.street : ''}, ${this.state.referral?.clinic?.zip ? this.state.referral.clinic.zip : ''} ${this.state.referral?.clinic?.city ? this.state.referral.clinic.city : ''}.`;
                                } else {
                                    successText += '.'
                                }
                                this.setState({ successModal: successText, payNowModal: null, _sendingReferral: false })

                            }
                            if (callback) {
                                callback()
                            }

                        })
                    } else {
                        if (!this.state.referral?.paymentBeforeSubmission) {
                            if (this.props.patientCreatedReferral) this.props.patientCreatedReferral();
                        }
                        this.setState({ payNowModal: this.state.referral })

                    }

                    this.props.verifyUser(null, null, true);

                } else if (result.error) {
                    this.setState({
                        error: result.error.translate(this.props.lang),
                        changeSelectedTime: true
                    })
                }
            })

        })

    }

    /**
    * Get questionary
    * @author   Milan Stanojevic
    * @Objectparam   {String} article       article id
    * @Objectparam   {String} referral      referral id
    */
    getQuestionary = () => {
        fetch(API_ENDPOINT + '/data/questionary/' + this.state.article, {
            method: 'GET',
            headers: {
                'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                'content-type': 'application/json'
            },
        }).then(parseJSON).then(({ result, status }) => {
            if (status >= 200 && status < 300) {
                this.setState(result);
                this.setState({ questionary })
            }
        });


        let params = getSearchParams(this.props[0].location.search);
        if (params.referral) {
            fetch(API_ENDPOINT + '/referrals/' + params.referral, {
                method: 'GET',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
            }).then(parseJSON).then(({ result, status }) => {
                if (status >= 200 && status < 300) {
                    result.recipientData = result.recipientClinicData;
                    result.patientData = result.patient;
                    result.version++;
                    this.setState(result);
                }
            });

        }


    }



    /**
    * Delete referral if patient close calendar before complete scheduling
    * @author   Aleksandar Dabic
    */
    checkDeleteReferral = () => {
        fetch(API_ENDPOINT + '/referrals/delete-last/' + this.state.checkRefId, {
            method: 'DELETE',
            headers: {
                'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                'content-type': 'application/json'
            },
        }).then(res => res.json()).then((result) => {
            fetch(API_ENDPOINT + '/referrals/check/que/delete', {
                method: 'POST',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
                body: JSON.stringify({
                    referralId: this.state.checkRefId,

                })
            }).then(parseJSON).then(({ result, status }) => {
                this.closeCalendar()
            })


        })
    }

    referralDeleteInterval = (id) => {
        this.interval = setInterval(() => {
            fetch(API_ENDPOINT + '/referrals/check/que', {
                method: 'POST',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
                body: JSON.stringify({
                    referralId: id,

                })
            }).then(parseJSON).then(({ result, status }) => {

            })
        }, 1000 * 10);

    }

    /**
    * Delete referral
    * @author   Stefan Pantic
    * @Objectparam    {String} referral             referral id
    */
    deleteReferral = (checkChangeLink = false) => {
        this.setState({
            loading: true
        }, () => {
            fetch(API_ENDPOINT + '/referrals/patient-schedule/delete/' + this.state.createdReferral, {
                method: 'DELETE',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
            }).then(res => res.json()).then((result) => {
                if (checkChangeLink) {
                    if (this.props.changeLinkModal) {
                        this.props[0].history.push(this.props.changeLinkModal)
                    } else {
                        this.props[0].history.push(`/${localStorage.getItem('groupAlias')}`)
                    }
                } else {
                    this.props[0].history.push(`/${localStorage.getItem('groupAlias')}`)
                }
                this.props.abortAction()

            })
        })
    }




    indexOfIndexOf(arr, str) {
        for (let i = 0; i < arr.length; i++) {
            let idx = arr[i].indexOf(str);
            if (idx !== -1) {
                return i;
            }

        }

        return -1;
    }

    updateParams = (name, value,) => {
        this.props[0].history.push(generateSearchLink(this.props[0].location.search, {}, name, value, false));
    }
    closeCalendar = () => {
        this.props.verifyUser(() => {
            if (this.props.uData && this.props.uData.userLevel == 1) {
                this.props[0].history.push(`/${localStorage.getItem('groupAlias')}`)
            } else {
                this.props[0].history.push('/my-data/personal-referrals/referrals')
            }
        }, null, true)
        this.setState({ closeWarn: false });


    }

    getReferral = () => {
        if (this.state.createdReferral) {
            fetch(API_ENDPOINT + '/data/referral/' + this.state.createdReferral, {
                method: 'GET',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
            }).then(parseJSON).then(({ result, status }) => {
                if (status >= 200 && status < 300) {
                    this.setState({
                        newReferral: result,
                    }, () => {

                        this.setPingTime()

                        fetch(API_ENDPOINT + '/users/users/get-age/' + this.props.uData._id, {
                            method: 'POST',
                            headers: {
                                'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                                'content-type': 'application/json'
                            },
                            body: JSON.stringify({})
                        }).then(res => res.json()).then((result) => {
                            this.setState({
                                age: result.age
                            })
                        })



                    }
                    );
                }
            });
        }
    }

    setPingTime = () => {
        // if(!this.state.pingTimeStop)
        fetch(API_ENDPOINT + '/referrals/check-ping-time', {
            method: 'POST',
            headers: {
                'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                'content-type': 'application/json'
            },
            body: JSON.stringify({ referral: this.state.createdReferral })
        }).then(res => res.json()).then((result) => {
            if (result && !result.stop)
                setTimeout(() => {
                    this.setPingTime()
                }, 1000 * 60 * 2)
        })
    }

    updatePaymentMethodFreeCard = (data) => {
        if (data && data.freeCardValidFrom && data.freeCardValidTo && this.state.newReferral && this.state.newReferral.patientReservedTimestamp && (new Date(data.freeCardValidFrom) > new Date(this.props.getStringDateTs(this.state.newReferral.patientReservedTimestamp, `${this.props.dateFormat} HH:mm`)/*moment.unix(this.state.newReferral.patientReservedTimestamp).format(`${`${this.props.dateFormat} HH:mm`} HH:mm`)*/) || new Date(data.freeCardValidTo) < new Date(this.props.getStringDateTs(this.state.newReferral.patientReservedTimestamp, this.props.dateFormat)/*moment.unix(this.state.newReferral.patientReservedTimestamp).format(`${this.props.dateFormat} HH:mm`)*/))) {
            this.setState({
                freeCardError: true
            })
        } else {
            if (data && data.freeCardRegion) {
                let region = this.state.regions.filter(item => item._id == data.freeCardRegion);
                if (region && region[0]) {
                    data.freeCardRegionName = region[0].shortName;
                }
            }
            data.freeCard = true;
            let onlinePaymentMethod = this.state.onlinePaymentMethod;
            onlinePaymentMethod = 1
            // let referral = this.state.referral;
            // referral.price = 0

            this.setState({
                onlinePaymentMethod,
                // referral
                freeCardData: data
            }, () => {
                this.updateReferral('freeCard')
            })

            // this.updateReferral('freeCard')
        }


    }

    updateReferral = (type) => {
        let data = {
            freePayment: this.state.onlinePaymentMethod > 0 && this.state.onlinePaymentMethod < 7 ? this.state.onlinePaymentMethod : null,
            paymentMethod: this.state.onlinePaymentMethod == 7 && this.state.choosenPayment ? this.state.choosenPayment : null,
        }
        if (this.state.freeCardData) {
            data.freeCardData = this.state.freeCardData
        }

        if (this.state.payson) {
            data.payson = true
        }
        if (this.state.swish) {
            data.swish = true
        }
        if (this.state.stripe) {
            data.stripe = true
        }
        if (data.paymentMethod == 2) {
            data.requsetedInvoice = true;
        }

        this.setState({
            loading: true
        }, () => {
            fetch(API_ENDPOINT + '/referrals/referrals/update/' + this.state.createdReferral, {
                method: 'POST',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
                body: JSON.stringify(data)
            }).then(res => res.json()).then((result) => {
                if (result) {
                    if (result.paymentMethod == 2 || result.paymentMethod == 1) {
                        if (this.state.referral?.paymentBeforeSubmission) {
                            if (this.props.patientCreatedReferral) this.props.patientCreatedReferral();
                        }
                        this.setState({
                            payNowModal: null,
                            loadingUpdatePaymentMethod: null
                        }, () => {
                            if (result.paymentMethod == 2) {
                                this.setState({
                                    successModal: "You have successfully scheduled a visit. Once the invoice is created, it will be delivered to your email address, and you will also be able to download it on the \"My payments\" page.".translate(this.props.lang),
                                    successLink: '/my-data/personal-referrals/my-payments'
                                })
                            } else if (result.paymentMethod == 1) {
                                this.setState({
                                    successModal: "You have successfully scheduled a visit. Payment must be made \"On site\" before the visit.".translate(this.props.lang),
                                    successLink: '/my-data/personal-referrals/my-payments'
                                })
                            } else {
                                this.props[0].history.push('/my-data/personal-referrals/referrals')
                            }


                        })
                    } else if (result.payson) {
                        this.setState({
                            paysonModal: true,
                            payNowModal: null,
                            loadingUpdatePaymentMethod: null
                        })
                    } else if (result.swish) {
                        this.setState({
                            swishModal: true,
                            payNowModal: null,
                            loadingUpdatePaymentMethod: null
                        })
                    } else if (result.stripe) {
                        this.setState({
                            stripeModal: false
                        }, () => {
                            this.setState({
                                // payNowModal: null,
                                stripeModal: true,
                            })
                        })

                    } else if (result.freePayment == 1) {
                        if (this.state.referral?.paymentBeforeSubmission) {
                            if (this.props.patientCreatedReferral) this.props.patientCreatedReferral();
                        }
                        fetch(API_ENDPOINT + '/referrals/generate-invoice/' + this.state.createdReferral, {
                            method: 'POST',
                            headers: {
                                'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                                'content-type': 'application/json'
                            },
                            body: JSON.stringify({ transaction: '', alias: localStorage.getItem('groupAlias') })
                        }).then(res => res.json()).then((result) => {
                            this.setState({
                                payNowModal: null,
                                loadingUpdatePaymentMethod: null
                            }, () => {
                                if (type && type == 'freeCard') {
                                    this.setState({
                                        successModal: "You have successfully scheduled a visit. Remember to take your free card with you.".translate(this.props.lang),
                                        successLink: '/my-data/personal-referrals/referrals'
                                    })
                                } else {
                                    this.props[0].history.push('/my-data/personal-referrals/referrals')
                                }

                            })
                        })

                    }

                }
                this.setState({
                    freeCardData: null
                })

            })
        })

    }
    getPersonalQuestionaries = () => {
        if (!this.state.availableProfessions || (this.state.availableProfessions && this.state.availableProfessions.length == 0)) {
            this.setState({ referrals: [] })
        } else {
            fetch(API_ENDPOINT + '/data/without-token/personal-questionary/all/' + this.props[0].match.params.alias, {
                method: 'POST',
                headers: {
                    'content-type': 'application/json'
                },
                body: JSON.stringify({
                    availableProfessions: this.state.availableProfessions,
                    // page: this.state.page,
                    filter: this.state.searchQuestionaries ? this.state.searchQuestionaries : '',
                })
            }).then(res => res.json()).then((result) => {
                // clinicGroup: clinicGroup,
                // referrals: referrals
                if (result.clinicGroup) {
                    this.setState({ clinicGroup: result.clinicGroup })
                }
                if (result.referrals) {
                    this.setState({ referrals: result.referrals }, this.checkSearchedReferrals)
                }
                if (result.total) {
                    this.setState({ total: result.total })
                } else {
                    this.setState({ total: 0 })
                }

            })

        }
    }
    checkSearchedReferrals = () => {
        this.checkLinkToForm()
        let searchedReferrals = []
        if (this.state.referrals && this.state.referrals.length) {
            for (let i = 0; i < this.state.referrals.length; i++) {
                if (this.state.searchQuestionaries) {
                    let name = this.state.referrals[i].name;
                    let anotherName = this.state.referrals[i].anotherName;
                    name = name.toLowerCase()
                    anotherName = anotherName.toLowerCase()
                    let searchQuestionaries = this.state.searchQuestionaries;
                    searchQuestionaries = searchQuestionaries.toLowerCase()
                    if ((name.indexOf(searchQuestionaries) != -1 || anotherName.indexOf(searchQuestionaries) != -1) && searchedReferrals.filter(el => el._id == this.state.referrals[i]._id).length == 0)
                        searchedReferrals.push(this.state.referrals[i])
                } else if (searchedReferrals.filter(el => el._id == this.state.referrals[i]._id).length == 0) {
                    searchedReferrals.push(this.state.referrals[i])
                }
                // if (searchedReferrals.filter(el => el._id == this.state.referrals[i]._id).length == 0) {
                //     searchedReferrals.push(this.state.referrals[i])
                // }

            }
        }
        this.setState({ searchedReferrals })
    }



    saveForm = () => {

        let obj = {
            state: {
                patientData: this.state.patientData,
                _chatDone: this.state._chatDone,
                selectedReferral: this.state.selectedReferral,
                wayOfScheduling: 'webBooking',
                referral: this.state.referral
            }
        }

        if (this.getSaveFormData) {
            let data = this.getSaveFormData();
            obj.state = { ...obj.state, ...data }
        }

        fetch(API_ENDPOINT + '/users/personal-questionaries/save/data', {
            method: 'POST',
            headers: {
                'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                'content-type': 'application/json'
            },
            body: JSON.stringify(obj)
        }).then(res => res.json()).then((result) => {
            if (result && !result.error) {
                this.setState({ savedFormModal: result._id })
            }

            if (typeof window != 'undefined') {
                let checkedGroupsSavedForms = localStorage.checkedGroupsSavedForms ? JSON.parse(localStorage.checkedGroupsSavedForms) : [];
                if (localStorage.groupAlias) {
                    checkedGroupsSavedForms.push(localStorage.groupAlias)
                    localStorage.setItem('checkedGroupsSavedForms', JSON.stringify(checkedGroupsSavedForms))
                }
            }
        })






        this.setState({ saveFormModal: false })

    }

    getSavedForms = (selectedReferral) => {
        if (typeof window == 'undefined') {
            return
        }
        if (!selectedReferral) {
            return;
        }
        let obj = {
            groupAlias: localStorage.groupAlias,
            wayOfScheduling: 'webBooking',
            selectedReferral: selectedReferral
        }




        fetch(API_ENDPOINT + '/users/personal-questionaries/saved/data', {
            method: 'POST',
            headers: {
                'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                'content-type': 'application/json'
            },
            body: JSON.stringify(obj)
        }).then(res => res.json()).then((result) => {
            if (result && result.savedForms) {
                if (result.savedForms.length) {
                    let savedForm = result.savedForms[0];
                    if (savedForm.questionaryNotExists || savedForm.questionaryNotAcitve) {
                        this.setState({ saveFormInfoModal: "You can't continue answering this form. The form has been deactivated or deleted." })
                    } else if (savedForm.questionaryModified) {
                        this.setState({ saveFormInfoModal: "You can't continue answering this form. The form content was changed in the meantime." })
                    } else {
                        this.setState({ checkSavedDropInForm: savedForm })
                    }
                }
            }
        })





    }
    submit = (data, callback) => {
        if (!this.props.uData?.phone || !this.props.uData?.email || (this.state.data?._id && !this.props.uData?.privacyPolicy?.[this.state.data._id])) {
            this.setState({ enterPatientDataModal: { data, callback } })
            return;
        } else {
            if (this.state.enterPatientDataModal)
                this.setState({ enterPatientDataModal: null })
        }
        let setObj = {};

        if (this.state.referral && this.state.age) {
            if (this.state.age < 18 && this.state.referral.under18) {
                let referal = this.state.referral;
                referal.price = 0;
                let onlinePaymentMethod = this.state.onlinePaymentMethod;
                onlinePaymentMethod = 4
                setObj.referal = referal;
                setObj.onlinePaymentMethod = onlinePaymentMethod;
                // this.setState({
                //     referal,
                //     onlinePaymentMethod
                // })
            } else if (this.state.age < 20 && this.state.referral.under20) {
                let referal = this.state.referral;
                referal.price = 0;
                let onlinePaymentMethod = this.state.onlinePaymentMethod;
                onlinePaymentMethod = 3;
                setObj.referal = referal;
                setObj.onlinePaymentMethod = onlinePaymentMethod;
                // this.setState({
                //     referal,
                //     onlinePaymentMethod
                // })
            } else if (this.state.age < 23 && this.state.referral.under23) {
                let referal = this.state.referral;
                referal.price = 0;
                let onlinePaymentMethod = this.state.onlinePaymentMethod;
                onlinePaymentMethod = 2
                // this.setState({
                //     referal,
                //     onlinePaymentMethod
                // })
                setObj.referal = referal;
                setObj.onlinePaymentMethod = onlinePaymentMethod;
            } else if (this.state.age > 85 && this.state.referral.older85) {
                let referal = this.state.referral;
                referal.price = 0;
                let onlinePaymentMethod = this.state.onlinePaymentMethod;
                onlinePaymentMethod = 5
                // this.setState({
                //     referal,
                //     onlinePaymentMethod
                // })
                setObj.referal = referal;
                setObj.onlinePaymentMethod = onlinePaymentMethod;
            } else if (this.state.forChild && this.state.referral.under18child) {
                let referal = this.state.referral;
                referal.price = 0;
                let onlinePaymentMethod = this.state.onlinePaymentMethod;
                onlinePaymentMethod = 6
                // this.setState({
                //     referal,
                //     onlinePaymentMethod
                // })
                setObj.referal = referal;
                setObj.onlinePaymentMethod = onlinePaymentMethod;
            }
        }



        if (this.state.referral.price != 0) {
            // this.setState({
            //     onlinePaymentMethod: 7
            // })
            setObj.onlinePaymentMethod = 7;
        }
        if (data?.answers) {
            setObj.answers = data.answers;
        }
        if (data?.sections) {
            setObj.sections = data.sections;
        }
        if (data?.referralContent) {
            setObj.referralContent = data.referralContent;
        }


        this.setState(setObj, () => {
            this.sendReferral(callback);

        })
        if (this.state.referral.paymentBeforeBookedTime && this.state.referral.price != 0 && !this.state.isDropIn) {
            this.setState({
                payNowOrLater: this.state.referral
            })

        } else if (this.state.referral.paymentAfterBookedTime && this.state.referral.price != 0 && !this.state.isDropIn) {
            this.setState({
                payNowOrLater: this.state.referral
            })
        }

    }
    continueSavedCase = () => {
        if (this.state.checkSavedDropInForm && this.state.checkSavedDropInForm.state) {
            let checkSavedDropInForm = this.state.checkSavedDropInForm;
            let states = JSON.parse(JSON.stringify(checkSavedDropInForm.state))
            let data = {}
            if (states.skippedQuestions) {
                data.skippedQuestions = states.skippedQuestions;
                delete states.skippedQuestions
            }
            if (states.answers) {
                data.answers = states.answers;
                delete states.answers
            }
            if (states.nextButtonClicked) {
                data.nextButtonClicked = states.nextButtonClicked;
                delete states.nextButtonClicked
            }
            if (states.questionsQueue) {
                data.questionsQueue = states.questionsQueue;
                delete states.questionsQueue
            }
            if (states.currentQuestion) {
                data.currentQuestion = states.currentQuestion;
                delete states.currentQuestion
            }
            if (states.backAnswers) {
                data.backAnswers = states.backAnswers;
                delete states.backAnswers
            }

            let resumeFormId = this.state.checkSavedDropInForm._id;
            this.setState({ ...states, checkSavedDropInForm: null, resumeForm: resumeFormId }, () => {
                if (this.setSaveFormData) {
                    this.setSaveFormData(data)
                }
            })
        }
    }

    // cleanLocalStorage = () => {
    //     if (typeof window !== 'undefined') {
    //         localStorage.removeItem('tempSelectedReferral')
    //         localStorage.removeItem('tempReferral')
    //         localStorage.removeItem('tempClinicGroup')
    //         localStorage.removeItem('tempEvent')
    //         localStorage.removeItem('tempDoctor')
    //         localStorage.removeItem('webBookingBankId')
    //         localStorage.removeItem('bankIdStarted')

    //     }
    // }

    checkUserAuth = () => {
        const user = this.props.uData;
        const groupId = this.state.data?._id;
        const loggedInWith2fa = localStorage?.getItem?.('2faLogin');

        if (loggedInWith2fa && loggedInWith2fa === 'true' && user && groupId) {
            this.setState({ loading: true }, async () => {
                const newState = {
                    loading: false,
                };

                const response = await fetch(API_ENDPOINT + '/users/two-factor-auth/check-clinic-group', {
                    method: 'POST',
                    headers: {
                        'content-type': 'application/json',
                        'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                    },
                    body: JSON.stringify({ groupId })
                });

                const result = await response?.json?.();

                if (!result) return this.setState(newState);

                if (result.error) {
                    newState.twoFactorAuthForbidden = result.error;
                    this.setState(newState);
                } else {
                    newState.twoFactorAuthForbidden = false;
                    this.setState(newState);
                }

                if (result.verify && result.verify === true) this.props.verifyUser?.();
            });
        }
    }

    navigateBackToProfile = () => {
        this.setState({ twoFactorAuthForbidden: false }, () => {
            this.props?.[0]?.history?.replace?.('/my-data/account');
        });
    }

    render() {
        let params = {};
        if (this.state.useState) {
            params = this.state.params;
        } else {
            params = getSearchParams(this.props[0].location.search, { entries: 10, page: 0 })
        }


        let subgroups = [{ name: 'None', value: null }];

        if (this.props.group) {
            for (let i = 0; i < this.state.subgroups.length; i++) {
                if (this.state.subgroups[i].groups && this.state.subgroups[i].groups.indexOf(this.props.group) !== -1) {
                    subgroups.push(this.state.subgroups[i]);
                }
            }
        }




        let dashboardClassname = this.props.autoHideSidebar ? "dashboard dashboard-auto-hide" : "dashboard";

        const currency = this.props.currency ? this.props.currency : typeof window !== 'undefined' && localStorage?.getItem("currency") ? localStorage.getItem("currency") : '';

        const skipToCalendar = !this.state.calendarBackOption && this.props[0].history.location.state && this.props[0].history.location.state.selectedReferral && this.props[0].history.location.state.referral && this.props[0].history.location.state.clinicGroup ? true : false;
        return (
            <div className={this.props.uData ? `${dashboardClassname}` : `${dashboardClassname} padding-left-0`}>
                {
                    this.state.enterPatientDataModal ?
                        <PatientMandatoryDataModal
                            {...this.props}
                            isOpen={this.state.enterPatientDataModal ? true : false}
                            callback={() => {
                                this.submit(this.state.enterPatientDataModal?.data, this.state.enterPatientDataModal?.callback)
                            }}
                        />
                        :
                        null
                }
                <Container fluid>
                    <Col lg="12">
                        {this.state.message ?
                            <UncontrolledAlert color="success">
                                {this.state.message.translate(this.props.lang)}
                            </UncontrolledAlert>
                            :
                            null
                        }

                    </Col>

                    <div className={this.state.newReferral ? 'panel referral-section referral-section-full-screen mob-version' : 'referral-section referral-section-full-screen p0-mobile mob-version'}>
                        <Row>


                            <Col lg="12" className="p0-mobile">
                                {
                                    !skipToCalendar && !this.state.newReferral && !this.state.selectedReferral && !this.state.referral && !this.state.twoFactorAuthForbidden && !this.props[0].location?.state?.formId ?
                                        <Modal isOpen={!this.state.newReferral && !this.state.selectedReferral && !this.state.referral && !this.state.twoFactorAuthForbidden && !this.props[0].location?.state?.formId} centered size="lg" className='webbooking-select-reason-wrap'>
                                            <ModalHeader>

                                                <div className='modal-title'> {'What do you need help with?'.translate(this.props.lang)}</div>
                                            </ModalHeader>
                                            <ModalBody className="delete-modal">
                                                <CustomSelectWithSearch
                                                    isInputClearable={true}
                                                    lang={this.props.lang}
                                                    items={this.state.searchedReferrals.map((item, idx) => {
                                                        return {
                                                            name: item.name,
                                                            value: item._id
                                                        }
                                                    })}
                                                    value={this.state.selectedReferral}
                                                    hideSearchField={this.state.clinicGroup?.hideFormSearchFild}
                                                    maxHeight={'50vh'}
                                                    onChange={(e) => {
                                                        // this.cleanLocalStorage()
                                                        if (e && e.value && this.state.searchedReferrals.filter(item => item._id === e.value).length) {
                                                            this.setState({ selectedReferral: e.value }, () => {
                                                                let referral = null;
                                                                for (let i = 0; i < this.state.searchedReferrals.length; i++) {
                                                                    if (this.state.searchedReferrals[i]._id == e.value) {
                                                                        referral = this.state.searchedReferrals[i];
                                                                        break;
                                                                    }
                                                                }
                                                                this.setState({
                                                                    selectedReferral: e.value,
                                                                    referral,
                                                                }, () => {
                                                                    // if (this.state.selectedReferral && this.state.referral && this.state.clinicGroup && typeof window !== 'undefined') {
                                                                    //     localStorage.setItem('tempSelectedReferral', JSON.stringify(this.state.selectedReferral))
                                                                    //     localStorage.setItem('tempReferral', JSON.stringify(this.state.referral))
                                                                    //     localStorage.setItem('tempClinicGroup', JSON.stringify(this.state.clinicGroup))
                                                                    // }
                                                                    this.openCalendar()
                                                                });
                                                            })
                                                        } else {
                                                            this.setState({ selectedReferral: null })
                                                        }


                                                    }}
                                                />
                                            </ModalBody>
                                            {/* <ModalBody>
                                                <div className='search-questionary-wrap'>
                                                    <FormGroup>
                                                        <Label>{'Search'.translate(this.props.lang)}</Label>
                                                        <Input type='text'
                                                            value={this.state.searchQuestionaries} onChange={(e) => {
                                                                let val = null;
                                                                if (e.target.value) {
                                                                    val = e.target.value
                                                                }
                                                                this.setState({ searchQuestionaries: val, page: 0 }, () => this.get())
                                                            }} />
                                                    </FormGroup>
                                                </div>
                                                <div className='list-of-questionaries-warp'>
                                                    {
                                                        this.state.searchedReferrals && this.state.searchedReferrals.map((item, idx) => {
                                                            // let page = entriesSearchReason * this.state.selectReasonPage + entriesSearchReason;
                                                            // if (idx < page)
                                                            return (
                                                                <div className='list-of-questionaries-item'>
                                                                    <div className='item-name'>
                                                                        {this.props.lang == 'en' ? item.name.replace(' (onlinebesök)', ' (online visit)') : item.name.replace(' (online visit)', ' (onlinebesök)')}
                                                                    </div>
                                                                    <Button color='primary' onClick={() => {
                                                                        let referral = null;
                                                                        for (let i = 0; i < this.state.searchedReferrals.length; i++) {
                                                                            if (this.state.searchedReferrals[i]._id == item._id) {
                                                                                referral = this.state.searchedReferrals[i];
                                                                                break;
                                                                            }
                                                                        }
                                                                        this.setState({
                                                                            selectedReferral: item._id,
                                                                            referral,
                                                                        }, () => this.openCalendar());

                                                                    }}>{'Select'.translate(this.props.lang)}</Button>
                                                                </div>
                                                            )
                                                        })

                                                    }
                                                    {
                                                        // this.state.searchedReferrals && this.state.searchedReferrals.length > (entriesSearchReason * this.state.selectReasonPage + entriesSearchReason)
                                                        this.state.total && this.state.total > this.state.searchedReferrals.length ?
                                                            <div className='show-more-btn'>
                                                                <Button color='primary' onClick={() => {
                                                                    // this.setState({ selectReasonPage: this.state.selectReasonPage + 1 })
                                                                    this.setState({ page: this.state.page + 1 }, this.get())
                                                                }}>{'Show more'.translate(this.props.lang)}</Button>
                                                            </div>
                                                            :
                                                            null
                                                    }
                                                </div>
                                            </ModalBody> */}
                                            <ModalFooter style={{ display: 'flex', justifyContent: 'center' }} className='buttons-right-blue'>
                                                <Button onClick={() => {
                                                    this.props[0].history.push(`/${this.props[0].match.params.alias}`)
                                                }}>{'Close'.translate(this.props.lang)}</Button>
                                            </ModalFooter>
                                        </Modal>
                                        :
                                        null
                                }
                            </Col>
                        </Row>
                        <Col lg='12' className="p0-mobile">

                            {
                                this.state.newReferral && !this.state._chatDone ?
                                    <div className="panel p0-mobile">
                                        {this.state.clinicGroup && this.state.age ?
                                            <ReferralChatHelper countryTwoLetter={this.props.countryTwoLetter} country={this.props.country} showDateOfBirth={this.props.showDateOfBirth} dateFormat={this.props.dateFormat} getDateOfBirthFromSSN={this.props.getDateOfBirthFromSSN} calculateAgeFromDate={this.props.calculateAgeFromDate} ring112Audio={(item) => this.props.ring112Audio(item)} lang={this.props.lang} clinicGroup={this.state.clinicGroup} chooseTime={true} age={this.state.age} uData={this.props.uData} privacyPolicy={`/${this.props[0].match.params.alias}/privacy-policy`} handleData={(data) => {
                                                if (data.socialSecurityNumber) {
                                                    this.setState({
                                                        _chatDone: true,
                                                        patientData: {
                                                            name: data.childName,
                                                            socialSecurityNumber: data.socialSecurityNumber,
                                                            parents: data.parentsData,
                                                            dateOfBirth: data.dateOfBirth
                                                        },
                                                        forChild: true

                                                    }, this.forceUpdate)
                                                } else {
                                                    this.setState({
                                                        _chatDone: true,
                                                        patientData: {
                                                            name: this.props.uData.userData.name,
                                                            socialSecurityNumber: this.props.uData.socialSecurityNumber,
                                                            phone: this.props.uData?.phone,
                                                            email: this.props.uData.email,
                                                            city: this.props.uData.city,
                                                            zip: this.props.uData.zip,
                                                            street: this.props.uData.street
                                                        }
                                                    }, this.forceUpdate);
                                                }
                                            }}></ReferralChatHelper>
                                            :
                                            null
                                        }
                                    </div>
                                    :
                                    null
                            }

                        </Col>
                        {
                            this.state.newReferral && this.state._chatDone ?
                                <Col lg="12">
                                    <AnswerFormComponent
                                        {...this.props}
                                        referral={this.state.referral}
                                        clinicGroup={this.state.clinicGroup}
                                        getSaveFormData={(getSaveFormData) => this.getSaveFormData = getSaveFormData}
                                        setSaveFormData={(setSaveFormData) => this.setSaveFormData = setSaveFormData}
                                        submitAction={(data, callback) => {
                                            this.submit(data, callback)
                                        }}
                                    />
                                </Col>
                                :
                                null
                        }
                    </div>

                    <Col lg="12">
                        <div className='fill-formular-footer-wrap'>
                            {
                                this.state.newReferral ?
                                    <Button className="close-button close-button-mobile" color="danger" outline onClick={() => {
                                        this.setState({ cancelModal: true })


                                    }}>{'Cancel'.translate(this.props.lang)}</Button>
                                    :
                                    null
                            }

                            {
                                this.state.referral && this.state.referral.saveForm && this.state._chatDone ?
                                    <Button className="save-formular-button save-formular-button-mobile" color="primary" onClick={() => this.setState({ saveFormModal: true })}>{'Continue later'.translate(this.props.lang)}</Button>
                                    :
                                    null
                            }
                        </div>
                    </Col>

                </Container>
                {this.state.checkSavedDropInForm ?
                    <InfoModal
                        isOpen={this.state.checkSavedDropInForm}
                        onClose={(item) => {
                            this.setState({
                                checkSavedDropInForm: item
                            })
                        }}
                        close={true}
                        header={"Continue answering".translate(this.props.lang)}
                        info={"Do you wish to continue answering previously saved form?".translate(this.props.lang)}
                        buttons={[
                            <Button color="primary" style={{ margin: 10 }} onClick={() => {
                                this.continueSavedCase()

                            }}>{'Yes'.translate(this.props.lang)}</Button>,
                            <Button color="primary" style={{ margin: 10 }} onClick={() => this.setState({ checkSavedDropInForm: false })}>{'No'.translate(this.props.lang)}</Button>
                        ]}
                    />
                    : null}
                {this.state.saveFormInfoModal ?
                    <InfoModal
                        isOpen={this.state.saveFormInfoModal}
                        onClose={(item) => {
                            this.setState({
                                saveFormInfoModal: item
                            })
                        }}
                        close={true}
                        header={"Info".translate(this.props.lang)}
                        info={this.state.saveFormInfoModal.translate(this.props.lang)}
                        buttons={[
                            <Button color="primary" style={{ margin: 10 }} onClick={() => this.setState({ saveFormInfoModal: false })}>{'Ok'.translate(this.props.lang)}</Button>
                        ]}
                    />
                    : null}


                {this.state.saveFormModal ?
                    <InfoModal
                        isOpen={this.state.saveFormModal}
                        onClose={(item) => {
                            this.setState({
                                saveFormModal: item
                            })
                        }}
                        close={true}
                        header={'Info'.translate(this.props.lang)}
                        info={'Are you sure you want to save the webboking case?'.translate(this.props.lang)}
                        buttons={[
                            <Button color="primary" style={{ margin: 10 }} onClick={() => this.saveForm()}>{'Yes'.translate(this.props.lang)}</Button>,
                            <Button color="primary" style={{ margin: 10 }} onClick={() => this.setState({ saveFormModal: false })}>{'No'.translate(this.props.lang)}</Button>


                        ]}
                    />
                    : null}
                {this.state.savedFormModal ?
                    <InfoModal
                        isOpen={this.state.savedFormModal}
                        onClose={(item) => {
                            this.setState({
                                savedFormModal: item
                            })
                        }}
                        close={true}
                        header={'Info'.translate(this.props.lang)}
                        info={'Your webboking case has been saved.'.translate(this.props.lang)}
                        buttons={[
                            <Button color="primary" style={{ margin: 10 }} onClick={() => {
                                let resumeForm = this.state.savedFormModal;
                                this.setState({ resumeForm: resumeForm, savedFormModal: false })
                            }}>{'Continue answering'.translate(this.props.lang)}</Button>,
                            <Button color="primary" style={{ margin: 10 }} onClick={() => {
                                this.deleteReferral()
                                // this.props[0].history.push(`/${this.props[0].match.params.alias}`)
                            }}>{'Close the form'.translate(this.props.lang)}</Button>


                        ]}
                    />
                    : null}


                {
                    this.state.cancelModal ?
                        <DeleteModal
                            lang={this.props.lang}
                            isOpen={this.state.cancelModal}
                            header={'Close case'.translate(this.props.lang)}
                            toggle={() => this.setState({ cancelModal: null })}
                            handler={() => {
                                if (this.state.createdReferral) {
                                    this.setState({ cancelCostModal: false }, () => this.deleteReferral())
                                } else {
                                    this.props[0].history.push(`/${this.props[0].match.params.alias}`)

                                }
                                // this.props.ring112Audio(false)
                            }}
                        >
                            {'Are you sure you want to close the case? It has not been completed and will not be saved.'.translate(this.props.lang)}
                        </DeleteModal>
                        :
                        null
                }
                {
                    this.state.calendarModal ?
                        <Modal isOpen={this.state.calendarModal} centered size="xxl" className="calendar-modal">
                            <ModalHeader>

                                <ModalHeader
                                    toggle={() =>
                                        this.setState({ calendarModal: !this.state.calendarModal, selectedReferral: -1 }, () => {
                                            // this.cleanLocalStorage()
                                        })
                                    }
                                    close={
                                        <button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }}
                                            onClick={() => this.setState({ calendarModal: !this.state.calendarModal, selectedReferral: -1 }, () => {
                                                // this.cleanLocalStorage()
                                                if (this.props[0].match.params.mainAlias) {
                                                    this.props[0].history.push({
                                                        pathname: `/${this.props[0].match.params.mainAlias}/${this.props[0].match.params.alias}`,

                                                    })
                                                } else {
                                                    this.props[0].history.push({
                                                        pathname: `/${this.props[0].match.params.alias}`,

                                                    })
                                                }
                                            })}><Isvg src={xIcon} /></button>}>
                                    <dev>{'Schedule calendar'.translate(this.props.lang)}</dev>
                                    <div className='web-booking-calendar-back-wrap'>
                                        <Button color='primary' className='web-booking-calendar-back-text' onClick={() => this.backFromCalendar()}>
                                            <Isvg src={backIcon} /> <div >{'Back'.translate(this.props.lang)}</div>
                                        </Button>
                                    </div>
                                </ModalHeader>

                            </ModalHeader>
                            <ModalBody>
                                <CalendarView
                                    {...this.props}
                                    uData={this.props.uData}
                                    handleDeleteCheck={(id) => {
                                        this.referralDeleteInterval(id);
                                    }}
                                    // setPingTime={() => this.setPingTime()}
                                    createdReferral={(item) => this.setState({ createdReferral: item }, () => this.getReferral())}
                                    closeCalendarModal={(item) => this.setState({ calendarModal: item }, () => {
                                        // if (this.props[0].match.params.mainAlias) {
                                        //     this.props[0].history.push({
                                        //         pathname: `/${this.props[0].match.params.mainAlias}/${this.props[0].match.params.alias}`,

                                        //     })
                                        // } else {
                                        //     this.props[0].history.push({
                                        //         pathname: `/${this.props[0].match.params.alias}`,

                                        //     })
                                        // }
                                    })}
                                    lang={this.props.lang}
                                    refId={(id) => this.setState({ checkRefId: id })}
                                    openPayWithCard={this.state.onlinePaymentMethod == 7 && this.state.choosenPayment == 3 ? true : false}
                                    price={this.state.referral.price}
                                    personalQuestionary={this.state.referral}
                                    availableProfessions={this.state.availableProfessions}
                                    selectedReferral={this.state.referral}
                                    backFromCalendar={() => this.backFromCalendar()}
                                    setSelectedTime={(selectedTime) => this.setState({ selectedTime })}
                                    displayPaymentInfoText={this.state.clinicGroup?.displayPaymentInfoText ? true : false}
                                />

                            </ModalBody>
                            {/* <ModalFooter>
                                <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end', paddingRight: '36px' }}>
                                    <Button color="primary" onClick={() => this.setState({ calendarModal: !this.state.calendarModal }, () => this.setState({ selectedReferral: -1 }))}>{'Close'.translate(this.props.lang)}</Button>
                                </div>
                            </ModalFooter> */}
                        </Modal>
                        :
                        null
                }

                {
                    this.state.changeSelectedTime ?
                        <Modal isOpen={this.state.changeSelectedTime} centered size="xxl" className="calendar-modal">
                            <ModalHeader>

                                <ModalHeader toggle={() => this.setState({ changeSelectedTime: !this.state.changeSelectedTime, selectedReferral: -1 })} close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => this.setState({ changeSelectedTime: !this.state.changeSelectedTime, selectedReferral: -1 }, () => {
                                    // this.cleanLocalStorage()
                                    if (this.props[0].match.params.mainAlias) {
                                        this.props[0].history.push({
                                            pathname: `/${this.props[0].match.params.mainAlias}/${this.props[0].match.params.alias}`,

                                        })
                                    } else {
                                        this.props[0].history.push({
                                            pathname: `/${this.props[0].match.params.alias}`,

                                        })
                                    }
                                })}><Isvg src={xIcon} /></button>}>
                                    <div>{'Schedule calendar'.translate(this.props.lang)}</div>
                                    <div className='web-booking-calendar-back-wrap'>
                                        <Button color='primary' className='web-booking-calendar-back-text' onClick={() => this.backFromCalendar()}>
                                            <Isvg src={backIcon} /> <div >{'Back'.translate(this.props.lang)}</div>
                                        </Button>
                                    </div>
                                </ModalHeader>


                            </ModalHeader>
                            <ModalBody>

                                <CalendarView
                                    {...this.props}
                                    uData={this.props.uData}
                                    handleDeleteCheck={(id) => {
                                        this.referralDeleteInterval(id);
                                    }}
                                    // setPingTime={() => this.setPingTime()}
                                    changeSelectedTime={this.state.createdReferral}
                                    createdReferral={(item) => this.setState({ createdReferral: item }, () => this.sendReferral())}
                                    closeCalendarModal={(item) => this.setState({ changeSelectedTime: item }, () => {
                                        // if (this.props[0].match.params.mainAlias) {
                                        //     this.props[0].history.push({
                                        //         pathname: `/${this.props[0].match.params.mainAlias}/${this.props[0].match.params.alias}`,

                                        //     })
                                        // } else {
                                        //     this.props[0].history.push({
                                        //         pathname: `/${this.props[0].match.params.alias}`,

                                        //     })
                                        // }
                                    })}
                                    lang={this.props.lang}
                                    refId={(id) => this.setState({ checkRefId: id })}
                                    openPayWithCard={this.state.onlinePaymentMethod == 7 && this.state.choosenPayment == 3 ? true : false}
                                    price={this.state.referral.price}
                                    personalQuestionary={this.state.referral}
                                    availableProfessions={this.state.availableProfessions}
                                    selectedReferral={this.state.referral}
                                    backFromCalendar={() => this.backFromCalendar()}
                                />

                            </ModalBody>
                            {/* <ModalFooter>
                                <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end', paddingRight: '36px' }}>
                                    <Button color="primary" onClick={() => this.setState({ calendarModal: !this.state.calendarModal }, () => this.setState({ selectedReferral: -1 }))}>{'Close'.translate(this.props.lang)}</Button>
                                </div>
                            </ModalFooter> */}
                        </Modal>
                        :
                        null
                }
                {this.state.successModal ?
                    <Modal isOpen={this.state.successModal} centered>
                        {/* <ModalHeader style={{ margin: 'auto' }}>{'Notification'.translate(this.props.lang)}</ModalHeader> */}
                        <ModalBody className="delete-modal">
                            {/* <div className="check-icon"> */}
                            {/* <Isvg src={info} /> */}
                            <p className='message'>
                                {
                                    this.state.successModal == 'Free Card payment successful.' ?
                                        'Free Card payment successful.'.translate(this.props.lang)
                                        :
                                        this.state.successModal == 'free' ?
                                            'Based on your age, you are entitled to a free visit.'.translate(this.props.lang)
                                            :
                                            this.state.successModal.translate(this.props.lang)
                                    // 'Your referral has been sent.'.translate(this.props.lang)
                                }
                            </p>
                            {/* </div> */}
                        </ModalBody>
                        <ModalFooter>
                            <Button color="primary" style={{ margin: 'auto', minWidth: 100 }} onClick={() => this.setState({ successModal: null }, () => {
                                if (this.state.successLink) {
                                    this.props[0].history.push(this.state.successLink)
                                } else {
                                    this.props[0].history.push('/my-data/personal-referrals/referrals')
                                }

                            })}>{'Ok'.translate(this.props.lang)}</Button>
                        </ModalFooter>
                    </Modal>
                    : null}
                {
                    this.state.payNowOrLater ?
                        <InfoModal
                            isOpen={this.state.payNowOrLater}
                            onClose={(item) => {
                                this.setState({
                                    payNowOrLater: item
                                })
                            }}
                            close={true}
                            // toggle={() => this.setState({ payNowOrLater: !this.state.payNowOrLater })}
                            header={'Payment'.translate(this.props.lang)}
                            info={'Do you want to pay for your visit now?'.translate(this.props.lang)}
                            buttons={[
                                <Button color="primary" style={{ margin: 10 }} onClick={() => this.setState({ payNowModal: this.state.payNowOrLater, payNowOrLater: false })}>{'Pay now'.translate(this.props.lang)}</Button>,
                                <Button color="primary" style={{ margin: 10 }}
                                    onClick={() => this.setState({
                                        successModal: "Your case has been submitted successfully. Remember to make the payment before the scheduled time.".translate(this.props.lang),
                                        payNowOrLater: false,
                                        successLink: '/my-data/personal-referrals/my-payments'
                                    })}>{'Pay later'.translate(this.props.lang)}</Button>


                            ]}
                        />
                        :
                        null
                }
                {this.state.freeCardError ?
                    <InfoModal
                        isOpen={this.state.freeCardError}
                        onClose={(item) => {
                            this.setState({
                                freeCardError: item
                            })
                        }}
                        close={true}
                        header={'Free card validation error'.translate(this.props.lang)}
                        info={'Your free card will not be valid on the day of the scheduled visit.'.translate(this.props.lang)}
                        buttons={[
                            <Button color="primary" style={{ margin: 10 }} onClick={() => this.setState({ freeCardError: false })}>{'Cancel'.translate(this.props.lang)}</Button>


                        ]}
                    />
                    : null}
                {
                    this.state.error ?

                        <ErrorModal lang={this.props.lang}
                            isOpen={this.state.error}
                            toggle={() => this.setState({ error: null }, () => {
                                if (!this.state.changeSelectedTime)
                                    this.props[0].history.push(`/${this.props[0].match.params.alias}`)
                            })}
                            closeButtonText={'Cancel'.translate(this.props.lang)}
                        >
                            {this.state.error}
                        </ErrorModal>

                        :
                        null
                }

                {
                    this.state.payNowModal ?

                        <PayNowModal lang={this.props.lang}
                            isOpen={this.state.payNowModal}
                            toggle={() => {
                                if (this.state.payNowModal && this.state.payNowModal.paymentBeforeSubmission) {
                                    this.setState({ cancelCostModal: true })
                                } else {
                                    this.setState({
                                        // successModal: "You have successfully scheduled a visit. Remember to make the payment before the scheduled time.".translate(this.props.lang),
                                        successModal: "Payment failed, please make sure to pay before your scheduled visit.".translate(this.props.lang),
                                        payNowOrLater: false,
                                        successLink: '/my-data/personal-referrals/my-payments'
                                    })
                                }
                                // this.setState({ successModal: true, payNowModal: null })

                            }}
                            regions={this.state.regions}
                            dropIn={this.state.isDropIn ? true : false}
                            groupPaymentData={{
                                agentId: this.state.clinicGroup?.agentId,
                                apiKey: this.state.clinicGroup?.apiKey,
                                bankAccount: this.state.clinicGroup?.bankAccount,

                                stripeAccountID: this.state.clinicGroup?.stripeAccountID,

                                canUsePaysonForPayment: this.state.clinicGroup?.canUsePaysonForPayment,
                                canUseSwishForPayment: this.state.clinicGroup?.canUseSwishForPayment,
                                canUseStripeForPayment: this.state.clinicGroup?.canUseStripeForPayment
                            }}
                            loadingUpdatePaymentMethod={this.state.loadingUpdatePaymentMethod}
                            updatePaymentMethod={(method) => {
                                if (this.state.loadingUpdatePaymentMethod) return;
                                let choosenPayment = this.state.choosenPayment;
                                choosenPayment = method.paymentMethod;
                                this.setState({
                                    choosenPayment,
                                    onlinePaymentMethod: 7,
                                    loadingUpdatePaymentMethod: true

                                }, () => {
                                    this.updateReferral()
                                })
                            }}
                            updatePaymentMethodFreeCard={(data) => this.updatePaymentMethodFreeCard(data)}
                            openPayson={(data) => {
                                let choosenPayment = this.state.choosenPayment;
                                choosenPayment = 3;
                                this.setState({
                                    choosenPayment,
                                    onlinePaymentMethod: 7,
                                    payson: data,
                                    swish: null,
                                    stripe: null
                                }, () => {
                                    this.updateReferral()
                                })

                            }}
                            openSwish={(data) => {
                                let choosenPayment = this.state.choosenPayment;
                                choosenPayment = 3;
                                this.setState({
                                    choosenPayment,
                                    onlinePaymentMethod: 7,
                                    swish: data,
                                    payson: null,
                                    stripe: null
                                }, () => {
                                    this.updateReferral()
                                })

                            }}
                            openStripe={(data) => {
                                let choosenPayment = this.state.choosenPayment;
                                choosenPayment = 3;
                                this.setState({
                                    choosenPayment,
                                    onlinePaymentMethod: 7,
                                    stripe: data,
                                    payson: null,
                                    swish: null
                                }, () => {
                                    this.updateReferral()
                                })

                            }}
                        >
                        </PayNowModal>

                        : null
                }

                {this.state.cardModal ?
                    <Modal isOpen={this.state.cardModal} centered>
                        <ModalHeader style={{ margin: 'auto' }}>{'Pick method of payment:'.translate(this.props.lang)}</ModalHeader>

                        <ModalBody className="delete-modal">
                            <Isvg src={Card} />
                        </ModalBody>
                        <ModalFooter className='buttons-right-blue'>
                            <div className="payment-container">
                                {this.state.clinicGroup.agentId && this.state.clinicGroup.apiKey ?
                                    <Button className="payment-button-payson" onClick={() => this.setState({ paysonModal: this.state.cardModal, cardModal: !this.state.cardModal })}><Isvg src={PaysonSvg} /> <span>{'Credit card'.translate(this.props.lang)}</span> </Button>
                                    : null}
                                {this.state.clinicGroup.bankAccount ?
                                    <Button className="payment-button-swish" onClick={() => this.setState({ swishModal: this.state.cardModal, cardModal: !this.state.cardModal })}><Isvg src={SwishSvg} /></Button>
                                    : null}
                            </div>
                        </ModalFooter>
                    </Modal>
                    : null}


                {
                    this.state.freeOfChargeModal ?
                        <Modal isOpen={this.state.freeOfChargeModal} centered >
                            {/* <ModalHeader style={{ margin: 'auto' }}>{'Free of charge'.translate(this.props.lang)}</ModalHeader> */}
                            <ModalBody className='delete-modal'>
                                {/* <Isvg src={info} /> */}
                                <p className='message'>{`This visit is free of charge.`.translate(this.props.lang)}</p>

                            </ModalBody>
                            <ModalFooter className='buttons-right-blue'>
                                <Button color="primary" style={{ margin: 10, minWidth: 100 }} onClick={() => this.setState({ freeOfChargeModal: false }, () => this.sendReferral())}>{'Ok'.translate(this.props.lang)}</Button>

                            </ModalFooter>
                        </Modal>
                        :
                        null
                }

                {this.state.cancelCostModal ?
                    <InfoModal
                        isOpen={this.state.cancelCostModal}
                        toggle={() => this.setState({ cancelCostModal: !this.state.cancelCostModal })}
                        header={'Warning'.translate(this.props.lang)}
                        info={`If you cancel, your case will be deleted. Are you sure you want to continue?`.translate(this.props.lang)}
                        buttons={[
                            <Button color="primary" style={{ margin: 10 }} onClick={() => this.setState({ cancelCostModal: false }, () => this.deleteReferral())}>{'Ok'.translate(this.props.lang)}</Button>,
                            <Button color="primary" style={{ margin: 10 }} onClick={() => this.setState({ cancelCostModal: false })}>{'Cancel'.translate(this.props.lang)}</Button>

                        ]}

                    />
                    : null}

                {this.state.costModal ?
                    <InfoModal
                        isOpen={this.state.costModal}
                        onClose={(item) => {
                            this.setState({
                                costModal: item
                            })
                        }}
                        close={true}
                        toggle={() => this.setState({ constModal: !this.state.costModal })}
                        header={this.state.questionary.paymentOnSite && this.state.choosenPayment == 1 ? 'Payment on site'.translate(this.props.lang) : 'Invoice'.translate(this.props.lang)}
                        info={this.state.questionary.paymentOnSite && this.state.choosenPayment == 1 ?
                            <p>{`This visit costs`.translate(this.props.lang) + ' ' + this.state.questionary.price + ' ' + currency + " " + `and payment is made directly when visiting us.`.translate(this.props.lang)}</p>
                            : this.state.questionary.invoice && this.state.choosenPayment == 2 ?
                                <div dangerouslySetInnerHTML={{ __html: this.state.referral.invoiceMessage }} /> : null
                        }
                        buttons={[
                            <Button color="primary" style={{ margin: 10 }} onClick={() => this.setState({ costModal: false }, () => this.sendReferral())}>{'Ok'.translate(this.props.lang)}</Button>,
                            <Button color="primary" style={{ margin: 10 }} onClick={() => this.setState({ costModal: false })}>{'Cancel'.translate(this.props.lang)}</Button>


                        ]}
                    />
                    : null}
                {this.state.paysonModal ?

                    <Payson
                        lang={this.props.lang}
                        openModal={(item) => this.setState({
                            paysonModal: item
                        }, () => {
                            if (this.state.referral && this.state.referral.paymentBeforeSubmission) {
                                this.setState({ payNowModal: this.state.referral })
                            } else {
                                this.setState({
                                    successModal: "You have successfully scheduled a visit. Remember to make the payment before the scheduled time.".translate(this.props.lang),
                                    successLink: '/my-data/personal-referrals/my-payments'
                                })
                            }

                        }
                        )}
                        referral={this.state.createdReferral}
                        price={this.state.questionary.price}
                        patient={this.props.uData._id}
                        successMessageNeeded={true}

                    />

                    : null}
                {this.state.stripeModal ?

                    <Stripe
                        {...this.props}
                        lang={this.props.lang}
                        openModal={(item) => this.setState({
                            stripeModal: item
                        }, () => {
                            if (this.state.referral && this.state.referral.paymentBeforeSubmission) {
                                this.setState({ payNowModal: this.state.referral })
                            } else {
                                this.setState({
                                    successModal: "You have successfully scheduled a visit. Remember to make the payment before the scheduled time.".translate(this.props.lang),
                                    successLink: '/my-data/personal-referrals/my-payments'
                                })

                            }
                        }
                        )}
                        referral={this.state.createdReferral}
                        price={this.state.questionary.price}
                        patient={this.props.uData._id}
                        successMessageNeeded={true}

                    />

                    : null}
                {this.state.swishModal ?

                    <Swish
                        getStringDateInverseTs={this.props.getStringDateInverseTs}
                        clearStatusBefore={() => this.props.clearStatusBefore()}
                        verifyUser={() => this.props.verifyUser(null, null, true)}
                        lang={this.props.lang}
                        history={this.props[0]}
                        open={this.state.swishModal}
                        phone={this.props.uData?.phone}
                        price={this.state.questionary.price}
                        referralNumber={this.state.newReferral && this.state.newReferral.patient && this.state.newReferral.patient.referralNumber}
                        referral={this.state.createdReferral}
                        openModal={(item) => this.setState({
                            swishModal: item
                        }, () => {
                            if (this.state.referral && this.state.referral.paymentBeforeSubmission) {
                                this.setState({ payNowModal: this.state.referral })
                            } else {
                                this.setState({
                                    successModal: "You have successfully scheduled a visit. Remember to make the payment before the scheduled time.".translate(this.props.lang),
                                    successLink: '/my-data/personal-referrals/my-payments'
                                })

                            }
                        })}
                        callbackFunction={() => {
                            if (this.state.referral?.paymentBeforeSubmission) {
                                if (this.props.patientCreatedReferral) this.props.patientCreatedReferral();
                            }
                            this.setState({
                                successModal: "Payment successful.".translate(this.props.lang),
                                successLink: '/my-data/personal-referrals/referrals'
                            })
                        }}
                    />

                    : null}
                {this.props.changeLinkModal ?
                    <DeleteModal
                        lang={this.props.lang}
                        isOpen={this.props.changeLinkModal}
                        header={'Close case'.translate(this.props.lang)}
                        toggle={() => {
                            this.props.abortAction()
                            if (!this.props.groupSettingsModal) {
                                this.props.groupSettingsChanged()
                            }
                        }}
                        handler={() => {

                            if (this.state.createdReferral) {
                                this.deleteReferral(true)
                            } else {
                                if (this.props.changeLinkModal) {
                                    this.props[0].history.push(this.props.changeLinkModal)
                                } else {
                                    this.props[0].history.push(`/${this.props[0].match.params.alias}`)
                                }
                                // this.props[0].history.push(`/${this.props[0].match.params.alias}`)

                            }
                            // this.props.ring112Audio(false)
                        }}
                    >
                        {'Are you sure you want to close the case? It has not been completed and will not be saved.'.translate(this.props.lang)}
                    </DeleteModal>
                    : null}

                {
                    this.state.twoFactorAuthForbidden ? <Modal
                        zIndex={99999}
                        size='md'
                        centered
                        isOpen={this.state.twoFactorAuthForbidden}
                    >
                        <ModalBody style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: 20 }}>
                            <p style={{ textAlign: 'center', margin: 0, padding: 0, }}>{typeof this.state.twoFactorAuthForbidden === 'string' ? this.state.twoFactorAuthForbidden.translate(this.props.lang) : 'This clinic does not accept cases from patients who use two factor authentication, if their account is not verified with them.'.translate(this.props.lang)}</p>
                        </ModalBody>
                        <ModalFooter style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <Button onClick={this.navigateBackToProfile} style={{ minWidth: 100 }}>
                                {'Ok'.translate(this.props.lang)}
                            </Button>
                        </ModalFooter>
                    </Modal> : null
                }


            </div>
        )



    }
}

const selector = formValueSelector('modalForm');

export default connect(state => {
    return {
        group: selector(state, 'group'),
        type: selector(state, 'type'),
        displayOnReferral: selector(state, 'displayOnReferral'),
        conditionQuestion: selector(state, 'conditionQuestion')

    }

}, {
    changeSubgroup: value => change("modalForm", "subgroup", value),

})(Page(WebBookingReferral));
